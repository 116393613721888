// src/contexts/GroupContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import { firestore } from '../firebase';
import {
  collection,
  getDocs,
  orderBy,
  query,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { useQuery } from '@tanstack/react-query';
import { AppSettingsContext } from './AppSettingsContext';
import { useParams } from 'react-router-dom';

export const GroupContext = createContext();

export const GroupProvider = ({ children }) => {
  const { organizationData } = useContext(AppSettingsContext);
  const [currentGroup, setCurrentGroup] = useState(null);
  const { groupId } = useParams();

  /**
   * (Existing) Helper to fetch *all* groups from Firestore,
   * optionally filtering where active = true, etc.
   */
  const fetchGroups = async (activeOnly = false) => {
    if (!organizationData?.organizationId) return [];
    const groupsRef = collection(firestore, 'groups');
    const q = query(groupsRef, orderBy('groupName', 'asc'));
    const snapshot = await getDocs(q);

    let fetchedData = snapshot.docs.map((docSnap) => ({
      id: docSnap.id,
      ...docSnap.data(),
    }));

    fetchedData = fetchedData.filter(
      (g) => g.organizationId?.id === organizationData.organizationId
    );

    if (activeOnly) {
      fetchedData = fetchedData.filter((g) => g.active === true);
    }

    return fetchedData;
  };

  // (Existing) Query to fetch ALL groups for the org
  const {
    data: groupsData,
    isLoading: groupsLoading,
    error: groupsError,
    refetch: refetchGroups,
  } = useQuery({
    queryKey: ['groups', organizationData?.organizationId],
    queryFn: () => fetchGroups(false),
    enabled: !!firestore && !!organizationData?.organizationId,
    onError: (err) => {
      console.error('Error fetching groups:', err);
    },
  });

  // (Existing) Query to fetch ONLY active groups
  const {
    data: activeGroupsData,
    isLoading: activeGroupsLoading,
    error: activeGroupsError,
    refetch: refetchActiveGroups,
  } = useQuery({
    queryKey: ['activeGroups', organizationData?.organizationId],
    queryFn: () => fetchGroups(true),
    enabled: !!firestore && !!organizationData?.organizationId,
    onError: (err) => {
      console.error('Error fetching active groups:', err);
    },
  });

  // (Existing) Function to update a group doc
  const updateGroup = async (groupId, updates) => {
    const groupDocRef = doc(firestore, 'groups', groupId);
    await updateDoc(groupDocRef, updates);
  };

  /**
   * (New) Fetch multiple groups by their IDs.
   */
  const fetchGroupsByIds = async (groupIds) => {
    console.log(groupIds);
    if (!groupIds || groupIds.length === 0) return {};

    const grpDataObj = {};
    for (const gid of groupIds) {
      const grpRef = doc(firestore, 'groups', gid);
      const grpSnap = await getDoc(grpRef);
      grpDataObj[gid] = grpSnap.exists()
        ? { id:gid, ...grpSnap.data()}
        : { groupName: 'Unknown Group', color: '#ccc' };
    }
    return grpDataObj;
  };

  useEffect(() => {
    if (groupsLoading) return;

    if (groupsData && groupsData.length > 0) {
      if (groupId) {
        const foundGroup = groupsData.find((g) => g.id === groupId);
        setCurrentGroup(foundGroup || null);
      } else {
        setCurrentGroup(groupsData[0] || null);
      }
    } else {
      setCurrentGroup(null);
    }
  }, [groupsData, groupsLoading, groupId]);

  return (
    <GroupContext.Provider
      value={{
        // Existing data
        groupsData,
        groupsLoading,
        groupsError,
        refetchGroups,

        activeGroupsData,
        activeGroupsLoading,
        activeGroupsError,
        refetchActiveGroups,

        currentGroup,
        setCurrentGroup,
        updateGroup,

        // New function
        fetchGroupsByIds,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};

export default GroupProvider;
