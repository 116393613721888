import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Contexts
import { DataContext } from '../../DataContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';

// Components
import AuthenticationContainer from './AuthenticationContainer';
import CustomModal from '../Common/CustomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Icon Map
import IconMap from '../Common/IconMap';

// Assets
import CatholicoreLogo from '../../assets/CatholicoreLogo.png';

// Styles
import './PasswordRecovery.css';

const PasswordRecovery = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const { groupId } = useParams();

  // Access DataContext methods and values
  const { appSettings, appSettingsLoading, sendPasswordResetEmail } =
    useContext(DataContext);

  // Access loading states
  const { loading: languageLoading } = useLanguageSettings();
  const { loading: colorLoading } = useColorSettings();

  // Combined loading
  const isLoading = colorLoading || languageLoading || appSettingsLoading;

  // For simplicity, we set these to false/'' since you don’t have a “global error” here
  const globalError = false;
  const globalErrorMessage = '';

  /**
   * SUBMIT HANDLER:
   * 1. Show modal immediately with a friendly message.
   * 2. Fire off the actual password reset call in the background.
   */
  const handleSubmit = (event) => {
    event.preventDefault();

    // Immediately show success message & modal
    setMessage(t('passwordRecovery.successMessage'));
    setShowModal(true);

    // Fire off the reset in background
    const catholicoreLogo =
      'https://catholicore.com/static/media/CatholicoreLogoWhite.2b64249d4eacac07ba63.png';
    sendPasswordResetEmail(email, catholicoreLogo, '#bb8300').catch((error) => {
      console.error('Error sending email:', error);
    });
  };

  // Close the modal and navigate back to login
  const handleCloseModal = () => {
    setShowModal(false);
    if (groupId) {
      navigate(`/${groupId}/login`);
    } else {
      navigate('/login');
    }
  };

  const goToLogin = () => {
    if (groupId) {
      navigate(`/${groupId}/login`);
    } else {
      navigate('/login');
    }
  };

  // ---------------- LEFT PANEL CONTENT ----------------
  const leftPanelContent = (
    <>
      <h2 className="sign-in-title">{t('passwordRecovery.title')}</h2>

      <form onSubmit={handleSubmit} className="password-recovery-form">
        <div className="password-recovery-input-group">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('passwordRecovery.emailPlaceholder')}
            required
            aria-label={t('passwordRecovery.emailLabel')}
          />
        </div>

        <button type="submit" className="password-recovery-submit-button">
          {t('passwordRecovery.resetButton')}
        </button>

        <div className="password-recovery-links-container">
          <button type="button" onClick={goToLogin} className="link-button">
            {t('passwordRecovery.backToLogin')}
          </button>
        </div>
      </form>

      {/* The modal is inside the same panel structure */}
      <CustomModal
        show={showModal}
        onClose={handleCloseModal}
        title={t('passwordRecovery.modalTitle')}
      >
        <div className="enhanced-password-recovery-modal">
          <FontAwesomeIcon
            icon={IconMap.faCheckCircle}
            className="enhanced-modal-icon-fa"
          />
          <p className="password-recovery-message">{message}</p>
        </div>
      </CustomModal>
    </>
  );

  return (
    <AuthenticationContainer
      isLoading={isLoading}
      error={globalError}
      errorMessage={globalErrorMessage}
      appSettings={appSettings}
      leftPanelComponent={leftPanelContent}
      // Optionally override the right panel or leave as default (BibleVerse)
    />
  );
};

export default PasswordRecovery;
