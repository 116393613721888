import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Contexts
import { DataContext } from '../../DataContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';

// Components
import AuthenticationContainer from './AuthenticationContainer';
import CustomModal from '../Common/CustomModal';

// Styles
import './ResetPassword.css';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  // Access appSettings and authentication functions from DataContext
  const {
    appSettings,
    appSettingsLoading,
    verifyPasswordResetCode,
    confirmPasswordReset,
  } = useContext(DataContext);

  // Access loading states
  const { loading: languageLoading } = useLanguageSettings();
  const { loading: colorLoading } = useColorSettings();

  // Combine loading states
  const isLoading = colorLoading || languageLoading || appSettingsLoading;

  // We won’t use global errors in the same sense as in Login
  const globalError = false;
  const globalErrorMessage = '';

  // Validate the reset code on mount
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('oobCode');

    if (code) {
      verifyPasswordResetCode(code)
        .then(() => setIsCodeValid(true))
        .catch(() => {
          setError(t('resetPasswordPage.invalidLink'));
          setIsCodeValid(false);
        });
    } else {
      setError(t('resetPasswordPage.missingCode'));
      setIsCodeValid(false);
    }
  }, [t, verifyPasswordResetCode]);

  // Handle navigation to login
  const goToLogin = () => {
    navigate('/login');
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setMessage('');
    setShowModal(false);

    // Basic check
    if (newPassword !== confirmPassword) {
      setError(t('resetPasswordPage.passwordMismatch'));
      return;
    }

    try {
      const params = new URLSearchParams(window.location.search);
      const oobCode = params.get('oobCode');
      await confirmPasswordReset(oobCode, newPassword);

      setMessage(t('resetPasswordPage.successMessage'));
      setShowModal(true);
    } catch (err) {
      setError(t('resetPasswordPage.error', { error: err.message }));
    }
  };

  // Close modal
  const handleCloseModal = () => {
    setShowModal(false);
    // After successful reset, you might redirect to login or a homepage
    navigate('/');
  };

  // ---------------- LEFT PANEL CONTENT ----------------
  const leftPanelContent = (
    <>
      {/* If the link is invalid, we show the error + "Back to login". 
          Otherwise, we show the reset form. */}
      {!isCodeValid ? (
        <>
          <h2 className="sign-in-title">{t('resetPasswordPage.title')}</h2>
          {error && <h4 className="reset-password-error">{error}</h4>}

          <button type="button" onClick={goToLogin} className="link-button">
            {t('resetPasswordPage.backToLogin')}
          </button>
        </>
      ) : (
        <>
          <h2 className="sign-in-title">{t('resetPasswordPage.title')}</h2>
          {error && <h4 className="reset-password-error">{error}</h4>}

          <form onSubmit={handleSubmit} className="reset-password-form">
            <div className="reset-password-input-group">
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder={t('resetPasswordPage.newPasswordPlaceholder')}
                required
                aria-label={t('resetPasswordPage.newPasswordLabel')}
              />
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder={t('resetPasswordPage.confirmPasswordPlaceholder')}
                required
                aria-label={t('resetPasswordPage.confirmPasswordLabel')}
              />
            </div>

            <button type="submit" className="reset-password-submit-button">
              {t('resetPasswordPage.updatePassword')}
            </button>
          </form>
        </>
      )}

      {/* Modal for success message */}
      <CustomModal
        show={showModal}
        onClose={handleCloseModal}
        title={t('resetPasswordPage.modalTitle')}
      >
        <p className="reset-password-message">{message}</p>
      </CustomModal>
    </>
  );

  return (
    <AuthenticationContainer
      isLoading={isLoading}
      error={globalError}
      errorMessage={globalErrorMessage}
      appSettings={appSettings}
      leftPanelComponent={leftPanelContent}
    />
  );
};

export default ResetPassword;
