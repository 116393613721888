// src/components/Policies/PrivacyPolicy.jsx

import React from 'react';
import './PrivacyPolicy.css'; // Updated CSS file for styling
import CatholicoreLogo from '../../assets/CatholicoreLogo.png';

const PrivacyPolicy = () => {
  return (
    <div className='privacy-policy-background'>
      <div className="privacy-policy-container">
        <header className="privacy-policy-header">
          <img className="privacy-policy-logo" src={CatholicoreLogo} alt="Catholicore Logo" />
          <h1>Privacy Policy</h1>
          <h2>Catholicore</h2>
        </header>

        <div className="privacy-policy-content">
          <div className="policy-dates">
            <p><strong>Effective Date:</strong> December 26th, 2024</p>
            {/* Updated the last updated date to reflect new changes */}
            <p><strong>Last Updated:</strong> January 18th, 2025</p>
          </div>

          <article>
            <h3>Introduction</h3>
            <p>
              Welcome to Catholicore, a dedicated platform for Catholic parishes and religious groups
              to manage their members efficiently. We are committed to protecting your privacy and ensuring
              a positive experience on our platform. This Privacy Policy outlines how we collect, use,
              disclose, and safeguard your information when you use our services.
            </p>
          </article>

          <article>
            <h3>Information We Collect</h3>
            <ul>
              <li>
                <strong>Personal Information:</strong> When you register with Catholicore, we collect information
                that personally identifies you, such as your name, email address, phone number, and any other
                information you provide.
              </li>
              <li>
                <strong>Group Communications:</strong> We collect information related to your participation in
                group communications, including messages, event participation, and other interactions within
                your parish or group.
              </li>
              <li>
                <strong>Usage and Device Information:</strong> We automatically collect information about how
                you access and use Catholicore, including your IP address, browser type, device information,
                and usage patterns.
              </li>
              <li>
                <strong>Billing Information:</strong> To process payments for our services, we collect billing
                details such as credit card information and billing addresses.
              </li>
              {/* Removed donation/charity verification bullet */}
            </ul>
          </article>

          <article>
            <h3>How We Use Your Information</h3>
            <ul>
              <li>
                <strong>To Provide Services:</strong> We use your information to manage your account, facilitate
                communications, organize and display events on the calendar, customize your subdomain,
                and provide other functionalities of Catholicore.
              </li>
              <li>
                <strong>To Improve Our Services:</strong> We analyze usage data to understand how our services
                are utilized and to enhance the functionality, user experience, and quality of Catholicore.
              </li>
              <li>
                <strong>To Communicate:</strong> We may use your information to send important notices, updates,
                promotional materials, and other communications related to our services.
              </li>
              <li>
                <strong>Billing and Payments:</strong> We use your billing information to process payments
                for our subscription services and to manage your account's billing history.
              </li>
            </ul>
          </article>

          {/* Removed "Nonprofit Verification Data" section */}

          {/* Removed "Donations and Payment Processing" section */}

          <article>
            <h3>Sharing of Your Information</h3>
            <p>
              We do not sell or rent your personal information to third parties. However, we may share your
              information in the following circumstances:
            </p>
            <ul>
              <li>
                <strong>Service Providers:</strong> We may share your information with third-party
                service providers who perform services on our behalf, such as payment processing,
                data analysis, email delivery, and customer service.
              </li>
              {/* Removed bullet about nonprofit verification and compliance */}
              <li>
                <strong>Legal Requirements:</strong> We may disclose your information if required by law,
                regulation, or legal process, or if we believe in good faith that such action is necessary
                to comply with legal obligations.
              </li>
              <li>
                <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all
                or a portion of our assets, your information may be transferred as part of that transaction.
              </li>
            </ul>
          </article>

          <article>
            <h3>Data Security</h3>
            <p>
              We implement appropriate technical and organizational measures to protect your personal
              information against unauthorized access, alteration, disclosure, or destruction. However,
              no method of transmission over the internet or electronic storage is 100% secure, and we cannot
              guarantee absolute security.
            </p>
          </article>

          <article>
            <h3>Your Rights</h3>
            <p>
              You have the right to access, correct, or delete your personal information. You can also
              object to or restrict the processing of your information. To exercise these rights,
              please contact us at <a href="mailto:info@catholicore.com">info@catholicore.com</a>.
            </p>
          </article>

          <article>
            <h3>Children's Privacy</h3>
            <p>
              Catholicore is not intended for use by individuals under the age of 13. We do not knowingly
              collect personal information from children under 13. If we become aware that we have inadvertently
              collected such information, we will take steps to delete it.
            </p>
          </article>

          <article>
            <h3>Changes to This Privacy Policy</h3>
            <p>
              We may update this Privacy Policy from time to time. The most current version will govern our
              processing of your personal data and will always be available on our website. We encourage
              you to review this policy periodically.
            </p>
          </article>

          <article>
            <h3>Contact Us</h3>
            <p>
              If you have any questions or concerns about our privacy practices, please contact us at
              <a href="mailto:info@catholicore.com"> info@catholicore.com</a>.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
