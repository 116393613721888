// src/contexts/TeamContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { firestore } from '../firebase';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { AppSettingsContext } from './AppSettingsContext';

export const TeamContext = createContext();

export const TeamProvider = ({ children }) => {
  const { organizationData } = useContext(AppSettingsContext) || {};
  // Safely grab groupId (could be undefined if userData not ready)
  const groupId = organizationData?.groupId;

  const [teams, setTeams] = useState([]);
  const [teamTypes, setTeamTypes] = useState([]);
  const [isTeamsLoading, setIsTeamsLoading] = useState(false);
  const [isTeamsError, setIsTeamsError] = useState(null);

  /**
   * Fetch all "teamTypes" for the current group
   */
  const fetchTeamTypes = async () => {
    if (!groupId) {
      // If groupId is missing, skip or return
      return;
    }
    try {
      const teamTypesCollectionRef = collection(firestore, 'teamGroups', groupId, 'teamTypes');
      const snapshot = await getDocs(teamTypesCollectionRef);

      let typesList = snapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));

      // Sort by 'order' field
      typesList.sort((a, b) => {
        const orderA = typeof a.order === 'number' ? a.order : 999999;
        const orderB = typeof b.order === 'number' ? b.order : 999999;
        return orderA - orderB;
      });

      setTeamTypes(typesList);
    } catch (error) {
      console.error('Error fetching team types:', error);
    }
  };

  /**
   * Fetch all teams for the current group
   */
  const fetchTeams = async () => {
    if (!groupId) {
      // If groupId is missing, skip or return
      return;
    }

    setIsTeamsLoading(true);
    setIsTeamsError(null);

    try {
      const teamsCollectionRef = collection(firestore, 'teamGroups', groupId, 'teams');
      const teamsSnapshot = await getDocs(teamsCollectionRef);

      const teamsList = teamsSnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));

      // For each team, fetch member data
      const teamsWithMemberData = await Promise.all(
        teamsList.map(async (team) => {
          const memberObjs = team.members || [];
          const membersData = await Promise.all(
            memberObjs.map(async (m) => {
              // Defensive check
              if (!m?.ref) {
                console.warn(`Skipping member in team "${team.id}" - no valid "m.ref":`, m);
                return null;
              }

              const memberSnapshot = await getDoc(m.ref);
              if (memberSnapshot.exists()) {
                const memberData = memberSnapshot.data();
                memberData.id = m.ref.id;

                // Attempt to parse groupId from the reference path if needed
                const pathParts = m.ref.path.split('/');
                const memberGroupId = pathParts[1];
                memberData.groupId = memberGroupId;

                // Flatten any .fields array
                if (Array.isArray(memberData.fields)) {
                  memberData.fields.forEach((field) => {
                    memberData[field.id] = field.value;
                  });
                }

                // Mark leader if specified
                memberData.leader = m.leader === true;
                return memberData;
              }
              return null;
            })
          );

          // Filter out any null members
          const filteredMembers = membersData.filter(Boolean);

          // Sort by leader (leaders first), then name
          filteredMembers.sort((a, b) => {
            if (a.leader && !b.leader) return -1;
            if (!a.leader && b.leader) return 1;

            const nameA = (a.firstName + ' ' + a.lastName).toLowerCase();
            const nameB = (b.firstName + ' ' + b.lastName).toLowerCase();
            return nameA.localeCompare(nameB);
          });

          return {
            ...team,
            membersData: filteredMembers,
          };
        })
      );

      setTeams(teamsWithMemberData);
    } catch (error) {
      console.error('Error fetching teams:', error);
      setIsTeamsError(error);
    } finally {
      setIsTeamsLoading(false);
    }
  };

  /**
   * Add a new team type
   */
  const addTeamType = async ({ name, icon }) => {
    if (!groupId) {
      throw new Error('Cannot add team type: groupId is missing in organizationData.');
    }
    if (!name.trim()) {
      throw new Error('Team Type name is required');
    }
    try {
      // Determine the next order value
      let maxOrder = 0;
      teamTypes.forEach((type) => {
        if (typeof type.order === 'number' && type.order > maxOrder) {
          maxOrder = type.order;
        }
      });
      const newOrder = maxOrder + 1;

      const teamTypesCollectionRef = collection(firestore, 'teamGroups', groupId, 'teamTypes');
      await addDoc(teamTypesCollectionRef, {
        name: name.trim(),
        icon: icon || '',
        order: newOrder,
        createdAt: serverTimestamp(),
      });

      // Re-fetch team types to refresh the list
      await fetchTeamTypes();
    } catch (error) {
      console.error('Error adding team type:', error);
      throw error;
    }
  };

  /**
   * Delete a team type by ID
   */
  const deleteTeamType = async (typeId) => {
    if (!groupId) {
      throw new Error('Cannot delete team type: groupId is missing.');
    }
    if (!typeId) {
      throw new Error('Team Type ID is required.');
    }
    try {
      const teamTypeDocRef = doc(firestore, 'teamGroups', groupId, 'teamTypes', typeId);
      await deleteDoc(teamTypeDocRef);
      await fetchTeamTypes();
    } catch (error) {
      console.error('Error deleting team type:', error);
      throw error;
    }
  };

  /**
   * Edit a team type by ID
   */
  const editTeamType = async (typeId, updatedFields) => {
    if (!groupId) {
      throw new Error('Cannot edit team type: groupId is missing.');
    }
    if (!typeId) {
      throw new Error('Team Type ID is required.');
    }
    try {
      const teamTypeDocRef = doc(firestore, 'teamGroups', groupId, 'teamTypes', typeId);
      await updateDoc(teamTypeDocRef, { ...updatedFields });
      await fetchTeamTypes();
    } catch (error) {
      console.error('Error editing team type:', error);
      throw error;
    }
  };

  /**
   * Add a new team
   */
  const addTeam = async ({ name, members, leaders = [], teamTypeId }) => {
    if (!groupId) {
      throw new Error('Cannot add team: groupId is missing.');
    }
    if (!name) {
      throw new Error('Team name is required.');
    }
    try {
      const teamsCollectionRef = collection(firestore, 'teamGroups', groupId, 'teams');

      // Build member objects
      const memberObjs = (members || []).map((memberKey) => {
        const [memberGroupId, memberId] = (memberKey || '').split('_');
        if (!memberGroupId || !memberId) {
          throw new Error(`Invalid member key: "${memberKey}"`);
        }
        const memberRef = doc(
          firestore,
          'formGroups',
          memberGroupId,
          'forms',
          'membersForm',
          'responses',
          memberId
        );
        return {
          ref: memberRef,
          leader: leaders.includes(memberKey),
        };
      });

      const newTeam = {
        name,
        members: memberObjs,
        teamTypeId: teamTypeId || null,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      await addDoc(teamsCollectionRef, newTeam);
      await fetchTeams();
    } catch (error) {
      console.error('Error adding team:', error);
      throw error;
    }
  };

  /**
   * Edit an existing team
   */
  const editTeam = async (teamId, { name, members, leaders = [], teamTypeId }) => {
    if (!groupId) {
      throw new Error('Cannot edit team: groupId is missing.');
    }
    if (!teamId || !name) {
      throw new Error('Team ID and name are required.');
    }
    try {
      const teamDocRef = doc(firestore, 'teamGroups', groupId, 'teams', teamId);

      const memberObjs = (members || []).map((memberKey) => {
        const [memberGroupId, memberId] = (memberKey || '').split('_');
        if (!memberGroupId || !memberId) {
          throw new Error(`Invalid member key: "${memberKey}"`);
        }
        const memberRef = doc(
          firestore,
          'formGroups',
          memberGroupId,
          'forms',
          'membersForm',
          'responses',
          memberId
        );
        return {
          ref: memberRef,
          leader: leaders.includes(memberKey),
        };
      });

      await updateDoc(teamDocRef, {
        name,
        members: memberObjs,
        teamTypeId: teamTypeId || null,
        updatedAt: serverTimestamp(),
      });
      await fetchTeams();
    } catch (error) {
      console.error('Error editing team:', error);
      throw error;
    }
  };

  /**
   * Delete a team by ID
   */
  const deleteTeam = async (teamId) => {
    if (!groupId) {
      throw new Error('Cannot delete team: groupId is missing.');
    }
    if (!teamId) {
      throw new Error('Team ID is required.');
    }
    try {
      const teamDocRef = doc(firestore, 'teamGroups', groupId, 'teams', teamId);
      await deleteDoc(teamDocRef);
      await fetchTeams();
    } catch (error) {
      console.error('Error deleting team:', error);
      throw error;
    }
  };

  /**
   * useEffect: Whenever groupId changes (meaning user/organization changed),
   * fetch all teams and team types if groupId is valid.
   */
  useEffect(() => {
    if (groupId) {
      fetchTeams();
      fetchTeamTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  return (
    <TeamContext.Provider
      value={{
        teams,
        teamTypes,
        isTeamsLoading,
        isTeamsError,
        fetchTeams,
        addTeam,
        editTeam,
        deleteTeam,
        fetchTeamTypes,
        addTeamType,
        deleteTeamType,
        editTeamType,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export default TeamProvider;
