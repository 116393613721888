// src/DataContext.js

import React, { createContext, useContext } from 'react';

// Import contexts (excluding UserContext, which is top-level)
import { AppSettingsProvider, AppSettingsContext } from './contexts/AppSettingsContext';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import { CatalogProvider, CatalogContext } from './contexts/CatalogContext';
import { FormProvider, FormContext } from './contexts/FormContext';
import { ResponseProvider, ResponseContext } from './contexts/ResponseContext';
import { TeamProvider, TeamContext } from './contexts/TeamContext';
import { UserManagementProvider, UserManagementContext } from './contexts/UserManagementContext';
import { ValidationProvider, ValidationContext } from './contexts/ValidationContext';
import { OrganizationProvider, OrganizationContext } from './contexts/OrganizationContext';
import { GroupProvider, GroupContext } from './contexts/GroupContext';
import { EventProvider, EventContext } from './contexts/EventContext';
import { MessageProvider, MessageContext } from './contexts/MessageContext';
// Singleton user context
import { UserProvider } from './contexts/UserContext';

// Utilities (optional)
import { getObjectByReference } from './utils/firebaseUtils';
import { compressBase64Image, compressImageFields } from './utils/imageUtils';

// Firebase if needed
import { firestore, auth } from './firebase';

export const DataContext = createContext();

/**
 * DataProvider merges all contexts except UserContext.
 * Accepts `groupID` so AppSettingsProvider can react to it.
 */
export const DataProvider = ({ children, groupID }) => {
  return (
    <UserProvider>
    <AppSettingsProvider groupID={groupID}>
      <AuthProvider>
        <CatalogProvider>
          <FormProvider>
            <ResponseProvider>
              <TeamProvider>
                <UserManagementProvider>
                  <ValidationProvider>
                    <OrganizationProvider>
                      <GroupProvider>
                        <EventProvider>
                          <MessageProvider>
                            <DataContextWrapper>
                              {children}
                            </DataContextWrapper>
                          </MessageProvider>
                        </EventProvider>
                      </GroupProvider>
                    </OrganizationProvider>
                  </ValidationProvider>
                </UserManagementProvider>
              </TeamProvider>
            </ResponseProvider>
          </FormProvider>
        </CatalogProvider>
      </AuthProvider>
    </AppSettingsProvider>
    </UserProvider>
  );
};

const DataContextWrapper = ({ children }) => {
  const appSettingsContext = useContext(AppSettingsContext);
  const authContext = useContext(AuthContext);
  const catalogContext = useContext(CatalogContext);
  const formContext = useContext(FormContext);
  const responseContext = useContext(ResponseContext);
  const teamContext = useContext(TeamContext);
  const userManagementContext = useContext(UserManagementContext);
  const validationContext = useContext(ValidationContext);
  const organizationContext = useContext(OrganizationContext);
  const groupContext = useContext(GroupContext);
  const eventContext = useContext(EventContext);
  const messageContext = useContext(MessageContext);

  // Merge them all
  const combinedContextValue = {
    ...appSettingsContext,
    ...authContext,
    ...catalogContext,
    ...formContext,
    ...responseContext,
    ...teamContext,
    ...userManagementContext,
    ...validationContext,
    ...organizationContext,
    ...groupContext,
    ...eventContext,
    ...messageContext,

    // Utilities
    getObjectByReference,
    compressBase64Image,
    compressImageFields,

    firestore,
    auth,
  };

  return (
    <DataContext.Provider value={combinedContextValue}>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
