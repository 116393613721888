// src/components/Common/Tabs.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './Tabs.css';

/**
 * Extended Tabs that allow a tabImage (icon) and tabColor (for active color)
 */
const Tabs = ({ tabs, selectedTab, onTabSelect }) => {
  return (
    <div className="tabs">
      {tabs.map((tab) => {
        // If tab has a color, we’ll use it only if this tab is active
        const isActive = selectedTab === tab.id;
        const activeStyle = isActive && tab.tabColor
          ? { color: tab.tabColor, borderBottom: `2px solid ${tab.tabColor}` }
          : {};

        return (
          <div
            key={tab.id}
            className={`tab ${isActive ? 'active' : ''}`}
            style={activeStyle}
            onClick={() => onTabSelect(tab.id)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onTabSelect(tab.id);
              }
            }}
          >
            {/* If there's an icon or image, display it */}
            {tab.tabImage && (
              <img
                src={tab.tabImage}
                alt="tab-icon"
                className="tab-icon"
                style={{ marginRight: '8px', width: '20px', height: '20px', objectFit: 'contain' }}
              />
            )}
            {tab.label}
          </div>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  /**
   * An array of tab objects: [
   *   { id: string, label: string, tabImage?: string, tabColor?: string }
   * ]
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
      ]).isRequired,
      tabImage: PropTypes.string,
      tabColor: PropTypes.string,
    })
  ).isRequired,

  /** The currently active tab ID */
  selectedTab: PropTypes.string.isRequired,

  /** Callback when a tab is selected */
  onTabSelect: PropTypes.func.isRequired,
};

export default Tabs;
