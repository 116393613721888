// src/components/ProfilePage/ProfilePage.jsx

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { DataContext } from '../../DataContext';
import { UserContext } from '../../contexts/UserContext';
import CustomModal from '../Common/CustomModal';
import Spinner from '../Common/Spinner';

import TextField from '../FormBuilder/Fields/TextField';
import EmailField from '../FormBuilder/Fields/EmailField';
import ImagePicker from '../FormBuilder/Fields/ImagePicker';
import SelectField from '../FormBuilder/Fields/SelectField';
import ActionButton from '../Common/ActionButton';
import ToastContainer from '../Common/ToastContainer';
import FillOutForm from '../FormBuilder/FillOutForm';

import TwoPanelLayout from '../Common/TwoPanelLayout';  // <-- NEW IMPORT

import UserIcon from '../../assets/UserIcon.png';
import './ProfilePage.css'; // We'll still keep this for styling unique to ProfilePage

const ProfilePage = ({ show, onClose }) => {
  const {
    currentUser,
    userData,
    userRolesLoading,
    userGroups,
    updateUserProfile,
    getMemberFormData,
  } = useContext(UserContext);

  const { getFormFields } = useContext(DataContext);

  // Local profile state (for "Account Details")
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    profilePicture: '',
    language: '',
    timeZone: '',
  });

  // Group selection
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  // Form data for the selected group's member doc
  const [memberFormData, setMemberFormData] = useState({
    formId: null,
    fields: null,
    responseId: null,
  });

  // Loading & error states
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);

  // Toast messages
  const [toasts, setToasts] = useState([]);
  const removeToast = (id) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };
  const showToastMessage = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prev) => [...prev, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  // Load userData into local profile state
  useEffect(() => {
    if (userData) {
      setProfile({
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        email: userData.email || '',
        profilePicture: userData.profilePicture || '',
        language: userData.language || '',
        timeZone: userData.timeZone || '',
      });
    }
  }, [userData]);

  // Handle group click => fetch member form data
  const handleGroupClick = async (grp) => {
    setSelectedGroupId(grp.id);
    setLoading(true);
    setFormError(null);
    setMemberFormData({ formId: null, fields: null, responseId: null });

    const { memberReference } = grp.roleEntry || {};
    if (!memberReference) {
      setLoading(false);
      return;
    }

    try {
      const data = await getMemberFormData(grp.id, memberReference, getFormFields);
      if (!data) {
        setMemberFormData({ formId: null, fields: null, responseId: null });
      } else {
        setMemberFormData(data);
      }
    } catch (err) {
      console.error('Error fetching member form data:', err);
      setFormError('Unable to load form data.');
    } finally {
      setLoading(false);
    }
  };

  // Profile form handlers
  const [submitting, setSubmitting] = useState(false);

  const handleProfileChange = (fieldId, value) => {
    setProfile((prev) => ({ ...prev, [fieldId]: value }));
  };

  // Immediately update Firestore for profile picture
  const handleProfilePictureChange = async (val) => {
    setProfile((prev) => ({ ...prev, profilePicture: val }));
    try {
      await updateUserProfile({ ...profile, profilePicture: val });
      showToastMessage('Profile picture updated!', 'success');
    } catch (error) {
      console.error('Error updating profile picture:', error);
      showToastMessage('Failed to update picture.', 'error');
    }
  };

  // Save all profile details
  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      await updateUserProfile(profile);
      showToastMessage('Profile updated successfully!', 'success');
    } catch (error) {
      console.error('Error updating profile:', error);
      showToastMessage('Failed to update profile.', 'error');
    } finally {
      setSubmitting(false);
    }
  };

  // Reset to "Account Details" view
  const handleAccountClick = () => {
    setSelectedGroupId(null);
    setMemberFormData({ formId: null, fields: null, responseId: null });
    setFormError(null);
  };

  // Early returns
  if (!show) return null;

  if (userRolesLoading) {
    return (
      <CustomModal show={show} onClose={onClose} title="Account Details">
        <div className="profile-loading">
          <Spinner size="50px" />
        </div>
      </CustomModal>
    );
  }

  if (!currentUser) return null;

  // ------------------------------------------------
  // RIGHT PANEL CONTENT
  // ------------------------------------------------
  let rightPanelContent;

  if (!selectedGroupId) {
    // "Account Details" form
    rightPanelContent = (
      <div className="response-card fill-out-form-container">
        <div className="response-details">
          <form className="profile-page-form" onSubmit={handleProfileSubmit}>
            <div className="form-row">
              <div className="half-width">
                <TextField
                  label="First Name"
                  name="firstName"
                  value={profile.firstName}
                  onChange={(val) => handleProfileChange('firstName', val)}
                  required
                />
              </div>
              <div className="half-width">
                <TextField
                  label="Last Name"
                  name="lastName"
                  value={profile.lastName}
                  onChange={(val) => handleProfileChange('lastName', val)}
                  required
                />
              </div>
            </div>

            <EmailField
              label="Email Address"
              name="email"
              value={profile.email}
              onChange={(val) => handleProfileChange('email', val)}
              required
            />

            <div className="form-row">
              <div className="half-width">
                <SelectField
                  label="Language"
                  name="language"
                  value={profile.language}
                  onChange={(val) => handleProfileChange('language', val)}
                  catalogId="languages"
                  placeholder="Select a Language"
                  required
                />
              </div>
              <div className="half-width">
                <SelectField
                  label="Time Zone"
                  name="timeZone"
                  value={profile.timeZone}
                  onChange={(val) => handleProfileChange('timeZone', val)}
                  catalogId="timezones"
                  placeholder="Select a Time Zone"
                  required
                />
              </div>
            </div>

            <div className="profile-actions">
              <ActionButton
                type="submit"
                text="Save"
                icon="faSave"
                colorType="primary"
                disabled={submitting}
              />
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    // A group is selected => show FillOutForm or fallback
    if (loading) {
      rightPanelContent = (
        <div className="no-form-loading">
          <Spinner size="50px" />
          <p>Loading form data...</p>
        </div>
      );
    } else if (formError) {
      rightPanelContent = (
        <div className="no-form-error">
          <FontAwesomeIcon icon={faExclamationCircle} size="2x" color="#ff4d4f" />
          <p>Error loading form for this group: {formError}</p>
        </div>
      );
    } else if (memberFormData.formId && memberFormData.fields) {
      rightPanelContent = (
        <div className="response-card fill-out-form-container">
          <div className="response-details">
            <FillOutForm
              fields={memberFormData.fields}
              formId={memberFormData.formId}
              responseId={memberFormData.responseId}
              showToast={(msg, type) => showToastMessage(msg, type)}
              initialData={{
                id: memberFormData.responseId,
                fields: memberFormData.fields,
              }}
              groupId={selectedGroupId}
            />
          </div>
        </div>
      );
    } else {
      rightPanelContent = (
        <div className="no-form-message">
          <FontAwesomeIcon icon={faExclamationCircle} size="3x" color="#ff4d4f" />
          <p>No Member Form found for this group.</p>
        </div>
      );
    }
  }

  // ------------------------------------------------
  // LEFT PANEL CONTENT
  // ------------------------------------------------
  const leftPanelContent = (
    <>
      <div className="profile-picture-container">
        <ImagePicker
          name="profilePicture"
          value={profile.profilePicture}
          onChange={handleProfilePictureChange}
          imageShape="circular"
          maxFileSize={1024}
          defaultImage={UserIcon}
        />
        <div className="user-name">
          <p>{profile.firstName} {profile.lastName}</p>
        </div>
      </div>

      <div className="groups-section">
        <h4>Profiles</h4>
        <ul className="left-groups-list">
          <li
            className={
              selectedGroupId === null ? 'group-item selected' : 'group-item'
            }
            onClick={handleAccountClick}
          >
            <img
              src={UserIcon}
              alt="account"
              className="group-thumbnail"
              style={{ backgroundColor: 'var(--main-color)' }}
            />
            <span>Account Details</span>
          </li>

          {userGroups?.length === 0 ? (
            <li className="group-item">
              <em>No groups found.</em>
            </li>
          ) : (
            userGroups?.map((grp) => (
              <li
                key={grp.id}
                className={
                  grp.id === selectedGroupId
                    ? 'group-item selected'
                    : 'group-item'
                }
                onClick={() => handleGroupClick(grp)}
              >
                <img
                  src={grp.profilePicture || UserIcon}
                  alt="grp"
                  className="group-thumbnail"
                  style={{
                    backgroundColor: grp.color || 'var(--main-color)',
                  }}
                />
                <span>{grp.groupName || '[Unnamed Group]'}</span>
              </li>
            ))
          )}
        </ul>
      </div>
    </>
  );

  // ------------------------------------------------
  // Final Render
  // ------------------------------------------------
  return (
    <CustomModal
      show={show}
      onClose={onClose}
      title="Account Details"
      closable={!submitting}
    >
      <TwoPanelLayout 
        leftPanel={leftPanelContent}
        rightPanel={rightPanelContent}
      />

      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </CustomModal>
  );
};

ProfilePage.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProfilePage;
