import React, { useContext, useState, useEffect } from 'react';
import './TeamTypesModal.css';
import CustomModal from '../Common/CustomModal';
import { TeamContext } from '../../contexts/TeamContext';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import ActionButton from '../Common/ActionButton';
import IconMapSelector from '../Common/IconMapSelector';
import { useTranslation } from 'react-i18next';

const TeamTypesModal = ({ show, onClose, showToast }) => {
  const { t } = useTranslation();  // <-- ADD THIS
  
  const {
    teamTypes,
    addTeamType,
    deleteTeamType,
    editTeamType,
    fetchTeamTypes,
  } = useContext(TeamContext);

  const [localTeamTypes, setLocalTeamTypes] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState(null);

  useEffect(() => {
    if (show) {
      setLocalTeamTypes([...teamTypes]);
    }
  }, [show, teamTypes]);

  // ADD NEW TYPE (just add a blank row)
  const handleAddNewType = () => {
    let maxOrder = 0;
    localTeamTypes.forEach((t) => {
      if (typeof t.order === 'number' && t.order > maxOrder) {
        maxOrder = t.order;
      }
    });

    const newType = {
      id: `temp-${Date.now()}`,
      name: '',
      icon: '',
      order: maxOrder + 1,
      isNew: true,
    };

    setLocalTeamTypes((prev) => [...prev, newType]);
  };

  // DELETE (local)
  const handleDeleteType = (type) => {
    setTypeToDelete(type);
    setDeleteModalOpen(true);
  };

  const confirmDeleteType = () => {
    if (!typeToDelete) return;
    if (typeToDelete.isNew) {
      // If it's newly added and not saved yet, just remove it locally
      setLocalTeamTypes((prev) => prev.filter((t) => t.id !== typeToDelete.id));
    } else {
      // Mark as deleted
      setLocalTeamTypes((prev) =>
        prev.map((t) =>
          t.id === typeToDelete.id ? { ...t, isDeleted: true } : t
        )
      );
    }

    setDeleteModalOpen(false);
    setTypeToDelete(null);
  };

  // EDIT name/icon (local)
  const handleNameChange = (typeId, newName) => {
    setLocalTeamTypes((prev) =>
      prev.map((t) => (t.id === typeId ? { ...t, name: newName } : t))
    );
  };

  const handleIconChange = (typeId, newIcon) => {
    setLocalTeamTypes((prev) =>
      prev.map((t) => (t.id === typeId ? { ...t, icon: newIcon } : t))
    );
  };

  // REORDER (local)
  const handleMoveUp = (type) => {
    const currentIndex = localTeamTypes.findIndex((t) => t.id === type.id);
    if (currentIndex <= 0) return;
    const newOrder = [...localTeamTypes];

    const prevType = newOrder[currentIndex - 1];
    const currentType = newOrder[currentIndex];

    const tempOrder = currentType.order;
    currentType.order = prevType.order;
    prevType.order = tempOrder;

    newOrder[currentIndex - 1] = currentType;
    newOrder[currentIndex] = prevType;

    setLocalTeamTypes(newOrder);
  };

  const handleMoveDown = (type) => {
    const currentIndex = localTeamTypes.findIndex((t) => t.id === type.id);
    if (currentIndex >= localTeamTypes.length - 1) return;
    const newOrder = [...localTeamTypes];

    const nextType = newOrder[currentIndex + 1];
    const currentType = newOrder[currentIndex];

    const tempOrder = currentType.order;
    currentType.order = nextType.order;
    nextType.order = tempOrder;

    newOrder[currentIndex + 1] = currentType;
    newOrder[currentIndex] = nextType;

    setLocalTeamTypes(newOrder);
  };

  // SAVE CHANGES
  const handleSaveChanges = async () => {
    const finalTypes = [...localTeamTypes];

    // Validate that all non-deleted types have a valid name
    if (finalTypes.some((t) => !t.isDeleted && (!t.name || !t.name.trim()))) {
      alert(t('teamTypesModal.validationError', 'All team types must have a valid name.'));
      return;
    }

    try {
      // Delete
      const typesToDelete = finalTypes.filter((t) => t.isDeleted && !t.isNew);
      for (const t of typesToDelete) {
        await deleteTeamType(t.id);
      }

      // Add
      const typesToAdd = finalTypes.filter((t) => t.isNew && !t.isDeleted);
      for (const t of typesToAdd) {
        await addTeamType({ name: t.name.trim(), icon: t.icon });
      }

      // Update existing
      const originalIds = teamTypes.map((tt) => tt.id);
      const existingTypesToUpdate = finalTypes.filter(
        (t) =>
          !t.isNew &&
          !t.isDeleted &&
          originalIds.includes(t.id) &&
          (() => {
            const original = teamTypes.find((orig) => orig.id === t.id);
            return (
              original.name !== t.name ||
              original.icon !== t.icon ||
              original.order !== t.order
            );
          })()
      );

      for (const t of existingTypesToUpdate) {
        const updatedFields = {
          name: t.name.trim(),
          icon: t.icon,
          order: t.order,
        };
        await editTeamType(t.id, updatedFields);
      }

      // Refetch updated list
      await fetchTeamTypes();

      // Show toast on success
      if (showToast) {
        showToast(t('teamTypesModal.saveSuccess'), 'success');
      }

      onClose();
    } catch (error) {
      console.error('Error saving changes:', error);
      if (showToast) {
        showToast(t('teamTypesModal.saveError', { error: error.message }), 'error');
      }
    }
  };

  return (
    <>
      <CustomModal
        show={show}
        onClose={onClose}
        // translated title
        title={t('teamTypesModal.modalTitle')}
      >
        <div className="team-types-modal-container">
          {localTeamTypes
            .filter((type) => !type.isDeleted)
            .map((type, index) => {
              const visibleTypes = localTeamTypes.filter((t) => !t.isDeleted);
              const isFirst = index === 0;
              const isLast = index === visibleTypes.length - 1;

              return (
                <div key={type.id} className="team-types-card">
                  <div className="team-types-main">
                    <IconMapSelector
                      selectedIcon={type.icon || ''}
                      onChange={(val) => handleIconChange(type.id, val)}
                    />
                    <input
                      type="text"
                      className="type-name-input"
                      value={type.name}
                      onChange={(e) => handleNameChange(type.id, e.target.value)}
                      // translated placeholder
                      placeholder={t('teamTypesModal.teamTypeNamePlaceholder')}
                    />
                  </div>

                  <div className="team-types-card-actions">
                    <ActionButton
                      onClick={() => handleMoveUp(type)}
                      icon="faArrowUp"
                      label={t('teamTypesModal.moveUpLabel', 'Up')}
                      colorType="secondary"
                      disabled={isFirst}
                    />
                    <ActionButton
                      onClick={() => handleMoveDown(type)}
                      icon="faArrowDown"
                      label={t('teamTypesModal.moveDownLabel', 'Down')}
                      colorType="secondary"
                      disabled={isLast}
                    />
                    <ActionButton
                      onClick={() => handleDeleteType(type)}
                      icon="faTrash"
                      label={t('teamTypesModal.deleteLabel', 'Delete')}
                      colorType="danger"
                    />
                  </div>
                </div>
              );
            })}

          {/* Always visible fullwidth button to add a new blank row */}
          <ActionButton
            onClick={handleAddNewType}
            icon="faPlus"
            colorType="primary"
            fullWidth={true}
            // translated button label
            text={t('teamTypesModal.createNewTeamTypeButton')}
          />
        </div>

        {/* Save changes button at the bottom */}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
          <ActionButton
            onClick={handleSaveChanges}
            icon="faSave"
            colorType="success"
            fullWidth={true}
            // translated text
            text={t('teamTypesModal.saveButton')}
          />
        </div>
      </CustomModal>

      {deleteModalOpen && (
        <DeleteConfirmationModal
          show={deleteModalOpen}
          onConfirm={confirmDeleteType}
          onCancel={() => setDeleteModalOpen(false)}
          // translated title
          title={t('teamTypesModal.deleteModalTitle')}
          // translated message with dynamic name
          message={t('teamTypesModal.deleteModalMessage', {
            typeName: typeToDelete?.name || '',
          })}
        />
      )}
    </>
  );
};

export default TeamTypesModal;
