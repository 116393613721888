import React, { useState, useEffect, useContext } from 'react';
import './Settings.css';

// Shared components
import Tabs from '../Common/Tabs';
import ToastContainer from '../Common/ToastContainer';
import ErrorMessage from './ErrorMessage';
import ProgressBar from './ProgressBar';
import LanguageSelector from './LanguageSelector';
import ColorPickerGroup from './ColorPickerGroup';
import LogoUploadGroup from './LogoUploadGroup';
import ActionButton from '../Common/ActionButton';
import Spinner from '../Common/Spinner';
import TextField from '../FormBuilder/Fields/TextField';
import SelectField from '../FormBuilder/Fields/SelectField';

// i18n
import { useTranslation } from 'react-i18next';

// Permission/data contexts
import { UserContext } from '../../contexts/UserContext';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';
import { DataContext } from '../../DataContext';
import { GroupContext } from '../../contexts/GroupContext';

// Additional contexts
import { useColorSettings } from '../Common/ColorSettingsContext';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';

const Settings = () => {
  const { t } = useTranslation();

  // Permission
  const { hasPermission } = useContext(AppSettingsContext);
  const { userRolesLoading } = useContext(UserContext);
  const canViewSettings = hasPermission('settings', 'viewSettings');
  const canEditSettings = hasPermission('settings', 'editSettings');

  // Data from DataContext
  const { organizationData, updateSettings, uploadLogo } = useContext(AppSettingsContext);

  // Data from GroupContext
  const {
    groupsLoading,
    groupsError,
    currentGroup,      // the currently selected group
    updateGroup,       // function to update Firestore doc for this group
  } = useContext(GroupContext);

  // Style contexts
  const { color, profilePicture } = useColorSettings();
  const { language } = useLanguageSettings();

  // Local state
  const [activeTab, setActiveTab] = useState('settingsTab');
  const [logoFile, setLogoFile] = useState(null);
  const [logoPreviewUrl, setLogoPreviewUrl] = useState(profilePicture || '');
  const [primaryColor, setPrimaryColor] = useState(color || '#bb8300');
  const [tempLanguage, setTempLanguage] = useState(language || 'en');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Firestore-sourced fields (for the group)
  const [groupName, setGroupName] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [timezone, setTimezone] = useState('');

  // Address fields
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [stateField, setStateField] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');

  // Toasts
  const [toasts, setToasts] = useState([]);
  const removeToast = (id) => setToasts((prev) => prev.filter((toast) => toast.id !== id));
  const showToastMessage = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prev) => [...prev, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  // ------------------ Hooks ------------------

  // Keep local color & logo in sync with context
  useEffect(() => {
    setLogoPreviewUrl(profilePicture || '');
    setPrimaryColor(color || '#bb8300');
  }, [profilePicture, color]);

  // Load group data into local state whenever currentGroup changes
  useEffect(() => {
    if (!currentGroup) return;
    setGroupName(currentGroup.groupName || '');
    setSubdomain(currentGroup.subdomain || '');
    setTimezone(currentGroup.timezone || '');
    setAddressLine1(currentGroup.addressLine1 || '');
    setAddressLine2(currentGroup.addressLine2 || '');
    setCity(currentGroup.city || '');
    setStateField(currentGroup.state || '');
    setZipCode(currentGroup.zipCode || '');
    setCountry(currentGroup.country || '');
  }, [currentGroup]);

  // Cleanup preview
  useEffect(() => {
    return () => {
      if (logoPreviewUrl && typeof logoPreviewUrl === 'string') {
        URL.revokeObjectURL(logoPreviewUrl);
      }
    };
  }, [logoPreviewUrl]);

  // ------------------ Permission checks ------------------

  if (userRolesLoading || groupsLoading) {
    return (
      <div className="loading-container">
        {t('loadingPermissions', 'Loading...')}
      </div>
    );
  }
  if (!canViewSettings) {
    return (
      <div className="access-denied">
        403 - {t('noViewSettingsPermission', 'You do not have permission to view Settings.')}
      </div>
    );
  }
  if (!organizationData) {
    return (
      <div className="loading-container">
        <Spinner size="100px" />
      </div>
    );
  }

  // ------------------ Handlers ------------------

  const handleLanguageChange = (e) => {
    if (!canEditSettings) return;
    setTempLanguage(e.target.value);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!canEditSettings) {
      showToastMessage(
        t('noEditSettingsPermission', 'You do not have permission to edit Settings.'),
        'error'
      );
      return;
    }

    // Additional validation: ensure required fields are populated
    if (
      !timezone ||
      !addressLine1 ||
      !city ||
      !stateField ||
      !zipCode ||
      !country
    ) {
      showToastMessage(
        t('requiredFieldsMissing', 'Please fill all required fields before saving.'),
        'error'
      );
      return;
    }

    setLoading(true);
    setError('');
    setUploadProgress(0);

    try {
      // 1) Upload logo if changed
      let uploadedProfilePicture = profilePicture || '';
      if (logoFile) {
        uploadedProfilePicture = await uploadLogo(logoFile, (progress) => {
          setUploadProgress(progress);
        });
      }

      // 2) Prepare combined settings
      const newSettings = {
        profilePicture: uploadedProfilePicture,
        color: primaryColor,
        // only set language if changed
        ...(tempLanguage !== language && { language: tempLanguage }),
        // fields from the group
        groupName: groupName || '',
        subdomain: subdomain || '',
        timezone: timezone,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: stateField,
        zipCode: zipCode,
        country: country,
      };

      // 3) Update org-level settings
      await updateSettings(newSettings);

      // 4) Update group doc in Firestore
      if (currentGroup?.id) {
        await updateGroup(currentGroup.id, {
          groupName: newSettings.groupName,
          subdomain: newSettings.subdomain,
          timezone: newSettings.timezone,
          addressLine1: newSettings.addressLine1,
          addressLine2: newSettings.addressLine2,
          city: newSettings.city,
          state: newSettings.state,
          zipCode: newSettings.zipCode,
          country: newSettings.country,
        });
      }

      showToastMessage(
        t('settingsUpdatedSuccess', 'Settings updated successfully'),
        'success'
      );
      setLogoFile(null);
      setUploadProgress(0);
    } catch (err) {
      console.error('Error updating settings:', err);
      setError(t('settingsUpdatedError', 'Error updating settings.'));
      showToastMessage(t('settingsUpdatedError', 'Error updating settings'), 'error');
    } finally {
      setLoading(false);
    }
  };

  // ------------------ Tabs config ------------------
  const tabs = [
    { id: 'settingsTab', label: t('settingsTab', 'Settings') },
    { id: 'informationTab', label: t('informationTab', 'Information') },
  ];

  // ------------------ RENDER TAB CONTENT ------------------

  const renderSettingsTab = () => (
    <div className="tab-content">
      {/* groupName */}
      <TextField
        label={t('groupName', 'Group Name')}
        name="groupName"
        value={groupName}
        onChange={(val) => canEditSettings && setGroupName(val)}
        disabled={!canEditSettings}
        required
      />

      {/* subdomain + timezone */}
      <div className="form-row subdomainRow">
        <div className="half-width">
          <TextField
            label={t('subdomain', 'Subdomain')}
            name="subdomain"
            value={subdomain}
            onChange={() => {}}
            disabled
          />
        </div>
        <div className="half-width">
          <SelectField
            label={t('timezone', 'Timezone')}
            name="timezone"
            value={timezone}
            onChange={(val) => canEditSettings && setTimezone(val)}
            catalogId="timezones"
            placeholder={t('selectTimezone', 'Select a timezone')}
            disabled={!canEditSettings}
            required
          />
        </div>
      </div>

      {/* color + language + logo */}
      <div className="form-row colorLanguageLogoRow">
        <ColorPickerGroup
          primaryColor={primaryColor}
          setPrimaryColor={(val) => canEditSettings && setPrimaryColor(val)}
        />
        <LanguageSelector
          language={tempLanguage}
          handleLanguageChange={handleLanguageChange}
        />
      </div>

      <div className="form-row logoRow">
        <LogoUploadGroup
          setLogoFile={(file) => canEditSettings && setLogoFile(file)}
          logoPreviewUrl={logoPreviewUrl}
          setLogoPreviewUrl={(val) => canEditSettings && setLogoPreviewUrl(val)}
        />
      </div>

      {uploadProgress > 0 && uploadProgress < 100 && (
        <ProgressBar uploadProgress={uploadProgress} />
      )}
    </div>
  );

  const renderInformationTab = () => (
    <div className="tab-content">
      <TextField
        label="Address Line 1"
        value={addressLine1}
        onChange={(val) => canEditSettings && setAddressLine1(val)}
        required
      />
      <TextField
        label="Address Line 2"
        value={addressLine2}
        onChange={(val) => canEditSettings && setAddressLine2(val)}
      />
      <div className="form-row">
        <div className="half-width">
          <TextField
            label="City"
            value={city}
            onChange={(val) => canEditSettings && setCity(val)}
            required
          />
        </div>
        <div className="half-width">
          <SelectField
            label="State"
            name="state"
            value={stateField}
            onChange={(val) => canEditSettings && setStateField(val)}
            catalogId="states"
            placeholder="Select a State"
            disabled={!canEditSettings}
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="half-width">
          <TextField
            label="Zip Code"
            value={zipCode}
            onChange={(val) => canEditSettings && setZipCode(val)}
            required
          />
        </div>
        <div className="half-width">
          <SelectField
            label="Country"
            name="country"
            value={country}
            onChange={(val) => canEditSettings && setCountry(val)}
            catalogId="countries"
            placeholder="Select a Country"
            disabled={!canEditSettings}
            required
          />
        </div>
      </div>
    </div>
  );

  // ------------------ RENDER ------------------
  return (
    <div className="settings-container">
      <h2>{t('applicationSettingsTitle', 'Application Settings')}</h2>

      <Tabs tabs={tabs} selectedTab={activeTab} onTabSelect={setActiveTab} />

      <form onSubmit={handleSave} className="settings-form">
        {error && <ErrorMessage error={error} />}

        {activeTab === 'settingsTab' && renderSettingsTab()}
        {activeTab === 'informationTab' && renderInformationTab()}

        {/* Save button if can edit */}
        {canEditSettings && (
          <ActionButton
            type="submit"
            text={loading ? t('saving', 'Saving...') : t('saveSettings', 'Save Settings')}
            icon={loading ? 'faSpinner' : 'faSave'}
            disabled={loading}
            size="large"
            fullWidth={true}
            colorType="primary"
          />
        )}
      </form>

      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

export default Settings;
