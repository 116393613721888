// src/App.js

import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ErrorBoundary from './components/Common/ErrorBoundary';

import DataProvider from './DataContext';

// Components
import LandingPage from './components/LandingPage/LandingPage';
import Login from './components/Authentication/Login';
import MainScreen from './components/Main/MainScreen';
import PasswordRecovery from './components/Authentication/PasswordRecovery';
import ProtectedRoute from './components/Authentication/ProtectedRoute';
import ResetPassword from './components/Authentication/ResetPassword';
import SignUpForm from './components/SignUp/SignUpForm';
import OrganizationGroupSelectionPage from './components/Authentication/OrganizationGroupSelectionPage';
import PoliciesContainer from './components/Policies/PoliciesContainer';
// import SubscriptionPage from './components/Checkout/SubscriptionPage';  // <-- We will use in sub-route
import Unauthorized from './Unauthorized';
import NotFound from './NotFound';

import ColorSettings from './components/Common/ColorSettings';
import LanguageSettings from './components/Common/LanguageSettings';
import Layout from './components/Common/Layout';

import PublicForm from './components/FormBuilder/PublicForm';

import './App.css';

// ADD the BillingGuard import:
import BillingGuard from './components/Billing/BillingGuard';
import SubscriptionPage from './components/Billing/SubscriptionPage'; // if it's in that folder

const queryClient = new QueryClient();

/**
 * Sub-routes for /:groupId/*
 * In this sub-tree, we want to pass `groupID` to DataProvider.
 */
const AppContentWrapper = () => {
  const { groupId } = useParams();

  return (
    <DataProvider groupID={groupId}>
      <LanguageSettings>
        <ColorSettings>
          <Layout>
            <Routes>
              {/* Route for /:groupId/login */}
              <Route path="login" element={<Login />} />

              <Route
                path="main"
                element={
                  <ProtectedRoute>
                    <MainScreen />
                  </ProtectedRoute>
                }
              />
              <Route path="password-recovery" element={<PasswordRecovery />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="forms/:formId" element={<PublicForm />} />

              {/* 
                NEW Billing Route under :groupId:
                Protected, and further guarded by BillingGuard
              */}
              <Route
                path="billing"
                element={
                    <BillingGuard>
                      <SubscriptionPage />
                    </BillingGuard>
                }
              />

              {/* Catch-all inside group routes */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        </ColorSettings>
      </LanguageSettings>
    </DataProvider>
  );
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        {/* The top-level Router */}
        <Router>
          <Routes>
            {/* Public or minimal routes that might not need group context */}
            <Route path="/" element={<LandingPage />} />

            {/* If user visits /login with NO groupId */}
            <Route
              path="/login"
              element={
                <DataProvider groupID="">
                  <LanguageSettings>
                    <ColorSettings>
                      <Layout>
                        <Login />
                      </Layout>
                    </ColorSettings>
                  </LanguageSettings>
                </DataProvider>
              }
            />

            {/* More standalone routes */}
            <Route
              path="/password-recovery"
              element={
                <DataProvider groupID="">
                  <LanguageSettings>
                    <ColorSettings>
                      <Layout>
                        <PasswordRecovery />
                      </Layout>
                    </ColorSettings>
                  </LanguageSettings>
                </DataProvider>
              }
            />
            <Route
              path="/reset-password"
              element={
                <DataProvider groupID="">
                  <LanguageSettings>
                    <ColorSettings>
                      <Layout>
                        <ResetPassword />
                      </Layout>
                    </ColorSettings>
                  </LanguageSettings>
                </DataProvider>
              }
            />
            <Route
              path="/sign-up"
              element={
                <DataProvider groupID="">
                  <LanguageSettings>
                    <ColorSettings>
                      <Layout>
                        <SignUpForm />
                      </Layout>
                    </ColorSettings>
                  </LanguageSettings>
                </DataProvider>
              }
            />
            <Route path="/policies" element={<PoliciesContainer />} />
            <Route
              path="/organization-selection"
              element={
                <DataProvider groupID="">
                  <LanguageSettings>
                    <ColorSettings>
                      <Layout>
                        <ProtectedRoute>
                          <OrganizationGroupSelectionPage />
                        </ProtectedRoute>
                      </Layout>
                    </ColorSettings>
                  </LanguageSettings>
                </DataProvider>
              }
            />

            {/* 
              REMOVED the old top-level /billing route 
              that was here in your original code 
            */}

            {/* Unauthorized route */}
            <Route path="/unauthorized" element={<Unauthorized />} />

            {/* Group-specific routes => /:groupId/* */}
            <Route path="/:groupId/*" element={<AppContentWrapper />} />

            {/* 404 Not Found (fallback) */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;
