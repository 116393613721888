// src/components/FormBuilder/PublicForm.jsx

import React, { useState, useEffect, useContext } from 'react';
import { useParams, Navigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FillOutForm from './FillOutForm';
import LoadingSpinner from '../Common/GlobalSpinner';
import './PublicForm.css';
import { DataContext } from '../../DataContext';

// 1) Import the Catholicore logo
import CatholicoreLogoWhite from '../../assets/CatholicoreLogoWhite.png';

const PublicForm = () => {
  const { t } = useTranslation();
  const { groupId, formId } = useParams();

  // 2) Read responseId from query params
  const [searchParams] = useSearchParams();
  const responseIdParam = searchParams.get('responseId') || null;

  // Local states
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isClosed, setIsClosed] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // 3) Store fetched response data
  const [responseData, setResponseData] = useState(null);

  // Extract needed functions/values from DataContext
  const {
    fetchFormDetails,
    fetchResponseData, // <-- Make sure to implement in DataContext
    appSettings,
  } = useContext(DataContext);

  // Utility to show toasts (currently console logs)
  const showToast = (message, type) => {
    console.log(`${type.toUpperCase()}: ${message}`);
  };

  // Branding from appSettings
  const primaryColor = appSettings?.color || '#ffffff';
  const profilePicture = appSettings?.profilePicture || '';

  /**
   * Fetch the form definition
   */
  useEffect(() => {
    const fetchForm = async () => {
      try {
        const formData = await fetchFormDetails(formId);
        if (formData) {
          if (formData.groupId === groupId) {
            setForm({
              id: formId,
              ...formData,
            });
            setIsClosed(formData.isClosed || false);
          } else {
            console.error('Form does not belong to this group.');
            setNotFound(true);
            showToast(t('publicForm.formNotFound'), 'error');
          }
        } else {
          console.error('Form does not exist.');
          setNotFound(true);
          showToast(t('publicForm.formNotFound'), 'error');
        }
      } catch (error) {
        console.error('Error fetching form:', error);
        showToast(t('publicForm.fetchError'), 'error');
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    };

    fetchForm();
  }, [formId, groupId, fetchFormDetails, t]);

  /**
   * If we have a valid responseId, fetch the existing response data
   */
  useEffect(() => {
    if (!responseIdParam) return;

    const fetchExistingResponse = async () => {
      try {
        const existingResponse = await fetchResponseData(formId, responseIdParam);
        if (!existingResponse) {
          console.error('No existing response found for this responseId.');
          // Optionally show a toast or mark notFound
          return;
        }
        setResponseData(existingResponse);
      } catch (error) {
        console.error('Error fetching existing response:', error);
      }
    };

    fetchExistingResponse();
  }, [responseIdParam, formId, fetchResponseData]);

  /**
   * Called when the form is successfully submitted
   */
  const handleFormSubmit = () => {
    setIsSubmitted(true);
  };

  // If still loading the form
  if (loading) {
    return <LoadingSpinner />;
  }

  // If the form was not found or doesn't belong to this group
  if (notFound) {
    return <Navigate to="/not-found" replace />;
  }

  return (
    <div className="public-form-background">
      {/* Catholicore Logo at Top-Left */}
      <div className="catholicore-logo-container">
        <img
          src={CatholicoreLogoWhite}
          alt="Catholicore Logo"
          className="catholicore-logo"
        />
      </div>

      <div className="public-form-container" style={{ backgroundColor: primaryColor }}>
        
        {/* Existing custom profile picture/logo (if any) */}
        {profilePicture && (
          <div className="logo-container">
            <img
              src={profilePicture}
              alt={t('publicForm.logoAlt')}
              className="form-logo"
            />
          </div>
        )}

        <h2>{form?.name}</h2>

        {isClosed ? (
          <div className="form-closed-message">
            <p>{t('publicForm.formClosedMessage')}</p>
          </div>
        ) : isSubmitted ? (
          <div className="form-submitted-message">
            {/* Success Message */}
            <p>{t('publicForm.formSubmittedMessage')}</p>

            {/* Advertisement with Catholicore Logo */}
            <div className="advertisement">
              <img
                src={CatholicoreLogoWhite}
                alt="Catholicore Logo"
                className="advertisement-logo"
              />
              <p className="advertisement-text">
                {t('advertisement.title')}
              </p>
              <a
                href="https://catholicore.com/sign-up"
                className="advertisement-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('advertisement.buttonText')}
              </a>
            </div>
          </div>
        ) : (
          <FillOutForm
            fields={form.fields}
            formId={form.id}
            showToast={showToast}
            onFormSubmit={handleFormSubmit}
            responseId={responseIdParam}
            initialData={responseData}
            groupId={groupId}
          />
        )}
      </div>
    </div>
  );
};

export default PublicForm;
