// src/components/UserForm.jsx

import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../Common/ActionButton';
import './UserForm.css';
import { useTranslation } from 'react-i18next';

import TextField from '../FormBuilder/Fields/TextField';
import EmailField from '../FormBuilder/Fields/EmailField';
import SelectField from '../FormBuilder/Fields/SelectField';
import Select from 'react-select';
import Tabs from '../Common/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconMap from '../Common/IconMap';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'; // Default avatar icon

const UserForm = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,

  // roles => each role: { id, name, icon, priority, ... }
  roles,

  // The selected role's ID
  role,
  setRole,

  // Current user’s numeric priority
  currentUserPriority,

  isMobile,
  onSubmit,
  isEditing,
  isSubmitting,
  membersData = [],
  users = [],

  // If editing an existing user => we may link them to a member
  editingMemberId = null,
}) => {
  const { t } = useTranslation();

  // If editing, we only show the "manual" tab
  const [selectedTab, setSelectedTab] = useState(isEditing ? 'manual' : 'manual');

  // State for creating multiple new users from Members tab
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [membersRole, setMembersRole] = useState('');

  // For editing a single user => link to an existing member
  const [selectedSingleMember, setSelectedSingleMember] = useState(null);

  useEffect(() => {
    if (isEditing && editingMemberId) {
      setSelectedSingleMember(editingMemberId);
    }
  }, [isEditing, editingMemberId]);

  // Gather existing user emails => used to exclude from members
  const existingUserEmails = useMemo(
    () => new Set(users.map((u) => (u.email || '').toLowerCase())),
    [users]
  );

  /**
   * Build "memberOptionsMultiple" => used in "From Members" tab for creating multiple new users
   */
  const memberOptionsMultiple = useMemo(() => {
    if (!membersData || membersData.length === 0) return [];

    // Exclude members who already have user accounts
    const filtered = membersData.filter((m) => {
      const memEmail = (m.email || '').toLowerCase();
      if (memEmail && existingUserEmails.has(memEmail)) {
        return false;
      }
      return true;
    });

    // Sort by name
    const sorted = [...filtered].sort((a, b) =>
      `${a.firstName || ''} ${a.lastName || ''}`.localeCompare(
        `${b.firstName || ''} ${b.lastName || ''}`
      )
    );

    // Split into "with email" vs "no email"
    const withEmail = sorted.filter((m) => m.email);
    const withoutEmail = sorted.filter((m) => !m.email);

    const withEmailOptions = withEmail.map((member) => ({
      value: member.id,
      label: `${member.firstName || ''} ${member.lastName || ''} (${member.email})`,
      data: member,
      isDisabled: false,
    }));

    const withoutEmailOptions = withoutEmail.map((member) => ({
      value: member.id,
      label: `${member.firstName || ''} ${member.lastName || ''} (Email is required)`,
      data: member,
      isDisabled: true,
    }));

    return [...withEmailOptions, ...withoutEmailOptions];
  }, [membersData, existingUserEmails]);

  /**
   * Build "memberOptionsSingle" => used in edit mode for linking a single member
   */
  const memberOptionsSingle = useMemo(() => {
    if (!membersData || membersData.length === 0) return [];

    const filtered = membersData.filter((m) => {
      const memEmail = (m.email || '').toLowerCase();
      // If there's already a user with this email, skip
      // unless it's the current user's own email (so we don't break edits).
      if (
        memEmail &&
        existingUserEmails.has(memEmail) &&
        memEmail !== (email || '').toLowerCase()
      ) {
        return false;
      }
      return true;
    });

    const sorted = [...filtered].sort((a, b) =>
      `${a.firstName || ''} ${a.lastName || ''}`.localeCompare(
        `${b.firstName || ''} ${b.lastName || ''}`
      )
    );

    return sorted.map((member) => ({
      value: member.id,
      label: `${member.firstName || ''} ${member.lastName || ''} (${member.email || 'No Email'})`,
      data: member,
    }));
  }, [membersData, existingUserEmails, email]);

  // react-select styling
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '8px',
      borderColor: '#ddd',
      fontSize: '0.95rem',
      boxShadow: 'none',
      ':hover': {
        borderColor: 'var(--main-color)',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '0.95rem',
      backgroundColor: state.isSelected
        ? '#f0f0f0'
        : state.isFocused
        ? '#f0f0f0'
        : '#fff',
      color: state.isSelected ? '#fff' : '#333',
      opacity: state.isDisabled ? 0.5 : 1,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  /**
   * Build role options => show all roles,
   * but disable if that role outranks me (rolePriority < myPriority).
   * => If rolePriority >= myPriority => allowed
   */
  const roleOptions = useMemo(() => {
    if (!Array.isArray(roles)) return [];
    return roles.map((r) => {
      const rp = typeof r.priority === 'number' ? r.priority : 999;
      return {
        label: r.name,
        value: r.id,
        icon: r.icon,
        priority: rp,
        // disable if rp < myPriority (i.e. outranks me)
        isDisabled: rp < currentUserPriority,
      };
    });
  }, [roles, currentUserPriority]);

  // Format role items => show icon + priority
  const formatRoleOptionLabel = (option) => {
    const iconKey = option.icon && IconMap[option.icon] ? option.icon : null;
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        {iconKey && (
          <FontAwesomeIcon icon={IconMap[iconKey]} style={{ color: 'var(--main-color)' }} />
        )}
        <span>
          {option.label} (P={option.priority})
        </span>
      </div>
    );
  };

  // Format member items => show optional avatar
  const formatMemberOptionLabel = (option) => {
    const member = option.data;
    const pictureURL = member.profilePicture;
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        {pictureURL ? (
          <img
            src={pictureURL}
            alt={`${member.firstName || ''} ${member.lastName || ''}`}
            style={{
              backgroundColor: 'var(--main-color)',
              width: '24px',
              height: '24px',
              borderRadius: '50%',
            }}
          />
        ) : (
          <FontAwesomeIcon icon={faUserCircle} style={{ color: 'var(--main-color)' }} />
        )}
        <span>{option.label}</span>
      </div>
    );
  };

  // Submit handler => calls onSubmit(...) with either "manual" or "members" data
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      // single user update
      onSubmit({
        mode: 'manual',
        firstName,
        lastName,
        email,
        role, // role ID
        memberId: selectedSingleMember || null,
      });
    } else {
      // creating new user(s)
      if (selectedTab === 'manual') {
        onSubmit({
          mode: 'manual',
          firstName,
          lastName,
          email,
          role, // role ID
        });
      } else {
        // multiple from members
        onSubmit({
          mode: 'members',
          members: selectedMembers,
          role: membersRole,
        });
      }
    }
  };

  // Define tabs => "Manual" vs "Members" (only shown if not editing)
  const tabsDef = [
    { id: 'manual', label: t('userForm.manualTab', 'Manual') },
    { id: 'members', label: t('userForm.membersTab', 'From Members') },
  ];

  // Decide label for main action button
  const submitButtonLabel = isSubmitting
    ? t('userForm.submitting', 'Submitting...')
    : isEditing
    ? t('userForm.updateButton', 'Update User')
    : selectedTab === 'manual'
    ? t('userForm.saveButton', 'Save User')
    : t('userForm.sendInvitation', 'Send Invitation');

  // Decide icon for main action button
  const submitButtonIcon = isSubmitting
    ? 'faSpinner'
    : !isEditing && selectedTab === 'members'
    ? 'faEnvelope'
    : 'faSave';

  return (
    <form className="users-modal-form enhanced-user-form" onSubmit={handleSubmit}>
      {/* Show tabs only if not editing */}
      {!isEditing && (
        <Tabs tabs={tabsDef} selectedTab={selectedTab} onTabSelect={setSelectedTab} />
      )}

      <div className="user-form-content">
        {/* ---------- MANUAL TAB OR EDIT MODE ---------- */}
        {(selectedTab === 'manual' || isEditing) && (
          <div className="user-form-manual">
            {/* First Name */}
            <div className="users-input-group">
              <TextField
                label={t('userForm.firstNameLabel', 'First Name')}
                name="firstName"
                value={firstName}
                onChange={setFirstName}
                required
                placeholder={t('userForm.firstNamePlaceholder', 'Enter first name')}
                size="full"
              />
            </div>

            {/* Last Name */}
            <div className="users-input-group">
              <TextField
                label={t('userForm.lastNameLabel', 'Last Name')}
                name="lastName"
                value={lastName}
                onChange={setLastName}
                required
                placeholder={t('userForm.lastNamePlaceholder', 'Enter last name')}
                size="full"
              />
            </div>

            {/* Email */}
            <div className="users-input-group">
              <EmailField
                label={t('userForm.emailLabel', 'Email')}
                name="email"
                value={email}
                onChange={setEmail}
                required
                disabled={isEditing} // typically read-only in edit mode
                style={isEditing ? { backgroundColor: '#e9ecef' } : {}}
                placeholder={t('userForm.emailPlaceholder', 'Enter email')}
                size="full"
              />
            </div>

            {/* Role (show all, disable if role outranks me) */}
            <div className="users-input-group">
              <label className="users-label">
                {t('userForm.roleLabel', 'Role')}
              </label>
              <SelectField
                name="role"
                value={role}
                onChange={setRole}
                required
                options={roleOptions}
                isOptionDisabled={(option) => option.isDisabled}
                placeholder={t('userForm.rolePlaceholder', 'Select a role')}
                size="full"
                formatOptionLabel={formatRoleOptionLabel}
              />
            </div>

            {/* If editing => optionally link single member */}
            {isEditing && (
              <div className="users-input-group">
                <label className="users-label">
                  {t('userForm.linkSingleMember', 'Link to Member')}
                </label>
                <Select
                  isClearable
                  options={memberOptionsSingle}
                  value={
                    memberOptionsSingle.find(
                      (opt) => opt.value === selectedSingleMember
                    ) || null
                  }
                  onChange={(selectedOption) => {
                    setSelectedSingleMember(selectedOption ? selectedOption.value : null);
                  }}
                  placeholder={t('userForm.selectSingleMemberPlaceholder', 'Select a member...')}
                  styles={customStyles}
                  formatOptionLabel={formatMemberOptionLabel}
                />
              </div>
            )}
          </div>
        )}

        {/* ---------- MEMBERS TAB (only if not editing) ---------- */}
        {!isEditing && selectedTab === 'members' && (
          <div className="user-form-members">
            {membersData && membersData.length > 0 ? (
              <>
                <div className="users-input-group">
                  <label className="users-label">
                    {t('userForm.selectMultipleMembers', 'Select Members')}
                  </label>
                  <div className="select-wrapper">
                    <Select
                      isMulti
                      options={memberOptionsMultiple}
                      value={memberOptionsMultiple.filter((opt) =>
                        selectedMembers.includes(opt.value)
                      )}
                      onChange={(selectedOptions) => {
                        const values = selectedOptions
                          ? selectedOptions.map((o) => o.value)
                          : [];
                        setSelectedMembers(values);
                      }}
                      placeholder={t('userForm.selectMemberPlaceholder', 'Search members...')}
                      styles={customStyles}
                      formatOptionLabel={formatMemberOptionLabel}
                    />
                  </div>
                </div>

                <div className="users-input-group">
                  <label className="users-label">
                    {t('userForm.roleLabel', 'Role')}
                  </label>
                  <SelectField
                    name="membersRole"
                    value={membersRole}
                    onChange={setMembersRole}
                    required
                    options={roleOptions}
                    isOptionDisabled={(option) => option.isDisabled}
                    placeholder={t('userForm.rolePlaceholder', 'Select a role')}
                    size="full"
                    formatOptionLabel={formatRoleOptionLabel}
                  />
                </div>
              </>
            ) : (
              <p>{t('userForm.noMembersAvailable', 'No members available.')}</p>
            )}
          </div>
        )}

        {/* ---------- SUBMIT BUTTON ---------- */}
        <div className="users-submit-button-container">
          <ActionButton
            type="submit"
            label={submitButtonLabel}
            text={submitButtonLabel}
            icon={submitButtonIcon}
            spin={isSubmitting}
            disabled={isSubmitting}
            isMobile={isMobile}
            colorType="primary"
            ariaLabel={
              isEditing
                ? t('userForm.updateButtonAria', 'Update this user')
                : selectedTab === 'manual'
                ? t('userForm.saveButtonAria', 'Save this user')
                : t('userForm.sendInvitationAria', 'Send Invitation')
            }
          />
        </div>
      </div>
    </form>
  );
};

UserForm.propTypes = {
  firstName: PropTypes.string.isRequired,
  setFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  setLastName: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,

  // roles => each role: { id, name, icon, priority, ... }
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      icon: PropTypes.string,
      priority: PropTypes.number, // so we can check rank
    })
  ).isRequired,

  role: PropTypes.string.isRequired, // the currently selected role's ID
  setRole: PropTypes.func.isRequired,

  // the current user's numeric priority
  currentUserPriority: PropTypes.number.isRequired,

  isMobile: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,

  membersData: PropTypes.array,
  users: PropTypes.array,
  editingMemberId: PropTypes.string,
};

export default UserForm;
