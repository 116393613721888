// ----------------------------------------------------------
// File: src/contexts/UserManagementContext.jsx
// ----------------------------------------------------------
import React, { createContext, useContext, useState, useEffect } from 'react';
import { firestore, auth } from '../firebase';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  addDoc,
  where,
  query,
} from 'firebase/firestore';
import { useQueryClient } from '@tanstack/react-query';
import UserIcon from '../assets/UserIcon.png';
import { defaultLanguage } from '../config';
import { AppSettingsContext } from './AppSettingsContext';

export const UserManagementContext = createContext();

export const UserManagementProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [rolesMap, setRolesMap] = useState({});
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isUsersError, setIsUsersError] = useState(null);

  const { organizationData } = useContext(AppSettingsContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (organizationData && organizationData.groupId) {
      fetchUsersAndRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData?.groupId]);

  // ----------------------------------------------------------
  // Helper: getPriorityForUserInGroup
  //   Returns the user’s smallest (best) priority for the given group.
  //   If none found, returns 999.
  // ----------------------------------------------------------
  const getPriorityForUserInGroup = async (userId, groupId) => {
    if (!userId || !groupId) return 999;

    try {
      const userDocRef = doc(firestore, 'users', userId);
      const userSnap = await getDoc(userDocRef);
      if (!userSnap.exists()) {
        return 999;
      }

      const userData = userSnap.data();
      const rolesArray = userData.roles || [];
      let minPriority = 999;

      for (const roleEntry of rolesArray) {
        if (roleEntry.groupId?.id === groupId) {
          // fetch role doc => get priority
          const roleSnap = roleEntry.role ? await getDoc(roleEntry.role) : null;
          if (roleSnap && roleSnap.exists()) {
            const rData = roleSnap.data();
            if (typeof rData.priority === 'number' && rData.priority < minPriority) {
              minPriority = rData.priority;
            }
          }
        }
      }
      return minPriority;
    } catch (error) {
      console.error('Error in getPriorityForUserInGroup:', error);
      return 999;
    }
  };

  // ----------------------------------------------------------
  // Helper: getPriorityOfRole
  //   Fetches the priority of a given role doc in the group. Returns 999 if missing.
  // ----------------------------------------------------------
  const getPriorityOfRole = async (roleId, groupId) => {
    if (!roleId || !groupId) return 999;
    try {
      const roleDocRef = doc(firestore, 'roleGroups', groupId, 'roles', roleId);
      const roleSnap = await getDoc(roleDocRef);
      if (!roleSnap.exists()) {
        return 999;
      }
      const roleData = roleSnap.data();
      return typeof roleData.priority === 'number' ? roleData.priority : 999;
    } catch (error) {
      console.error('Error in getPriorityOfRole:', error);
      return 999;
    }
  };

  // ----------------------------------------------------------
  // NEW Helper: isUserAdminInGroup
  //   Checks if the user has a role named "Admin" in the given group.
  //   Returns true if found, otherwise false.
  // ----------------------------------------------------------
  const isUserAdminInGroup = async (userId, groupId) => {
    try {
      if (!userId || !groupId) return false;
      const userDocRef = doc(firestore, 'users', userId);
      const userSnap = await getDoc(userDocRef);
      if (!userSnap.exists()) return false;

      const userData = userSnap.data();
      const rolesArray = userData.roles || [];
      for (const roleEntry of rolesArray) {
        if (roleEntry.groupId?.id === groupId) {
          const roleSnap = roleEntry.role ? await getDoc(roleEntry.role) : null;
          if (roleSnap && roleSnap.exists()) {
            const rData = roleSnap.data();
            if (rData.name === 'Admin') {
              return true; // Found an Admin role
            }
          }
        }
      }
    } catch (error) {
      console.error('Error in isUserAdminInGroup:', error);
    }
    return false;
  };

  // ----------------------------------------------------------
  // fetchUsersAndRoles => loads users + roles for the current org/group
  // ----------------------------------------------------------
  const fetchUsersAndRoles = async () => {
    if (!organizationData || !organizationData.groupId) {
      console.error('Organization data is not available for fetchUsersAndRoles.');
      return;
    }

    setIsUsersLoading(true);
    const groupId = organizationData.groupId;

    try {
      // 1) Fetch all roles in this group
      const rolesCollectionRef = collection(firestore, 'roleGroups', groupId, 'roles');
      const rolesSnapshot = await getDocs(rolesCollectionRef);
      const rolesList = rolesSnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));

      // Build a rolesMap
      const rolesMapTemp = {};
      rolesList.forEach((role) => {
        rolesMapTemp[role.id] = role;
      });
      setRoles(rolesList);
      setRolesMap(rolesMapTemp);

      // 2) Fetch all users
      console.log('Fetching all users from => users/');
      const usersCollectionRef = collection(firestore, 'users');
      const usersSnapshot = await getDocs(usersCollectionRef);
      const allUsers = usersSnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));

      // 3) Filter to users that have at least one role in this group
      const filteredUsers = allUsers.filter((user) =>
        user.roles?.some((roleEntry) => roleEntry.groupId?.id === groupId)
      );

      // 4) Build convenience data for each user
      const usersWithRoles = filteredUsers.map((user) => {
        const roleNames = user.roles
          .filter((roleEntry) => roleEntry.groupId?.id === groupId)
          .map((roleEntry) => {
            const roleRef = roleEntry.role;
            const roleId = roleRef?.id;
            const fromMap = rolesMapTemp[roleId];
            return {
              roleId,
              roleName: fromMap?.name || 'Unknown Role',
            };
          });
        return {
          id: user.id,
          fullName: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
          email: user.email || '',
          roleNames,
          ...user,
        };
      });

      setUsers(usersWithRoles);
    } catch (error) {
      console.error('Error fetching users and roles:', error);
      setIsUsersError(error);
    } finally {
      setIsUsersLoading(false);
    }
  };

  // For multi-group usage if needed
  const getUsersAndRolesByGroupId = async (someGroupId) => {
    console.log('getUsersAndRolesByGroupId called with:', someGroupId);
    try {
      if (!someGroupId) {
        console.warn('No group ID provided.');
        return { users: [], roles: [], rolesMap: {} };
      }

      // 1) Fetch roles
      const rolesCollectionRef = collection(firestore, 'roleGroups', someGroupId, 'roles');
      const rolesSnapshot = await getDocs(rolesCollectionRef);
      const rolesList = rolesSnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      const rolesMapTemp = {};
      rolesList.forEach((role) => {
        rolesMapTemp[role.id] = role;
      });

      // 2) Fetch all users
      console.log('Fetching all users from => users/');
      const usersCollectionRef = collection(firestore, 'users');
      const usersSnapshot = await getDocs(usersCollectionRef);
      const allUsers = usersSnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));

      // 3) Filter to users in someGroupId
      const filteredUsers = allUsers.filter((user) =>
        user.roles?.some((roleEntry) => roleEntry.groupId?.id === someGroupId)
      );

      // 4) Build convenience data
      const usersWithRoles = filteredUsers.map((user) => {
        const roleNames = user.roles
          .filter((roleEntry) => roleEntry.groupId?.id === someGroupId)
          .map((roleEntry) => {
            const roleRef = roleEntry.role;
            const roleId = roleRef?.id;
            const fromMap = rolesMapTemp[roleId];
            return {
              roleId,
              roleName: fromMap?.name || 'Unknown Role',
            };
          });
        return {
          id: user.id,
          fullName: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
          email: user.email || '',
          roleNames,
          ...user,
        };
      });

      return {
        users: usersWithRoles,
        roles: rolesList,
        rolesMap: rolesMapTemp,
      };
    } catch (error) {
      console.error('Error in getUsersAndRolesByGroupId:', error);
      throw error;
    }
  };

  // ----------------------------------------------------------
  // sendPasswordResetEmail
  // ----------------------------------------------------------
  const sendPasswordResetEmail = async (email, logoImage, accentColor) => {
    const CLOUD_FUNCTION_URL = 'https://sendresetpasswordemail-lgfph5hmwq-uc.a.run.app';
    console.log('sendPasswordResetEmail => email:', email);

    try {
      const response = await fetch(CLOUD_FUNCTION_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, logoImage, accentColor }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Server Error: ${errorText}`);
      }

      const data = await response.json();
      console.log('Password reset email sent:', data.message);
      return true;
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  };

  // ----------------------------------------------------------
  // addUser => with double validation
  // ----------------------------------------------------------
  const addUser = async (
    { email, firstName, lastName, roleId, memberId = null, logo, color },
    targetGroupId = null
  ) => {
    console.log('addUser called =>', {
      email,
      firstName,
      lastName,
      roleId,
      memberId,
      targetGroupId,
    });

    if (!email || !firstName || !lastName || !roleId) {
      throw new Error('Missing required fields in addUser');
    }

    try {
      const groupId = targetGroupId || organizationData.groupId;
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user found in addUser');
      }

      // Check if current user is Admin. If yes, skip priority checks.
      const userIsAdmin = await isUserAdminInGroup(currentUser.uid, groupId);

      if (!userIsAdmin) {
        // --- Double Validation for rank
        const userPriority = await getPriorityForUserInGroup(currentUser.uid, groupId);
        const rolePriority = await getPriorityOfRole(roleId, groupId);
        if (rolePriority <= userPriority) {
          throw new Error(
            'You cannot assign a role that is equal or higher rank than your own.'
          );
        }
      }

      const usersRef = collection(firestore, 'users');
      const qExisting = query(usersRef, where('email', '==', email));
      const querySnapshot = await getDocs(qExisting);

      const groupDocRef = doc(firestore, 'groups', groupId);
      const roleDocRef = doc(firestore, 'roleGroups', groupId, 'roles', roleId);

      // Create a roleEntry with active = true by default
      const roleEntry = {
        groupId: groupDocRef,
        organizationId: organizationData.organizationId,
        role: roleDocRef,
        active: true,
      };
      if (memberId) {
        roleEntry.memberReference = doc(
          firestore,
          `formGroups/${groupId}/forms/membersForm/responses/${memberId}`
        );
      }

      // If user (by email) already exists => update roles array
      if (!querySnapshot.empty) {
        const existingUserDoc = querySnapshot.docs[0];
        const existingUserData = existingUserDoc.data();
        let existingRolesArray = existingUserData.roles || [];

        const roleIndex = existingRolesArray.findIndex(
          (r) => r.groupId?.id === groupId
        );
        if (roleIndex >= 0) {
          // Overwrite that group's role
          existingRolesArray[roleIndex] = {
            ...existingRolesArray[roleIndex],
            role: roleDocRef,
            organizationId: organizationData.organizationId,
            active: true,
            memberReference: memberId
              ? doc(
                  firestore,
                  `formGroups/${groupId}/forms/membersForm/responses/${memberId}`
                )
              : null,
          };
        } else {
          // Insert new role
          existingRolesArray.push(roleEntry);
        }

        const userDocRef = doc(firestore, 'users', existingUserDoc.id);
        await updateDoc(userDocRef, {
          firstName,
          lastName,
          roles: existingRolesArray,
        });

        await fetchUsersAndRoles();
        return { action: 'updated', userId: existingUserDoc.id };
      } else {
        // brand-new user => call cloud function
        const idToken = await currentUser.getIdToken();
        const finalRolesPayload = [
          {
            groupId: `groups/${groupId}`,
            organizationId: organizationData.organizationId,
            role: `roleGroups/${groupId}/roles/${roleId}`,
            active: true,
            ...(memberId
              ? {
                  memberReference: `formGroups/${groupId}/forms/membersForm/responses/${memberId}`,
                }
              : {}),
          },
        ];

        const response = await fetch('https://create-user-lgfph5hmwq-uc.a.run.app', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            email,
            firstName,
            lastName,
            groupId,
            roles: finalRolesPayload,
            active: true,
            language: defaultLanguage.main,
            profilePicture: UserIcon,
          }),
        });

        const data = await response.json();
        if (!response.ok) {
          throw new Error(`Error creating user: ${data.message || data}`);
        }

        // Send password reset link
        console.log('User created, sending password reset =>', email);
        await sendPasswordResetEmail(email, logo, color);
        await fetchUsersAndRoles();
        return { action: 'created', userId: data.uid };
      }
    } catch (error) {
      console.error('Error adding user:', error);
      throw error;
    }
  };

  // ----------------------------------------------------------
  // editUser => with double validation
  // ----------------------------------------------------------
  const editUser = async (
    userId,
    { firstName, lastName, roleId, memberId = null },
    targetGroupId = null
  ) => {
    console.log('editUser called =>', {
      userId,
      firstName,
      lastName,
      roleId,
      memberId,
      targetGroupId,
    });

    if (!userId || !firstName || !lastName || !roleId) {
      throw new Error('Missing required fields in editUser');
    }

    try {
      const groupId = targetGroupId || organizationData.groupId;
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user found in editUser');
      }

      // Check if current user is Admin. If yes, skip priority checks.
      const userIsAdmin = await isUserAdminInGroup(currentUser.uid, groupId);

      if (!userIsAdmin) {
        // Double Validation
        const userPriority = await getPriorityForUserInGroup(currentUser.uid, groupId);
        const rolePriority = await getPriorityOfRole(roleId, groupId);
        if (rolePriority <= userPriority) {
          throw new Error(
            'You cannot assign/edit a user to a role of equal or higher rank than you.'
          );
        }
      }

      // read existing user data
      const userDocRef = doc(firestore, 'users', userId);
      const userSnapshot = await getDoc(userDocRef);
      if (!userSnapshot.exists()) {
        throw new Error('User does not exist in editUser');
      }

      const existingUserData = userSnapshot.data();
      let rolesArray = existingUserData.roles || [];

      const groupDocRef = doc(firestore, 'groups', groupId);
      const roleDocRef = doc(firestore, 'roleGroups', groupId, 'roles', roleId);

      // Overwrite or add the group’s role entry
      const idx = rolesArray.findIndex((r) => r.groupId?.id === groupId);
      if (idx >= 0) {
        rolesArray[idx] = {
          ...rolesArray[idx],
          role: roleDocRef,
          active: true,
          organizationId: organizationData.organizationId,
          memberReference: memberId
            ? doc(
                firestore,
                `formGroups/${groupId}/forms/membersForm/responses/${memberId}`
              )
            : null,
        };
      } else {
        const newRoleEntry = {
          groupId: groupDocRef,
          organizationId: organizationData.organizationId,
          role: roleDocRef,
          active: true,
          ...(memberId
            ? {
                memberReference: doc(
                  firestore,
                  `formGroups/${groupId}/forms/membersForm/responses/${memberId}`
                ),
              }
            : {}),
        };
        rolesArray.push(newRoleEntry);
      }

      await updateDoc(userDocRef, {
        firstName,
        lastName,
        roles: rolesArray,
        active: true, // top-level "active" if needed
        language: defaultLanguage.main,
        profilePicture: UserIcon,
      });

      await fetchUsersAndRoles();
      return { action: 'updated', userId };
    } catch (error) {
      console.error('Error editing user:', error);
      throw error;
    }
  };

  // ----------------------------------------------------------
  // deleteUser => now sets the role for that group to active=false
  // ----------------------------------------------------------
  const deleteUser = async (userId, targetGroupId = null) => {
    console.log('deleteUser => userId:', userId, 'targetGroupId:', targetGroupId);
    if (!userId) {
      throw new Error('User ID is required in deleteUser');
    }

    try {
      const groupId = targetGroupId || organizationData.groupId;
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user found in deleteUser');
      }

      // Check if current user is Admin. If yes, skip priority checks.
      const userIsAdmin = await isUserAdminInGroup(currentUser.uid, groupId);

      if (!userIsAdmin) {
        // Optional double validation: check rank
        // 1) get the target user's priority in this group
        // 2) compare to currentUser's priority
        const currentUserPriority = await getPriorityForUserInGroup(
          currentUser.uid,
          groupId
        );
        const targetUserPriority = await getPriorityForUserInGroup(userId, groupId);

        if (targetUserPriority <= currentUserPriority) {
          throw new Error('You cannot remove/disable a user of equal or higher rank.');
        }
      }

      // Read the user doc
      const userDocRef = doc(firestore, 'users', userId);
      const userSnap = await getDoc(userDocRef);
      if (!userSnap.exists()) {
        throw new Error('Cannot disable: user not found');
      }

      const userData = userSnap.data();
      let rolesArray = userData.roles || [];

      // For the group in question => set active=false
      rolesArray = rolesArray.map((r) => {
        if (r.groupId?.id === groupId) {
          return {
            ...r,
            active: false, // Mark this role entry as inactive
          };
        }
        return r;
      });

      // Update the user doc
      await updateDoc(userDocRef, {
        roles: rolesArray,
      });

      console.log('Successfully disabled user for group =>', userId, groupId);
      await fetchUsersAndRoles();
      return { action: 'disabled', userId };
    } catch (error) {
      console.error('Error disabling user:', error);
      throw error;
    }
  };

  // ----------------------------------------------------------
  // addRole => with double validation
  // ----------------------------------------------------------
  const addRole = async (
    { name, description, icon, permissions, priority = 999 },
    targetGroupId = null
  ) => {
    console.log('addRole =>', { name, description, icon, priority, targetGroupId });
    if (!name) {
      throw new Error('Role name is required in addRole');
    }
    try {
      const groupId = targetGroupId || organizationData?.groupId;
      if (!groupId) throw new Error('No groupId in addRole');

      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user found in addRole');
      }

      // Check if current user is Admin. If yes, skip priority checks.
      const userIsAdmin = await isUserAdminInGroup(currentUser.uid, groupId);

      if (!userIsAdmin) {
        // Double Validation
        const userPriority = await getPriorityForUserInGroup(currentUser.uid, groupId);
        if (priority <= userPriority) {
          throw new Error(
            'You cannot create a role with equal or higher rank than yourself.'
          );
        }
      }

      const rolesCollectionRef = collection(firestore, 'roleGroups', groupId, 'roles');
      await addDoc(rolesCollectionRef, {
        name,
        description: description || '',
        icon: icon || '',
        permissions: permissions || [],
        priority,
      });

      console.log('Successfully added role =>', { name });
      if (groupId === organizationData?.groupId) {
        await fetchUsersAndRoles();
      }
    } catch (error) {
      console.error('Error adding role:', error);
      throw error;
    }
  };

  // ----------------------------------------------------------
  // editRole => with double validation
  // ----------------------------------------------------------
  const editRole = async (
    roleId,
    { name, description, icon, permissions, priority = 999 },
    targetGroupId = null
  ) => {
    console.log('editRole =>', { roleId, name, priority, targetGroupId });
    if (!roleId || !name) {
      throw new Error('Missing required fields in editRole');
    }
    try {
      const groupId = targetGroupId || organizationData?.groupId;
      if (!groupId) throw new Error('No groupId in editRole');

      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user found in editRole');
      }

      // Check if current user is Admin. If yes, skip priority checks.
      const userIsAdmin = await isUserAdminInGroup(currentUser.uid, groupId);

      if (!userIsAdmin) {
        // Double Validation
        const userPriority = await getPriorityForUserInGroup(currentUser.uid, groupId);
        const oldPriority = await getPriorityOfRole(roleId, groupId);
        if (oldPriority <= userPriority || priority <= userPriority) {
          throw new Error(
            'You cannot edit a role that is equal or higher rank than you (new or old).'
          );
        }
      }

      const roleDocRef = doc(firestore, 'roleGroups', groupId, 'roles', roleId);
      const roleSnapshot = await getDoc(roleDocRef);
      if (!roleSnapshot.exists()) {
        throw new Error('Role does not exist in editRole');
      }

      await updateDoc(roleDocRef, {
        name,
        description: description || '',
        icon: icon || '',
        permissions: permissions || [],
        priority,
      });

      console.log('Successfully edited role =>', { roleId, name });
      if (groupId === organizationData?.groupId) {
        await fetchUsersAndRoles();
      }
    } catch (error) {
      console.error('Error editing role:', error);
      throw error;
    }
  };

  // ----------------------------------------------------------
  // deleteRole => optionally do rank checks
  // ----------------------------------------------------------
  const deleteRole = async (roleId, targetGroupId = null) => {
    console.log('deleteRole => roleId:', roleId, 'targetGroupId:', targetGroupId);
    if (!roleId) {
      throw new Error('Role ID is required in deleteRole');
    }
    try {
      const groupId = targetGroupId || organizationData?.groupId;
      if (!groupId) throw new Error('No groupId in deleteRole');

      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user found in deleteRole');
      }

      // Check if current user is Admin. If yes, skip priority checks.
      const userIsAdmin = await isUserAdminInGroup(currentUser.uid, groupId);

      if (!userIsAdmin) {
        // Double Validation
        const userPriority = await getPriorityForUserInGroup(currentUser.uid, groupId);
        const oldPriority = await getPriorityOfRole(roleId, groupId);
        if (oldPriority <= userPriority) {
          throw new Error(
            'You cannot delete a role that has equal or higher rank than you.'
          );
        }
      }

      const roleDocRef = doc(firestore, 'roleGroups', groupId, 'roles', roleId);
      await deleteDoc(roleDocRef);

      console.log('Successfully deleted role =>', roleId);
      if (groupId === organizationData?.groupId) {
        await fetchUsersAndRoles();
      }
    } catch (error) {
      console.error('Error deleting role:', error);
      throw error;
    }
  };

  return (
    <UserManagementContext.Provider
      value={{
        // Exposed States
        users,
        roles,
        rolesMap,
        isUsersLoading,
        isUsersError,

        // Exposed Methods
        fetchUsersAndRoles,
        getUsersAndRolesByGroupId,
        sendPasswordResetEmail,

        addUser,
        editUser,
        deleteUser, // now sets role active:false for that group
        addRole,
        editRole,
        deleteRole,
      }}
    >
      {children}
    </UserManagementContext.Provider>
  );
};

export default UserManagementProvider;
