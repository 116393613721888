import React, { useState, useRef, useEffect, useContext } from 'react'; 
import { useTranslation } from 'react-i18next';
import TextField from '../FormBuilder/Fields/TextField';
import TextAreaField from '../FormBuilder/Fields/TextAreaField';
import SelectField from '../FormBuilder/Fields/SelectField';
import DatePicker from '../FormBuilder/Fields/DatePicker';
import TimePicker from '../FormBuilder/Fields/TimePicker';
import ToggleSwitch from '../FormBuilder/Fields/ToggleSwitch';
import ColorPicker from '../FormBuilder/Fields/ColorPicker';
import ActionButton from '../Common/ActionButton';
import CheckboxField from '../FormBuilder/Fields/CheckboxField';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import CustomModal from '../Common/CustomModal';
import './EventForm.css';

import { AppSettingsContext } from '../../contexts/AppSettingsContext';

const EventForm = ({
  initialData = {},
  onSave,
  onCancel,
  onDelete,
  onDeleteOccurrence,
  selectedDate,
}) => {
  const { t } = useTranslation();
  const { hasPermission } = useContext(AppSettingsContext);
  const canDeleteEvent = hasPermission('calendar', 'deleteEvent');

  // State
  const [name, setName] = useState(initialData.name || '');
  const [description, setDescription] = useState(initialData.description || '');
  const [visibility, setVisibility] = useState(initialData.visibility || 'open');
  const [status, setStatus] = useState(initialData.status || 'draft');
  const [location, setLocation] = useState(initialData.location || '');
  const [isAllDay, setIsAllDay] = useState(initialData.isAllDay || false);
  const [color, setColor] = useState(initialData.color || '#007bff');

  const isNameValid = name.trim().length > 0;

  const [eventType, setEventType] = useState(() => {
    if (initialData.isRecurring) {
      return 'recurring';
    } else if (initialData.isMultiDay) {
      return 'multi';
    } else {
      return 'single';
    }
  });

  const daysOfWeekOptions = [
    { value: '0', label: t('sundayAbbr', 'Sun') },
    { value: '1', label: t('mondayAbbr', 'Mon') },
    { value: '2', label: t('tuesdayAbbr', 'Tue') },
    { value: '3', label: t('wednesdayAbbr', 'Wed') },
    { value: '4', label: t('thursdayAbbr', 'Thu') },
    { value: '5', label: t('fridayAbbr', 'Fri') },
    { value: '6', label: t('saturdayAbbr', 'Sat') },
  ];

  const [recurringDays, setRecurringDays] = useState(() => {
    const initDays = initialData.recurringDays || [];
    return daysOfWeekOptions.map((option) => ({
      name: option.value,
      completed: initDays.includes(option.value),
    }));
  });

  // Date/Time default
  const defaultStartDate = initialData.startDateTime
    ? new Date(
        initialData.startDateTime.getFullYear(),
        initialData.startDateTime.getMonth(),
        initialData.startDateTime.getDate()
      )
    : new Date();

  const defaultEndDate = initialData.endDateTime
    ? new Date(
        initialData.endDateTime.getFullYear(),
        initialData.endDateTime.getMonth(),
        initialData.endDateTime.getDate()
      )
    : new Date();

  const defaultStartTime =
    initialData.startDateTime && !initialData.isAllDay
      ? new Date(
          1970,
          0,
          1,
          initialData.startDateTime.getHours(),
          initialData.startDateTime.getMinutes(),
          initialData.startDateTime.getSeconds()
        )
      : null;

  const defaultEndTime =
    initialData.endDateTime && !initialData.isAllDay
      ? new Date(
          1970,
          0,
          1,
          initialData.endDateTime.getHours(),
          initialData.endDateTime.getMinutes(),
          initialData.endDateTime.getSeconds()
        )
      : null;

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [startTime, setStartTime] = useState(defaultStartTime);
  const [endTime, setEndTime] = useState(defaultEndTime);

  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteAction, setDeleteAction] = useState(null); // 'single' or 'all'

  const [showExceptionModal, setShowExceptionModal] = useState(false);
  const [pendingEventData, setPendingEventData] = useState(null);

  // Validation errors
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [dateError, setDateError] = useState('');
  const [timeError, setTimeError] = useState('');
  const [recurringError, setRecurringError] = useState('');

  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };
    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutsideMenu);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [menuVisible]);

  const handleSave = (desiredStatus) => {
    setStatus(desiredStatus);
    setStartDateError('');
    setEndDateError('');
    setDateError('');
    setTimeError('');
    setRecurringError('');

    let hasError = false;

    // Name validation
    if (!isNameValid) {
      hasError = true;
    }

    // Date validations
    if (!startDate) {
      setStartDateError(t('eventForm.startDateRequired', 'Start date is required.'));
      hasError = true;
    }

    if ((eventType === 'multi' || eventType === 'recurring') && !endDate) {
      setEndDateError(t('eventForm.endDateRequired', 'End date is required.'));
      hasError = true;
    }

    if (
      startDate &&
      (eventType === 'multi' || eventType === 'recurring') &&
      endDate &&
      endDate < startDate
    ) {
      setDateError(
        t('eventForm.endDateBeforeStartDate', 'End Date cannot be before Start Date.')
      );
      hasError = true;
    }

    // Time validation if not all-day
    if (!isAllDay) {
      if (!startTime || !endTime) {
        setTimeError(
          t('eventForm.timeRequired', 'Start and End Time are required if not all-day.')
        );
        hasError = true;
      } else if (
        startDate &&
        endDate &&
        startDate.toDateString() === endDate.toDateString() &&
        endTime < startTime
      ) {
        setTimeError(
          t('eventForm.endTimeBeforeStartTime', 'End Time cannot be before Start Time.')
        );
        hasError = true;
      }
    }

    // Recurring validation
    const selectedRecurringDays = recurringDays
      .filter((day) => day.completed)
      .map((day) => day.name);

    if (eventType === 'recurring' && selectedRecurringDays.length === 0) {
      setRecurringError(
        t('eventForm.selectRecurringDays', 'Please select at least one recurring day.')
      );
      hasError = true;
    }

    if (hasError) {
      return;
    }

    // Construct eventData
    let startDateTime;
    let endDateTime;

    if (isAllDay) {
      // All-day => ignore times
      startDateTime = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      );
      if (eventType === 'single') {
        endDateTime = new Date(startDateTime);
      } else {
        endDateTime = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate()
        );
      }
    } else {
      // Not all-day
      if (eventType === 'single') {
        startDateTime = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          startTime ? startTime.getHours() : 0,
          startTime ? startTime.getMinutes() : 0,
          startTime ? startTime.getSeconds() : 0
        );
        endDateTime = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          endTime ? endTime.getHours() : 0,
          endTime ? endTime.getMinutes() : 0,
          endTime ? endTime.getSeconds() : 0
        );
      } else {
        // multi or recurring
        startDateTime = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          startTime ? startTime.getHours() : 0,
          startTime ? startTime.getMinutes() : 0,
          startTime ? startTime.getSeconds() : 0
        );
        endDateTime = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          endTime ? endTime.getHours() : 0,
          endTime ? endTime.getMinutes() : 0,
          endTime ? endTime.getSeconds() : 0
        );
      }
    }

    const eventData = {
      name: name.trim(),
      description: description || '',
      visibility: visibility || 'open',
      status: desiredStatus || 'draft',
      location: location || '',
      isAllDay: isAllDay || false,
      isRecurring: eventType === 'recurring',
      recurringDays: eventType === 'recurring' ? selectedRecurringDays : [],
      color: color || '#007bff',
      isMultiDay: eventType !== 'single',
      startDateTime,
      endDateTime,
      exceptionDates: initialData.exceptionDates || [],
    };

    const initialExceptionDates = initialData.exceptionDates || [];
    const isExceptionDatesExist = initialExceptionDates.length > 0;

    const oldEventType = initialData.isRecurring
      ? 'recurring'
      : initialData.isMultiDay
      ? 'multi'
      : 'single';

    const isEventChanged =
      eventType !== oldEventType ||
      (initialData.startDateTime &&
        (startDate.getFullYear() !== initialData.startDateTime.getFullYear() ||
         startDate.getMonth() !== initialData.startDateTime.getMonth() ||
         startDate.getDate() !== initialData.startDateTime.getDate())) ||
      (initialData.endDateTime &&
        (endDate.getFullYear() !== initialData.endDateTime.getFullYear() ||
         endDate.getMonth() !== initialData.endDateTime.getMonth() ||
         endDate.getDate() !== initialData.endDateTime.getDate())) ||
      JSON.stringify(selectedRecurringDays) !==
        JSON.stringify(initialData.recurringDays || []);

    if (isExceptionDatesExist && isEventChanged) {
      setPendingEventData(eventData);
      setShowExceptionModal(true);
    } else {
      onSave(eventData);
    }
  };

  return (
    <form className="event-form" onSubmit={(e) => e.preventDefault()}>
      {onDelete && canDeleteEvent && (
        <div className="delete-button-container" ref={menuRef}>
          <ActionButton
            onClick={() => setMenuVisible(!menuVisible)}
            icon="faTrash"
            colorType="danger"
            isMobile={false}
            size="small"
            ariaLabel={t('eventForm.deleteEvent', 'Delete Event')}
          />
          {menuVisible && (
            <div className="dropdown-menu">
              {(initialData.isMultiDay || initialData.isRecurring) ? (
                <>
                  <button
                    onClick={() => {
                      setDeleteAction('single');
                      setShowDeleteConfirmation(true);
                      setMenuVisible(false);
                    }}
                  >
                    {t('eventForm.deleteThisOccurrence', 'Delete This Date')}
                  </button>
                  <button
                    onClick={() => {
                      setDeleteAction('all');
                      setShowDeleteConfirmation(true);
                      setMenuVisible(false);
                    }}
                  >
                    {t('eventForm.deleteAllOccurrences', 'Delete Entire Series')}
                  </button>
                </>
              ) : (
                <button
                  onClick={() => {
                    setDeleteAction('single');
                    setShowDeleteConfirmation(true);
                    setMenuVisible(false);
                  }}
                >
                  {t('eventForm.deleteEvent', 'Delete Event')}
                </button>
              )}
            </div>
          )}
        </div>
      )}

      <div className="form-row">
        <div className="form-field full-width">
          <TextField
            label={t('eventForm.name', 'Name')}
            value={name}
            onChange={(value) => setName(value)}
            required
            error={!isNameValid ? t('eventForm.nameRequired', 'Name is required') : ''}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-field full-width">
          <TextAreaField
            label={t('eventForm.description', 'Description')}
            value={description}
            onChange={(value) => setDescription(value)}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-field half-width">
          <SelectField
            label={t('eventForm.visibility', 'Visibility')}
            value={visibility}
            onChange={(value) => setVisibility(value)}
            options={[
              { value: 'open', label: t('eventForm.open', 'Open') },
              { value: 'closed', label: t('eventForm.closed', 'Closed') },
            ]}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-field full-width">
          <TextField
            label={t('eventForm.location', 'Location')}
            value={location}
            onChange={(value) => setLocation(value)}
            placeholder={t('eventForm.locationPlaceholder', 'Enter location (optional)')}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-field full-width">
          <SelectField
            label={t('eventForm.eventType', 'Event Type')}
            value={eventType}
            onChange={(value) => setEventType(value)}
            options={[
              { value: 'single', label: t('eventForm.singleDayEvent', 'Single Day Event') },
              { value: 'multi', label: t('eventForm.multiDayEvent', 'Multi-Day Event') },
              { value: 'recurring', label: t('eventForm.recurringEvent', 'Recurring Event') },
            ]}
            required
            error={
              !eventType ? t('eventForm.eventTypeRequired', 'Event Type is required') : ''
            }
          />
        </div>
      </div>

      {eventType === 'recurring' && (
        <div className="form-row recurring-days-row">
          <div className="form-field full-width">
            <CheckboxField
              label={t('eventForm.recurringDays', 'Recurring Days')}
              options={daysOfWeekOptions}
              value={recurringDays}
              onChange={(values) => setRecurringDays(values)}
              layout="horizontal"
            />
            {recurringError && <div className="error-message-field">{recurringError}</div>}
          </div>
        </div>
      )}

      <div className="form-row">
        <div className="form-field half-width">
          <DatePicker
            label={
              eventType === 'single'
                ? t('eventForm.date', 'Date')
                : t('eventForm.startDate', 'Start Date')
            }
            value={startDate}
            onChange={(value) => setStartDate(value)}
            required
            error={startDateError}
          />
        </div>
        {(eventType === 'multi' || eventType === 'recurring') && (
          <div className="form-field half-width">
            <DatePicker
              label={t('eventForm.endDate', 'End Date')}
              value={endDate}
              onChange={(value) => setEndDate(value)}
              required
              error={endDateError}
            />
          </div>
        )}
        {dateError && <div className="error-message-field full-width">{dateError}</div>}
      </div>

      <div className="form-row">
        <div className="form-field half-width">
          <ToggleSwitch
            id="isAllDay"
            label={t('eventForm.allDay', 'All Day Event')}
            checked={isAllDay}
            onChange={(checked) => setIsAllDay(checked)}
          />
        </div>
      </div>

      {!isAllDay && (
        <div className="form-row">
          <div className="form-field half-width">
            <TimePicker
              label={t('eventForm.startTime', 'Start Time')}
              value={startTime}
              onChange={(value) => setStartTime(value)}
              required
              error={timeError && !startTime ? timeError : ''}
            />
          </div>
          <div className="form-field half-width">
            <TimePicker
              label={t('eventForm.endTime', 'End Time')}
              value={endTime}
              onChange={(value) => setEndTime(value)}
              required
              error={timeError && !endTime ? timeError : ''}
            />
          </div>
          {timeError && startTime && endTime && (
            <div className="error-message-field full-width">{timeError}</div>
          )}
        </div>
      )}

      <div className="form-row">
        <div className="form-field full-width">
          <ColorPicker
            label={t('eventForm.color', 'Event Color')}
            value={color}
            onChange={(value) => setColor(value)}
          />
        </div>
      </div>

      <div className="form-actions status-actions">
        <ActionButton
          type="button"
          text={t('eventForm.saveDraft', 'Save Draft')}
          icon="faSave"
          colorType="secondary"
          onClick={() => handleSave('draft')}
          fullWidth={true}
          disabled={!isNameValid}
        />
        <ActionButton
          type="button"
          text={t('eventForm.publish', 'Publish')}
          icon="faCheck"
          colorType="primary"
          onClick={() => handleSave('published')}
          fullWidth={true}
          disabled={!isNameValid}
        />
      </div>

      <ActionButton
        type="button"
        label={t('cancel', 'Cancel')}
        text={t('cancel', 'Cancel')}
        onClick={onCancel}
        icon="faTimes"
        colorType="secondary"
        fullWidth={true}
      />

      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          show={showDeleteConfirmation}
          onConfirm={() => {
            if (deleteAction === 'single') {
              onDeleteOccurrence?.(selectedDate);
            } else if (deleteAction === 'all') {
              onDelete?.();
            }
            setShowDeleteConfirmation(false);
          }}
          onCancel={() => setShowDeleteConfirmation(false)}
          title={
            deleteAction === 'single'
              ? t('eventForm.confirmDeleteDate', 'Confirm Delete Date')
              : t('eventForm.confirmDeleteSeries', 'Confirm Delete Series')
          }
          message={
            deleteAction === 'single'
              ? t(
                  'eventForm.confirmDeleteDateMessage',
                  'Are you sure you want to delete this date?'
                )
              : t(
                  'eventForm.confirmDeleteSeriesMessage',
                  'Are you sure you want to delete the entire series?'
                )
          }
        />
      )}

      {showExceptionModal && (
        <CustomModal
          show={showExceptionModal}
          onClose={() => setShowExceptionModal(false)}
          title={t('eventForm.exceptionDatesTitle', 'Exception Dates Detected')}
          closable={false}
        >
          <p>
            {t(
              'eventForm.exceptionDatesMessage',
              'You have previously deleted occurrences of this event.'
            )}
          </p>
          <p>
            {t(
              'eventForm.exceptionDatesRestoreMessage',
              'Changing the date range or recurrence pattern may restore these occurrences.'
            )}
          </p>
          <p>{t('eventForm.chooseOption', 'Please choose an option:')}</p>
          <div className="modal-actions">
            <ActionButton
              onClick={() => {
                const updatedEventData = {
                  ...pendingEventData,
                  exceptionDates: [],
                };
                setShowExceptionModal(false);
                onSave(updatedEventData);
              }}
              label={t('eventForm.overwriteClear', 'Overwrite and Clear')}
              text={t('eventForm.overwriteClear', 'Overwrite and Clear')}
              colorType="primary"
            />
            <ActionButton
              onClick={() => {
                setShowExceptionModal(false);
                onSave(pendingEventData);
              }}
              label={t('eventForm.keepExceptions', 'Keep Exceptions')}
              text={t('eventForm.keepExceptions', 'Keep Exceptions')}
              colorType="secondary"
            />
            <ActionButton
              onClick={() => {
                setShowExceptionModal(false);
              }}
              label={t('cancel', 'Cancel')}
              text={t('cancel', 'Cancel')}
              colorType="danger"
            />
          </div>
        </CustomModal>
      )}
    </form>
  );
};

export default EventForm;
