// src/components/Authentication/SignUpForm.jsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CatholicoreLogo from '../../assets/CatholicoreLogo.png';

// Steps
import Step1Form from './SignUpSteps/Step1Form';
import Step2Form from './SignUpSteps/Step2Form';
import Step3Form from './SignUpSteps/Step3Form';
import Step4Form from './SignUpSteps/Step4Form';
import Step5Form from './SignUpSteps/Step5Form';
import Step6Form from './SignUpSteps/Step6Form';

import IconMap from '../Common/IconMap'; // Adjust the path as necessary

import './SignUpForm.css';

const SignUpForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [signupResponse, setSignupResponse] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const totalSteps = 6;

  // Step labels with associated icons
  const stepLabels = [
    { label: t('signUp.step1Label'), icon: 'faEnvelope' },        // Enter Email
    { label: t('signUp.step2Label'), icon: 'faCheckCircle' },    // Verify Code
    { label: t('signUp.step3Label'), icon: 'faUser' },            // Personal Details
    { label: t('signUp.step4Label'), icon: 'faBuilding' },        // Organization Info
    { label: t('signUp.step5Label'), icon: 'faClipboardCheck' },  // Review & Confirm
    { label: t('signUp.step6Label'), icon: 'faSmile' },           // Complete
  ];

  const handleNext = (data) => {
    if (data.response) {
      setSignupResponse(data.response);
    } else {
      setFormData((prevData) => ({ ...prevData, ...data }));
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = (data) => {
    setFormData((prevData) => ({ ...prevData, ...data }));
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1Form onNext={handleNext} formData={formData} />;
      case 2:
        return <Step2Form onNext={handleNext} onBack={handleBack} formData={formData} />;
      case 3:
        return <Step3Form onNext={handleNext} onBack={handleBack} formData={formData} />;
      case 4:
        return <Step4Form onNext={handleNext} onBack={handleBack} formData={formData} />;
      case 5:
        return <Step5Form onNext={handleNext} onBack={handleBack} formData={formData} />;
      case 6:
        return <Step6Form formData={formData} response={signupResponse} />;
      default:
        return null;
    }
  };

  return (
    <div className="sign-up-split-container">
      {/* LEFT PANEL */}
      <div className="sign-up-left-panel">
        <div className="sign-up-left-logo">
          <a href="/">
            <img src={CatholicoreLogo} alt="Catholicore Logo" />
          </a>
        </div>

        <div className="sign-up-left-content">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={currentStep}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <div className="sign-up-step">
                {renderCurrentStep()}
              </div>
            </CSSTransition>
          </SwitchTransition>

          {/* "Already have an account?" button (only on Step 1) */}
          {currentStep === 1 && (
            <div className="sign-up-login-link">
              <button
                type="button"
                className="link-button"
                onClick={() => navigate('/login')}
              >
                {t('signUp.alreadyHaveAccount')}
              </button>
            </div>
          )}
        </div>

        <footer className="sign-up-footer desktop-footer">
          <div className="sign-up-policy-links">
            <a href="/policies?policy=privacy" className="sign-up-policy-link">
              Privacy Policy
            </a>
            <span className="sign-up-policy-separator">|</span>
            <a href="/policies?policy=terms" className="sign-up-policy-link">
              Terms of Service
            </a>
          </div>
        </footer>
      </div>

      {/* RIGHT PANEL (vertical/horizontal progress steps) */}
      <div className="sign-up-right-panel">
        <div className="sign-up-steps-container">
          <div className="sign-up-progress-bar">
            {stepLabels.map((step, index) => {
              const stepNumber = index + 1;
              const isActive = currentStep >= stepNumber;
              const isCompleted = currentStep > stepNumber;

              return (
                <div key={stepNumber} className="progress-step-wrapper">
                  <div className="progress-step-description">
                    {/* Icon */}
                    <FontAwesomeIcon
                      icon={IconMap[step.icon]}
                      className="progress-step-icon"
                    />
                    {/* Label */}
                    <span className="progress-step-label">
                      {step.label}
                    </span>
                  </div>
                  <div className="progress-step-marker">
                    <div
                      className={`progress-step-circle ${
                        isActive ? 'active' : ''
                      }`}
                    >
                      {isCompleted ? (
                        <span className="progress-step-checkmark">&#10003;</span>
                      ) : (
                        <span>{stepNumber}</span>
                      )}
                    </div>
                    {stepNumber < totalSteps && (
                      <div
                        className={`progress-step-line ${
                          isCompleted ? 'active' : ''
                        }`}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <footer className="sign-up-footer-mobile">
          <div className="sign-up-policy-links-mobile">
            <a href="/policies?policy=privacy" className="sign-up-policy-link-mobile">
              Privacy Policy
            </a>
            <span className="sign-up-policy-separator-mobile">|</span>
            <a href="/policies?policy=terms" className="sign-up-policy-link-mobile">
              Terms of Service
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default SignUpForm;
