import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import FormBuilder from './FormBuilder';
import FillOutForm from './FillOutForm';
import ResponsesTab from './ResponsesTab';
import ShareTab from './ShareTab';
import GeneralTab from './GeneralTab';
import Tabs from '../Common/Tabs';
import { DataContext } from '../../DataContext';
import './FormManager.css';
import { useTranslation } from 'react-i18next';
import ToastContainer from '../Common/ToastContainer';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

const FormManager = ({
  initialData = null,
  initialActiveTab = 'general',
  onUpdate,
}) => {
  const { t } = useTranslation();
  const {
    organizationData,
    saveForm,
    fetchResponsesForForm,
    deleteResponse,
    refetchAllForms,
  } = useContext(DataContext);

  // Pull from UserContext to check "editForm"
  const { hasPermission } = useContext(AppSettingsContext);
  const canEdit = hasPermission('forms', 'editForm');

  // Tabs
  const [activeTab, setActiveTab] = useState(initialActiveTab);

  // Responses
  const [responses, setResponses] = useState([]);

  // Selected form ID & form info
  const [selectedFormId, setSelectedFormId] = useState(initialData?.id || null);
  const [formName, setFormName] = useState('');
  const [isClosed, setIsClosed] = useState(false);
  const [formData, setFormData] = useState(initialData || {});
  const [isSavingGeneral, setIsSavingGeneral] = useState(false);

  // Toasts
  const [toasts, setToasts] = useState([]);

  const showToastMessage = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // Load initial data if provided
  useEffect(() => {
    if (initialData) {
      setFormName(initialData.name || '');
      setIsClosed(initialData.isClosed || false);
      setSelectedFormId(initialData.id);
      setFormData(initialData);

      // If the initialActiveTab is 'responses', go directly there
      if (initialActiveTab === 'responses') {
        setActiveTab('responses');
      } else {
        setActiveTab('general');
      }
    } else {
      // If no initial data, just open the specified tab
      setActiveTab(initialActiveTab);
    }
  }, [initialData, initialActiveTab]);

  // Define your form tabs
  const formTabs = [
    { id: 'general', label: t('tabs.general') },
    { id: 'builder', label: t('tabs.builder') },
    { id: 'fill', label: t('tabs.preview') },
    { id: 'responses', label: t('tabs.responses') },
    { id: 'share', label: t('tabs.share') },
  ];

  // Update the form in Firestore
  const handleUpdateForm = async (updatedFormData) => {
    try {
      await saveForm(selectedFormId, updatedFormData);
      setFormName(updatedFormData.name);
      setIsClosed(updatedFormData.isClosed);
      setFormData(updatedFormData);
      refetchAllForms();
      if (onUpdate) {
        onUpdate(updatedFormData);
      }
    } catch (error) {
      showToastMessage(t('formManager.errorUpdatingForm'), 'error');
    }
  };

  // Save "General" tab
  const handleSaveGeneral = async () => {
    if (!canEdit) {
      showToastMessage(t('formManager.noEditPermission'), 'error');
      return;
    }
    if (!formName.trim()) {
      showToastMessage(t('formManager.formNameRequired'), 'error');
      return;
    }

    setIsSavingGeneral(true);

    try {
      // Certain built-in forms might have a locked name
      const isNameEditable = !['membersForm', 'groupsForm'].includes(selectedFormId);

      const updatedFormData = {
        isClosed,
      };

      if (isNameEditable) {
        updatedFormData.name = formName.trim();
      }

      if (selectedFormId) {
        // Update existing form
        await saveForm(selectedFormId, updatedFormData);
        showToastMessage(
          isNameEditable
            ? t('formManager.generalInfoUpdated')
            : t('formManager.generalInfoUpdatedWithoutName'),
          'success'
        );
        setFormData((prev) => ({ ...prev, ...updatedFormData }));
      } else {
        // Create new form
        const newFormId = await saveForm(null, updatedFormData);
        showToastMessage(t('formManager.newFormCreated'), 'success');
        setSelectedFormId(newFormId);
        setActiveTab('builder');
        setFormData({ id: newFormId, ...updatedFormData });
      }
      refetchAllForms();
    } catch (error) {
      console.error('Error saving general settings:', error);
      showToastMessage(t('formManager.errorSavingGeneral'), 'error');
    } finally {
      setIsSavingGeneral(false);
    }
  };

  // If we switch to 'responses' tab, fetch form responses
  useEffect(() => {
    if (activeTab !== 'responses') return;

    const fetchResponses = async () => {
      try {
        const fetchedResponses = await fetchResponsesForForm(selectedFormId);
        setResponses(fetchedResponses);
      } catch (error) {
        console.error('Error fetching responses:', error);
        showToastMessage(t('formManager.errorFetchingResponses'), 'error');
      }
    };

    if (selectedFormId) {
      fetchResponses();
    }
  }, [activeTab, selectedFormId, fetchResponsesForForm, t]);

  // Delete a specific response from Firestore
  const handleDeleteResponse = async (responseId) => {
    if (!canEdit) {
      showToastMessage(t('formManager.noEditPermissionResponses'), 'error');
      return;
    }
    try {
      await deleteResponse(selectedFormId, responseId);
      setResponses((prev) => prev.filter((r) => r.id !== responseId));
      showToastMessage(t('formManager.responseDeleted'), 'success');
    } catch (error) {
      console.error('Error deleting response:', error);
      showToastMessage(t('formManager.errorDeletingResponse'), 'error');
    }
  };

  return (
    <div className="form-manager-container">
      <div className="form-content">
        {/* Reusable Tabs component */}
        <Tabs
          tabs={formTabs}
          selectedTab={activeTab}
          onTabSelect={setActiveTab}
        />

        <div className="tab-content-container">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={activeTab}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <div className="tab-content">
                {activeTab === 'general' && (
                  <GeneralTab
                    formId={selectedFormId}
                    formName={formName}
                    setFormName={setFormName}
                    isClosed={isClosed}
                    setIsClosed={setIsClosed}
                    onSave={handleSaveGeneral}
                    isSaving={isSavingGeneral}
                  />
                )}

                {activeTab === 'builder' && (
                  selectedFormId ? (
                    <FormBuilder
                      initialData={formData}
                      onUpdate={handleUpdateForm}
                      showToast={showToastMessage}
                      // Pass readOnly if user lacks editForm
                      readOnly={!canEdit}
                    />
                  ) : (
                    <div className="info-message">
                      {t('formManager.assignFormNameToBuild')}
                    </div>
                  )
                )}

                {activeTab === 'fill' && (
                  selectedFormId ? (
                    <FillOutForm
                      fields={formData.fields || []}
                      formId={selectedFormId}
                      showToast={showToastMessage}
                    />
                  ) : (
                    <div className="info-message">
                      {t('formManager.assignFormNameToFill')}
                    </div>
                  )
                )}

                {activeTab === 'responses' && (
                  selectedFormId ? (
                    <ResponsesTab
                      formId={selectedFormId}
                      fields={formData.fields || []}
                      responses={responses}
                      showToast={showToastMessage}
                      onDeleteResponse={handleDeleteResponse}
                      canEdit={canEdit} // pass canEdit to hide Edit/Delete if false
                    />
                  ) : (
                    <div className="info-message">
                      {t('formManager.assignFormNameToViewResponses')}
                    </div>
                  )
                )}

                {activeTab === 'share' && (
                  selectedFormId ? (
                    <ShareTab
                      formId={selectedFormId}
                      formName={formName}
                      groupId={organizationData.groupId}
                      showToast={showToastMessage}
                    />
                  ) : (
                    <div className="info-message">
                      {t('formManager.assignFormNameToShare')}
                    </div>
                  )
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>

      {/* Toast Notifications */}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

FormManager.propTypes = {
  initialData: PropTypes.object,
  initialActiveTab: PropTypes.oneOf(['general','builder','fill','responses','share']),
  onUpdate: PropTypes.func.isRequired,
};

FormManager.defaultProps = {
  initialData: null,
  initialActiveTab: 'general',
};

export default FormManager;
