// src/components/FormBuilder/Fields/ImagePicker.jsx

import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../Common/Spinner';
import ContextMenu from '../../Common/ContextMenu';
import placeholderImage from '../../../assets/placeholder.png';
import imageCompression from 'browser-image-compression';
import './ImagePicker.css';

// Example context for org data:
import { AppSettingsContext } from '../../../contexts/AppSettingsContext';

/**
 * Loads file or blob into an <img>.
 */
async function loadImageElement(fileOrBlob) {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(fileOrBlob);
    const img = new Image();
    img.onload = () => {
      URL.revokeObjectURL(url);
      resolve(img);
    };
    img.onerror = reject;
    img.src = url;
  });
}

/**
 * Crop to square from center, resize to dimension × dimension.
 * Returns a Blob (JPEG).
 */
async function centerCropAndResize(file, dimension) {
  const img = await loadImageElement(file);
  const { width, height } = img;
  const minSide = Math.min(width, height);
  const startX = (width - minSide) / 2;
  const startY = (height - minSide) / 2;

  const canvas = document.createElement('canvas');
  canvas.width = dimension;
  canvas.height = dimension;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    img,
    startX,
    startY,
    minSide,
    minSide,
    0,
    0,
    dimension,
    dimension
  );

  const blob = await new Promise((resolve) => {
    canvas.toBlob((b) => resolve(b), 'image/jpeg', 1.0);
  });
  return blob;
}

/**
 * Multi-pass compress to keep size < targetKB
 */
async function compressBelowTargetKB(blob, targetKB, onProgress) {
  let currentBlob = blob;
  let currentSizeKB = currentBlob.size / 1024;

  const qualities = [0.8, 0.6, 0.4, 0.3, 0.2, 0.1];
  let pass = 0;

  while (currentSizeKB > targetKB && pass < qualities.length) {
    const options = {
      maxSizeMB: targetKB / 1024,
      initialQuality: qualities[pass],
      useWebWorker: true,
      onProgress,
    };
    try {
      const compressed = await imageCompression(currentBlob, options);
      currentBlob = compressed;
      currentSizeKB = compressed.size / 1024;
      console.log(`Compression pass #${pass + 1}, q=${qualities[pass]}, size=${Math.round(currentSizeKB)}KB`);
    } catch (err) {
      console.error(`Compression pass #${pass + 1} error:`, err);
      break;
    }
    pass++;
  }
  return currentBlob;
}

/**
 * Actually POST the file to your upload endpoint.
 */
async function uploadImageImmediately(file, folderPath) {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('folderPath', folderPath);

  const res = await fetch('https://uploadimage-lgfph5hmwq-uc.a.run.app', {
    method: 'POST',
    body: formData,
  });
  if (!res.ok) {
    throw new Error(`uploadImageImmediately error: ${res.status}`);
  }
  const data = await res.json();
  return data.imageUrl;
}

const ImagePicker = ({
  label,
  name,
  value,
  onChange,
  required,
  imageShape = 'circular',
  maxFileSize = 5120,      // ~5MB
  defaultImage,
  maxFinalSizeKB = 500,    // e.g. 500KB
  maxDimension = 200,      // final dimension
  responseId = 'temp',     // ADDED: fallback if not passed
}) => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [compressionProgress, setCompressionProgress] = useState(0);

  // For context menu
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

  // Example: get org data from context
  const { organizationData } = useContext(AppSettingsContext);
  // Build folder path => "orgId/groupId/members"
  const folderPath = organizationData
    ? `${organizationData.organizationId}/${organizationData.groupId}/members`
    : 'fallbackFolder';

  // Keep old blob URLs
  const prevPreviewUrlRef = useRef(null);
  useEffect(() => {
    return () => {
      if (prevPreviewUrlRef.current && prevPreviewUrlRef.current.startsWith('blob:')) {
        URL.revokeObjectURL(prevPreviewUrlRef.current);
      }
    };
  }, []);

  // Set initial preview
  useEffect(() => {
    if (typeof value === 'string' && value.trim() !== '') {
      setPreviewUrl(value);
      prevPreviewUrlRef.current = null;
    } else if (defaultImage) {
      setPreviewUrl(defaultImage);
      prevPreviewUrlRef.current = null;
    } else {
      setPreviewUrl(placeholderImage);
      prevPreviewUrlRef.current = null;
    }
  }, [value, defaultImage]);

  /**
   * When user picks a file
   */
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      onChange(null);
      setPreviewUrl(defaultImage || placeholderImage);
      setError('');
      return;
    }

    // Check raw size
    const fileSizeKB = file.size / 1024;
    if (fileSizeKB > maxFileSize) {
      alert(`File is ${Math.round(fileSizeKB)}KB, exceeds limit of ${maxFileSize}KB.`);
      return;
    }

    setLoading(true);
    setError('');
    setCompressionProgress(0);

    try {
      // 1) center crop + resize => JPEG Blob
      const croppedBlob = await centerCropAndResize(file, maxDimension);

      // 2) compress
      let finalBlob = croppedBlob;
      if (finalBlob.size / 1024 > maxFinalSizeKB) {
        finalBlob = await compressBelowTargetKB(finalBlob, maxFinalSizeKB, (p) => {
          setCompressionProgress(p);
        });
      }

      // 3) build a name => e.g. "Bj5cQK1jmXiiQntrpmaK_01_15_25_09_48_24.jpg"
      // We generate a date/time stamp => MM_DD_YY_HH_mm_ss
      const now = new Date();
      const mm = String(now.getMonth() + 1).padStart(2, '0');
      const dd = String(now.getDate()).padStart(2, '0');
      const yy = String(now.getFullYear()).slice(-2);
      const hh = String(now.getHours()).padStart(2, '0');
      const min = String(now.getMinutes()).padStart(2, '0');
      const sec = String(now.getSeconds()).padStart(2, '0');

      const timestamp = `${mm}${dd}${yy}_${hh}${min}${sec}`;

      // If original was "image/png", let's do .png, else .jpg
      let extension = file.type.includes('png') ? '.png' : '.jpg';
      const newFileName = `${responseId}_${timestamp}${extension}`;

      // 4) convert Blob => File
      const finalFile = new File([finalBlob], newFileName, {
        type: file.type.includes('png') ? 'image/png' : 'image/jpeg',
        lastModified: Date.now(),
      });

      // 5) Upload
      const uploadedUrl = await uploadImageImmediately(finalFile, folderPath);

      // 6) set preview + call onChange
      setPreviewUrl(uploadedUrl);
      onChange(uploadedUrl);
    } catch (err) {
      console.error('Error processing/uploading image:', err);
      setError('Failed to process image.');
    } finally {
      setLoading(false);
      setCompressionProgress(0);
    }
  };

  const handleRemove = () => {
    onChange(null);
    setPreviewUrl(defaultImage || placeholderImage);
    setError('');
  };

  // Right-click / 3-dot
  const handleOpenContextMenu = (e) => {
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    setContextMenuPosition({ x: rect.left, y: rect.bottom });
    setIsContextMenuVisible(true);
  };
  const handleCloseContextMenu = () => {
    setIsContextMenuVisible(false);
  };

  return (
    <div className="image-picker-container">
      <div className="image-picker">
        <input
          type="file"
          id={name}
          name={name}
          accept="image/png, image/jpeg"
          onChange={handleImageChange}
          className="image-picker-input"
        />
        <label htmlFor={name} className="image-picker-label">
          <div className="image-picker-wrapper">
            <div
              className={`image-picker-icon ${imageShape}`}
              onContextMenu={handleOpenContextMenu}
            >
              {loading && (
                <div className="image-spinner">
                  <Spinner size="50px" fullScreen={false} />
                </div>
              )}
              {!loading && (
                <img
                  src={previewUrl}
                  alt={label || 'Selected'}
                  onError={() => {
                    setPreviewUrl(defaultImage || placeholderImage);
                    setError('Failed to load image.');
                  }}
                />
              )}
            </div>

            {previewUrl &&
              previewUrl !== placeholderImage &&
              previewUrl !== defaultImage &&
              !loading && (
                <button
                  type="button"
                  className="three-dot-menu"
                  onClick={handleOpenContextMenu}
                  aria-label="Image options"
                >
                  ⋮
                </button>
              )}
          </div>

          {label && (
            <span className="image-picker-text">
              {label}
              {required && <span className="required-asterisk">*</span>}
            </span>
          )}
        </label>
      </div>

      {error && <p className="image-picker-error">{error}</p>}

      {compressionProgress > 0 && compressionProgress < 100 && (
        <div className="image-compression-progress">
          Compressing... {Math.round(compressionProgress)}%
        </div>
      )}

      {/* 3-dot context menu => "Remove" */}
      <ContextMenu
        isVisible={isContextMenuVisible}
        position={contextMenuPosition}
        onClose={handleCloseContextMenu}
        options={[
          { label: 'Remove', onClick: handleRemove },
        ]}
      />
    </div>
  );
};

ImagePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  /**
   * 'circular' or 'square'
   */
  imageShape: PropTypes.oneOf(['circular', 'square']),
  maxFileSize: PropTypes.number,
  defaultImage: PropTypes.string,
  maxFinalSizeKB: PropTypes.number,
  maxDimension: PropTypes.number,

  /**
   * The ID for naming the file. If none, fallback is 'temp'.
   */
  responseId: PropTypes.string,
};

ImagePicker.defaultProps = {
  label: '',
  value: null,
  required: false,
  imageShape: 'circular',
  maxFileSize: 5120,
  defaultImage: null,
  maxFinalSizeKB: 500,
  maxDimension: 200,
  responseId: 'temp',
};

export default ImagePicker;
