import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

const CustomDatePicker = ({ label, name, value, onChange, placeholder, required, error }) => {
  const [localError, setLocalError] = useState(error);

  useEffect(() => {
    setLocalError(error);
  }, [error]);

  const handleDateChange = (date) => {
    onChange(date);

    if (required && !date) {
      setLocalError('This field is required.');
    } else {
      setLocalError('');
    }
  };

  const handleBlur = () => {
    if (required && !value) {
      setLocalError('This field is required.');
    }
  };

  // Convert the value to a Date object if it's a string
  const selectedDate = value && typeof value === 'string' ? new Date(value) : value;

  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {required && <span className="required-asterisk">*</span>}
      </label>
      <ReactDatePicker
        id={name}
        selected={selectedDate}
        onChange={handleDateChange}
        onBlur={handleBlur}
        placeholderText={placeholder}
        className={`form-control ${localError ? 'input-error' : ''}`}
        dateFormat="MM/dd/yyyy"
        isClearable
      />
      {localError && <span className="error-message">{localError}</span>}
    </div>
  );
};

CustomDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
};

CustomDatePicker.defaultProps = {
  name: '',
  value: null,
  placeholder: '',
  required: false,
  error: '',
};

export default CustomDatePicker;
