import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TeamsTable from './TeamsTable';
import { DataContext } from '../../DataContext';
import { UserContext } from '../../contexts/UserContext'; // for hasPermission
import Spinner from '../Common/Spinner';
import CustomModal from '../Common/CustomModal';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import ToastContainer from '../Common/ToastContainer';

import TeamForm from '../Teams/TeamForm';
import ActionButton from '../Common/ActionButton';
import TeamTypesModal from '../Teams/TeamTypesModal';
import Tabs from '../Common/Tabs';
import IconMap from '../Common/IconMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

import './TeamsPage.css';

const TeamsPage = () => {
  const { t } = useTranslation();

  // Pull from DataContext
  const {
    teams,
    teamTypes,
    isTeamsLoading,
    addTeam,
    editTeam,
    deleteTeam,
    fetchTeams,
    fetchTeamTypes,
    useResponses,
  } = useContext(DataContext);

  // Pull from UserContext (for permissions)
  const { userRolesLoading } = useContext(UserContext);
  const { hasPermission } = useContext(AppSettingsContext);

  // Local state
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [name, setName] = useState('');
  const [members, setMembers] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [editingTeam, setEditingTeam] = useState(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');
  const [toasts, setToasts] = useState([]);
  const [showTeamTypesModal, setShowTeamTypesModal] = useState(false);
  const [selectedTeamType, setSelectedTeamType] = useState(null);

  // Hook to fetch members for populating team
  const {
    data: responses = [],
    isLoading: membersLoading,
  } = useResponses('membersForm');

  // Convert responses → members array
  const membersData = responses.map((response) => {
    const member = {};
    response.fields.forEach((field) => {
      member[field.id] = field.value;
    });
    member.id = response.id;
    member.fields = response.fields;
    member.submittedAt = response.submittedAt;
    member.createdAt = response.createdAt;
    member.updatedAt = response.updatedAt;
    member.groupId = response.groups ? response.groups[0].groupId : '';
    return member;
  });

  // Toast helpers
  const showToast = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prev) => [...prev, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // Handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Initial data fetch
  useEffect(() => {
    fetchTeams();
    fetchTeamTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Auto-select the first team type
  useEffect(() => {
    if (teamTypes.length > 0 && !selectedTeamType) {
      setSelectedTeamType(teamTypes[0].id);
    }
  }, [teamTypes, selectedTeamType]);

  // Permission checks
  // If user doesn't have 'viewTeam', show 403
  if (userRolesLoading) {
    return <div className="loading-container">Loading user permissions...</div>;
  }
  if (!hasPermission('teams', 'viewTeam')) {
    return (
      <div className="access-denied">
        403 - You do not have permission to view teams.
      </div>
    );
  }

  // Loading states
  if (isTeamsLoading || membersLoading) {
    return (
      <div className="loading-container">
        <Spinner size="100px" />
      </div>
    );
  }

  // ----------------------------------------------------------------
  // Delete a team
  // ----------------------------------------------------------------
  const handleDeleteTeam = (team) => {
    // Must have 'deleteTeam' to proceed
    if (!hasPermission('teams', 'deleteTeam')) {
      showToast(t('teams.noDeletePermission'), 'error');
      return;
    }
    setTeamToDelete(team);
    setDeleteModalMessage(
      t('deleteConfirmation.message', { item: team.name })
    );
    setShowDeleteModal(true);
  };

  const confirmDeleteTeam = async () => {
    if (!teamToDelete) return;
    try {
      await deleteTeam(teamToDelete.id);
      showToast(t('teams.deleteSuccess'), 'success');
    } catch (error) {
      console.error('Error deleting team: ', error);
      showToast(`${t('teams.deleteError')}: ${error.message}`, 'error');
    } finally {
      setShowDeleteModal(false);
      setTeamToDelete(null);
    }
  };

  // ----------------------------------------------------------------
  // Add a team
  // ----------------------------------------------------------------
  const handleAddTeam = () => {
    // Must have 'addTeam' permission
    if (!hasPermission('teams', 'addTeam')) {
      showToast(t('teams.noAddPermission'), 'error');
      return;
    }
    setModalTitle(t('teams.addTeam'));
    setName('');
    setMembers([]);
    setLeaders([]);
    setEditingTeam(null);
    setShowTeamModal(true);
  };

  // ----------------------------------------------------------------
  // Edit a team (or just "View" if user lacks 'editTeam')
  // ----------------------------------------------------------------
  const handleEditTeam = (team) => {
    // If we can edit => "Edit", else if we only have "viewTeam" => read-only
    const canEdit = hasPermission('teams', 'editTeam');

    // If user lacks even 'viewTeam', do nothing
    if (!hasPermission('teams', 'viewTeam')) {
      showToast(t('teams.noViewPermission'), 'error');
      return;
    }

    // Pre-fill the form
    setModalTitle(
      canEdit
        ? t('teams.editTeam', { name: team.name })
        : t('teams.viewTeam', { name: team.name })
    );
    setName(team.name);

    // Extract member IDs
    const memberIds = (team.membersData || []).map((member) => {
      const groupId = member.groupId || '';
      return groupId + '_' + member.id;
    });
    setMembers(memberIds);

    // Determine which are leaders
    const leaderKeys = team.membersData
      .filter((m) => m.leader === true)
      .map((m) => `${m.groupId}_${m.id}`);
    setLeaders(leaderKeys);

    setEditingTeam(team);
    setShowTeamModal(true);
  };

  // ----------------------------------------------------------------
  // Submit form (Add or Edit)
  // ----------------------------------------------------------------
  const handleTeamModalSubmit = async (e) => {
    e.preventDefault();

    if (!name.trim()) {
      showToast(t('teams.nameRequired'), 'error');
      return;
    }

    const payload = {
      name,
      members,
      leaders,
      teamTypeId: selectedTeamType,
    };

    if (!editingTeam) {
      // CREATE
      try {
        // Must have addTeam
        if (!hasPermission('teams', 'addTeam')) {
          showToast(t('teams.noAddPermission'), 'error');
          return;
        }
        await addTeam(payload);
        showToast(t('teams.addSuccess'), 'success');
        setShowTeamModal(false);
      } catch (error) {
        console.error('Error adding team: ', error);
        showToast(`${t('teams.addError')}: ${error.message}`, 'error');
      }
    } else {
      // UPDATE
      try {
        // Must have editTeam
        if (!hasPermission('teams', 'editTeam')) {
          showToast(t('teams.noEditPermission'), 'error');
          return;
        }
        await editTeam(editingTeam.id, payload);
        showToast(t('teams.updateSuccess'), 'success');
        setShowTeamModal(false);
        setEditingTeam(null);
      } catch (error) {
        console.error('Error updating team: ', error);
        showToast(`${t('teams.updateError')}: ${error.message}`, 'error');
      }
    }
  };

  // ----------------------------------------------------------------
  // Filter teams by selectedTeamType
  // ----------------------------------------------------------------
  const filteredTeams = selectedTeamType
    ? teams.filter((team) => team.teamTypeId === selectedTeamType)
    : teams;

  // Check if no team types exist
  const hasNoTeamTypes = teamTypes.length === 0;

  // Prepare the tab data
  const tabsData = teamTypes.map((type) => {
    let labelContent = type.name;
    if (type.icon && IconMap[type.icon]) {
      labelContent = (
        <>
          <FontAwesomeIcon
            icon={IconMap[type.icon]}
            style={{ color: 'var(--main-color)', marginRight: '6px' }}
          />
          {type.name}
        </>
      );
    }
    return {
      id: type.id,
      label: labelContent,
    };
  });

  // If user doesn't have 'configureTeam', hide the gear icon
  const canConfigureTeamTypes = hasPermission('teams', 'configureTeam');

  return (
    <div className="teams-page-container">
      <div className="teams-page-header">
        <h2>{t('teamsHeader.title')}</h2>
        {/* Show gear only if user has 'configureTeam' */}
        {canConfigureTeamTypes && (
          <div className="team-types-gear-button">
            <ActionButton
              onClick={() => setShowTeamTypesModal(true)}
              text=""
              icon="faGear"
              isMobile={isMobile}
              colorType="secondary"
              ariaLabel="Manage Team Types"
            />
          </div>
        )}
      </div>

      {hasNoTeamTypes ? (
        <div className="no-team-types-cta">
          <h3>{t('teamsPage.noTeamTypesMessage', 'No team types found.')}</h3>
          <p className="cta-description">
            {t('teamsPage.createYourFirstTypeInfo', 'Start by creating a Team Type to organize your teams.')}
          </p>
          {canConfigureTeamTypes && (
            <ActionButton
              onClick={() => setShowTeamTypesModal(true)}
              text={t('teamsPage.createFirstTeamType', 'Create a Team Type')}
              icon="faPlus"
              colorType="primary"
              isMobile={isMobile}
            />
          )}
        </div>
      ) : (
        <>
          <div className="teams-page-tabs">
            <Tabs
              tabs={tabsData}
              selectedTab={selectedTeamType || ''}
              onTabSelect={setSelectedTeamType}
            />
          </div>

          <TeamsTable
            teams={filteredTeams}
            handleAddTeam={handleAddTeam}
            handleEditTeam={handleEditTeam}
            handleDeleteTeam={handleDeleteTeam}
            isTeamsLoading={isTeamsLoading}
            isMobile={isMobile}
          />
        </>
      )}

      {/* Add/Edit Team Modal */}
      {showTeamModal && (
        <CustomModal
          show={showTeamModal}
          onClose={() => setShowTeamModal(false)}
          title={modalTitle}
        >
          <TeamForm
            name={name}
            setName={setName}
            members={members}
            setMembers={setMembers}
            leaders={leaders}
            setLeaders={setLeaders}
            isMobile={isMobile}
            onSubmit={handleTeamModalSubmit}
            isEditing={editingTeam !== null}
            membersData={membersData}
          />
        </CustomModal>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmationModal
          show={showDeleteModal}
          onConfirm={confirmDeleteTeam}
          onCancel={() => setShowDeleteModal(false)}
          title={t('deleteConfirmation.title')}
          message={deleteModalMessage}
          isMobile={isMobile}
        />
      )}

      {/* TeamTypesModal (Configure) */}
      {showTeamTypesModal && canConfigureTeamTypes && (
        <TeamTypesModal
          show={showTeamTypesModal}
          onClose={() => setShowTeamTypesModal(false)}
          showToast={showToast}
        />
      )}

      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

export default TeamsPage;
