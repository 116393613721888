// src/contexts/UserContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [authInitialized, setAuthInitialized] = useState(false);
  const [currentUser, setCurrentUser] = useState(auth.currentUser);

  // For user roles
  const [userRoles, setUserRoles] = useState([]);
  const [userRolesLoading, setUserRolesLoading] = useState(false);

  // Extra user data
  const [userData, setUserData] = useState({
    fullName: '',
    profilePicture: '',
    email: '',
    firstName: '',
    lastName: '',
    language: '',
    timeZone: '',
  });

  // Optionally track the groups
  const [userGroups, setUserGroups] = useState([]);

  /**
   * onAuthStateChanged => sets currentUser + authInitialized
   */
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setAuthInitialized(true);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  /**
   * fetchUserRoleAndData => Firestore user doc => roles => userData
   * Each role doc may contain `priority`.
   */
  const fetchUserRoleAndData = useCallback(async () => {
    if (!currentUser) {
      // If no user => clear roles + userData
      setUserRoles([]);
      setUserData({
        fullName: '',
        profilePicture: '',
        email: '',
        firstName: '',
        lastName: '',
        language: '',
        timeZone: '',
      });
      return;
    }

    try {
      setUserRolesLoading(true);

      // Grab user doc
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      const userSnap = await getDoc(userDocRef);

      if (userSnap.exists()) {
        const data = userSnap.data();
        const rolesFromDoc = data.roles || [];

        // For each role ref => fetch role doc (which may include priority)
        const updatedRoles = await Promise.all(
          rolesFromDoc.map(async (entry) => {
            if (entry.role) {
              const roleSnap = await getDoc(entry.role);
              if (roleSnap.exists()) {
                // roleSnap.data() might include => name, permissions, priority, etc.
                return { ...entry, roleData: roleSnap.data() };
              }
            }
            // fallback if role doesn't exist
            return {
              ...entry,
              roleData: { name: 'Unknown', permissions: [], priority: 999 },
            };
          })
        );

        setUserRoles(updatedRoles);

        // Combine name
        const fullName =
          `${data.firstName || ''} ${data.lastName || ''}`.trim() ||
          currentUser.email;

        setUserData({
          fullName,
          profilePicture: data.profilePicture || '',
          email: data.email || currentUser.email,
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          language: data.language || '',
          timeZone: data.timeZone || '',
        });
      } else {
        // If no doc => fallback
        setUserRoles([]);
        setUserData({
          fullName: currentUser.email,
          profilePicture: '',
          email: currentUser.email,
          firstName: '',
          lastName: '',
          language: '',
          timeZone: '',
        });
      }
    } catch (err) {
      console.error('[UserContext] => error in fetchUserRoleAndData:', err);
    } finally {
      setUserRolesLoading(false);
    }
  }, [currentUser]);

  /**
   * Whenever currentUser changes => fetchUserRoleAndData
   */
  useEffect(() => {
    if (currentUser) {
      setUserRolesLoading(true);
    }
    fetchUserRoleAndData();
  }, [currentUser, fetchUserRoleAndData]);

  /**
   * If userRoles changes => fetch group docs (if needed)
   */
  useEffect(() => {
    const fetchGroups = async () => {
      if (!Array.isArray(userRoles)) {
        setUserGroups([]);
        return;
      }
      const resolvedGroups = [];
      try {
        for (const roleEntry of userRoles) {
          if (!roleEntry.groupId) continue;
          const gSnap = await getDoc(roleEntry.groupId);
          if (gSnap.exists()) {
            resolvedGroups.push({ id: gSnap.id, ...gSnap.data(), roleEntry });
          } else {
            resolvedGroups.push({
              id: roleEntry.groupId.id,
              groupName: '[Group data unavailable]',
              roleEntry,
            });
          }
        }
      } catch (error) {
        console.error('[UserContext] => Error fetching user groups:', error);
      }
      setUserGroups(resolvedGroups);
    };

    if (userRoles.length > 0) {
      fetchGroups();
    } else {
      setUserGroups([]);
    }
  }, [userRoles]);

  /**
   * If you need a manual refetch
   */
  const refetchUserRoleAndData = useCallback(() => {
    return fetchUserRoleAndData();
  }, [fetchUserRoleAndData]);

  /**
   * hasPermission => checks if user has a certain subPermission
   */
  const hasPermission = (moduleName, permissionName, groupId) => {
    if (!Array.isArray(userRoles)) return false;

    // Filter to roles that match the target group (if provided)
    const rolesToCheck = groupId
      ? userRoles.filter((r) => r.groupId?.id === groupId)
      : userRoles;

    for (const roleEntry of rolesToCheck) {
      const { roleData } = roleEntry;
      if (!roleData || !Array.isArray(roleData.permissions)) continue;

      const moduleObj = roleData.permissions.find((p) => p.name === moduleName);
      if (!moduleObj || !moduleObj.subPermissions) continue;

      const subPermObj = moduleObj.subPermissions.find(
        (subPerm) => subPerm.name === permissionName
      );
      if (subPermObj && subPermObj.completed) {
        return true;
      }
    }
    return false;
  };

  /**
   * OPTIONAL: getUserPriorityForGroup => returns the lowest numeric priority
   * among all roles for a given groupId. If none found => 999
   */
  const getUserPriorityForGroup = (groupId) => {
    if (!Array.isArray(userRoles)) return 999;
    const relevant = userRoles.filter((r) => r.groupId?.id === groupId);
    if (relevant.length === 0) return 999;

    let minPriority = 999;
    for (const entry of relevant) {
      const pr = entry.roleData?.priority;
      if (typeof pr === 'number' && pr < minPriority) {
        minPriority = pr;
      }
    }
    return minPriority;
  };

  /**
   * updateUserProfile
   */
  const updateUserProfile = async (profile) => {
    if (!currentUser) {
      throw new Error('No authenticated user found.');
    }

    const userDocRef = doc(firestore, 'users', currentUser.uid);
    await updateDoc(userDocRef, {
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      profilePicture: profile.profilePicture,
      language: profile.language,
      timeZone: profile.timeZone,
    });

    setUserData((prev) => ({
      ...prev,
      firstName: profile.firstName,
      lastName: profile.lastName,
      fullName: `${profile.firstName} ${profile.lastName}`.trim(),
      email: profile.email,
      profilePicture: profile.profilePicture || '',
      language: profile.language,
      timeZone: profile.timeZone,
    }));
  };

  return (
    <UserContext.Provider
      value={{
        authInitialized,
        currentUser,
        userRoles,
        userRolesLoading,
        userData,
        userGroups,
        hasPermission,
        updateUserProfile,
        refetchUserRoleAndData,
        // new helper
        getUserPriorityForGroup,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
