// src/components/Common/TwoPanelLayout.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './TwoPanelLayout.css';

/**
 * A reusable layout that displays a left panel and a right panel side by side.
 * You can pass in any React elements as the "leftPanel" and "rightPanel" props.
 *
 * Example usage in your component:
 *   <TwoPanelLayout
 *     leftPanel={<YourLeftPanel />}
 *     rightPanel={<YourRightPanel />}
 *   />
 */
const TwoPanelLayout = ({ leftPanel, rightPanel, className = '' }) => {
  return (
    <div className={`two-panel-layout-container ${className}`}>
      <div className="two-panel-left">{leftPanel}</div>
      <div className="two-panel-right">{rightPanel}</div>
    </div>
  );
};

TwoPanelLayout.propTypes = {
  leftPanel: PropTypes.node,
  rightPanel: PropTypes.node,
  className: PropTypes.string,
};

export default TwoPanelLayout;
