// src/contexts/ResponseContext.js

import React, { createContext, useContext } from 'react';
import { firestore, auth } from '../firebase';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  writeBatch,
  Timestamp,
} from 'firebase/firestore';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AppSettingsContext } from './AppSettingsContext';

export const ResponseContext = createContext();

export const ResponseProvider = ({ children }) => {
  const { organizationData } = useContext(AppSettingsContext);
  const queryClient = useQueryClient();

  /**
   * Function to fetch responses for a form (general listing of responses)
   */
  const fetchResponsesForForm = async (formId) => {
    if (!formId) {
      console.warn('fetchResponsesForForm called without a formId.');
      return [];
    }

    if (!organizationData) {
      console.error('organizationData is not defined.');
      throw new Error('organizationData is not defined.');
    }

    const { groupId, organizationId } = organizationData;
    console.log(organizationData);

    if (!groupId) {
      console.error('Group ID is not defined in organizationData.');
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Helper function to fetch responses for a specific groupId
      const fetchResponsesByGroup = async (currentGroupId) => {
        const responsesCollection = collection(
          firestore,
          'formGroups',
          currentGroupId,
          'forms',
          formId,
          'responses'
        );

        const responsesQuery = query(
          responsesCollection,
          orderBy('submittedAt', 'desc')
          // You can add limit(XX) if you want
        );
        const responsesSnapshot = await getDocs(responsesQuery);

        return responsesSnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
      };

      let fetchedResponses = [];

      // If groupId === organizationId, fetch all groups under this org
      if (groupId === organizationId) {
        const formGroupsCollection = collection(firestore, 'formGroups');
        const formGroupsQuery = query(
          formGroupsCollection,
          where('organizationId', '==', organizationId)
        );
        const formGroupsSnapshot = await getDocs(formGroupsQuery);

        if (formGroupsSnapshot.empty) {
          console.warn('No formGroups found.');
          return [];
        }

        const formGroupIds = formGroupsSnapshot.docs.map((docSnap) => docSnap.id);

        // Fetch responses from all formGroups in parallel
        const allResponsesPromises = formGroupIds.map((currentGroupId) =>
          fetchResponsesByGroup(currentGroupId)
        );

        const allResponsesArrays = await Promise.all(allResponsesPromises);
        fetchedResponses = allResponsesArrays.flat();
      } else {
        // Otherwise, fetch from the specific groupId
        fetchedResponses = await fetchResponsesByGroup(groupId);
      }

      return fetchedResponses;
    } catch (error) {
      console.error(`Error fetching responses for formId ${formId}:`, error);
      throw error;
    }
  };

  /**
   * Custom hook to manage responses using React Query
   */
  const useResponses = (formId) => {
    // We destructure organizationData again because hooks can't share references from outer scope
    const { organizationData } = useContext(AppSettingsContext);

    return useQuery({
      queryKey: ['responses', formId],
      queryFn: () => fetchResponsesForForm(formId),
      enabled: !!formId && !!organizationData?.organizationId,
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: 2, // Retry failed requests
      onError: (error) => {
        // Optional: Implement global error handling or notifications
        console.error('Failed to fetch responses:', error);
      },
    });
  };

  /**
   * Function to fetch a specific response by formId + responseId
   */
  const fetchResponseData = async (formId, responseId) => {
    if (!formId || !responseId) {
      console.warn('fetchResponseData called without formId or responseId.');
      return null;
    }

    if (!organizationData) {
      console.error('organizationData is not defined.');
      throw new Error('organizationData is not defined.');
    }

    const { groupId } = organizationData;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Build doc ref to the specific response
      const responseDocRef = doc(
        firestore,
        'formGroups',
        groupId,
        'forms',
        formId,
        'responses',
        responseId
      );
      const snap = await getDoc(responseDocRef);

      if (!snap.exists()) {
        return null;
      }

      // Return the document data with an 'id' prop
      return { id: snap.id, ...snap.data() };
    } catch (error) {
      console.error(`Error fetching response ${responseId} for formId ${formId}:`, error);
      throw error;
    }
  };

  /**
   * Function to submit a new form response
   */
  const submitFormResponse = async (formId, responseData) => {
    if (!formId) {
      console.warn('submitFormResponse called without a formId.');
      throw new Error('Form ID is required to submit a response.');
    }

    const groupId = organizationData?.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Compress images in responseData.fields
      //const compressedFields = await compressImageFields(responseData.fields);
      //responseData.fields = compressedFields;

      // Define path: formGroups/{groupId}/forms/{formId}/responses
      const responsesCollectionRef = collection(
        firestore,
        'formGroups',
        groupId,
        'forms',
        formId,
        'responses'
      );

      // Add the response data with a timestamp
      const docRef = await addDoc(responsesCollectionRef, {
        ...responseData,
        submittedAt: serverTimestamp(),
      });

      // If the current form is the "groupsForm", do extra group creation logic
      if (formId === 'groupsForm') {
        // Transform responseData.fields into a doc structure
        const groupData = {};
        responseData.fields.forEach((field) => {
          groupData[field.id] = field.value;
        });
        groupData.createdAt = serverTimestamp();
        groupData.updatedAt = serverTimestamp();
        groupData.organizationId = doc(
          firestore,
          'organization',
          organizationData.organizationId
        );
        groupData.id = docRef.id;

        // Create a new doc in the "groups" collection
        const groupsDocRef = doc(firestore, 'groups', docRef.id);
        await setDoc(groupsDocRef, groupData);

        // Also create a doc in formGroups/{docRef.id}
        const formGroupDocRef = doc(firestore, 'formGroups', docRef.id);
        await setDoc(formGroupDocRef, {
          id: docRef.id,
          organizationId: organizationData.organizationId,
        });

        // Copy default forms to the new group's forms collection
        const defaultFormsRef = collection(firestore, 'defaultForms');
        const defaultFormsSnapshot = await getDocs(defaultFormsRef);
        const formsBatch = writeBatch(firestore);

        defaultFormsSnapshot.forEach((defaultFormDoc) => {
          const defaultFormData = defaultFormDoc.data();
          defaultFormData.groupId = docRef.id;
          defaultFormData.organizationId = organizationData.organizationId;

          const defaultFormId = defaultFormDoc.id;
          const targetFormDocRef = doc(
            firestore,
            'formGroups',
            docRef.id,
            'forms',
            defaultFormId
          );
          formsBatch.set(targetFormDocRef, defaultFormData);
        });
        await formsBatch.commit();

        // Create a doc in roleGroups/{docRef.id}
        const roleGroupDocRef = doc(firestore, 'roleGroups', docRef.id);
        await setDoc(roleGroupDocRef, {
          id: docRef.id,
          organizationId: organizationData.organizationId,
        });

        // Copy default roles to that role group
        const defaultRolesRef = collection(firestore, 'defaultRoles');
        const defaultRolesSnapshot = await getDocs(defaultRolesRef);
        const rolesBatch = writeBatch(firestore);

        defaultRolesSnapshot.forEach((defaultRoleDoc) => {
          const defaultRoleData = defaultRoleDoc.data();
          defaultRoleData.groupId = docRef.id;
          defaultRoleData.organizationId = organizationData.organizationId;

          const roleId = defaultRoleDoc.id;
          const targetRoleDocRef = doc(
            firestore,
            'roleGroups',
            docRef.id,
            'roles',
            roleId
          );
          rolesBatch.set(targetRoleDocRef, defaultRoleData);
        });
        await rolesBatch.commit();

        // Add the Admin role to the current user
        if (auth.currentUser) {
          const currentUserId = auth.currentUser.uid;
          const userDocRef = doc(firestore, 'users', currentUserId);
          const userSnapshot = await getDoc(userDocRef);

          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            let rolesArray = userData.roles || [];

            rolesArray.push({
              groupId: doc(firestore, 'groups', docRef.id),
              organizationId: organizationData.organizationId,
              role: doc(
                firestore,
                'roleGroups',
                docRef.id,
                'roles',
                'Qk6agHI01TjA0vqGqUGT' // Admin role doc ID
              ),
            });

            await updateDoc(userDocRef, { roles: rolesArray });
          } else {
            console.warn(`User document for user ID ${currentUserId} does not exist.`);
          }
        } else {
          console.warn('No authenticated user found when trying to add admin role.');
        }
      }

      // Invalidate the relevant queries to force refetch
      queryClient.invalidateQueries(['formGroups', groupId, 'forms', formId, 'responses']);

      return { id: docRef.id };
    } catch (error) {
      console.error('Error submitting response:', error);
      throw error;
    }
  };

  /**
   * Function to update an existing response
   */
  const updateResponse = async (formId, responseId, responseData, groupId) => {
    if (!formId || !responseId) {
      console.warn('updateResponse called without formId or responseId.');
      throw new Error('Form ID and Response ID are required to update a response.');
    }

    // Use provided groupId or fallback to org's groupId
    const effectiveGroupId = groupId || organizationData?.groupId;
    if (!effectiveGroupId) {
      throw new Error('Group ID is not defined in organizationData and was not provided.');
    }

    try {
      const responseDocRef = doc(
        firestore,
        'formGroups',
        effectiveGroupId,
        'forms',
        formId,
        'responses',
        responseId
      );

      await updateDoc(responseDocRef, {
        ...responseData,
        updatedAt: serverTimestamp(),
      });

      // If this is the groupsForm, update the matching doc in 'groups'
      if (formId === 'groupsForm') {
        const groupData = {};
        responseData.fields.forEach((field) => {
          groupData[field.id] = field.value;
        });
        groupData.updatedAt = serverTimestamp();
        groupData.organizationId = doc(
          firestore,
          'organization',
          organizationData.organizationId
        );
        groupData.id = responseId;

        const groupsDocRef = doc(firestore, 'groups', responseId);
        await updateDoc(groupsDocRef, groupData);
      }

      // Invalidate the relevant queries
      queryClient.invalidateQueries([
        'formGroups',
        effectiveGroupId,
        'forms',
        formId,
        'responses',
      ]);
    } catch (error) {
      console.error('Error updating response:', error);
      throw error;
    }
  };

  /**
   * Function to delete a response
   */
  const deleteResponse = async (formId, responseId) => {
    if (!formId || !responseId) {
      console.warn('deleteResponse called without formId or responseId.');
      throw new Error('Form ID and Response ID are required to delete a response.');
    }

    const groupId = organizationData?.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      const responseDocRef = doc(
        firestore,
        'formGroups',
        groupId,
        'forms',
        formId,
        'responses',
        responseId
      );
      await deleteDoc(responseDocRef);

      if (formId === 'groupsForm') {
        const groupsDocRef = doc(firestore, 'groups', responseId);
        await deleteDoc(groupsDocRef);
      }

      queryClient.invalidateQueries(['formGroups', groupId, 'forms', formId, 'responses']);
    } catch (error) {
      console.error('Error deleting response:', error);
      throw error;
    }
  };

  return (
    <ResponseContext.Provider
      value={{
        fetchResponsesForForm,
        useResponses,
        fetchResponseData,     // <--- Added here
        submitFormResponse,
        updateResponse,
        deleteResponse,
      }}
    >
      {children}
    </ResponseContext.Provider>
  );
};
