// src/components/FormBuilder/FormBuilder.jsx

import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import './FormBuilder.css';
import ActionButton from '../Common/ActionButton';
import FieldSelector from './FieldSelector';
import ContextMenu from '../Common/ContextMenu';
import { DataContext } from '../../DataContext';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { exportToJsonFile } from './JSONUtility'; // Import the custom export utility

const FormBuilder = ({ initialData, onUpdate, showToast }) => {
  const { t } = useTranslation();
  const { organizationData, saveForm } = useContext(DataContext);

  // Pull from UserContext for permission checks
  const { hasPermission } = useContext(AppSettingsContext);
  // canEdit => for form editing actions
  const canEdit = hasPermission('forms', 'editForm');
  // canExport => for import/export fields
  const canExport = hasPermission('forms', 'exportForm');

  const [formName, setFormName] = useState('');
  const [fields, setFields] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState(null);

  const [editingFieldId, setEditingFieldId] = useState(null);
  const [showFieldSelector, setShowFieldSelector] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const fieldSelectorRef = useRef(null);

  // State for ContextMenu
  const [contextMenu, setContextMenu] = useState({
    isVisible: false,
    position: { x: 0, y: 0 },
    fieldIndex: null,
  });

  // Ref for the hidden file input (import)
  const fileInputRef = useRef(null);

  // Ref for the menu container
  const menuRef = useRef(null);

  // State for the top menu (3-dot menu)
  const [menuVisible, setMenuVisible] = useState(false);

  // -------------------------------------------
  //   Initialize from initialData
  // -------------------------------------------
  useEffect(() => {
    if (initialData) {
      const fieldsArray = Array.isArray(initialData.fields)
        ? initialData.fields
        : initialData.fields
        ? Object.values(initialData.fields)
        : [];

      setFormName(initialData.name || '');
      setFields(fieldsArray);
      setSelectedFormId(initialData.id);
      setIsClosed(initialData.isClosed || false);
    }
  }, [initialData]);

  // -------------------------------------------
  //   Deep cleanup function to remove undefined
  // -------------------------------------------
  const deepClean = (obj) => {
    if (Array.isArray(obj)) {
      return obj
        .map((item) => deepClean(item))
        .filter((item) => item !== undefined);
    } else if (obj !== null && typeof obj === 'object') {
      const cleanedObj = {};
      Object.keys(obj).forEach((key) => {
        const cleanedValue = deepClean(obj[key]);
        if (cleanedValue !== undefined) {
          cleanedObj[key] = cleanedValue;
        }
      });
      return Object.keys(cleanedObj).length > 0 ? cleanedObj : undefined;
    } else {
      return obj;
    }
  };

  // -------------------------------------------
  //   Handle Saving (create/update) form
  // -------------------------------------------
  const handleSaveForm = async (updatedFields = fields) => {
    if (!canEdit) {
      return; // user lacks "editForm"
    }

    if (!formName.trim()) {
      showToast(t('formBuilder.formNameRequired'), 'error');
      return;
    }

    // Validate & clean
    const fieldIds = new Set();
    for (let field of updatedFields) {
      // skip mandatory
      if (field.mandatory) {
        continue;
      } else {
        if (field.type !== 'Section' && !field.label.trim()) {
          showToast(t('formBuilder.fieldLabelRequired'), 'error');
          return;
        }
      }
      if (fieldIds.has(field.id)) {
        showToast(
          t('formBuilder.duplicateFieldName', { label: field.label }),
          'error'
        );
        return;
      } else {
        fieldIds.add(field.id);
      }

      // Additional validations
      if (['Checkbox', 'RadioButton', 'Select'].includes(field.type)) {
        if ((!field.options || field.options.length === 0) && !field.catalogId) {
          showToast(
            t('formBuilder.fieldMustHaveOptionsOrCatalog', { label: field.label }),
            'error'
          );
          return;
        }
        if (field.options && field.options.length > 0) {
          for (let option of field.options) {
            if (!option.label.trim() || !option.value.trim()) {
              showToast(
                t('formBuilder.optionsMustHaveLabelAndValue', { label: field.label }),
                'error'
              );
              return;
            }
          }
        }
      }
      if (field.type === 'RangeSlider') {
        if (typeof field.min !== 'number' || typeof field.max !== 'number') {
          showToast(
            t('formBuilder.rangeSliderMustHaveValidMinMax', { label: field.label }),
            'error'
          );
          return;
        }
        if (field.min >= field.max) {
          showToast(
            t('formBuilder.rangeSliderMinLessThanMax', { label: field.label }),
            'error'
          );
          return;
        }
        if (field.step === undefined || typeof field.step !== 'number') {
          showToast(
            t('formBuilder.rangeSliderMustHaveValidStep', { label: field.label }),
            'error'
          );
          return;
        }
        if (typeof field.showValue !== 'boolean') {
          showToast(
            t('formBuilder.rangeSliderMustHaveShowValueBoolean', { label: field.label }),
            'error'
          );
          return;
        }
      }
      if (field.type === 'MultiRowControl') {
        if (!field.rowFields || field.rowFields.length === 0) {
          showToast(
            t('formBuilder.multiRowControlMustHaveFields', { label: field.label }),
            'error'
          );
          return;
        }
        for (let rowField of field.rowFields) {
          if (!rowField.label.trim()) {
            showToast(
              t('formBuilder.rowFieldLabelRequired', { label: rowField.label }),
              'error'
            );
            return;
          }
        }
      }

      // Cleanup
      const cleanedField = deepClean(field);
      if (cleanedField === undefined) {
        showToast(
          t('formBuilder.fieldContainsUndefinedValues', { label: field.label }),
          'error'
        );
        return;
      }
      Object.assign(field, cleanedField);
    }

    const formConfig = {
      name: formName.trim(),
      fields: updatedFields,
      groupId: organizationData.groupId,
      isClosed,
    };

    setSaving(true);
    try {
      const newFormId = await saveForm(selectedFormId, formConfig);
      if (!selectedFormId && newFormId) {
        setSelectedFormId(newFormId);
      }
      if (onUpdate) {
        onUpdate({
          id: selectedFormId || newFormId,
          ...formConfig,
        });
      }
    } catch (error) {
      console.error('Error saving form:', error);
      showToast(t('formBuilder.errorSavingForm'), 'error');
    } finally {
      setSaving(false);
    }
  };

  // -------------------------------------------
  //   Handle Field Save
  // -------------------------------------------
  const handleSaveField = (fieldData) => {
    if (!canEdit) {
      return;
    }

    let updatedFields;

    if (editingFieldId) {
      const fieldToEdit = fields.find((field) => field.id === editingFieldId);
      if (fieldToEdit.mandatory) {
        const { id, label, type, mandatory, ...rest } = fieldData;
        updatedFields = fields.map((field) =>
          field.id === editingFieldId
            ? {
                ...field,
                ...rest, // only non-protected
              }
            : field
        );
      } else {
        updatedFields = fields.map((field) =>
          field.id === editingFieldId
            ? {
                ...field,
                ...fieldData,
              }
            : field
        );
      }
      setEditingFieldId(null);
    } else {
      const fieldId = camelCase(fieldData.label.trim());
      const existingField = fields.find((field) => field.id === fieldId);
      if (existingField) {
        showToast(
          t('formBuilder.duplicateFieldName', { label: fieldData.label }),
          'error'
        );
        return;
      }
      const newField = {
        id: fieldId,
        ...fieldData,
      };
      updatedFields = [...fields, newField];
    }

    setFields(updatedFields);
    setShowFieldSelector(false);
  };

  // -------------------------------------------
  //   Handle Field Edit
  // -------------------------------------------
  const handleEditField = (fieldId) => {
    if (!canEdit) {
      return;
    }
    setEditingFieldId(fieldId);
    setShowFieldSelector(true);
  };

  // -------------------------------------------
  //   Handle Field Cancel
  // -------------------------------------------
  const handleCancelEdit = () => {
    setEditingFieldId(null);
    setShowFieldSelector(false);
  };

  // -------------------------------------------
  //   Remove Field
  // -------------------------------------------
  const handleRemoveField = (fieldId) => {
    if (!canEdit) {
      return;
    }
    const fieldToRemove = fields.find((field) => field.id === fieldId);
    if (fieldToRemove.mandatory) {
      showToast(
        t('formBuilder.cannotDeleteMandatoryField', { label: fieldToRemove.label }),
        'error'
      );
      return;
    }
    const updatedFields = fields.filter((field) => field.id !== fieldId);
    setFields(updatedFields);
  };

  // -------------------------------------------
  //   Move Field Up/Down
  // -------------------------------------------
  const moveFieldUp = (index) => {
    if (!canEdit) {
      return;
    }
    if (index === 0) return;
    const newFields = [...fields];
    [newFields[index - 1], newFields[index]] = [
      newFields[index],
      newFields[index - 1],
    ];
    setFields(newFields);
  };

  const moveFieldDown = (index) => {
    if (!canEdit) {
      return;
    }
    if (index === fields.length - 1) return;
    const newFields = [...fields];
    [newFields[index + 1], newFields[index]] = [
      newFields[index],
      newFields[index + 1],
    ];
    setFields(newFields);
  };

  const moveFieldToTop = (index) => {
    if (!canEdit) {
      return;
    }
    if (index === 0) return;
    const newFields = [...fields];
    const [movedField] = newFields.splice(index, 1);
    newFields.unshift(movedField);
    setFields(newFields);
  };

  const moveFieldToBottom = (index) => {
    if (!canEdit) {
      return;
    }
    if (index === fields.length - 1) return;
    const newFields = [...fields];
    const [movedField] = newFields.splice(index, 1);
    newFields.push(movedField);
    setFields(newFields);
  };

  // -------------------------------------------
  //   Context Menu
  // -------------------------------------------
  const handleContextMenu = (event, index) => {
    if (!canEdit) {
      return;
    }
    event.preventDefault();
    setContextMenu({
      isVisible: true,
      position: { x: event.clientX, y: event.clientY },
      fieldIndex: index,
    });
  };

  const closeContextMenu = () => {
    setContextMenu({
      isVisible: false,
      position: { x: 0, y: 0 },
      fieldIndex: null,
    });
  };

  const makeFieldHalfLength = (index) => {
    if (!canEdit) {
      return;
    }
    const newFields = [...fields];
    newFields[index] = { ...newFields[index], size: 'half' };
    setFields(newFields);
    closeContextMenu();
  };

  const makeFieldFullLength = (index) => {
    if (!canEdit) {
      return;
    }
    const newFields = [...fields];
    newFields[index] = { ...newFields[index], size: 'full' };
    setFields(newFields);
    closeContextMenu();
  };

  const getContextMenuOptions = () => {
    if (contextMenu.fieldIndex === null) return [];
    const field = fields[contextMenu.fieldIndex];

    const options = [
      {
        label: t('formBuilder.makeHalfLength'),
        onClick: () => makeFieldHalfLength(contextMenu.fieldIndex),
        disabled: field.size === 'half',
      },
      {
        label: t('formBuilder.makeFullLength'),
        onClick: () => makeFieldFullLength(contextMenu.fieldIndex),
        disabled: field.size === 'full',
      },
      {
        label: t('formBuilder.moveToTop'),
        onClick: () => moveFieldToTop(contextMenu.fieldIndex),
        disabled: contextMenu.fieldIndex === 0,
      },
      {
        label: t('formBuilder.moveUp'),
        onClick: () => moveFieldUp(contextMenu.fieldIndex),
        disabled: contextMenu.fieldIndex === 0,
      },
      {
        label: t('formBuilder.moveDown'),
        onClick: () => moveFieldDown(contextMenu.fieldIndex),
        disabled: contextMenu.fieldIndex === fields.length - 1,
      },
      {
        label: t('formBuilder.moveToBottom'),
        onClick: () => moveFieldToBottom(contextMenu.fieldIndex),
        disabled: contextMenu.fieldIndex === fields.length - 1,
      },
    ];
    return options;
  };

  // -------------------------------------------
  //   Rendering Fields
  // -------------------------------------------
  const renderFieldSummary = (field, index) => {
    let additionalInfo = '';

    if (field.type === 'Select') {
      if (field.catalogId) {
        additionalInfo = field.catalogId;
      } else if (field.options && field.options.length > 0) {
        additionalInfo = t('formBuilder.optionsCount', {
          count: field.options.length,
        });
      }
    }
    if (field.type === 'MultiRowControl') {
      if (field.rowFields && field.rowFields.length > 0) {
        additionalInfo = t('formBuilder.fieldsCount', {
          count: field.rowFields.length,
        });
      }
    }

    return (
      <div
        key={field.id}
        className={`field-summary ${
          field.size === 'half' ? 'half-width' : 'full-width'
        }`}
        onClick={() => canEdit && handleEditField(field.id)}
        onContextMenu={(e) => canEdit && handleContextMenu(e, index)}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => {
          if (canEdit && (e.key === 'Enter' || e.key === ' ')) {
            handleEditField(field.id);
          }
        }}
      >
        <div className="field-summary-content">
          <span className="field-label">
            {field.label} {field.required && <span className="required-asterisk">*</span>}
            {field.mandatory && <span className="mandatory-label"> (Mandatory)</span>}
          </span>
          <span className="field-type">
            {t(`fieldTypes.${field.type}`)} {additionalInfo}
          </span>
        </div>
        {!field.mandatory && canEdit && (
          <button
            type="button"
            className="remove-field-button"
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveField(field.id);
            }}
            aria-label={t('formBuilder.removeField')}
          >
            &times;
          </button>
        )}
      </div>
    );
  };

  // -------------------------------------------
  //   Outside-click to close field editing
  // -------------------------------------------
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        fieldSelectorRef.current &&
        !fieldSelectorRef.current.contains(event.target)
      ) {
        handleCancelEdit();
      }
    };

    if (showFieldSelector) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFieldSelector]);

  // -------------------------------------------
  //   Outside-click to close 3-dot menu
  // -------------------------------------------
  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutsideMenu);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [menuVisible]);

  // -------------------------------------------
  //   Export / Import
  // -------------------------------------------
  const handleExportFields = () => {
    // Now, only allow if user has 'exportForm'
    if (!canExport) {
      showToast(
        t('formBuilder.noExportPermission', 'You do not have permission to export fields.'),
        'error'
      );
      return;
    }
    exportToJsonFile(fields, `${formName || 'form'}-fields.json`);
  };

  const handleImportFields = () => {
    // Only if user has 'exportForm'
    if (!canExport) {
      showToast(
        t('formBuilder.noExportPermission', 'You do not have permission to import fields.'),
        'error'
      );
      return;
    }
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (event) => {
    // again check if canExport
    if (!canExport) {
      return;
    }
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const importedFields = JSON.parse(e.target.result);
          if (!Array.isArray(importedFields)) {
            showToast(t('formBuilder.invalidFieldsJson'), 'error');
            return;
          }

          const existingFieldIds = new Set(fields.map((f) => f.id));
          const newFields = [...fields];
          for (const field of importedFields) {
            let fieldId = field.id;
            while (existingFieldIds.has(fieldId)) {
              fieldId = `${fieldId}_copy`;
            }
            field.id = fieldId;
            existingFieldIds.add(fieldId);
            newFields.push(field);
          }

          setFields(newFields);
          showToast(t('formBuilder.fieldsImported'), 'success');
        } catch (error) {
          showToast(t('formBuilder.invalidFieldsJson'), 'error');
        }
      };
      reader.readAsText(file);
      event.target.value = null;
    }
  };

  // -------------------------------------------
  //   Ensure fields is array
  // -------------------------------------------
  if (!Array.isArray(fields)) {
    console.error('fields is not an array:', fields);
    return <div>{t('formBuilder.loading')}</div>;
  }

  // -------------------------------------------
  //   Render
  // -------------------------------------------
  return (
    <div className="form-builder-container">
      {/* If user can't export, we hide the entire 3-dot menu */}
      {canExport && (
        <div className="top-button-container">
          <div className="menu-container" ref={menuRef}>
            <ActionButton
              onClick={() => setMenuVisible(!menuVisible)}
              label={t('formBuilder.moreOptions')}
              icon="faEllipsisV"
              isMobile={false}
              colorType="secondary"
              spacing="8px"
              className="more-options-button"
            />
            {menuVisible && (
              <div className="dropdown-menu">
                <button
                  onClick={() => {
                    handleImportFields();
                    setMenuVisible(false);
                  }}
                >
                  {t('formBuilder.importFields')}
                </button>
                <button
                  onClick={() => {
                    handleExportFields();
                    setMenuVisible(false);
                  }}
                >
                  {t('formBuilder.exportFields')}
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Hidden File Input for Importing Fields */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept=".json"
        onChange={handleFileInputChange}
      />

      {/* Fields Container */}
      <div className="fields-container">
        {fields.map((field, index) =>
          editingFieldId === field.id ? (
            <div
              key={field.id}
              ref={fieldSelectorRef}
              className={`field-selector-wrapper ${
                field.size === 'half' ? 'half-width' : 'full-width'
              }`}
            >
              <FieldSelector
                onAddField={handleSaveField}
                onCancel={handleCancelEdit}
                initialField={field}
                isMandatory={field.mandatory}
                disabled={saving}
              />
            </div>
          ) : (
            renderFieldSummary(field, index)
          )
        )}
        {!showFieldSelector && editingFieldId === null && canEdit && (
          <ActionButton
            onClick={() => {
              setEditingFieldId(null);
              setShowFieldSelector(true);
            }}
            label={t('formBuilder.addField')}
            text={t('formBuilder.addField')}
            icon="faPlus"
            isMobile={false}
            colorType="secondary"
            spacing="8px"
            className="add-field-button with-plus full-width"
          />
        )}
        {showFieldSelector && editingFieldId === null && (
          <div ref={fieldSelectorRef} className="field-selector-wrapper full-width">
            <FieldSelector
              onAddField={handleSaveField}
              onCancel={handleCancelEdit}
              isMandatory={false}
              disabled={saving}
            />
          </div>
        )}
      </div>

      {/* Save Form Button */}
      <div className="save-button-container">
        <ActionButton
          onClick={() => handleSaveForm()}
          label={t('formBuilder.saveForm')}
          text={t('formBuilder.saveForm')}
          icon="faSave"
          isMobile={false}
          colorType="primary"
          disabled={!canEdit || saving || !formName.trim() || fields.length === 0}
          spacing="8px"
          className="save-form-button full-width-button"
        />
      </div>

      {/* Context Menu */}
      <ContextMenu
        isVisible={contextMenu.isVisible}
        position={contextMenu.position}
        options={getContextMenuOptions()}
        onClose={closeContextMenu}
      />
    </div>
  );
};

FormBuilder.propTypes = {
  initialData: PropTypes.object,
  onUpdate: PropTypes.func,
  showToast: PropTypes.func.isRequired,
};

FormBuilder.defaultProps = {
  initialData: null,
  onUpdate: null,
};

export default FormBuilder;
