import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

// Contexts
import { EventContext } from '../../contexts/EventContext';
import { DataContext } from '../../DataContext';
import { MessageContext } from '../../contexts/MessageContext';
import { ResponseContext } from '../../contexts/ResponseContext';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

// Components & Shared
import { useTranslation } from 'react-i18next';
import Spinner from '../Common/Spinner';
import CustomModal from '../Common/CustomModal';
import EventForm from './EventForm';
import ToastContainer from '../Common/ToastContainer';
import ActionButton from '../Common/ActionButton';
import MessageSender from '../Communications/MessageSender';
import ToggleSwitch from '../FormBuilder/Fields/ToggleSwitch';

// Icons & Styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faEnvelope,
  faStickyNote,
  faTimes,
  faBirthdayCake,
  faChurch,
} from '@fortawesome/free-solid-svg-icons';
import './CalendarPage.css';

const CalendarPage = () => {
  const { t } = useTranslation();

  // 1) Call Hooks
  const {
    events,
    isEventsLoading,
    isEventsError,
    addEvent,
    editEvent,
    deleteEvent,
    deleteEventOccurrence,
  } = useContext(EventContext);

  const { messages, isMessagesLoading, isMessagesError } = useContext(MessageContext);

  const { useResponses } = useContext(ResponseContext);
  const {
    data: membersResponses = [],
    isLoading: isMembersLoading,
    isError: isMembersError,
  } = useResponses('membersForm');

  const { hasPermission } = useContext(AppSettingsContext);

  // For "Add" button checks:
  const canAddEvent = hasPermission('calendar', 'addEvent');
  const canScheduleMessage = hasPermission('communications', 'scheduleMessage');
  const canAddPost = hasPermission('posts', 'addPost');
  const canShowAddButton = canAddEvent || canScheduleMessage || canAddPost;

  // For editing & deleting events
  const canEditEvent = hasPermission('calendar', 'editEvent');
  const canDeleteEvent = hasPermission('calendar', 'deleteEvent');

  // Local UI states
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);

  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);

  // Toggles for filtering
  const [showEvents, setShowEvents] = useState(true);
  const [showCommunications, setShowCommunications] = useState(true);
  const [showBirthdays, setShowBirthdays] = useState(true);
  const [showLiturgyEvents, setShowLiturgyEvents] = useState(true);

  // Toasts
  const [toasts, setToasts] = useState([]);
  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };
  const showToast = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prev) => [...prev, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  // Must have "viewEvent" permission to see this page
  const hasViewPermission = hasPermission('calendar', 'viewEvent');

  const isLoadingAny = isEventsLoading || isMessagesLoading || isMembersLoading;
  const isErrorAny = isEventsError || isMessagesError || isMembersError;

  // Helpers
  const formatDateKey = (date) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const parseDateKey = (dateKey) => {
    const [year, month, day] = dateKey.split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  // 2) useMemo for 6 weeks of the current month
  const calendarRows = useMemo(() => {
    const rows = [];
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const startDay = startOfMonth.getDay(); // 0=Sunday, 1=Monday, etc.

    let dayCounter = 1 - startDay; // so if startDay=0 (Sun), we begin on "1"

    for (let week = 0; week < 6; week++) {
      const weekRow = [];
      for (let day = 0; day < 7; day++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), dayCounter);
        weekRow.push(date);
        dayCounter++;
      }
      rows.push(weekRow);
    }
    return rows;
  }, [currentDate]);

  // 3) useMemo to build the eventMap (merging events, messages, birthdays)
  const eventMap = useMemo(() => {
    const map = {};
    const now = new Date();
    const currentYear = now.getFullYear();

    // Insert "regular" or "liturgy" events
    events.forEach((eventItem) => {
      // Because event objects might have Date objects already
      const startDate = new Date(
        eventItem.startDateTime.getFullYear(),
        eventItem.startDateTime.getMonth(),
        eventItem.startDateTime.getDate()
      );
      const endDate = new Date(
        eventItem.endDateTime.getFullYear(),
        eventItem.endDateTime.getMonth(),
        eventItem.endDateTime.getDate()
      );
      const recurringDays = eventItem.recurringDays || [];
      const exceptionDates = eventItem.exceptionDates || [];

      if (eventItem.isRecurring && recurringDays.length > 0) {
        // Recurring
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
          const dayIndex = d.getDay().toString();
          const dateKey = formatDateKey(d);
          if (exceptionDates.includes(dateKey)) continue;
          if (recurringDays.includes(dayIndex)) {
            if (!map[dateKey]) map[dateKey] = [];
            map[dateKey].push({ ...eventItem });
          }
        }
      } else {
        // Single or multi-day
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
          const dateKey = formatDateKey(d);
          if (exceptionDates.includes(dateKey)) continue;
          if (!map[dateKey]) map[dateKey] = [];
          map[dateKey].push({ ...eventItem });
        }
      }
    });

    // Insert scheduled messages
    messages.forEach((msg) => {
      if (msg.messageType === 'scheduled' && msg.scheduledDateTime) {
        const dateKey = formatDateKey(msg.scheduledDateTime);
        if (!map[dateKey]) map[dateKey] = [];
        map[dateKey].push({
          id: msg.id,
          name: msg.title || t('calendar.scheduledCommunication', 'Scheduled Communication'),
          isAllDay: false,
          startDateTime: msg.scheduledDateTime,
          endDateTime: msg.scheduledDateTime,
          description: msg.content,
          type: 'message',
          color: '#9c27b0',
        });
      }
    });

    // Insert birthdays
    membersResponses.forEach((resp) => {
      const fields = resp.fields || [];
      let firstName = 'Member';
      let lastName = '';
      let dob = null;
      fields.forEach((f) => {
        if (f.id === 'dateOfBirth') dob = f.value;
        if (f.id === 'firstName') firstName = f.value || 'Member';
        if (f.id === 'lastName') lastName = f.value || '';
      });
      if (dob) {
        const dobDate = new Date(dob);
        if (!isNaN(dobDate)) {
          const yearsToShow = [currentYear - 1, currentYear, currentYear + 1];
          yearsToShow.forEach((year) => {
            const displayDOB = new Date(year, dobDate.getMonth(), dobDate.getDate());
            const dateKey = formatDateKey(displayDOB);
            if (!map[dateKey]) map[dateKey] = [];
            map[dateKey].push({
              id: resp.id + '-birthday-' + year,
              name: `${firstName} ${lastName}`.trim(),
              isAllDay: true,
              startDateTime: displayDOB,
              endDateTime: displayDOB,
              type: 'birthday',
              color: '#ffa500',
            });
          });
        }
      }
    });

    // Sort each date's array
    Object.keys(map).forEach((dateKey) => {
      map[dateKey].sort((a, b) => a.startDateTime - b.startDateTime);
    });

    return map;
    // We'll recalc if events, messages, membersResponses, or the translation function changes
  }, [events, messages, membersResponses, t]);

  // 4) Additional helpers for the UI
  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };
  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  // Handler "Add Communication"
  const handleAddCommunication = () => {
    if (!canScheduleMessage) {
      showToast(
        t('calendar.noScheduleMessagePermission', 'No permission to schedule messages.'),
        'error'
      );
      setMenuVisible(false);
      return;
    }
    setSelectedDate(null);
    setModalTitle(t('calendar.addCommunication', 'Add Communication'));
    setModalContent(
      <MessageSender onlyScheduled={true} onClose={() => setShowModal(false)} />
    );
    setShowModal(true);
    setMenuVisible(false);
  };

  // Handler "Add Post"
  const handleAddPost = () => {
    if (!canAddPost) {
      showToast(
        t('calendar.noAddPostPermission', 'No permission to add posts.'),
        'error'
      );
      setMenuVisible(false);
      return;
    }
    setSelectedDate(null);
    console.log('Add Post triggered...');
    setMenuVisible(false);
  };

  // Handler "Add Event"
  const handleAddEvent = () => {
    if (!canAddEvent) {
      showToast(
        t('calendar.noAddEventPermission', 'No permission to add events.'),
        'error'
      );
      setMenuVisible(false);
      return;
    }
    setSelectedDate(null);
    setModalTitle(t('calendar.addEvent', 'Add Event'));
    setModalContent(
      <EventForm
        onSave={async (eventData) => {
          try {
            await addEvent(eventData);
            setShowModal(false);
            showToast(t('calendar.eventAdded', 'Event added successfully'), 'success');
          } catch (error) {
            console.error('Error adding event:', error);
            showToast(t('calendar.errorAddingEvent', 'Error adding event'), 'error');
          }
        }}
        onCancel={() => setShowModal(false)}
      />
    );
    setShowModal(true);
    setMenuVisible(false);
  };

  // "Edit" event
  const handleEditEvent = (eventItem, occurrenceDate) => {
    if (!canEditEvent) {
      showToast(t('calendar.noEditPermission', 'No permission to edit events.'), 'error');
      return;
    }
    setModalTitle(t('calendar.editEvent', 'Edit Event'));
    setModalContent(
      <EventForm
        initialData={eventItem}
        selectedDate={occurrenceDate}
        onSave={async (eventData) => {
          try {
            await editEvent(eventItem.id, eventData);
            setShowModal(false);
            showToast(t('calendar.eventUpdated', 'Event updated successfully'), 'success');
          } catch (error) {
            console.error('Error editing event:', error);
            showToast(t('calendar.errorEditingEvent', 'Error editing event'), 'error');
          }
        }}
        onCancel={() => setShowModal(false)}
        onDelete={async () => {
          if (!canDeleteEvent) {
            showToast(t('calendar.noDeletePermission', 'No permission to delete events.'), 'error');
            return;
          }
          try {
            await deleteEvent(eventItem.id);
            setShowModal(false);
            showToast(t('calendar.eventDeleted', 'Event deleted successfully'), 'success');
          } catch (err) {
            console.error('Error deleting event:', err);
            showToast(t('calendar.errorDeletingEvent', 'Error deleting event'), 'error');
          }
        }}
        onDeleteOccurrence={async (date) => {
          if (!canDeleteEvent) {
            showToast(t('calendar.noDeletePermission', 'No permission to delete events.'), 'error');
            return;
          }
          try {
            await deleteEventOccurrence(eventItem.id, date);
            setShowModal(false);
            showToast(t('calendar.occurrenceDeleted', 'Occurrence deleted successfully'), 'success');
          } catch (err) {
            console.error('Error deleting occurrence:', err);
            showToast(t('calendar.errorDeletingOccurrence', 'Error deleting occurrence'), 'error');
          }
        }}
      />
    );
    setShowModal(true);
  };

  // "filteredEvents" utility if needed
  const filteredEvents = (arr) => {
    return arr.filter((ev) => {
      if (ev.type === 'event' && !showEvents) return false;
      if (ev.type === 'message' && !showCommunications) return false;
      if (ev.type === 'birthday' && !showBirthdays) return false;
      if (ev.type === 'liturgy' && !showLiturgyEvents) return false;
      return true;
    });
  };

  const handleDateClick = (date) => {
    const dateKey = formatDateKey(date);
    const eventList = eventMap[dateKey] || [];
    eventList.sort((a, b) => a.startDateTime - b.startDateTime);
    const filtered = filteredEvents(eventList);
    setSelectedDate(dateKey);
    setSelectedEvents(filtered);
  };

  const createEventBarRoot = () => {
    const eventBarRoot = document.createElement('div');
    eventBarRoot.setAttribute('id', 'event-bar-root');
    document.body.appendChild(eventBarRoot);
    return eventBarRoot;
  };

  const startOfToday = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );

  const getEventIcon = (ev) => {
    let icon = faCalendarAlt;
    let iconStyle = {};
    if (ev.type === 'event') {
      icon = faCalendarAlt;
      iconStyle = { color: '#fff' };
    } else if (ev.type === 'message') {
      icon = faEnvelope;
      iconStyle = { color: '#fff' };
    } else if (ev.type === 'birthday') {
      icon = faBirthdayCake;
      iconStyle = { color: '#fff' };
    } else if (ev.type === 'liturgy') {
      icon = faChurch;
      if (ev.color === '#FFFFFF') {
        iconStyle = { color: 'black' };
      } else {
        iconStyle = { color: '#fff' };
      }
    }
    return <FontAwesomeIcon icon={icon} className="item-icon" style={iconStyle} />;
  };

  const renderEventBottomBar = () => {
    if (!selectedDate) return null;

    const eventBarRoot =
      document.getElementById('event-bar-root') || createEventBarRoot();
    const parsedSelectedDate = parseDateKey(selectedDate);

    const sortedEvents = [...selectedEvents].sort(
      (a, b) => a.startDateTime - b.startDateTime
    );
    const hasNoEvents = sortedEvents.length === 0;

    return ReactDOM.createPortal(
      <div className="event-bottom-bar">
        <div className="event-bottom-bar-content">
          <button className="close-button" onClick={() => setSelectedDate(null)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h3>{parsedSelectedDate.toLocaleDateString()}</h3>

          {hasNoEvents ? (
            <div className="no-events-message">
              {t('calendar.noEventsForThisDay', 'No events for this day')}
            </div>
          ) : (
            <div className="event-list">
              {sortedEvents.map((ev, index) => {
                let icon = faCalendarAlt;
                let iconStyle = {};
                if (ev.type === 'message') {
                  icon = faEnvelope;
                  iconStyle = { color: '#9c27b0' };
                } else if (ev.type === 'birthday') {
                  icon = faBirthdayCake;
                  iconStyle = { color: '#ffa500' };
                } else if (ev.type === 'liturgy') {
                  icon = faChurch;
                  if (ev.color === '#FFFFFF') {
                    iconStyle = { color: 'black' };
                  } else {
                    iconStyle = { color: ev.color || '#006400' };
                  }
                } else if (ev.type === 'event') {
                  icon = faCalendarAlt;
                  iconStyle = { color: ev.color || 'var(--main-color)' };
                }

                return (
                  <div
                    className={`event-row ${
                      index === sortedEvents.length - 1 ? 'last' : ''
                    }`}
                    key={`${ev.id}-${selectedDate}-${index}`}
                  >
                    <div className="event-info">
                      <div className="event-header">
                        <FontAwesomeIcon icon={icon} className="item-icon" style={iconStyle} />
                        <span className="item-type-label">
                          {ev.type === 'event' && t('calendar.eventLabel', 'Event')}
                          {ev.type === 'message' &&
                            t('calendar.communicationLabel', 'Communication')}
                          {ev.type === 'birthday' &&
                            t('calendar.birthdayLabel', 'Birthday')}
                          {ev.type === 'liturgy' &&
                            t('calendar.liturgyLabel', 'Liturgy')}
                        </span>
                      </div>
                      <div className="event-name">{ev.name}</div>

                      {ev.type === 'event' && (
                        <>
                          <div className="event-time">
                            {ev.isAllDay
                              ? t('All day', 'All day')
                              : `${ev.startDateTime.toLocaleTimeString([], {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })} - ${ev.endDateTime.toLocaleTimeString([], {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })}`}
                          </div>
                          {ev.location && (
                            <div className="event-location">{ev.location}</div>
                          )}
                          {ev.description && (
                            <div className="event-description">{ev.description}</div>
                          )}
                        </>
                      )}

                      {ev.type === 'message' && (
                        <>
                          <div className="event-time">
                            {ev.startDateTime.toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                            })}
                          </div>
                          {ev.description && (
                            <div className="event-description">
                              <strong>
                                {t('calendar.scheduledMessageContent', 'Message:')}
                              </strong>{' '}
                              {ev.description}
                            </div>
                          )}
                        </>
                      )}

                      {ev.type === 'birthday' && (
                        <div className="event-time">
                          {t('All day', 'All day')}
                        </div>
                      )}

                      {ev.type === 'liturgy' && (
                        <div className="event-time">
                          {t('All day', 'All day')}
                        </div>
                      )}
                    </div>

                    {ev.type === 'event' && canEditEvent && (
                      <button
                        className="edit-button"
                        onClick={() => {
                          handleEditEvent(ev, selectedDate);
                          setSelectedDate(null);
                        }}
                      >
                        {t('calendar.edit', 'Edit')}
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {canAddEvent && (
            <button
              className="add-event-button"
              onClick={() => {
                setSelectedDate(null);
                setModalTitle(t('calendar.addEvent', 'Add Event'));
                setModalContent(
                  <EventForm
                    initialData={{ startDateTime: parseDateKey(selectedDate) }}
                    onSave={async (eventData) => {
                      try {
                        await addEvent(eventData);
                        setShowModal(false);
                        showToast(
                          t('calendar.eventAdded', 'Event added successfully'),
                          'success'
                        );
                      } catch (error) {
                        console.error('Error adding event:', error);
                        showToast(
                          t('calendar.errorAddingEvent', 'Error adding event'),
                          'error'
                        );
                      }
                    }}
                    onCancel={() => setShowModal(false)}
                  />
                );
                setShowModal(true);
              }}
            >
              {t('calendar.addEvent', 'Add Event')}
            </button>
          )}
        </div>
      </div>,
      eventBarRoot
    );
  };

  // 5) Return the main UI
  return (
    <>
      {!hasViewPermission ? (
        <div className="access-denied">
          403 - You do not have permission to view the calendar.
        </div>
      ) : isLoadingAny ? (
        <div className="loading-container">
          <Spinner size="100px" />
        </div>
      ) : isErrorAny ? (
        <div className="error-message">
          {t('calendar.errorFetchingEvents', 'Error fetching data')}
        </div>
      ) : (
        <div className="calendar-page-container">
          <div className="header-container">
            <h2>{t('calendar.title', 'Calendar')}</h2>

            {canShowAddButton && (
              <div className="config-button">
                <div className="menu-container" ref={menuRef}>
                  <ActionButton
                    onClick={() => setMenuVisible(!menuVisible)}
                    icon="faPlus"
                    variant="secondary"
                    label={t('add', 'Add')}
                    isMobile={false}
                  />
                  {menuVisible && (
                    <div className="dropdown-menu">
                      {canAddEvent && (
                        <button onClick={handleAddEvent}>
                          <FontAwesomeIcon icon={faCalendarAlt} className="menu-icon" />
                          {t('calendar.addEvent', 'Add Event')}
                        </button>
                      )}
                      {canScheduleMessage && (
                        <button onClick={handleAddCommunication}>
                          <FontAwesomeIcon icon={faEnvelope} className="menu-icon" />
                          {t('calendar.addCommunication', 'Add Communication')}
                        </button>
                      )}
                      {canAddPost && (
                        <button onClick={handleAddPost}>
                          <FontAwesomeIcon icon={faStickyNote} className="menu-icon" />
                          {t('calendar.addPost', 'Add Post')}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="calendar-navigation">
            <button className="nav-button" onClick={handlePrevMonth}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <span className="current-month">
              {currentDate.toLocaleString('default', {
                month: 'long',
                year: 'numeric',
              })}
            </span>
            <button className="nav-button" onClick={handleNextMonth}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>

          <div className="calendar-table-container">
            <table className="calendar-table">
              <thead>
                <tr>
                  <th>{t('calendar.sunday', 'Sun')}</th>
                  <th>{t('calendar.monday', 'Mon')}</th>
                  <th>{t('calendar.tuesday', 'Tue')}</th>
                  <th>{t('calendar.wednesday', 'Wed')}</th>
                  <th>{t('calendar.thursday', 'Thu')}</th>
                  <th>{t('calendar.friday', 'Fri')}</th>
                  <th>{t('calendar.saturday', 'Sat')}</th>
                </tr>
              </thead>
              <tbody>
                {calendarRows.map((week, i) => (
                  <tr key={i}>
                    {week.map((date, j) => {
                      const dateKey = formatDateKey(date);
                      let eventsForDate = eventMap[dateKey] || [];
                      eventsForDate.sort((a, b) => a.startDateTime - b.startDateTime);
                      eventsForDate = filteredEvents(eventsForDate);

                      const isCurrentMonth = date.getMonth() === currentDate.getMonth();
                      const today = new Date();
                      const isToday =
                        date.getDate() === today.getDate() &&
                        date.getMonth() === today.getMonth() &&
                        date.getFullYear() === today.getFullYear();

                      let eventsToShow = eventsForDate;
                      let extraCount = 0;
                      if (eventsForDate.length > 3) {
                        eventsToShow = eventsForDate.slice(0, 2);
                        extraCount = eventsForDate.length - 2;
                      }

                      return (
                        <td
                          key={j}
                          className={`${isCurrentMonth ? 'current-month' : 'other-month'} ${
                            isToday ? 'today' : ''
                          }`}
                          onClick={() => handleDateClick(date)}
                        >
                          <div className="date-number">{date.getDate()}</div>
                          {eventsToShow.map((ev) => {
                            let isPastEvent;
                            if (ev.isAllDay) {
                              const eventDay = new Date(
                                date.getFullYear(),
                                date.getMonth(),
                                date.getDate()
                              );
                              isPastEvent = eventDay < startOfToday;
                            } else {
                              isPastEvent = ev.endDateTime < new Date();
                            }

                            const customStyle =
                              ev.color === '#FFFFFF'
                                ? {
                                    backgroundColor: ev.color,
                                    color: 'black',
                                    border: '1px solid black',
                                  }
                                : {
                                    backgroundColor: ev.color || 'var(--main-color)',
                                    color: '#fff',
                                  };

                            return (
                              <div
                                key={`${ev.id}-${dateKey}`}
                                className={`event ${isPastEvent ? 'past-event' : ''}`}
                                style={customStyle}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedDate(dateKey);
                                  setSelectedEvents([ev]);
                                }}
                              >
                                {getEventIcon(ev)} {ev.name}
                              </div>
                            );
                          })}
                          {extraCount > 0 && (
                            <div
                              className="event extra-count"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedDate(dateKey);
                                setSelectedEvents(eventsForDate);
                              }}
                            >
                              +{extraCount}
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="filters-container">
            <ToggleSwitch
              id="showEvents"
              checked={showEvents}
              onChange={(checked) => setShowEvents(checked)}
              label={t('calendar.showEvents', 'Show Events')}
              labelPosition="right"
            />
            <ToggleSwitch
              id="showCommunications"
              checked={showCommunications}
              onChange={(checked) => setShowCommunications(checked)}
              label={t('calendar.showCommunications', 'Show Communications')}
              labelPosition="right"
            />
            <ToggleSwitch
              id="showBirthdays"
              checked={showBirthdays}
              onChange={(checked) => setShowBirthdays(checked)}
              label={t('calendar.showBirthdays', 'Show Birthdays')}
              labelPosition="right"
            />
            <ToggleSwitch
              id="showLiturgy"
              checked={showLiturgyEvents}
              onChange={(checked) => setShowLiturgyEvents(checked)}
              label={t('calendar.showLiturgyEvents', 'Show Liturgy Events')}
              labelPosition="right"
            />
          </div>
        </div>
      )}

      <CustomModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
        }}
        title={modalTitle}
      >
        {modalContent}
      </CustomModal>

      <ToastContainer toasts={toasts} removeToast={removeToast} />

      {renderEventBottomBar()}
    </>
  );
};

export default CalendarPage;
