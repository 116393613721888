// src/components/Policies/PoliciesContainer.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TermsService from './TermsService';
import PrivacyPolicy from './PrivacyPolicy';
import './PoliciesContainer.css';

const PoliciesContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Parse the 'policy' query parameter (e.g. ?policy=privacy or ?policy=terms)
  const queryParams = new URLSearchParams(location.search);
  const initialPolicy = queryParams.get('policy');

  // Decide initial active policy based on the query param
  const getInitialPolicy = () => {
    if (initialPolicy === 'privacy') {
      return 'privacy';
    }
    // Default to 'terms' if not specified or invalid
    return 'terms';
  };

  const [activePolicy, setActivePolicy] = useState(getInitialPolicy);

  // Control fade-in state
  const [fadeIn, setFadeIn] = useState(false);

  // Scroll to top immediately on mount, then trigger fade-in
  useEffect(() => {
    // Scroll to top without smooth scrolling
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    
    // Trigger the fade-in after the scroll
    setFadeIn(true);
  }, []);

  // If the user changes the URL directly or navigates, sync state
  useEffect(() => {
    const currentPolicy = queryParams.get('policy');
    if (currentPolicy === 'privacy') {
      setActivePolicy('privacy');
    } else {
      setActivePolicy('terms');
    }
  }, [location.search]);

  return (
    <div className={`policies-container ${fadeIn ? 'fade-in' : ''}`}>
      {/* Left Panel for tabs */}
      <div className="policies-left-panel">
        <h2>Policies</h2>
        <button
          className={activePolicy === 'terms' ? 'active-tab' : ''}
          onClick={() => {
            setActivePolicy('terms');
            navigate('/policies?policy=terms');
          }}
        >
          Terms of Service
        </button>
        <button
          className={activePolicy === 'privacy' ? 'active-tab' : ''}
          onClick={() => {
            setActivePolicy('privacy');
            navigate('/policies?policy=privacy');
          }}
        >
          Privacy Policy
        </button>
        <button className="home-button" onClick={() => navigate('/')}>
          Go Back to Home
        </button>
      </div>

      {/* Right Panel to display the selected policy */}
      <div className="policies-right-panel">
        {activePolicy === 'terms' && <TermsService />}
        {activePolicy === 'privacy' && <PrivacyPolicy />}
      </div>
    </div>
  );
};

export default PoliciesContainer;
