import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from '../../contexts/UserContext'; // <-- Access permissions here
import './MembersTable.css'; // local CSS
import ActionButton from '../Common/ActionButton';
import Table from '../Common/Table';
import PaginationControls from '../Common/PaginationControls';
import SearchBar from '../Common/SearchBar';
import { useTranslation } from 'react-i18next';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

const MembersTable = ({
  fields,
  actions,
  members,
  addMember,
  onRowClick,
}) => {
  const { t } = useTranslation();

  // Pull in hasPermission if you want to hide the button in this component
  const { hasPermission } = useContext(AppSettingsContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  // Handle sorting
  const setSorting = (field) => {
    if (sortField === field) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
    setCurrentPage(1);
  };

  // Filtered data by search term
  const filteredRecords = useMemo(() => {
    if (!searchTerm) return members || [];
    const lowercasedTerm = searchTerm.toLowerCase();

    return (members || []).filter((member) =>
      fields.some((field) => {
        const value = Array.isArray(field.accessor)
          ? field.accessor.map((key) => member[key] || '').join(' ')
          : typeof field.accessor === 'function'
          ? field.accessor(member)
          : member[field.accessor];

        return (
          value &&
          value.toString().toLowerCase().includes(lowercasedTerm)
        );
      })
    );
  }, [searchTerm, members, fields]);

  // Sort data
  const sortedRecords = useMemo(() => {
    if (!filteredRecords) return [];
    if (!sortField) return filteredRecords;

    const column = fields.find((f) => f.id === sortField);
    if (!column) return filteredRecords;

    const getFieldValue = (record, col) => {
      if (Array.isArray(col.accessor)) {
        return col.accessor.map((key) => record[key] || '').join(' ').trim();
      } else if (typeof col.accessor === 'function') {
        return col.accessor(record);
      } else {
        return record[col.accessor];
      }
    };

    return [...filteredRecords].sort((a, b) => {
      const aValue = getFieldValue(a, column);
      const bValue = getFieldValue(b, column);

      if (aValue == null) return sortOrder === 'asc' ? -1 : 1;
      if (bValue == null) return sortOrder === 'asc' ? 1 : -1;

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortOrder === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredRecords, sortField, sortOrder, fields]);

  // Pagination
  const paginatedRecords = useMemo(() => {
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    return sortedRecords.slice(startIdx, endIdx);
  }, [sortedRecords, currentPage, itemsPerPage]);

  const totalPages = useMemo(() => {
    return Math.ceil(filteredRecords.length / itemsPerPage) || 1;
  }, [filteredRecords.length, itemsPerPage]);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  // Searching
  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  }, []);

  // Items per page
  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  // Only show Add Member button if user has the 'addMember' permission
  const canAddMember = hasPermission('members', 'addMember');

  return (
    <div className="member-table-container">
      <div className="members-header-actions">
        <SearchBar
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          placeholder={t('membersTable.searchPlaceholder', 'Search members...')}
        />

        {/* Hide the button if user lacks addMember */}
        {canAddMember && (
          <ActionButton
            onClick={addMember}
            label={t('membersTable.addNewMember', 'Add New Member')}
            icon="faPlus"
            colorType="primary"
          />
        )}
      </div>

      <div className="record-count" aria-live="polite">
        {filteredRecords.length === 1
          ? t(
              'membersTable.recordsFound',
              { count: filteredRecords.length },
              '1 member found'
            )
          : t(
              'membersTable.recordsFound_plural',
              { count: filteredRecords.length },
              `${filteredRecords.length} members found`
            )}
      </div>

      <Table
        data={paginatedRecords}
        columns={fields}
        actions={actions}
        setSorting={setSorting}
        sortField={sortField}
        sortOrder={sortOrder}
        noRecordsMessage={t('membersTable.noRecordsFound', 'No members found')}
        actionsHeaderLabel={t('actions', 'Actions')}
        onRowClick={onRowClick}
      />

      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPrev={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        onNext={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        onPageClick={(page) => setCurrentPage(page)}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
        itemsPerPageLabel={t(
          'membersTable.itemsPerPageLabel',
          'Members per page:'
        )}
        previousLabel={t('previousLabel', 'Previous')}
        nextLabel={t('nextLabel', 'Next')}
        pageLabel={t('pageLabel', 'Page')}
      />
    </div>
  );
};

MembersTable.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      accessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.string),
      ]).isRequired,
      sortable: PropTypes.bool,
      type: PropTypes.string,
      clickToEdit: PropTypes.bool,
    })
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
      variant: PropTypes.string,
      condition: PropTypes.func,
      as: PropTypes.string,
      hrefAccessor: PropTypes.func,
      disabledAccessor: PropTypes.func,
    })
  ).isRequired,
  members: PropTypes.array.isRequired,
  addMember: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default MembersTable;
