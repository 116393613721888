// src/components/Common/IconMapSelector.jsx
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconMap from './IconMap'; // The file containing all FontAwesome solid icons
import './IconMapSelector.css';

const IconMapSelector = ({
  selectedIcon,
  onChange,
  placeholder = 'Select Icon',
  showPreview = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const selectorRef = useRef(null);
  const toggleRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  // Convert IconMap object keys to a sorted array
  const iconKeys = Object.keys(IconMap).sort();

  // Filter icon keys by search term
  const filteredKeys = iconKeys.filter((key) =>
    key.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        isOpen &&
        selectorRef.current &&
        !selectorRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  const handleToggle = (e) => {
    e.stopPropagation();
    if (!isOpen) {
      setSearchTerm(''); // Reset search when reopening
      // Calculate position for the dropdown
      const rect = selectorRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY + 5,
        left: rect.left + window.scrollX,
      });
    }
    setIsOpen(!isOpen);
  };

  const handleIconSelect = (iconKey) => {
    onChange(iconKey);
    setIsOpen(false);
  };

  const displayedIcon = selectedIcon && IconMap[selectedIcon] ? selectedIcon : '';

  // Create a root element for the portal if it doesn't exist
  const getIconSelectorRoot = () => {
    let root = document.getElementById('icon-selector-root');
    if (!root) {
      root = document.createElement('div');
      root.setAttribute('id', 'icon-selector-root');
      document.body.appendChild(root);
    }
    return root;
  };

  const dropdownContent = isOpen && (
    <div
      className="icon-dropdown-portal"
      style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div className="icon-dropdown-content">
        <input
          className="icon-search"
          type="text"
          placeholder="Search icons..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={(e) => e.stopPropagation()} // Prevent closing dropdown
        />
        <div className="icon-grid">
          {/* Option for 'no icon' */}
          <div
            className="icon-dropdown-item none-option"
            onClick={() => handleIconSelect('')}
          >
            None
          </div>

          {filteredKeys.map((key) => (
            <div
              key={key}
              className="icon-dropdown-item"
              onClick={() => handleIconSelect(key)}
            >
              <FontAwesomeIcon
                icon={IconMap[key]}
                style={{ color: 'var(--main-color)' }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`icon-map-selector ${showPreview ? 'preview-mode' : ''}`}
      ref={selectorRef}
    >
      {showPreview ? (
        // Large circle preview
        <div className="icon-preview-circle" ref={toggleRef} onClick={handleToggle}>
          {displayedIcon ? (
            <FontAwesomeIcon
              icon={IconMap[displayedIcon]}
              style={{ color: 'var(--main-color)', fontSize: '50px' }}
            />
          ) : (
            <FontAwesomeIcon
              icon={IconMap['faUserCircle']}
              style={{ color: 'var(--main-color)', fontSize: '50px' }}
            />
          )}
        </div>
      ) : (
        // Small square icon mode
        <div className="icon-selector-square" ref={toggleRef} onClick={handleToggle}>
          {displayedIcon ? (
            <FontAwesomeIcon
              icon={IconMap[displayedIcon]}
              style={{ color: 'var(--main-color)', fontSize: '16px' }}
            />
          ) : (
            /* MORE VISIBLE PLUS SIGN HERE */
            <FontAwesomeIcon
              icon="plus"
              style={{ color: '#000', fontSize: '20px', opacity: 1 }}
            />
          )}
          <FontAwesomeIcon icon="chevron-down" className="chevron-icon" />
        </div>
      )}

      {isOpen && ReactDOM.createPortal(dropdownContent, getIconSelectorRoot())}
    </div>
  );
};

export default IconMapSelector;
