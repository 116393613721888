import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import './GroupsTable.css';
import ActionButton from '../Common/ActionButton';
import Table from '../Common/Table';
import PaginationControls from '../Common/PaginationControls';
import SearchBar from '../Common/SearchBar';
import { useTranslation } from 'react-i18next';

const GroupsTable = ({
  fields,
  actions,
  groups,
  addGroup,      // callback or undefined
  onRowClick,    // row click handler
  formId,        // unused but available if needed
}) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  // Sorting logic
  const setSorting = (field) => {
    if (sortField === field) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
    setCurrentPage(1);
  };

  // Filter records by searchTerm
  const filteredRecords = useMemo(() => {
    if (!searchTerm) return groups || [];
    const lowercasedTerm = searchTerm.toLowerCase();

    return (groups || []).filter((group) =>
      fields.some((field) => {
        const value =
          typeof field.accessor === 'function'
            ? field.accessor(group)
            : Array.isArray(field.accessor)
            ? field.accessor.map((key) => group[key] || '').join(' ')
            : group[field.accessor];

        if (value && typeof value === 'string') {
          return value.toLowerCase().includes(lowercasedTerm);
        }
        return false;
      })
    );
  }, [searchTerm, groups, fields]);

  // Sort records
  const sortedRecords = useMemo(() => {
    if (!filteredRecords.length || !sortField) return filteredRecords;

    const column = fields.find((f) => f.id === sortField);
    if (!column) return filteredRecords;

    const getFieldValue = (record, col) => {
      const { accessor } = col;
      if (Array.isArray(accessor)) {
        return accessor.map((key) => record[key] || '').join(' ').trim();
      } else if (typeof accessor === 'string') {
        return record[accessor];
      } else if (typeof accessor === 'function') {
        return accessor(record);
      }
      return '';
    };

    return [...filteredRecords].sort((a, b) => {
      const aValue = getFieldValue(a, column);
      const bValue = getFieldValue(b, column);

      if (aValue == null) return sortOrder === 'asc' ? -1 : 1;
      if (bValue == null) return sortOrder === 'asc' ? 1 : -1;

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortOrder === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredRecords, sortField, sortOrder, fields]);

  // Paginate
  const paginatedRecords = useMemo(() => {
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    return sortedRecords.slice(startIdx, endIdx);
  }, [sortedRecords, currentPage, itemsPerPage]);

  const totalPages = useMemo(() => {
    return Math.ceil(filteredRecords.length / itemsPerPage) || 1;
  }, [filteredRecords.length, itemsPerPage]);

  // Keep current page within bounds
  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  // Handlers
  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  }, []);

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  return (
    <div className="groups-table-container">
      {/* Action Bar: Search + optional "Add" Button */}
      <div className="groups-header-actions">
        <SearchBar
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          placeholder={t('groupsTable.searchPlaceholder', 'Search groups...')}
        />

        {/* Hide the Add button if addGroup is not provided */}
        {addGroup && (
          <ActionButton
            onClick={addGroup}
            label={t('groupsTable.addNewGroup', 'Add New Group')}
            icon="faPlus"
            colorType="primary"
          />
        )}
      </div>

      <div className="record-count" aria-live="polite">
        {filteredRecords.length === 1
          ? t('groupsTable.recordsFound', { count: filteredRecords.length }, '1 group found')
          : t('groupsTable.recordsFound_plural', { count: filteredRecords.length }, `${filteredRecords.length} groups found`)}
      </div>

      {/* Table with row-level actions */}
      <Table
        data={paginatedRecords}
        columns={fields}
        actions={actions}
        setSorting={setSorting}
        sortField={sortField}
        sortOrder={sortOrder}
        noRecordsMessage={t('groupsTable.noRecordsFound', 'No groups found')}
        actionsHeaderLabel={t('actions', 'Actions')}
        onRowClick={onRowClick}
      />

      {/* Pagination Controls */}
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPrev={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        onNext={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        onPageClick={(page) => setCurrentPage(page)}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
        itemsPerPageLabel={t('groupsTable.itemsPerPageLabel', 'Groups per page:')}
        previousLabel={t('previousLabel', 'Previous')}
        nextLabel={t('nextLabel', 'Next')}
        pageLabel={t('pageLabel', 'Page')}
      />
    </div>
  );
};

GroupsTable.propTypes = {
  formId: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      accessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.string),
      ]).isRequired,
      sortable: PropTypes.bool,
      type: PropTypes.string,
      clickToEdit: PropTypes.bool,
    })
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
      variant: PropTypes.string,
      condition: PropTypes.func,
      as: PropTypes.string,
      hrefAccessor: PropTypes.func,
      disabledAccessor: PropTypes.func,
    })
  ).isRequired,
  groups: PropTypes.array.isRequired,
  addGroup: PropTypes.func, // we can pass undefined if user lacks addGroup
  onRowClick: PropTypes.func,
};

GroupsTable.defaultProps = {
  formId: '',
  addGroup: undefined,
  onRowClick: null,
};

export default GroupsTable;
