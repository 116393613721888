// src/components/Common/CustomTooltip.jsx

import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './CustomTooltip.css';

const tooltipRoot = document.getElementById('tooltip-root');

const CustomTooltip = ({ text, children }) => {
  const triggerRef = useRef(null);

  // Track tooltip visibility + screen position
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  // Show tooltip & calculate position
  const handleMouseEnter = () => {
    if (!triggerRef.current) return;
    const rect = triggerRef.current.getBoundingClientRect();

    // Add scroll offsets
    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Center X: midpoint of the trigger (plus scroll offset)
    let tooltipX = rect.left + rect.width / 2 + scrollX;
    // Y: top of the trigger (plus scroll offset)
    let tooltipY = rect.top + scrollY;

    // Clamping horizontally so it doesn't overflow screen
    const screenWidth = window.innerWidth;
    const safePadding = 20;
    if (tooltipX < safePadding) {
      tooltipX = safePadding;
    } else if (tooltipX > screenWidth - safePadding) {
      tooltipX = screenWidth - safePadding;
    }

    setPosition({ x: tooltipX, y: tooltipY });
    setIsVisible(true);
  };

  // Hide tooltip
  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  // Portal's tooltip element
  const tooltipElement = isVisible ? (
    <div
      className="enhanced-tooltip"
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
    >
      {text}
    </div>
  ) : null;

  return (
    <>
      <span
        ref={triggerRef}
        className="tooltip-trigger-wrapper"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </span>

      {ReactDOM.createPortal(tooltipElement, tooltipRoot)}
    </>
  );
};

CustomTooltip.propTypes = {
  text: PropTypes.string.isRequired,   // Tooltip text
  children: PropTypes.node.isRequired, // The element the tooltip is attached to
};

export default CustomTooltip;
