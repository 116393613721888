// src/components/TeamsTable/TeamsTable.jsx

import React, { useState, useEffect, useMemo } from 'react';
import './TeamsTable.css';
import SearchBar from '../Common/SearchBar';
import PaginationControls from '../Common/PaginationControls';
import Table from '../Common/Table';
import { useTranslation } from 'react-i18next';
import ActionButton from '../Common/ActionButton';

const TeamsTable = ({
  teams,
  handleAddTeam,
  handleEditTeam,
  handleDeleteTeam,
  isTeamsLoading,
  isMobile,
}) => {
  const { t } = useTranslation();

  // State variables
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search term changes
  };

  // Sorting function
  const setSorting = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  // Compute filtered teams
  const filteredTeams = useMemo(() => {
    const searchString = searchTerm.toLowerCase();
    return teams.filter((team) => {
      const name = team.name.toLowerCase();
      return name.includes(searchString);
    });
  }, [teams, searchTerm]);

  // Define table columns
  const columns = [
    {
      id: 'name',
      label: t('teamTable.name'),
      accessor: 'name',
      sortable: true,
    },
    {
      id: 'members',
      label: t('teamTable.members'),
      accessor: (team) => team.members?.length || 0,
      sortable: true,
    },
  ];

  // Define table actions
  const actions = [
    {
      label: t('edit'),
      icon: 'faEdit',
      callback: handleEditTeam,
      condition: () => true,
    },
    {
      label: t('delete'),
      icon: 'faTrash',
      callback: handleDeleteTeam,
      condition: () => true,
    },
  ];

  // Sort teams based on sorting state
  const sortedTeams = useMemo(() => {
    const data = filteredTeams;
    if (!sortField) return data;
    const column = columns.find((col) => col.id === sortField);
    if (!column) return data;

    const accessor = column.accessor;
    return [...data].sort((a, b) => {
      let aValue;
      let bValue;

      if (typeof accessor === 'function') {
        aValue = accessor(a);
        bValue = accessor(b);
      } else if (typeof accessor === 'string') {
        aValue = a[accessor];
        bValue = b[accessor];
      } else {
        aValue = '';
        bValue = '';
      }

      if (!aValue) aValue = '';
      if (!bValue) bValue = '';

      if (typeof aValue === 'string') aValue = aValue.toLowerCase();
      if (typeof bValue === 'string') bValue = bValue.toLowerCase();

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredTeams, sortField, sortOrder, columns]);

  // Compute total pages
  const totalPages = Math.ceil(sortedTeams.length / itemsPerPage) || 1;

  // Keep currentPage within bounds
  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  // Compute paginated teams
  const paginatedTeams = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sortedTeams.slice(startIndex, endIndex);
  }, [sortedTeams, currentPage, itemsPerPage]);

  // Pagination handlers
  const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  const handlePageClick = (pageNumber) => setCurrentPage(pageNumber);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(Number(value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  return (
    <div className="teams-table-container">
    
      {/* Action Buttons */}
      <div className="teams-header-actions">
      {/* Search Bar */}
      <SearchBar
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        placeholder={t('teams.searchPlaceholder')}
        isLoading={isTeamsLoading}
      />
      <ActionButton
            onClick={handleAddTeam}
            text=""
            label={t('teamsHeader.addTeam')}
            icon="faPlus"
            isMobile={isMobile}
            colorType="primary"
            ariaLabel={t('teamsHeader.addTeamAria')}
          />
    </div>

      <div className="record-count" aria-live="polite">
        {filteredTeams.length === 1
          ? t('teamTable.recordsFound', { count: filteredTeams.length })
          : t('teamTable.recordsFound_plural', { count: filteredTeams.length })}
      </div>

      <Table
        data={paginatedTeams}
        columns={columns}
        actions={actions}
        setSorting={setSorting}
        sortField={sortField}
        sortOrder={sortOrder}
        noRecordsMessage={t('teams.noRecords')}
        actionsHeaderLabel={t('actions')}
      />

      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPrev={handlePrevPage}
        onNext={handleNextPage}
        onPageClick={handlePageClick}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
        itemsPerPageOptions={[20, 50, 100]}
        itemsPerPageLabel={t('teamTable.itemsPerPageLabel', 'Teams per page:')}
        noun={t('teamTable.teams')}
      />
    </div>
  );
};

export default TeamsTable;
