// src/components/FormBuilder/ResponsesTab.jsx

import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconMap from '../Common/IconMap';
import ActionButton from '../Common/ActionButton';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Spinner from '../Common/Spinner';
import './ResponsesTab.css';

import {
  generateCSV,
  parseCSV,
  mapCSVRowToResponseData,
  validateResponseData,
  downloadCSV,
  isValidDate,
} from './CSVUtility';

import { generatePDF } from './PDFUtility';

import { DataContext } from '../../DataContext';
import { UserContext } from '../../contexts/UserContext'; // <-- Import for permissions
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

import { useQueryClient } from '@tanstack/react-query';

import FillOutForm from './FillOutForm';

import UserIcon from '../../assets/UserIcon.png';

const ResponsesTab = ({
  showToast,
  fields = [],
  formId,
  catalogData,
}) => {
  const { t } = useTranslation();
  const [expandedResponseIds, setExpandedResponseIds] = useState([]);
  const [editingResponseId, setEditingResponseId] = useState(null);
  const [editingResponseData, setEditingResponseData] = useState(null);

  // For CSV Import
  const [isImporting, setIsImporting] = useState(false);
  const fileInputRef = useRef(null);

  // Pull from DataContext
  const {
    submitFormResponse,
    deleteResponse,
    updateResponse,
    useResponses,
    fetchFormDetails,
  } = useContext(DataContext);

    // Pull from DataContext
    const {
      organizationData,
      appSettings,
    } = useContext(AppSettingsContext);

  // Pull permission checks from UserContext
  const { hasPermission } = useContext(AppSettingsContext);
  const canEditForm = hasPermission('forms', 'editForm');
  const canDeleteForm = hasPermission('forms', 'deleteForm');
  const canExportForm = hasPermission('forms', 'exportForm');

  // Keep track of form name
  const [formName, setFormName] = useState('Form');

  // React Query's QueryClient
  const queryClient = useQueryClient();

  // Fetch form name from Firestore
  useEffect(() => {
    const fetchFormName = async () => {
      if (formId) {
        const formDetails = await fetchFormDetails(formId);
        if (formDetails && formDetails.name) {
          setFormName(formDetails.name);
        }
      }
    };
    fetchFormName();
  }, [formId, fetchFormDetails]);

  // Get responses for this form
  const {
    data: responses = [],
    isLoading,
    error,
  } = useResponses(formId);

  // Toggle response expansion
  const toggleResponse = (responseId) => {
    setExpandedResponseIds((prevIds) =>
      prevIds.includes(responseId)
        ? prevIds.filter((id) => id !== responseId)
        : [...prevIds, responseId]
    );
  };

  // Helper: Format date/time
  const formatDate = (value) => {
    const date = new Date(value);
    return isNaN(date.getTime()) ? value : date.toLocaleString();
  };

  const formatDateOnly = (value) => {
    const date = new Date(value);
    return isNaN(date.getTime()) ? value : date.toLocaleDateString();
  };

  const camelCaseToNormal = (text) => {
    if (!text) return '';
    const result = text
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase());
    return result;
  };

  // Render field value
  const renderFieldValue = (field, fieldDef) => {
    const { value, type, label } = field;

    switch (type) {
      case 'MultiRowControl':
        if (!value || !Array.isArray(value) || !fieldDef || !fieldDef.rowFields) {
          return '';
        }
        return (
          <table className="multi-row-control-table">
            <thead>
              <tr>
                {fieldDef.rowFields.map((rowField) => (
                  <th key={rowField.id}>{rowField.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {value.map((row, index) => (
                <tr key={index}>
                  {fieldDef.rowFields.map((rowField) => (
                    <td key={rowField.id}>
                      {row[rowField.id] != null ? row[rowField.id].toString() : ''}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );

      case 'Checkbox':
        if (Array.isArray(value)) {
          return value
            .map((option) => {
              const optionLabel = camelCaseToNormal(option.name);
              const completedText = option.completed
                ? t('responsesTab.yes')
                : t('responsesTab.no');
              return `${optionLabel}: ${completedText}`;
            })
            .join(', ');
        }
        return '';

      case 'ImagePicker':
        return <img src={value} alt={label} className="response-image" />;

      case 'DatePicker':
        return isValidDate(value) ? formatDateOnly(value) : null;

      case 'DateTimePicker':
      case 'TimePicker':
        return isValidDate(value) ? formatDate(value) : null;

      case 'RangeSlider':
      case 'Number':
        return value.toString();

      case 'RadioButton':
      case 'Select':
        return value !== null && value !== undefined ? value.toString() : '';

      case 'ToggleSwitch':
        return value ? t('responsesTab.yes') : t('responsesTab.no');

      case 'TextArea':
      case 'Text':
        return value.toString();

      default:
        if (Array.isArray(value)) {
          return value.join(', ');
        }
        return value !== null && value !== undefined ? value.toString() : '';
    }
  };

  // ------------------------------------------------------------
  //  Export Handlers (CSV/PDF) => gate by "exportForm"
  // ------------------------------------------------------------
  const handleExportCSV = () => {
    // If user lacks "exportForm", do nothing
    if (!canExportForm) {
      showToast(t('responsesTab.noExportPermission', 'You do not have permission to export.'), 'error');
      return;
    }

    if (responses.length === 0) {
      showToast(t('responsesTab.noResponsesToExport'), 'error');
      return;
    }

    try {
      const csvContent = generateCSV(responses, fields);
      downloadCSV(csvContent, 'responses.csv');
      showToast(t('responsesTab.csvExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.csvExportError'), 'error');
      console.error('Error exporting CSV:', error);
    }
  };

  const handleExportPDF = async () => {
    // If user lacks "exportForm", do nothing
    if (!canExportForm) {
      showToast(t('responsesTab.noExportPermission', 'You do not have permission to export.'), 'error');
      return;
    }

    if (responses.length === 0) {
      showToast(t('responsesTab.noResponsesToExport'), 'error');
      return;
    }

    try {
      const groupLogo = appSettings?.profilePicture || null;
      generatePDF(responses, fields, false, groupLogo, formName);
      showToast(t('responsesTab.pdfExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.pdfExportError'), 'error');
      console.error('Error exporting PDF:', error);
    }
  };

  const handleDownloadPDF = (response) => {
    // Also check "exportForm" for single PDF
    if (!canExportForm) {
      showToast(t('responsesTab.noExportPermission', 'You do not have permission to export.'), 'error');
      return;
    }

    try {
      const groupLogo = appSettings?.profilePicture || null;
      generatePDF([response], fields, true, groupLogo, formName);
      showToast(t('responsesTab.pdfDownloadSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.pdfDownloadError'), 'error');
      console.error('Error downloading PDF:', error);
    }
  };

  // ------------------------------------------------------------
  //  Delete Handler => gate by "deleteForm"
  // ------------------------------------------------------------
  const handleDelete = async (responseId) => {
    if (!canDeleteForm) {
      showToast(t('responsesTab.noDeletePermission', 'You do not have permission to delete responses.'), 'error');
      return;
    }

    const confirmDelete = window.confirm(t('responsesTab.confirmDelete'));
    if (confirmDelete) {
      try {
        await deleteResponse(formId, responseId);
        setExpandedResponseIds((prevIds) => prevIds.filter((id) => id !== responseId));
        queryClient.invalidateQueries(['responses', formId]);
      } catch (error) {
        showToast(t('responsesTab.deleteError'), 'error');
        console.error(`Error deleting response with ID ${responseId}:`, error);
      }
    }
  };

  // ------------------------------------------------------------
  //  Import Handler => can remain open if you'd like,
  //  or also gate by "exportForm". 
  //  We'll assume you want only "exportForm" to handle import as well,
  //  or you can do a separate permission if you prefer
  // ------------------------------------------------------------
  const handleImportCSV = async (event) => {
    // We'll assume a user who can "exportForm" can also import CSV
    if (!canExportForm) {
      showToast(t('responsesTab.noExportPermission', 'You do not have permission to import.'), 'error');
      return;
    }

    const file = event.target.files[0];
    if (!file) return;

    event.target.value = '';

    setIsImporting(true);

    try {
      const parsedData = await parseCSV(file);

      // Process each row
      for (let i = 0; i < parsedData.length; i++) {
        const row = parsedData[i];

        const mappedData = mapCSVRowToResponseData(
          row,
          fields,
          formId,
          organizationData,
          UserIcon
        );

        const validationErrors = validateResponseData(mappedData, fields);
        if (validationErrors) {
          Object.values(validationErrors).forEach((errorMessage) => {
            showToast(
              t('responsesTab.importValidationError', {
                row: i + 1,
                message: errorMessage,
              }),
              'error'
            );
          });
          continue; 
        }

        try {
          await submitFormResponse(formId, mappedData);
        } catch (submitError) {
          showToast(
            t('responsesTab.importSubmissionError', {
              row: i + 1,
              message: submitError.message,
            }),
            'error'
          );
          console.error(`Error importing row ${i + 1}:`, submitError);
        }
      }

      showToast(t('responsesTab.importSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.csvParseError'), 'error');
      console.error('Error importing CSV:', error);
    } finally {
      setIsImporting(false);
      queryClient.invalidateQueries(['responses', formId]);
    }
  };

  // ------------------------------------------------------------
  //  Editing => gate by "editForm"
  // ------------------------------------------------------------
  const handleCancelEdit = () => {
    setEditingResponseId(null);
    setEditingResponseData(null);
    setExpandedResponseIds((prevIds) =>
      prevIds.filter((id) => id !== editingResponseId)
    );
  };

  return (
    <div className="responses-tab-container">
      {/* Top Section: Total Responses and Action Buttons */}
      <div className="top-actions">
        {/* Total Responses Count */}
        <div className="responses-count">
          {t('responsesTab.totalResponses')}:{" "}
          <span className="count-number">{responses.length}</span>
        </div>

        {/* Action Buttons Container */}
        <div className="action-buttons">
          {/* CSV Export Button => hidden if cannot exportForm */}
          {canExportForm && (
            <ActionButton
              onClick={handleExportCSV}
              label={t('responsesTab.exportCsv')}
              icon="faFileCsv"
              isMobile={false}
              colorType="secondary"
              spacing="0px"
              className="export-csv-button"
              ariaLabel={t('responsesTab.exportCsvAriaLabel')}
            />
          )}

          {/* PDF Export Button => hidden if cannot exportForm */}
          {canExportForm && (
            <ActionButton
              onClick={handleExportPDF}
              label={t('responsesTab.exportPdf')}
              icon="faFilePdf"
              isMobile={false}
              colorType="secondary"
              spacing="0px"
              className="export-pdf-button"
              ariaLabel={t('responsesTab.exportPdfAriaLabel')}
            />
          )}

          {/* CSV Import Button - Only show if formId is 'membersForm' AND user can exportForm */}
          {formId === 'membersForm' && canExportForm && (
            <>
              <ActionButton
                onClick={() => fileInputRef.current.click()}
                label={t('responsesTab.importCsv')}
                icon="faFileImport"
                isMobile={false}
                colorType="secondary"
                spacing="0px"
                className="import-csv-button"
                ariaLabel={t('responsesTab.importCsvAriaLabel')}
              />
              <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleImportCSV}
              />
            </>
          )}
        </div>
      </div>

      {/* Display loading spinner if needed */}
      {isLoading && (
        <div className="loading-spinner">
          <Spinner />
        </div>
      )}

      {/* Display error message if there's an error */}
      {error && <p className="error">{t('responsesTab.loadError')}</p>}

      {/* Display message if there are no responses and not loading */}
      {!isLoading && responses.length === 0 && (
        <p className="no-responses">{t('responsesTab.noResponses')}</p>
      )}

      {/* Importing Overlay */}
      {isImporting && (
        <div className="importing-overlay">
          <Spinner size="60px" />
          <span className="importing-text">{t('responsesTab.importing')}</span>
        </div>
      )}

      {/* Responses List with Animations */}
      <TransitionGroup className="responses-list">
        {responses.map((response) => (
          <CSSTransition key={response.id} timeout={200} classNames="response">
            <div className="response-card">
              <div
                className="response-header"
                onClick={() => {
                  if (editingResponseId !== response.id) {
                    toggleResponse(response.id);
                  }
                }}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    if (editingResponseId !== response.id) {
                      toggleResponse(response.id);
                    }
                  }
                }}
              >
                <div className="response-header-content">
                  <span className="response-submitted-at">
                    {response.submittedAt
                      ? new Date(response.submittedAt.seconds * 1000).toLocaleString()
                      : t("responsesTab.unknownDate")}
                  </span>
                </div>

                <div className="response-header-buttons">
                  {/* Edit Button => hidden if user lacks editForm */}
                  {canEditForm && (
                    <button
                      className="edit-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (editingResponseId === response.id) {
                          handleCancelEdit();
                        } else {
                          setEditingResponseId(response.id);
                          setEditingResponseData(response);
                          // Expand the response card
                          if (!expandedResponseIds.includes(response.id)) {
                            toggleResponse(response.id);
                          }
                        }
                      }}
                      aria-label={
                        editingResponseId === response.id
                          ? t("responsesTab.cancelEdit")
                          : t("responsesTab.editResponse")
                      }
                    >
                      <FontAwesomeIcon icon={iconMap["faEdit"]} />
                    </button>
                  )}

                  {/* Delete Button => hidden if user lacks deleteForm */}
                  {canDeleteForm && (
                    <button
                      className="delete-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(response.id);
                      }}
                      aria-label={t("responsesTab.deleteResponse")}
                    >
                      <FontAwesomeIcon icon={iconMap["faTrash"]} />
                    </button>
                  )}

                  {/* PDF Download => always displayed if user can exportForm => already checked above? 
                      but let's do it again. If you want it always visible, remove. 
                  */}
                  {canExportForm && (
                    <button
                      className="pdf-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownloadPDF(response);
                      }}
                      aria-label={t('responsesTab.downloadPdf')}
                    >
                      <FontAwesomeIcon icon={iconMap['faFilePdf']} />
                    </button>
                  )}

                  <div className="response-toggle-icon">
                    <FontAwesomeIcon
                      icon={
                        expandedResponseIds.includes(response.id)
                          ? iconMap["faMinus"]
                          : iconMap["faPlus"]
                      }
                    />
                  </div>
                </div>
              </div>

              <CSSTransition
                in={expandedResponseIds.includes(response.id)}
                timeout={200}
                classNames="collapse"
                unmountOnExit
              >
                <div className="response-details">
                  {/* If currently editing this response => show FillOutForm */}
                  {editingResponseId === response.id ? (
                    <FillOutForm
                      fields={fields}
                      formId={formId}
                      showToast={showToast}
                      initialData={editingResponseData}
                      responseId={response.id}
                      onCancel={handleCancelEdit}
                      /*
                        If user canEditForm => normal editing.
                        If you want read-only for them, pass viewMode
                        but here we do real editing because canEditForm => true
                      */
                    />
                  ) : (
                    <table className="response-table">
                      <tbody>
                        {response.fields
                          .slice()
                          .sort((a, b) => a.order - b.order)
                          .map((field) => {
                            // find field definition
                            const fieldDef = fields.find((f) => f.id === field.id);

                            // skip image
                            if (field.type === 'ImagePicker') {
                              return null;
                            }

                            return (
                              <tr key={field.id}>
                                <td className="response-label">{field.label}</td>
                                <td className="response-value">
                                  {renderFieldValue(field, fieldDef)}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  )}
                </div>
              </CSSTransition>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};

ResponsesTab.propTypes = {
  showToast: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  formId: PropTypes.string.isRequired,
  catalogData: PropTypes.object,
};

export default ResponsesTab;
