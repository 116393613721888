// src/components/Authentication/OrganizationGroupSelectionPage.jsx

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Contexts
import { AuthContext } from '../../contexts/AuthContext';
import { UserContext } from '../../contexts/UserContext';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { GroupContext } from '../../contexts/GroupContext';

// Assets
import CatholicoreLogo from '../../assets/CatholicoreLogo.png';
import IconMap from '../Common/IconMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../Common/Spinner';

import './OrganizationGroupSelectionPage.css';

function OrganizationGroupSelectionPage() {
  // 1) AuthContext for signOut
  const { signOut } = useContext(AuthContext);

  // 2) UserContext for userRoles, userRolesLoading
  const { userRoles, userRolesLoading } = useContext(UserContext);

  // 3) OrganizationContext and GroupContext for fetching org/group data
  const { fetchOrganizationsByIds } = useContext(OrganizationContext);
  const { fetchGroupsByIds } = useContext(GroupContext);

  const navigate = useNavigate();

  // State for storing the fetched organizations and groups
  const [organizationData, setOrganizationData] = useState({});
  const [groupData, setGroupData] = useState({});

  // Local loading for the main spinner
  const [loading, setLoading] = useState(true);

  // Indicates when group fetches are fully complete
  const [isGroupsFetched, setIsGroupsFetched] = useState(false);

  // Track selected organization and color for the right panel
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [rightPanelColor, setRightPanelColor] = useState('#006699');

  // Manage a short delay for “No groups found”
  const [groupsLoadingDelay, setGroupsLoadingDelay] = useState(false);

  /**
   * 1) Fetch organizations and groups once userRoles are loaded.
   */
  useEffect(() => {
    const fetchData = async () => {
      // Wait until userRoles is actually available (not loading)
      if (userRolesLoading) return;

      // If no roles, no orgs/groups to fetch
      if (!userRoles || userRoles.length === 0) {
        setLoading(false);
        return;
      }

      try {
        // Group roles by organizationId
        const rolesByOrg = {};
        userRoles.forEach((role) => {
          const orgId = role.organizationId;
          const groupId = role.groupId?.id;
          if (!rolesByOrg[orgId]) {
            rolesByOrg[orgId] = [];
          }
          rolesByOrg[orgId].push(groupId);
        });

        // Fetch all organizations by their IDs
        const orgIds = Object.keys(rolesByOrg);
        const orgDataObj = await fetchOrganizationsByIds(orgIds);

        // Fetch all groups for each org
        const groupPromises = orgIds.map((orgId) =>
          fetchGroupsByIds(rolesByOrg[orgId])
        );
        const groupResults = await Promise.all(groupPromises);

        // Flatten all group objects into one big object
        const allGroups = {};
        for (const partialObj of groupResults) {
          Object.assign(allGroups, partialObj);
        }

        // Update component state
        setOrganizationData(orgDataObj);
        setGroupData(allGroups);

        // Auto-select the first org if any
        if (orgIds.length > 0) {
          setSelectedOrgId(orgIds[0]);
        }

        // Mark fetches complete
        setIsGroupsFetched(true);
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userRoles, userRolesLoading, fetchOrganizationsByIds, fetchGroupsByIds]);

  /**
   * 2) Update the right panel color once everything is loaded.
   */
  useEffect(() => {
    if (loading || !isGroupsFetched) return;
    if (!userRoles || userRoles.length === 0 || !selectedOrgId) return;

    const rolesForOrg = userRoles.filter(
      (role) => role.organizationId === selectedOrgId
    );
    const groupsForOrg = rolesForOrg
      .map((r) => groupData[r.groupId.id])
      .filter((g) => g?.active);

    if (groupsForOrg.length > 0) {
      setRightPanelColor(groupsForOrg[0].color || '#006699');
    } else {
      setRightPanelColor('#006699');
    }
  }, [loading, isGroupsFetched, userRoles, groupData, selectedOrgId]);

  /**
   * 3) Handle a 3-second delay if no groups found
   */
  useEffect(() => {
    if (loading || !isGroupsFetched) return;
    if (!selectedOrgId || !userRoles || userRoles.length === 0) return;

    const rolesForOrg = userRoles.filter(
      (role) => role.organizationId === selectedOrgId
    );
    const groupsForOrg = rolesForOrg
      .map((r) => groupData[r.groupId.id])
      .filter((g) => g?.active);

    if (groupsForOrg.length === 0) {
      setGroupsLoadingDelay(true);
      const timer = setTimeout(() => {
        setGroupsLoadingDelay(false);
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      setGroupsLoadingDelay(false);
    }
  }, [loading, isGroupsFetched, selectedOrgId, userRoles, groupData]);

  /**
   * 4) If still loading userRoles/orgs/groups, show spinner
   */
  if (loading || userRolesLoading || !isGroupsFetched) {
    return (
      <div className="orgsplit-container loading-container fade-bg">
        <div className="loading-content">
          <Spinner size="60px" />
          <p>Retrieving your organizations and groups…</p>
        </div>
      </div>
    );
  }

  /**
   * 5) If user has no roles
   */
  if (!userRoles || userRoles.length === 0) {
    return (
      <div className="orgsplit-container fade-bg">
        <div className="org-left-panel">
          <div className="org-left-logo">
            <img src={CatholicoreLogo} alt="Catholicore Logo" />
          </div>
          <h2>No Roles Assigned</h2>
          <p>You currently have no organizations.</p>
        </div>
        <div
          className="org-right-panel fade-bg"
          style={{ backgroundColor: rightPanelColor }}
        />
      </div>
    );
  }

  /**
   * Logout
   */
  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (err) {
      console.error('Logout error:', err);
    }
  };

  /**
   * Helper: pick an org's "logo" from the first active group that has one
   */
  const getOrgLogo = (orgId) => {
    const rolesForOrg = userRoles.filter(
      (role) => role.organizationId === orgId
    );
    for (const role of rolesForOrg) {
      const grp = groupData[role.groupId.id];
      if (grp?.active && grp.profilePicture) {
        return grp.profilePicture;
      }
    }
    return CatholicoreLogo; // fallback
  };

  // If you want to pick a text color for the logout button
  const isLightColor = (hex) => {
    if (!hex || !hex.startsWith('#') || hex.length < 7) return true;
    const c = hex.slice(1);
    const r = parseInt(c.substr(0, 2), 16);
    const g = parseInt(c.substr(2, 2), 16);
    const b = parseInt(c.substr(4, 2), 16);
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma > 185;
  };
  const logoutBtnTextColor = isLightColor(rightPanelColor) ? '#000' : '#fff';

  // All fetched org IDs
  const orgIds = Object.keys(organizationData);
  // Info for the selected org
  const selectedOrg = organizationData[selectedOrgId] || {};

  // The org's active groups
  const rolesForSelectedOrg = userRoles.filter(
    (role) => role.organizationId === selectedOrgId
  );
  const groupsForSelectedOrg = rolesForSelectedOrg
    .map((role) => groupData[role.groupId.id])
    .filter((grp) => grp?.active);

  // Organization selection
  const handleSelectOrg = (orgId) => {
    setSelectedOrgId(orgId);
  };

  // Navigate to group
  const handleEnterGroup = (grpId) => {
    navigate(`/${grpId}/main`);
  };

  return (
    <div className="orgsplit-container fade-bg">
      {/* LEFT PANEL */}
      <div className="org-left-panel">
        <div className="org-left-logo">
          <img src={CatholicoreLogo} alt="Catholicore Logo" />
        </div>

        {/* Logout button */}
        <div className="org-logout-button">
          <button
            onClick={handleLogout}
            style={{
              backgroundColor: rightPanelColor,
              color: logoutBtnTextColor,
            }}
          >
            <FontAwesomeIcon icon={IconMap['faRightFromBracket']} />
            &nbsp; Logout
          </button>
        </div>

        <h2 className="org-left-title">Select Organization</h2>

        <div className="org-list-container fade-cards">
          <ul className="org-list">
            {orgIds.map((orgId) => {
              const orgInfo = organizationData[orgId];
              const isSelected = orgId === selectedOrgId;
              const textColor = isLightColor(rightPanelColor) ? '#000' : '#fff';
              const orgLogo = getOrgLogo(orgId);

              const itemStyle = isSelected
                ? { backgroundColor: rightPanelColor, color: textColor }
                : {};

              return (
                <li
                  key={orgId}
                  className="org-list-item fade-cards-item"
                  style={itemStyle}
                  onClick={() => handleSelectOrg(orgId)}
                >
                  <img src={orgLogo} alt="Org Logo" className="org-item-logo" />
                  <span className="org-item-name">
                    {orgInfo.name || `Org ${orgId}`}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {/* RIGHT PANEL */}
      <div
        key={selectedOrgId}
        className="org-right-panel fade-bg"
        style={{ backgroundColor: rightPanelColor }}
      >
        <h2>Groups for {selectedOrg.name || '...'}</h2>

        {groupsForSelectedOrg.length === 0 ? (
          groupsLoadingDelay ? (
            <div className="loading-groups-delay">
              <Spinner size="50px" color="#fff" />
              <p>Loading group info…</p>
            </div>
          ) : (
            <p>No groups found for this organization.</p>
          )
        ) : (
          <div className="org-groups-container fade-cards">
            {groupsForSelectedOrg.map((grp) => {
              const textColor = isLightColor(grp.color) ? '#000' : '#fff';
              return (
                <div
                  key={grp.id}
                  className="org-group-card"
                  style={{
                    backgroundColor: grp.color || '#999',
                    color: textColor,
                  }}
                >
                  {grp.profilePicture ? (
                    <img
                      src={grp.profilePicture}
                      alt={grp.groupName}
                      className="org-group-logo"
                    />
                  ) : (
                    <div className="org-group-placeholder">
                      {grp.groupName?.charAt(0) || 'G'}
                    </div>
                  )}
                  <h4>{grp.groupName}</h4>
                  <button onClick={() => handleEnterGroup(grp.id)}>Enter</button>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default OrganizationGroupSelectionPage;
