// src/components/Users.jsx

import React, { useState, useEffect, useContext, useMemo } from 'react';
import './Users.css';
import Spinner from '../Common/Spinner';
import Table from '../Common/Table';
import UserForm from './UserForm';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import ResetPasswordConfirmationModal from '../Common/ResetPasswordConfirmationModal';
import CustomModal from '../Common/CustomModal';
import { useTranslation } from 'react-i18next';
import ToastContainer from '../Common/ToastContainer';
import ActionButton from '../Common/ActionButton';
import SearchBar from '../Common/SearchBar';
import PaginationControls from '../Common/PaginationControls';
import { useColorSettings } from '../Common/ColorSettingsContext';
import { ResponseContext } from '../../contexts/ResponseContext';

// For permission checks
import { UserContext } from '../../contexts/UserContext';
import { UserManagementContext } from '../../contexts/UserManagementContext';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

/**
 * Helper: get the current user's numeric priority (lowest number)
 * from among their roles in the selected group. If none found,
 * fallback to e.g. 999.
 */
function getCurrentUserPriority(userRoles, selectedGroupId) {
  if (!Array.isArray(userRoles)) return 999;

  // filter to just roles for selectedGroupId
  const relevantEntries = userRoles.filter(
    (r) => r.groupId?.id === selectedGroupId
  );
  if (relevantEntries.length === 0) {
    return 999;
  }

  // Among these roles, find the minimal roleData.priority
  let minPriority = 999;
  for (const entry of relevantEntries) {
    const pr = entry.roleData?.priority;
    if (typeof pr === 'number' && pr < minPriority) {
      minPriority = pr;
    }
  }
  return minPriority;
}

/**
 * Helper: check if the current user has role name "Admin" in this group
 */
function isCurrentUserAdmin(userRoles, selectedGroupId) {
  if (!Array.isArray(userRoles)) return false;
  const relevantEntries = userRoles.filter((r) => r.groupId?.id === selectedGroupId);
  return relevantEntries.some((r) => r.roleData?.name === 'Admin');
}

const Users = ({
  selectedGroupId = null,
  groupUsers = [],
  groupRolesMap = {},
  loadingGroupData = false,
  onRefreshGroup = null, // optional callback to refresh after add/delete/edit
}) => {
  const { t } = useTranslation();

  // from user context
  const { userRolesLoading, userRoles, currentUser } = useContext(UserContext);

  const { hasPermission } = useContext(AppSettingsContext);
  const canViewUsers = hasPermission('security', 'viewUsers');
  const canAddUsers = hasPermission('security', 'addUsers');
  const canEditUsers = hasPermission('security', 'editUsers');
  const canDeleteUsers = hasPermission('security', 'deleteUsers');
  const canResetPassword = canEditUsers;

  // from user management context
  const {
    users: globalUsers,
    roles: globalRoles,
    isUsersLoading,
    addUser,
    editUser,
    deleteUser,
    sendPasswordResetEmail,
    getUsersAndRolesByGroupId, // not used locally, but can be
    fetchUsersAndRoles,        // if needed
  } = useContext(UserManagementContext);

  // color settings
  const { color } = useColorSettings();

  // members form responses
  const { useResponses } = useContext(ResponseContext);
  const { data: responses = [], isLoading: membersLoading } = useResponses('membersForm');

  // which users to show
  const finalUsers = selectedGroupId ? groupUsers : globalUsers;

  // which roles do we pass to UserForm by default
  const usedRolesForDropdown = selectedGroupId
    ? Object.values(groupRolesMap)
    : globalRoles;

  // local state
  const [showUserModal, setShowUserModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [editingUser, setEditingUser] = useState(null);

  const [rolesMap, setRolesMap] = useState({});
  const [isRolesLoading, setIsRolesLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');

  const [showResetModal, setShowResetModal] = useState(false);
  const [userToReset, setUserToReset] = useState(null);

  const [toasts, setToasts] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const [isSubmittingUser, setIsSubmittingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);

  // build "membersData" from responses
  const membersData = useMemo(() => {
    return responses.map((resp) => {
      const member = {};
      resp.fields.forEach((field) => {
        member[field.id] = field.value;
      });
      member.id = resp.id;
      member.fields = resp.fields;
      member.submittedAt = resp.submittedAt;
      member.createdAt = resp.createdAt;
      member.updatedAt = resp.updatedAt;
      member.groupId = resp.groups ? resp.groups[0].groupId : '';
      member.firstName = member.firstName || '';
      member.lastName = member.lastName || '';
      member.email = member.email || '';
      member.profilePicture = member.profilePicture || null;
      return member;
    });
  }, [responses]);

  // toast system
  const showToastMessage = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prev) => [...prev, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };
  const removeToast = (id) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  // adapt window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // If no group selected and have finalUsers => fetch roles for display
  useEffect(() => {
    if (!selectedGroupId && finalUsers.length > 0) {
      fetchRolesForUsers(finalUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalUsers, selectedGroupId]);

  /**
   * fetchRolesForUsers => build a local rolesMap so we can show role names in the table
   */
  const fetchRolesForUsers = async (usersList) => {
    setIsRolesLoading(true);
    try {
      const uniqueRoleRefs = new Set();

      usersList.forEach((user) => {
        const userRoles = user.roles || [];
        // If a group is selected, we only care about that group’s roleRef:
        // but if not, we might pick the first role or all roles.
        // For simplicity, let’s just pick the role references for the relevant group:
        const userRoleEntry = selectedGroupId
          ? userRoles.find((r) => r?.groupId?.id === selectedGroupId)
          : null;

        if (selectedGroupId) {
          if (userRoleEntry && userRoleEntry.role) {
            uniqueRoleRefs.add(userRoleEntry.role);
          }
        } else {
          // If no group selected, gather all roles for the user
          userRoles.forEach((r) => {
            if (r.role) uniqueRoleRefs.add(r.role);
          });
        }
      });

      const rolesMapTemp = {};
      const promises = Array.from(uniqueRoleRefs).map(async (roleRef) => {
        if (!roleRef) return;
        const roleDoc = await roleRef.get(); // getDoc from firestore
        if (roleDoc.exists()) {
          rolesMapTemp[roleRef.id] = roleDoc.data();
        }
      });
      await Promise.all(promises);
      setRolesMap(rolesMapTemp);
    } catch (err) {
      console.error('Error fetching roles for users:', err);
    } finally {
      setIsRolesLoading(false);
    }
  };

  /**
   * compute the user's numeric priority => smallest priority among their roles
   */
  const myPriority = useMemo(() => {
    return getCurrentUserPriority(userRoles, selectedGroupId);
  }, [userRoles, selectedGroupId]);

  /**
   * check if the current user is an "Admin" in this group
   */
  const iAmAdmin = useMemo(() => {
    return isCurrentUserAdmin(userRoles, selectedGroupId);
  }, [userRoles, selectedGroupId]);

  // Handlers

  const handleAddUser = () => {
    setModalTitle(t('users.addUser', 'Add User'));
    setEmail('');
    setRole('');
    setFirstName('');
    setLastName('');
    setEditingUser(null);
    setShowUserModal(true);
  };

  const handleEditUser = (user) => {
    // If I'm not Admin, we also do a quick priority check on the front-end
    if (!iAmAdmin) {
      // ----------------------------
      // Guard: Check that user's priority
      // ----------------------------
      const userRoleEntry = (user.roles || []).find(
        (r) => r.groupId?.id === selectedGroupId
      );
      const thatUsersPriority = userRoleEntry?.roleData?.priority ?? 999;

      if (thatUsersPriority <= myPriority) {
        // Show toast and return — not allowed to edit
        showToastMessage(
          t(
            'users.cannotEditEqualOrHigher',
            'You cannot edit a user who is of equal or higher rank than you.'
          ),
          'error'
        );
        return;
      }
    }

    setModalTitle(t('users.editUser', 'Edit User'));
    setEmail(user.email);
    setFirstName(user.firstName);
    setLastName(user.lastName);

    const userRoles = user.roles || [];
    const userRoleEntry2 = userRoles.find(
      (r) => selectedGroupId && r?.groupId?.id === selectedGroupId
    );
    const roleId = userRoleEntry2?.role?.id || '';
    const memberId = userRoleEntry2?.memberReference?.id || null;

    setRole(roleId);
    setEditingUser({ ...user, roleId, memberId });
    setShowUserModal(true);
  };

  const handleDeleteUser = (user) => {
    setUserToDelete(user);
    setDeleteModalMessage(
      t('deleteConfirmation.message', {
        item: `${user.firstName} ${user.lastName}`,
      })
    );
    setShowDeleteModal(true);
  };

  const confirmDeleteUser = async () => {
    if (!userToDelete) return;
    setIsDeletingUser(true);
    try {
      await deleteUser(userToDelete.id, selectedGroupId);
      showToastMessage(t('users.deleteSuccess', 'User deleted successfully'), 'success');

      // AFTER deleting => refresh
      if (onRefreshGroup) {
        await onRefreshGroup();
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      showToastMessage(
        `${t('users.deleteError', 'Error deleting user')}: ${error.message}`,
        'error'
      );
    } finally {
      setIsDeletingUser(false);
      setShowDeleteModal(false);
      setUserToDelete(null);
    }
  };

  const handleResetPassword = (user) => {
    setUserToReset(user);
    setShowResetModal(true);
  };

  const confirmResetPassword = async () => {
    if (!userToReset) return;
    try {
      const logo =
        'https://catholicore.com/static/media/CatholicoreLogoWhite.2b64249d4eacac07ba63.png';
      await sendPasswordResetEmail(userToReset.email, logo, color);
      showToastMessage(t('users.resetPasswordSuccess', 'Password reset link sent'), 'success');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      showToastMessage(t('users.resetPasswordError', 'Failed to send reset link'), 'error');
    } finally {
      setShowResetModal(false);
      setUserToReset(null);
    }
  };

  // After the form is submitted, add/edit users
  const handleUserModalSubmit = async (data) => {
    setIsSubmittingUser(true);
    const logo =
      'https://catholicore.com/static/media/CatholicoreLogoWhite.2b64249d4eacac07ba63.png';

    try {
      if (data.mode === 'manual') {
        const { firstName, lastName, email, role, memberId } = data;

        // Basic validations
        if (!firstName.trim()) {
          showToastMessage(t('users.firstNameRequired', 'First name is required'), 'error');
          setIsSubmittingUser(false);
          return;
        }
        if (!lastName.trim()) {
          showToastMessage(t('users.lastNameRequired', 'Last name is required'), 'error');
          setIsSubmittingUser(false);
          return;
        }

        if (!editingUser) {
          // Creating brand-new user
          if (!email) {
            showToastMessage(t('users.emailRequired', 'Email is required'), 'error');
            setIsSubmittingUser(false);
            return;
          }

          await addUser(
            { email, firstName, lastName, roleId: role, memberId, logo, color },
            selectedGroupId // pass group
          );
          showToastMessage(t('users.addSuccess', 'User added successfully'), 'success');
        } else {
          // Editing
          await editUser(
            editingUser.id,
            {
              firstName,
              lastName,
              roleId: role,
              memberId,
            },
            selectedGroupId // pass group
          );
          showToastMessage(t('users.updateSuccess', 'User updated successfully'), 'success');
        }
      } else {
        // mode === 'members'
        const { members, role } = data;
        if (!members || members.length === 0) {
          showToastMessage(t('users.noMembersSelected', 'No members selected'), 'error');
          setIsSubmittingUser(false);
          return;
        }
        if (!role) {
          showToastMessage(t('users.roleRequired', 'Role is required'), 'error');
          setIsSubmittingUser(false);
          return;
        }

        // Create new user for each selected member
        for (const memberId of members) {
          const member = membersData.find((m) => m.id === memberId);
          if (member) {
            if (!member.email) {
              showToastMessage(
                t('users.memberMissingEmail', 'A selected member is missing an email'),
                'error'
              );
              continue;
            }
            await addUser(
              {
                email: member.email,
                firstName: member.firstName || '',
                lastName: member.lastName || '',
                roleId: role,
                memberId,
                logo,
                color,
              },
              selectedGroupId
            );
          }
        }
        showToastMessage(
          t('users.addUsersFromMembersSuccess', 'Users created from members successfully'),
          'success'
        );
      }

      // close modal
      setShowUserModal(false);
      setEditingUser(null);

      // Refresh
      if (onRefreshGroup) {
        await onRefreshGroup();
      }
    } catch (error) {
      console.error('Error adding/updating user(s):', error);
      showToastMessage(
        `${t('users.operationError', 'Operation failed')}: ${error.message}`,
        'error'
      );
    } finally {
      setIsSubmittingUser(false);
    }
  };

  // Sorting
  const setSorting = (field) => {
    if (sortField === field) {
      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  // Searching
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  // Filter finalUsers
  const filteredUsers = useMemo(() => {
    const searchString = searchTerm.toLowerCase();
    return finalUsers.filter((user) => {
      const fullName = `${user.firstName || ''} ${user.lastName || ''}`.toLowerCase();
      const emailVal = (user.email || '').toLowerCase();

      const userRoles = user.roles || [];
      const userRoleEntry = userRoles.find(
        (roleEntry) => roleEntry?.groupId?.id === selectedGroupId
      );

      let roleName = '';
      if (userRoleEntry && userRoleEntry.role) {
        const roleId = userRoleEntry.role.id;
        const usedRolesMap = selectedGroupId ? groupRolesMap : rolesMap;
        const roleData = usedRolesMap[roleId];
        if (roleData) {
          roleName = roleData.name.toLowerCase();
        }
      }
      return (
        fullName.includes(searchString) ||
        emailVal.includes(searchString) ||
        roleName.includes(searchString)
      );
    });
  }, [finalUsers, searchTerm, rolesMap, groupRolesMap, selectedGroupId]);

  // Table columns
  const columns = useMemo(() => {
    return [
      {
        id: 'fullName',
        label: t('userTable.fullName', 'Name'),
        accessor: ['firstName', 'lastName'],
        sortable: true,
      },
      {
        id: 'email',
        label: t('userTable.email', 'Email'),
        accessor: 'email',
        sortable: true,
      },
      {
        id: 'role',
        label: t('userTable.role', 'Role'),
        accessor: (user) => {
          const userRoles = user.roles || [];
          const userRoleEntry = userRoles.find(
            (roleEntry) => roleEntry?.groupId?.id === selectedGroupId
          );
          if (userRoleEntry && userRoleEntry.role) {
            const roleId = userRoleEntry.role.id;
            const usedRolesMap = selectedGroupId ? groupRolesMap : rolesMap;
            const roleData = usedRolesMap[roleId];
            return roleData ? roleData.name : t('loading', 'Loading...');
          }
          return '';
        },
        sortable: true,
      },
    ];
  }, [t, selectedGroupId, rolesMap, groupRolesMap]);

  // PREVENT DELETING ONESELF + block editing if the user outranks you
  const actions = useMemo(
    () => [
      {
        label: t('edit', 'Edit'),
        icon: 'faEdit',
        callback: handleEditUser,
        condition: (record) => {
          if (!canEditUsers) return false;
          // If I'm admin, skip the rank check
          if (iAmAdmin) return true;
          // We need to figure out that user’s priority in this group:
          const userRoleEntry = (record.roles || []).find(
            (r) => r.groupId?.id === selectedGroupId
          );
          const thatUsersPriority = userRoleEntry?.roleData?.priority ?? 999;
          // If that user's priority <= myPriority => disallow
          return thatUsersPriority > myPriority;
        },
      },
      {
        label: t('delete', 'Delete'),
        icon: 'faTrash',
        callback: handleDeleteUser,
        condition: (record) => {
          if (!canDeleteUsers) return false;
          // if this user is the current user => skip
          if (record.id === currentUser?.uid) {
            return false;
          }
          return true;
        },
      },
      {
        label: t('resetPassword', 'Reset Password'),
        icon: 'faKey',
        callback: handleResetPassword,
        condition: () => canResetPassword,
      },
    ],
    [
      t,
      canEditUsers,
      canDeleteUsers,
      canResetPassword,
      selectedGroupId,
      currentUser,
      myPriority,
      iAmAdmin
    ]
  );

  // Sorting + pagination
  const sortedUsers = useMemo(() => {
    if (!sortField) return filteredUsers;
    const column = columns.find((col) => col.id === sortField);
    if (!column) return filteredUsers;

    const accessor = column.accessor;
    const sorted = [...filteredUsers].sort((a, b) => {
      let aValue;
      let bValue;

      if (typeof accessor === 'function') {
        aValue = accessor(a);
        bValue = accessor(b);
      } else if (typeof accessor === 'string') {
        aValue = a[accessor] || '';
        bValue = b[accessor] || '';
      } else if (Array.isArray(accessor)) {
        aValue = accessor.map((key) => a[key] || '').join(' ');
        bValue = accessor.map((key) => b[key] || '').join(' ');
      }

      aValue = aValue.toString().toLowerCase();
      bValue = bValue.toString().toLowerCase();

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
    return sorted;
  }, [filteredUsers, sortField, sortOrder, columns]);

  const totalPages = Math.ceil(sortedUsers.length / itemsPerPage);
  const paginatedUsers = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return sortedUsers.slice(start, start + itemsPerPage);
  }, [sortedUsers, currentPage, itemsPerPage]);

  // content render
  let content;
  if (!canViewUsers) {
    content = (
      <div className="access-denied">
        403 - {t('users.noViewPermission', 'You do not have permission to view users.')}
      </div>
    );
  } else if (
    userRolesLoading ||
    isUsersLoading ||
    isRolesLoading ||
    membersLoading ||
    loadingGroupData
  ) {
    content = (
      <div className="loading-container">
        <Spinner size="100px" />
      </div>
    );
  } else {
    content = (
      <div className="users-table-container">
        <div className="users-table-header">
          <h2>{t('usersHeader.title', 'Users')}</h2>
        </div>

        <div className="users-header-actions">
          <SearchBar
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            placeholder={t('users.searchPlaceholder', 'Search users...')}
            isLoading={isUsersLoading || isRolesLoading}
          />
          {canAddUsers && (
            <ActionButton
              onClick={handleAddUser}
              text=""
              label={t('usersHeader.addUser', 'Add User')}
              icon="faPlus"
              isMobile={isMobile}
              colorType="primary"
              ariaLabel={t('usersHeader.addUserAria', 'Add a new user')}
            />
          )}
        </div>

        <div className="record-count" aria-live="polite">
          {filteredUsers.length === 1
            ? t('userTable.recordsFound', { count: filteredUsers.length })
            : t('userTable.recordsFound_plural', { count: filteredUsers.length })}
        </div>

        <Table
          data={paginatedUsers}
          columns={columns}
          actions={actions}
          setSorting={setSorting}
          sortField={sortField}
          sortOrder={sortOrder}
          noRecordsMessage={t('users.noRecords', 'No users found.')}
          actionsHeaderLabel={t('actions', 'Actions')}
        />

        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPrev={() => setCurrentPage((p) => Math.max(p - 1, 1))}
          onNext={() => setCurrentPage((p) => Math.min(p + 1, totalPages))}
          onPageClick={(page) => setCurrentPage(page)}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={setItemsPerPage}
          itemsPerPageOptions={[20, 50, 100]}
          itemsPerPageLabel={t('userTable.itemsPerPageLabel', 'Users per page:')}
          previousLabel={t('previousLabel', 'Previous')}
          nextLabel={t('nextLabel', 'Next')}
          pageLabel={t('pageLabel', 'Page')}
          isMobileBreakpoint={768}
        />

        {/* Modals */}
        {showUserModal && (
          <CustomModal
            show={showUserModal}
            onClose={() => setShowUserModal(false)}
            title={modalTitle}
          >
            <UserForm
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              roles={usedRolesForDropdown}
              role={role}
              setRole={setRole}
              currentUserPriority={myPriority}
              isMobile={isMobile}
              onSubmit={handleUserModalSubmit}
              isEditing={editingUser !== null}
              isSubmitting={isSubmittingUser}
              membersData={membersData}
              users={finalUsers}
              editingMemberId={editingUser?.memberId || null}
            />
          </CustomModal>
        )}

        {showDeleteModal && (
          <DeleteConfirmationModal
            show={showDeleteModal}
            onConfirm={confirmDeleteUser}
            onCancel={() => setShowDeleteModal(false)}
            title={t('deleteConfirmation.title', 'Confirm Delete')}
            message={deleteModalMessage}
            isMobile={isMobile}
            isLoading={isDeletingUser}
          />
        )}

        {showResetModal && (
          <ResetPasswordConfirmationModal
            show={showResetModal}
            onConfirm={confirmResetPassword}
            onCancel={() => setShowResetModal(false)}
            record={userToReset}
            isMobile={isMobile}
          />
        )}
      </div>
    );
  }

  return (
    <>
      {content}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </>
  );
};

export default Users;
