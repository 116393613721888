// src/components/Authentication/ProtectedRoute.jsx

import React, { useContext, useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();

  const {
    // From UserContext
    authInitialized,
    currentUser,
    userRoles,
    userRolesLoading,
    userGroupsLoading, 
    hasPermission,
  } = useContext(UserContext);

  // If your group ID is in AppSettingsContext
  const { organizationData } = useContext(AppSettingsContext);
  const { groupId } = organizationData || {};

  // The final "authorized?" state
  const [isAuthorized, setIsAuthorized] = useState(null);

  /**
   * 1) A "delayedInitialized" that becomes true 1 second after authInitialized is true.
   *    This ensures we don't proceed too fast if there's a small timing issue.
   */
  const [delayedInitialized, setDelayedInitialized] = useState(false);

  useEffect(() => {
    if (!authInitialized) {
      // If not initialized => reset delayedInitialized
      setDelayedInitialized(false);
    } else {
      // Once authInitialized is true => wait 1 second
      const timer = setTimeout(() => {
        setDelayedInitialized(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [authInitialized]);

  // Example modules/tabs
  const tabs = [
    { moduleName: 'members', viewPerm: 'viewMember' },
    { moduleName: 'groups', viewPerm: 'viewGroup' },
    { moduleName: 'teams', viewPerm: 'viewTeam' },
    {
      moduleName: 'communications',
      customCheck: () =>
        hasPermission('communications', 'sendMessage', groupId) ||
        hasPermission('communications', 'scheduleMessage', groupId),
    },
    { moduleName: 'forms', viewPerm: 'viewForm' },
    { moduleName: 'calendar', viewPerm: 'viewEvent' },
    { moduleName: 'settings', viewPerm: 'viewSettings' },
    { moduleName: 'billing', viewPerm: 'viewBilling' },
    {
      moduleName: 'security',
      customCheck: () =>
        hasPermission('security', 'viewUsers', groupId) ||
        hasPermission('security', 'viewRoles', groupId),
    },
  ];

  const canViewTab = (tab) => {
    if (tab.customCheck) return tab.customCheck();
    if (tab.viewPerm) return hasPermission(tab.moduleName, tab.viewPerm, groupId);
    return false;
  };

  const determineAuthorization = () => {

    // 1) If we haven't even done the 1-second grace => still deciding
    if (!delayedInitialized) {
      return null;
    }

    // 2) If no currentUser => maybe public path
    if (!currentUser) {
      const publicPaths = ['/login','/password-recovery','/reset-password','/sign-up'];
      const pathEndsWithLogin = location.pathname.endsWith('/login');
      const isPublicPath = publicPaths.some((p) => location.pathname === p);
      if (isPublicPath || pathEndsWithLogin) {
        return true;
      }
      return false;
    }

    // 3) If user is on /organization-selection => let them in
    if (location.pathname === '/organization-selection') {
      return true;
    }

    // 4) If roles or group docs are still loading => still deciding
    if (userRolesLoading || userGroupsLoading) {
      return null;
    }

    // 5) If user has no roles => unauthorized
    if (!Array.isArray(userRoles) || userRoles.length === 0) {
      return false;
    }

    // 6) If group-based check => ensure user has a role for the group
    if (groupId) {
      const activeRole = userRoles.find((r) => r.groupId?.id === groupId);
      if (!activeRole) {
        return false;
      }
    }

    // 7) If you want to ensure at least one module is viewable
    const userCanViewSomething = tabs.some((tab) => canViewTab(tab));
    return userCanViewSomething;
  };

  // 8) Once anything changes => recalc
  useEffect(() => {

    // If not done with the 1-second grace or roles/groups still loading => null => spinner
    if (!delayedInitialized || userRolesLoading || userGroupsLoading) {
      setIsAuthorized(null);
      return;
    }

    const result = determineAuthorization();
    setIsAuthorized(result);
  }, [
    delayedInitialized,
    userRolesLoading,
    userGroupsLoading,
    currentUser,
    userRoles,
    groupId,
    location.pathname,
  ]);


  if (isAuthorized === false) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default ProtectedRoute;
