// src/components/Roles.jsx

import React, { useContext, useMemo, useState } from 'react';
import './Roles.css';
import Spinner from '../Common/Spinner';
import Table from '../Common/Table';
import SearchBar from '../Common/SearchBar';
import { useTranslation } from 'react-i18next';
import PaginationControls from '../Common/PaginationControls';
import ActionButton from '../Common/ActionButton';
import CustomModal from '../Common/CustomModal';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import RolesForm from './RolesForm';
import ToastContainer from '../Common/ToastContainer';
import IconMap from '../Common/IconMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// For permission checks
import { UserContext } from '../../contexts/UserContext';
import { UserManagementContext } from '../../contexts/UserManagementContext';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

const Roles = ({
  selectedGroupId = null, // The group currently being displayed
  groupRoles = [],
  loadingGroupData = false,
  onRefreshGroup = null, // callback prop to refresh data after add/update/delete
}) => {
  const { t } = useTranslation();

  // Check permissions
  const { hasPermission, organizationData } = useContext(AppSettingsContext);
  const { userRolesLoading, userRoles } = useContext(UserContext);

  const canViewRoles = hasPermission('security', 'viewRoles');
  const canAddRoles = hasPermission('security', 'addRoles');
  const canEditRoles = hasPermission('security', 'editRoles');
  const canDeleteRoles = hasPermission('security', 'deleteRoles');

  // From UserManagementContext
  const {
    roles: globalRoles,
    isUsersLoading,
    addRole,
    editRole,
    deleteRole,
  } = useContext(UserManagementContext);

  // Which roles to display
  const finalRoles = selectedGroupId ? groupRoles : globalRoles;

  // -------------------------
  // Compute current user priority
  // -------------------------
  const myPriority = useMemo(() => {
    if (!Array.isArray(userRoles)) return 999;

    // Filter to roles for the selected group
    const relevantEntries = userRoles.filter(
      (r) => r.groupId?.id === selectedGroupId
    );
    if (relevantEntries.length === 0) return 999;

    // Find the minimal priority among these
    let minPriority = 999;
    for (const entry of relevantEntries) {
      const pr = entry.roleData?.priority;
      if (typeof pr === 'number' && pr < minPriority) {
        minPriority = pr;
      }
    }
    return minPriority;
  }, [userRoles, selectedGroupId]);

  // Local state for searching, sorting, pagination
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // State for modals
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [editingRole, setEditingRole] = useState(null);

  // Fields for Add/Edit Role
  const [roleName, setRoleName] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [roleIcon, setRoleIcon] = useState('');
  const [rolePermissions, setRolePermissions] = useState([]);
  const [rolePriority, setRolePriority] = useState(999);

  const [modalTitle, setModalTitle] = useState('');
  const [viewOnly, setViewOnly] = useState(false);

  // State for deleting a role
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');

  const [isSubmittingRole, setIsSubmittingRole] = useState(false);
  const [isDeletingRole, setIsDeletingRole] = useState(false);

  // Toast system
  const [toasts, setToasts] = useState([]);
  const isMobile = window.innerWidth <= 768;

  // Toast helpers
  const showToastMessage = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prev) => [...prev, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };
  const removeToast = (id) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  // Master list of modules => to pass to RolesForm
  const permissionOptions = useMemo(
    () => [
      {
        label: 'Members',
        value: 'members',
        icon: 'faUserFriends',
        subPermissions: [
          { label: 'View', value: 'viewMember' },
          { label: 'Add', value: 'addMember' },
          { label: 'Edit', value: 'editMember' },
          { label: 'Delete', value: 'deleteMember' },
          { label: 'Export', value: 'exportMember' },
          { label: 'Configure', value: 'configureMember' },
        ],
      },
      {
        label: 'Groups',
        value: 'groups',
        icon: 'faUsers',
        subPermissions: [
          { label: 'View', value: 'viewGroup' },
          { label: 'Add', value: 'addGroup' },
          { label: 'Edit', value: 'editGroup' },
          { label: 'Delete', value: 'deleteGroup' },
          { label: 'Export', value: 'exportGroup' },
          { label: 'Configure', value: 'configureGroup' },
        ],
      },
      {
        label: 'Teams',
        value: 'teams',
        icon: 'faUserCog',
        subPermissions: [
          { label: 'View', value: 'viewTeam' },
          { label: 'Add', value: 'addTeam' },
          { label: 'Edit', value: 'editTeam' },
          { label: 'Delete', value: 'deleteTeam' },
          { label: 'Export', value: 'exportTeam' },
          { label: 'Configure', value: 'configureTeam' },
        ],
      },
      // Uncomment if you want "Posts"
      // {
      //   label: 'Posts',
      //   value: 'posts',
      //   icon: 'faFileLines',
      //   subPermissions: [
      //     { label: 'View', value: 'viewPost' },
      //     { label: 'Add', value: 'addPost' },
      //     { label: 'Edit', value: 'editPost' },
      //     { label: 'Delete', value: 'deletePost' },
      //   ],
      // },
      {
        label: 'Communications',
        value: 'communications',
        icon: 'faComments',
        subPermissions: [
          { label: 'Send Message', value: 'sendMessage' },
          { label: 'Schedule Message', value: 'scheduleMessage' },
          { label: 'View History Messages', value: 'viewHistory' },
        ],
      },
      {
        label: 'Forms',
        value: 'forms',
        icon: 'faFilePen',
        subPermissions: [
          { label: 'View', value: 'viewForm' },
          { label: 'Add', value: 'addForm' },
          { label: 'Edit', value: 'editForm' },
          { label: 'Delete', value: 'deleteForm' },
          { label: 'Export', value: 'exportForm' },
        ],
      },
      {
        label: 'Calendar',
        value: 'calendar',
        icon: 'faCalendarAlt',
        subPermissions: [
          { label: 'View', value: 'viewEvent' },
          { label: 'Add', value: 'addEvent' },
          { label: 'Edit', value: 'editEvent' },
          { label: 'Delete', value: 'deleteEvent' },
        ],
      },
      {
        label: 'Settings',
        value: 'settings',
        icon: 'faCog',
        subPermissions: [
          { label: 'View', value: 'viewSettings' },
          { label: 'Edit', value: 'editSettings' },
        ],
      },
      {
        label: 'Billing',
        value: 'billing',
        icon: 'faCreditCard',
        subPermissions: [
          { label: 'View', value: 'viewBilling' },
          { label: 'Edit', value: 'editBilling' },
        ],
      },
      {
        label: 'Security',
        value: 'security',
        icon: 'faLock',
        subPermissions: [
          { label: 'View Users', value: 'viewUsers' },
          { label: 'Add Users', value: 'addUsers' },
          { label: 'Edit Users', value: 'editUsers' },
          { label: 'Delete Users', value: 'deleteUsers' },
          { label: 'View Roles', value: 'viewRoles' },
          { label: 'Add Roles', value: 'addRoles' },
          { label: 'Edit Roles', value: 'editRoles' },
          { label: 'Delete Roles', value: 'deleteRoles' },
        ],
      },
    ],
    []
  );

  // Possibly remove "groups" if group-level
  const filteredPermissionOptions = useMemo(() => {
    if (!organizationData?.organizationId || !organizationData?.groupId) {
      // Not loaded => return everything
      return permissionOptions;
    }
    if (organizationData.organizationId === organizationData.groupId) {
      // org-level => keep "groups"
      return permissionOptions;
    }
    // group-level => remove "groups"
    return permissionOptions.filter((opt) => opt.value !== 'groups');
  }, [organizationData, permissionOptions]);

  // Search
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  // Filter roles by searchTerm
  const filteredRoles = useMemo(() => {
    const term = searchTerm.toLowerCase();
    return finalRoles.filter((role) => {
      const n = (role.name || '').toLowerCase();
      const d = (role.description || '').toLowerCase();
      return n.includes(term) || d.includes(term);
    });
  }, [finalRoles, searchTerm]);

  // Sorting
  const handleSetSorting = (field) => {
    if (sortField === field) {
      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const sortedRoles = useMemo(() => {
    if (!sortField) return filteredRoles;
    return [...filteredRoles].sort((a, b) => {
      const aVal = (a.name || '').toLowerCase();
      const bVal = (b.name || '').toLowerCase();
      if (aVal < bVal) return sortOrder === 'asc' ? -1 : 1;
      if (aVal > bVal) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredRoles, sortField, sortOrder]);

  // Pagination
  const totalPages = Math.ceil(sortedRoles.length / itemsPerPage);
  const paginatedRoles = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return sortedRoles.slice(start, start + itemsPerPage);
  }, [sortedRoles, currentPage, itemsPerPage]);

  const handlePrevPage = () => setCurrentPage((p) => Math.max(p - 1, 1));
  const handleNextPage = () => setCurrentPage((p) => Math.min(p + 1, totalPages));
  const handlePageClick = (p) => setCurrentPage(p);
  const handleItemsPerPageChange = (val) => {
    setItemsPerPage(Number(val));
    setCurrentPage(1);
  };

  // For creating a brand-new role => blank permissions
  const buildBlankPermissions = () =>
    filteredPermissionOptions.map((mod) => ({
      name: mod.value,
      subPermissions: mod.subPermissions.map((sp) => ({
        name: sp.value,
        completed: false,
      })),
    }));

  // ------------------------------------------------------
  // CRUD Handlers
  // ------------------------------------------------------
  const handleAddRole = () => {
    setModalTitle(t('roles.addRole', 'Add Role'));
    setEditingRole(null);
    setRoleName('');
    setRoleDescription('');
    setRoleIcon('');
    setRolePermissions(buildBlankPermissions());
    setRolePriority(999);
    setViewOnly(false);
    setShowRoleModal(true);
  };

  // If we outrank the role => we can edit; if same or higher => no edit
  const handleEditRoleClick = (roleObj) => {
    const thatRolePriority = typeof roleObj.priority === 'number' ? roleObj.priority : 999;
    if (thatRolePriority <= myPriority) {
      showToastMessage(
        t(
          'roles.cannotEditEqualOrHigher',
          'You cannot edit a role that has equal or higher rank than you.'
        ),
        'error'
      );
      return;
    }

    // proceed with editing
    setModalTitle(t('roles.editRole', 'Edit Role'));
    setEditingRole(roleObj);

    setRoleName(roleObj.name);
    setRoleDescription(roleObj.description || '');
    setRoleIcon(roleObj.icon || '');

    const pr = typeof roleObj.priority === 'number' ? roleObj.priority : 999;
    setRolePriority(pr);

    // Merge existing perms
    const existing = Array.isArray(roleObj.permissions) ? roleObj.permissions : [];
    const newPerms = filteredPermissionOptions.map((mod) => {
      const found = existing.find((p) => p.name === mod.value);
      if (!found) {
        return {
          name: mod.value,
          subPermissions: mod.subPermissions.map((sp) => ({
            name: sp.value,
            completed: false,
          })),
        };
      }
      return {
        name: mod.value,
        subPermissions: mod.subPermissions.map((sp) => {
          const foundSub = found.subPermissions?.find((x) => x.name === sp.value);
          return {
            name: sp.value,
            completed: foundSub ? !!foundSub.completed : false,
          };
        }),
      };
    });
    setRolePermissions(newPerms);

    setViewOnly(false);
    setShowRoleModal(true);
  };

  // Always allow "View" if we have permission + rolePriority <= myPriority
  const handleViewRoleClick = (roleObj) => {
    setModalTitle(t('roles.viewRole', 'View Role'));
    setEditingRole(roleObj);

    setRoleName(roleObj.name);
    setRoleDescription(roleObj.description || '');
    setRoleIcon(roleObj.icon || '');

    const pr = typeof roleObj.priority === 'number' ? roleObj.priority : 999;
    setRolePriority(pr);

    // Merge existing perms
    const existing = Array.isArray(roleObj.permissions) ? roleObj.permissions : [];
    const newPerms = filteredPermissionOptions.map((mod) => {
      const found = existing.find((p) => p.name === mod.value);
      if (!found) {
        return {
          name: mod.value,
          subPermissions: mod.subPermissions.map((sp) => ({
            name: sp.value,
            completed: false,
          })),
        };
      }
      return {
        name: mod.value,
        subPermissions: mod.subPermissions.map((sp) => {
          const foundSub = found.subPermissions?.find((x) => x.name === sp.value);
          return {
            name: sp.value,
            completed: foundSub ? !!foundSub.completed : false,
          };
        }),
      };
    });
    setRolePermissions(newPerms);

    setViewOnly(true);
    setShowRoleModal(true);
  };

  // Disallow delete if rolePriority <= myPriority
  const handleDeleteRoleClick = (roleObj) => {
    const thatRolePriority = typeof roleObj.priority === 'number' ? roleObj.priority : 999;
    if (thatRolePriority <= myPriority) {
      showToastMessage(
        t(
          'roles.cannotDeleteEqualOrHigher',
          'You cannot delete a role that has equal or higher rank than you.'
        ),
        'error'
      );
      return;
    }

    setRoleToDelete(roleObj);
    setDeleteModalMessage(
      t('deleteConfirmation.message', {
        item: roleObj.name,
      })
    );
    setShowDeleteModal(true);
  };

  const confirmDeleteRole = async () => {
    if (!roleToDelete) return;
    setIsDeletingRole(true);
    try {
      await deleteRole(roleToDelete.id, selectedGroupId);
      showToastMessage(t('roles.deleteSuccess', 'Role deleted successfully'), 'success');

      if (onRefreshGroup) {
        await onRefreshGroup();
      }
    } catch (err) {
      console.error('Error deleting role:', err);
      showToastMessage(
        `${t('roles.deleteError', 'Error deleting role')}: ${err.message}`,
        'error'
      );
    } finally {
      setIsDeletingRole(false);
      setShowDeleteModal(false);
      setRoleToDelete(null);
    }
  };

  // Add/Edit Role => submit
  const handleRoleModalSubmit = async (e) => {
    e.preventDefault();
    if (!roleName.trim()) {
      showToastMessage(t('roles.nameRequired', 'Role name is required'), 'error');
      return;
    }
    setIsSubmittingRole(true);

    const roleData = {
      name: roleName,
      description: roleDescription,
      icon: roleIcon,
      permissions: rolePermissions,
      priority: rolePriority,
    };

    try {
      if (!editingRole) {
        // CREATE
        await addRole(roleData, selectedGroupId);
        showToastMessage(t('roles.addSuccess', 'Role added successfully'), 'success');
      } else {
        // EDIT
        await editRole(editingRole.id, roleData, selectedGroupId);
        showToastMessage(t('roles.updateSuccess', 'Role updated successfully'), 'success');
      }
      setShowRoleModal(false);
      setEditingRole(null);
      if (onRefreshGroup) {
        await onRefreshGroup();
      }
    } catch (err) {
      console.error('Error adding/editing role:', err);
      const prefix = editingRole
        ? t('roles.updateError', 'Error updating role')
        : t('roles.addError', 'Error adding role');
      showToastMessage(`${prefix}: ${err.message}`, 'error');
    } finally {
      setIsSubmittingRole(false);
    }
  };

  // Table columns
  const columns = useMemo(
    () => [
      {
        id: 'name',
        label: t('rolesTable.roleName', 'Role Name'),
        accessor: (r) => r.name,
        sortable: true,
        render: (r) => {
          const iconKey = r.icon && IconMap[r.icon] ? r.icon : null;
          return (
            <div className="role-name-cell">
              {iconKey && (
                <FontAwesomeIcon icon={IconMap[iconKey]} className="role-icon" />
              )}
              <span>{r.name}</span>
            </div>
          );
        },
      },
      // Uncomment if you want to display the priority in the table
      // {
      //   id: 'priority',
      //   label: t('rolesTable.priority', 'Priority'),
      //   accessor: (r) => r.priority ?? 999,
      //   sortable: true,
      //   render: (r) => {
      //     if (r.priority < myPriority) return '—';
      //     return r.priority;
      //   },
      // },
    ],
    [t, myPriority]
  );

  // Table actions
  const actions = useMemo(
    () => [
      {
        // Edit if rolePriority > myPriority
        label: t('edit', 'Edit'),
        icon: 'faEdit',
        callback: handleEditRoleClick,
        condition: (record) => {
          if (!canEditRoles) return false;
          const rolePriority = record.priority ?? 999;
          return rolePriority > myPriority;
        },
      },
      {
        // NEW LOGIC:
        // Show "View" if user canViewRoles AND the role’s priority <= myPriority
        // => same or higher rank => we can only View
        label: t('view', 'View'),
        icon: 'faEye',
        callback: handleViewRoleClick,
        condition: (record) => {
          if (!canViewRoles) return false;
          const rolePriority = record.priority ?? 999;
          return rolePriority <= myPriority;
        },
      },
      {
        // Delete if user outranks the role
        label: t('delete', 'Delete'),
        icon: 'faTrash',
        callback: handleDeleteRoleClick,
        condition: (record) => {
          if (!canDeleteRoles) return false;
          const rolePriority = record.priority ?? 999;
          return rolePriority > myPriority;
        },
      },
    ],
    [t, canEditRoles, canViewRoles, canDeleteRoles, myPriority]
  );

  // Render final content
  let content;
  if (!canViewRoles) {
    content = (
      <div className="access-denied">
        403 - {t('roles.noViewPermission', 'You do not have permission to view roles.')}
      </div>
    );
  } else if (userRolesLoading || isUsersLoading || loadingGroupData) {
    content = (
      <div className="loading-container">
        <Spinner size="50px" />
      </div>
    );
  } else {
    content = (
      <div className="roles-table-container">
        <div className="roles-table-header">
          <h2>{t('rolesHeader.title', 'Roles')}</h2>
        </div>

        <div className="roles-header-actions">
          <SearchBar
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            placeholder={t('roles.searchPlaceholder', 'Search Roles...')}
            isLoading={isUsersLoading}
          />
          {canAddRoles && (
            <ActionButton
              onClick={handleAddRole}
              text=""
              label={t('rolesHeader.addRole', 'Add Role')}
              icon="faPlus"
              isMobile={isMobile}
              colorType="primary"
              ariaLabel={t('rolesHeader.addRoleAria', 'Add a new role')}
            />
          )}
        </div>

        <div className="record-count">
          {filteredRoles.length === 1
            ? t('rolesTable.recordsFound', { count: filteredRoles.length })
            : t('rolesTable.recordsFound_plural', { count: filteredRoles.length })}
        </div>

        <Table
          data={paginatedRoles}
          columns={columns}
          actions={actions}
          setSorting={handleSetSorting}
          sortField={sortField}
          sortOrder={sortOrder}
          noRecordsMessage={t('roles.noRecords', 'No roles found.')}
          actionsHeaderLabel={t('actions', 'Actions')}
        />

        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPrev={handlePrevPage}
          onNext={handleNextPage}
          onPageClick={handlePageClick}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
          itemsPerPageOptions={[20, 50, 100]}
          itemsPerPageLabel={t('rolesTable.itemsPerPageLabel', 'Roles per page:')}
          previousLabel={t('previousLabel', 'Previous')}
          nextLabel={t('nextLabel', 'Next')}
          pageLabel={t('pageLabel', 'Page')}
          isMobileBreakpoint={768}
        />

        {/* Add/Edit/View Role Modal */}
        {showRoleModal && (
          <CustomModal
            show={showRoleModal}
            onClose={() => setShowRoleModal(false)}
            title={modalTitle}
          >
            <RolesForm
              name={roleName}
              setName={setRoleName}
              description={roleDescription}
              setDescription={setRoleDescription}
              icon={roleIcon}
              setIcon={setRoleIcon}
              permissions={rolePermissions}
              setPermissions={setRolePermissions}
              priority={rolePriority}
              setPriority={setRolePriority}
              currentUserPriority={myPriority} // ensure RolesForm sees user’s priority
              isMobile={isMobile}
              onSubmit={handleRoleModalSubmit}
              isEditing={editingRole !== null}
              isSubmitting={isSubmittingRole}
              permissionOptions={filteredPermissionOptions}
              organizationId={organizationData?.organizationId || ''}
              groupId={organizationData?.groupId || ''}
              viewOnly={viewOnly}
            />
          </CustomModal>
        )}

        {/* Delete Confirmation */}
        {showDeleteModal && (
          <DeleteConfirmationModal
            show={showDeleteModal}
            onConfirm={confirmDeleteRole}
            onCancel={() => setShowDeleteModal(false)}
            title={t('deleteConfirmation.title', 'Confirm Delete')}
            message={deleteModalMessage}
            isMobile={isMobile}
            isLoading={isDeletingRole}
          />
        )}
      </div>
    );
  }

  return (
    <>
      {content}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </>
  );
};

export default Roles;
