import React, { useState } from 'react';
import SelectField from '../../FormBuilder/Fields/SelectField';
import ColorPicker from '../../FormBuilder/Fields/ColorPicker';
import ImagePicker from '../../FormBuilder/Fields/ImagePicker';
import Spinner from '../../Common/Spinner';
import Tabs from '../../Common/Tabs';
import { useTranslation } from 'react-i18next';
import './Step4Form.css';

const Step4Form = ({ onNext, onBack, formData }) => {
  const { t } = useTranslation();

  // Tabs
  const [selectedTab, setSelectedTab] = useState('general');

  // General Info
  const [profilePicture, setProfilePicture] = useState(formData.profilePicture || null);
  const [organizationName, setOrganizationName] = useState(formData.organizationName || '');
  const [subdomain, setSubdomain] = useState(formData.subdomain || '');
  const [timezone, setTimezone] = useState(formData.timezone || '');
  const [language, setLanguage] = useState(formData.language || '');
  const [color, setColor] = useState(formData.color || '#000000');

  // Address
  const [addressLine1, setAddressLine1] = useState(formData.addressLine1 || '');
  const [addressLine2, setAddressLine2] = useState(formData.addressLine2 || '');
  const [city, setCity] = useState(formData.city || '');
  const [state, setState] = useState(formData.state || '');
  const [zipCode, setZipCode] = useState(formData.zipCode || '');
  const [country, setCountry] = useState(formData.country || '');

  const [loading, setLoading] = useState(false);

  // Consolidate data to pass along
  const currentData = {
    profilePicture,
    organizationName,
    subdomain,
    timezone,
    language,
    color,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    country,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      onNext(currentData);
    } catch (error) {
      console.error('Error proceeding to summary:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    onBack(currentData);
  };

  const tabs = [
    { id: 'general', label: t('signUp.generalInformation') },
    { id: 'address', label: t('signUp.address') },
  ];

  return (
    <>
      <h2>{t('signUp.step4Title')}</h2>
      <form onSubmit={handleSubmit} className="sign-up-form">
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          onTabSelect={setSelectedTab}
        />

        <div className="tab-content">
          {selectedTab === 'general' && (
            <div className="tab-panel">
              {/* Organization Logo (Profile Picture) */}
              <div className="sign-up-input-group full-width">
                <ImagePicker
                  name="profilePicture"
                  value={profilePicture}
                  onChange={setProfilePicture}
                  required
                  imageShape="circular"
                  maxFileSize={5120}
                  maxFinalSizeKB={500}
                  maxDimension={200}
                />
              </div>

              {/* Organization Name */}
              <div className="sign-up-input-group full-width">
                <label htmlFor="organizationName">{t('signUp.organizationNameLabel')}</label>
                <input
                  id="organizationName"
                  type="text"
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                  placeholder={t('signUp.organizationNamePlaceholder')}
                  required
                />
              </div>

              {/* Subdomain */}
              <div className="sign-up-input-group full-width">
                <label htmlFor="subdomain">{t('signUp.subdomainLabel')}</label>
                <input
                  id="subdomain"
                  type="text"
                  value={subdomain}
                  onChange={(e) => setSubdomain(e.target.value)}
                  placeholder={t('signUp.subdomainPlaceholder')}
                  required
                />
              </div>

              {/* Timezone */}
              <div className="sign-up-input-group full-width">
                <SelectField
                  label={t('signUp.timezoneLabel')}
                  name="timezone"
                  value={timezone}
                  onChange={setTimezone}
                  placeholder={t('signUp.selectTimezonePlaceholder')}
                  catalogId="timezones"
                  required
                />
              </div>

              {/* Language */}
              <div className="sign-up-input-group full-width">
                <SelectField
                  label={t('signUp.languageLabel')}
                  name="language"
                  value={language}
                  onChange={setLanguage}
                  placeholder={t('signUp.selectLanguagePlaceholder')}
                  catalogId="languages"
                  required
                />
              </div>

              {/* Color Picker */}
              <div className="sign-up-input-group full-width">
                <ColorPicker
                  label={t('signUp.colorLabel')}
                  value={color}
                  onChange={setColor}
                  required
                />
              </div>
            </div>
          )}

          {selectedTab === 'address' && (
            <div className="tab-panel">
              {/* Address Line 1 */}
              <div className="sign-up-input-group full-width">
                <label htmlFor="addressLine1">{t('signUp.addressLine1Label')}</label>
                <input
                  id="addressLine1"
                  type="text"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                  placeholder={t('signUp.addressLine1Placeholder')}
                  required
                />
              </div>

              {/* Address Line 2 */}
              <div className="sign-up-input-group full-width">
                <label htmlFor="addressLine2">{t('signUp.addressLine2Label')}</label>
                <input
                  id="addressLine2"
                  type="text"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                  placeholder={t('signUp.addressLine2Placeholder')}
                />
              </div>

              {/* City */}
              <div className="sign-up-input-group full-width">
                <label htmlFor="city">{t('signUp.cityLabel')}</label>
                <input
                  id="city"
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder={t('signUp.cityPlaceholder')}
                  required
                />
              </div>

              {/* State */}
              <div className="sign-up-input-group full-width">
                <SelectField
                  label={t('signUp.stateLabel')}
                  name="state"
                  value={state}
                  onChange={setState}
                  placeholder={t('signUp.selectStatePlaceholder')}
                  catalogId="states"
                  required
                />
              </div>

              {/* Zip Code */}
              <div className="sign-up-input-group full-width">
                <label htmlFor="zipCode">{t('signUp.zipCodeLabel')}</label>
                <input
                  id="zipCode"
                  type="text"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  placeholder={t('signUp.zipCodePlaceholder')}
                  required
                />
              </div>

              {/* Country */}
              <div className="sign-up-input-group full-width">
                <SelectField
                  label={t('signUp.countryLabel')}
                  name="country"
                  value={country}
                  onChange={setCountry}
                  placeholder={t('signUp.selectCountryPlaceholder')}
                  catalogId="countries"
                  required
                />
              </div>
            </div>
          )}
        </div>

        <div className="form-navigation-buttons">
          <button
            type="button"
            className="sign-up-back-button"
            onClick={handleBack}
            disabled={loading}
          >
            {t('signUp.backButton')}
          </button>

          <button
            type="submit"
            className="sign-up-submit-button"
            disabled={loading}
          >
            {loading ? <Spinner /> : t('signUp.nextButton')}
          </button>
        </div>
      </form>
    </>
  );
};

export default Step4Form;
