// src/pages/MembersPage.jsx

import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';
import { DataContext } from '../../DataContext';
import { MessageContext } from '../../contexts/MessageContext';
import { useTranslation } from 'react-i18next';

import MembersTable from './MembersTable';
import Spinner from '../Common/Spinner';
import FillOutForm from '../FormBuilder/FillOutForm';
import CustomModal from '../Common/CustomModal';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import ToastContainer from '../Common/ToastContainer';
import ActionButton from '../Common/ActionButton';
import ConfigureFieldsModal from '../Common/ConfigureFieldsModal';
import MessageSender from '../Communications/MessageSender';
import './MembersPage.css';

import { generateCSV, downloadCSV } from '../FormBuilder/CSVUtility';
import { generatePDF } from '../FormBuilder/PDFUtility';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faCog,
  faLink,
  faFileCsv,
  faFilePdf,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

const MembersPage = () => {
  const { t } = useTranslation();

  // 1) Pull from context
  const { userRolesLoading } = useContext(UserContext);
  const { hasPermission } = useContext(AppSettingsContext);
  const {
    useResponses,
    deleteResponse,
    useFormFields,
    getTableFields,
    saveTableFields,
    appSettings,
    // ...
  } = useContext(DataContext);

  // 2) Pull addMessage from MessageContext
  const { addMessage } = useContext(MessageContext);

  // 3) React Query hooks
  const {
    data: responses = [],
    isLoading,
    error,
    refetch,
  } = useResponses('membersForm');

  const {
    data: formFieldsData = {},
    isLoading: formFieldsLoading,
  } = useFormFields('membersForm');

  // State for table fields, exports, modals, etc.
  const [tableFields, setTableFields] = useState([]);
  const [loadingTableFields, setLoadingTableFields] = useState(true);

  const [isExportingCSV, setIsExportingCSV] = useState(false);
  const [isExportingPDF, setIsExportingPDF] = useState(false);

  // Generic modal
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');

  // Delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');

  // Confirmation modals for sending link(s)
  const [showConfirmSendLinkModal, setShowConfirmSendLinkModal] = useState(false);
  const [recordToSendLink, setRecordToSendLink] = useState(null);

  const [showConfirmSendAllLinkModal, setShowConfirmSendAllLinkModal] = useState(false);

  // Toasts + config
  const [toasts, setToasts] = useState([]);
  const [showConfigModal, setShowConfigModal] = useState(false);

  // More options menu
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);

  // 4) Fetch table fields
  useEffect(() => {
    const fetchTableFields = async () => {
      try {
        const fields = await getTableFields('membersForm');
        if (fields && fields.length > 0) {
          setTableFields(fields);
        } else {
          // Default fallback if no config
          setTableFields([
            {
              id: 'fullName',
              label: 'Full Name',
              accessor: ['firstName', 'lastName'],
              sortable: true,
              hideInMobile: false,
              type: 'Text',
              clickToEdit: false,
            },
            {
              id: 'location',
              label: 'Location',
              accessor: ['city', 'state'],
              sortable: true,
              hideInMobile: true,
              type: 'Text',
              clickToEdit: false,
            },
            {
              id: 'dateOfBirth',
              label: 'Date of Birth',
              accessor: 'dateOfBirth',
              sortable: true,
              hideInMobile: false,
              type: 'DatePicker',
              clickToEdit: false,
            },
            {
              id: 'phoneNumber',
              label: 'Phone Number',
              accessor: 'phoneNumber',
              sortable: true,
              hideInMobile: false,
              type: 'Telephone',
              clickToEdit: false,
            },
          ]);
        }
      } catch (error) {
        console.error('Error fetching table fields:', error);
      } finally {
        setLoadingTableFields(false);
      }
    };
    fetchTableFields();
  }, [getTableFields]);

  // Close menu if clicked outside
  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };
    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutsideMenu);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [menuVisible]);

  // 5) Permission checks & loading
  if (userRolesLoading) {
    return <div className="loading-container">Loading user permissions...</div>;
  }

  if (!hasPermission('members', 'viewMember')) {
    return (
      <div className="access-denied">
        403 - You do not have permission to view members.
      </div>
    );
  }

  if (isLoading || loadingTableFields || formFieldsLoading) {
    return (
      <div className="loading-container">
        <Spinner size="100px" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-message">
        {t('membersPage.errorFetchingMembers')}
      </div>
    );
  }

  // -------------------------------------------
  //    Toast Helpers
  // -------------------------------------------
  const showToast = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // -------------------------------------------
  //    Build Members Array
  // -------------------------------------------
  const members = responses.map((response) => {
    const member = {};
    response.fields.forEach((field) => {
      member[field.id] = field.value;
    });
    member.id = response.id;
    member.fields = response.fields;
    member.submittedAt = response.submittedAt;
    member.createdAt = response.createdAt;
    member.updatedAt = response.updatedAt;
    member.groups = response.groups ? response.groups.map((gRef) => gRef.groupId) : [];
    member.response = response;
    return member;
  });

  // -------------------------------------------
  //    Export Handlers
  // -------------------------------------------
  const handleExportCSV = async () => {
    if (!hasPermission('members', 'exportMember')) {
      showToast('You do not have permission to export members.', 'error');
      setMenuVisible(false);
      return;
    }

    if (responses.length === 0) {
      showToast(t('responsesTab.noResponsesToExport'), 'error');
      return;
    }
    setIsExportingCSV(true);
    try {
      const csvContent = generateCSV(responses, formFieldsData.fields);
      downloadCSV(csvContent, 'members.csv');
      showToast(t('responsesTab.csvExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.csvExportError'), 'error');
      console.error('Error exporting CSV:', error);
    } finally {
      setIsExportingCSV(false);
      setMenuVisible(false);
    }
  };

  const handleExportPDF = async () => {
    if (!hasPermission('members', 'exportMember')) {
      showToast('You do not have permission to export members.', 'error');
      setMenuVisible(false);
      return;
    }

    if (responses.length === 0) {
      showToast(t('responsesTab.noResponsesToExport'), 'error');
      return;
    }
    setIsExportingPDF(true);
    try {
      const groupLogo = appSettings?.profilePicture || null;
      await generatePDF(
        responses,
        formFieldsData.fields,
        false,
        groupLogo,
        t('membersForm', 'Members')
      );
      showToast(t('responsesTab.pdfExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.pdfExportError'), 'error');
      console.error('Error exporting PDF:', error);
    } finally {
      setIsExportingPDF(false);
      setMenuVisible(false);
    }
  };

  // -------------------------------------------
  //    Action Handlers
  // -------------------------------------------
  // Add new member
  const handleAdd = () => {
    if (!hasPermission('members', 'addMember')) {
      showToast('You are not authorized to add members.', 'error');
      return;
    }
    setModalContent(
      <FillOutForm
        fields={formFieldsData.fields}
        formId="membersForm"
        showToast={showToast}
        initialData={null}
        // NOTE: no responseId here => means "new"
        // We pass autoSwitchToUpdate={true} so that after first submission
        // the same form instance changes from "Submit" to "Update".
        autoSwitchToUpdate={true} // <-- Added autoSwitchToUpdate={true}
        onCancel={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
          refetch();
        }}
        // If you want to close or refresh after the first submit, 
        // you could handle onFormSubmit differently:
        onFormSubmit={() => {
          // Example: refetch data
          refetch();
        }}
      />
    );
    setModalTitle(t('membersTable.addMember', 'Add Member'));
    setShowModal(true);
  };

  // Edit or view existing member
  const handleEdit = (record) => {
    const canEdit = hasPermission('members', 'editMember');

    setModalContent(
      <FillOutForm
        fields={formFieldsData.fields}
        formId="membersForm"
        showToast={showToast}
        initialData={record}
        responseId={record.id}
        groupId={record.groups[0]}
        onCancel={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
          refetch();
        }}
        viewMode={!canEdit}
      />
    );

    setModalTitle(
      canEdit
        ? t('membersTable.editMember', {
            name: `${record.firstName || ''} ${record.lastName || ''}`,
          })
        : t('membersTable.viewMember', {
            name: `${record.firstName || ''} ${record.lastName || ''}`,
          })
    );
    setShowModal(true);
  };

  // Basic "Send Message" button (placeholder)
  const handleSendMessage = (record) => {
    setModalContent(
      <MessageSender
        single
        recipient={record.phoneNumber}
        showToast={showToast}
      />
    );
    setModalTitle(
      t('membersTable.sendMessageTo', {
        name: `${record.firstName} ${record.lastName}`,
      })
    );
    setShowModal(true);
  };

  // Step 1: Show confirmation modal for sending link to one member
  const handleSendFormLink = (record) => {
    if (!record.phoneNumber) {
      showToast('Member has no phone number', 'error');
      return;
    }
    if (!hasPermission('communications', 'sendMessage')) {
      showToast('You do not have permission to send form links.', 'error');
      return;
    }

    setRecordToSendLink(record);
    setShowConfirmSendLinkModal(true);
  };

  // Step 2: On confirming, do the actual send for single record
  const confirmSendFormLink = () => {
    if (!recordToSendLink) return;

    const origin = window.location.origin;
    const groupId = recordToSendLink.groups[0];
    const link = `${origin}/${groupId}/forms/membersForm?responseId=${recordToSendLink.id}`;

    const defaultMessage = `Hello ${
      recordToSendLink.firstName || ''
    },\nPlease complete or update your form at the link below:\n${link}\nThank you!`;

    const recipients = [
      {
        phoneNumber: recordToSendLink.phoneNumber,
        name: `${recordToSendLink.firstName} ${recordToSendLink.lastName}`,
      },
    ];

    const { success } = addMessage({
      title: 'Form Link',
      message: defaultMessage,
      image: null,
      recipients,
      recipientType: 'single',
      selectedGroups: [],
      selectedTeams: [],
      selectedMembers: [],
      isScheduled: false,
      scheduledDateTime: null,
    });

    if (success) {
      showToast(
        `SMS with form link queued to send to ${recordToSendLink.firstName}`,
        'success'
      );
    } else {
      showToast('Unable to send message', 'error');
    }

    setShowConfirmSendLinkModal(false);
    setRecordToSendLink(null);
  };

  // "Call" action
  const handleCall = (record) => {
    window.location.href = `tel:${record.phoneNumber}`;
  };

  // "Send Email" action
  const handleSendEmail = (record) => {
    window.location.href = `mailto:${record.email}`;
  };

  // Handle Delete
  const handleDelete = (record) => {
    if (!hasPermission('members', 'deleteMember')) {
      showToast('You are not authorized to delete members.', 'error');
      return;
    }
    setRecordToDelete(record);
    setDeleteModalMessage(
      t('deleteConfirmation.message', {
        item: `${record.firstName || ''} ${record.lastName || ''}`,
      })
    );
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!recordToDelete) return;
    try {
      await deleteResponse('membersForm', recordToDelete.id);
      showToast(
        t('memberTable.recordDeleted', 'Member has been deleted successfully'),
        'success'
      );
      setShowDeleteModal(false);
      setRecordToDelete(null);
      refetch();
    } catch (error) {
      console.error('Error deleting member:', error);
      showToast(t('memberTable.deleteError', 'Error deleting member'), 'error');
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setRecordToDelete(null);
  };

  // PDF for single record
  const handleGeneratePdf = async (record) => {
    if (!hasPermission('members', 'exportMember')) {
      showToast('You do not have permission to export members.', 'error');
      return;
    }
    try {
      const groupLogo = appSettings?.profilePicture || null;
      await generatePDF(
        [record.response],
        formFieldsData.fields,
        false,
        groupLogo,
        t('membersForm', 'Member')
      );
      showToast(t('responsesTab.pdfExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.pdfExportError'), 'error');
      console.error('Error exporting PDF:', error);
    }
  };

  // -------------------------------------------
  //    Row-level actions
  // -------------------------------------------
  const actions = [
    {
      label: hasPermission('members', 'editMember')
        ? t('edit', 'Edit')
        : t('view', 'View'),
      icon: hasPermission('members', 'editMember') ? 'faEdit' : 'faEye',
      callback: handleEdit,
      variant: 'secondary',
      condition: () => hasPermission('members', 'viewMember'),
    },
    {
      label: t('sendMessage', 'Send Message'),
      icon: 'faPaperPlane',
      callback: handleSendMessage,
      variant: 'primary',
      condition: (record) =>
        !!record.phoneNumber && hasPermission('communications', 'sendMessage'),
    },
    {
      label: t('sendFormLink', 'Send Form Link'),
      icon: 'faLink',
      callback: handleSendFormLink,
      variant: 'primary',
      condition: (record) =>
        !!record.phoneNumber &&
        (hasPermission('members', 'sendFormLink') ||
          hasPermission('communications', 'sendMessage')),
    },
    {
      label: t('call', 'Call'),
      icon: 'faPhone',
      callback: handleCall,
      variant: 'secondary',
      condition: (record) => !!record.phoneNumber,
      as: 'a',
      hrefAccessor: (record) => `tel:${record.phoneNumber}`,
    },
    {
      label: t('sendEmail', 'Send Email'),
      icon: 'faEnvelope',
      callback: handleSendEmail,
      variant: 'info',
      disabledAccessor: (record) => !record.email,
    },
    {
      label: t('exportPDF', 'Export PDF'),
      icon: 'faFilePdf',
      callback: handleGeneratePdf,
      variant: 'secondary',
      condition: () => hasPermission('members', 'exportMember'),
    },
    {
      label: t('delete', 'Delete'),
      icon: 'faTrash',
      callback: handleDelete,
      variant: 'danger',
      condition: () => hasPermission('members', 'deleteMember'),
    },
  ];

  // -------------------------------------------
  //    Send Link to ALL
  // -------------------------------------------
  const handleSendAllFormLinks = () => {
    if (!hasPermission('communications', 'sendMessage')) {
      showToast('You do not have permission to send form links.', 'error');
      return;
    }
    setShowConfirmSendAllLinkModal(true);
    setMenuVisible(false);
  };

  const confirmSendLinkToAllMembers = () => {
    // Gather all members that have phoneNumber
    const membersWithPhone = members.filter((m) => m.phoneNumber);

    if (membersWithPhone.length === 0) {
      showToast('No members have phone numbers.', 'error');
      setShowConfirmSendAllLinkModal(false);
      return;
    }

    // For each member, construct a link and send an individual message
    membersWithPhone.forEach((member) => {
      const origin = window.location.origin;
      const groupId = member.groups[0] || '';
      const link = `${origin}/${groupId}/forms/membersForm?responseId=${member.id}`;

      const defaultMessage = `Hello ${
        member.firstName || ''
      },\nPlease complete or update your form at the link below:\n${link}\nThank you!`;

      addMessage({
        title: 'Form Link',
        message: defaultMessage,
        image: null,
        recipients: [
          {
            phoneNumber: member.phoneNumber,
            name: `${member.firstName} ${member.lastName}`,
          },
        ],
        recipientType: 'single',
        selectedGroups: [],
        selectedTeams: [],
        selectedMembers: [],
        isScheduled: false,
        scheduledDateTime: null,
      });
    });

    showToast(
      `SMS links have been queued for ${membersWithPhone.length} members.`,
      'success'
    );
    setShowConfirmSendAllLinkModal(false);
  };

  // -------------------------------------------
  //    Table Config Modal
  // -------------------------------------------
  const handleOpenConfigModal = () => {
    if (!hasPermission('members', 'configureMember')) {
      showToast('You do not have permission to configure members table.', 'error');
      setMenuVisible(false);
      return;
    }
    setShowConfigModal(true);
    setMenuVisible(false);
  };

  const handleSaveConfig = async (configuredFields) => {
    try {
      await saveTableFields('membersForm', configuredFields);
      setTableFields(configuredFields);
      setShowConfigModal(false);
      showToast(
        t('tableConfigurationSuccessful', 'Table configuration saved successfully'),
        'success'
      );
    } catch (error) {
      console.error('Error saving table configuration:', error);
      showToast(
        t('tableConfigurationFailed', 'Failed to save table configuration'),
        'error'
      );
    }
  };

  const canConfigureOrExport =
    hasPermission('members', 'configureMember') ||
    hasPermission('members', 'exportMember');

  // -------------------------------------------
  //    Render
  // -------------------------------------------
  return (
    <div className="members-page-container">
      {/* Header */}
      <div className="header-container">
        <h2>{t('membersPage.title', 'Members')}</h2>

        {/* Configuration / Export / Send to All Menu */}
        {canConfigureOrExport && (
          <div className="config-button">
            <div className="menu-container" ref={menuRef}>
              <ActionButton
                onClick={() => setMenuVisible(!menuVisible)}
                icon="faEllipsisV"
                variant="secondary"
                label={t('moreOptions', 'More Options')}
              />
              {menuVisible && (
                <div className="dropdown-menu">
                  {hasPermission('members', 'configureMember') && (
                    <button onClick={handleOpenConfigModal}>
                      <FontAwesomeIcon icon={faCog} className="menu-icon" />
                      {t('configureTableFields', 'Configure Table Fields')}
                    </button>
                  )}

                  {hasPermission('members', 'exportMember') && (
                    <>
                      <button
                        onClick={handleExportCSV}
                        disabled={isExportingCSV}
                      >
                        <FontAwesomeIcon icon={faFileCsv} className="menu-icon" />
                        {t('exportCSV', 'Export CSV')}
                        {isExportingCSV && (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            className="menu-spinner"
                          />
                        )}
                      </button>
                      <button
                        onClick={handleExportPDF}
                        disabled={isExportingPDF}
                      >
                        <FontAwesomeIcon icon={faFilePdf} className="menu-icon" />
                        {t('exportPDF', 'Export PDF')}
                        {isExportingPDF && (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            className="menu-spinner"
                          />
                        )}
                      </button>
                    </>
                  )}

                  {/* Send form link to all members option */}
                  {hasPermission('communications', 'sendMessage') && (
                    <button onClick={handleSendAllFormLinks}>
                      <FontAwesomeIcon icon={faLink} className="menu-icon" />
                      {t('sendFormLinkAll', 'Send Form Link to All')}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Main Table */}
      <MembersTable
        formId="membersForm"
        fields={tableFields}
        actions={actions}
        members={members}
        addMember={handleAdd}
        exportMember={handleExportCSV}
        onRowClick={handleEdit}
      />

      {/* Add/Edit/Message Modal (Generic) */}
      <CustomModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
        }}
        title={modalTitle}
      >
        {modalContent}
      </CustomModal>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmationModal
          show={showDeleteModal}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          title={t('deleteConfirmation.title')}
          message={deleteModalMessage}
        />
      )}

      {/* Confirm Send Link (Single Member) */}
      <CustomModal
        show={showConfirmSendLinkModal}
        onClose={() => {
          setShowConfirmSendLinkModal(false);
          setRecordToSendLink(null);
        }}
        title="Confirm Send Link"
        closable={false}
      >
        <div className="delete-confirmation">
          <p>
            {recordToSendLink
              ? `Are you sure you want to send the form link to ${recordToSendLink.firstName} ${recordToSendLink.lastName}?`
              : 'Are you sure you want to send this form link?'}
          </p>
          <div className="delete-confirmation-actions">
            <ActionButton
              onClick={confirmSendFormLink}
              label="Send"
              text="Send"
              icon="faPaperPlane"
              colorType="primary"
            />
            <ActionButton
              onClick={() => {
                setShowConfirmSendLinkModal(false);
                setRecordToSendLink(null);
              }}
              label="Cancel"
              text="Cancel"
              icon="faTimes"
              colorType="secondary"
            />
          </div>
        </div>
      </CustomModal>

      {/* Confirm Send Link to ALL */}
      <CustomModal
        show={showConfirmSendAllLinkModal}
        onClose={() => setShowConfirmSendAllLinkModal(false)}
        title="Confirm Send Link to All"
        closable={false}
      >
        <div className="delete-confirmation">
          <p>Are you sure you want to send the form link to all listed members?</p>
          <div className="delete-confirmation-actions">
            <ActionButton
              onClick={confirmSendLinkToAllMembers}
              label="Send"
              text="Send"
              icon="faPaperPlane"
              colorType="primary"
            />
            <ActionButton
              onClick={() => setShowConfirmSendAllLinkModal(false)}
              label="Cancel"
              text="Cancel"
              icon="faTimes"
              colorType="secondary"
            />
          </div>
        </div>
      </CustomModal>

      {/* Configure Fields Modal */}
      {showConfigModal && (
        <ConfigureFieldsModal
          show={showConfigModal}
          onClose={() => setShowConfigModal(false)}
          onSave={handleSaveConfig}
          availableFields={formFieldsData.fields}
          currentConfig={tableFields}
        />
      )}

      {/* Toast Notifications */}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

export default MembersPage;
