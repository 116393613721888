/**
 * Compress a base64-encoded image with adjustable options.
 *
 * @param {string} base64Image - The base64 string of the image.
 * @param {Object} [options={}]
 * @param {number} [options.maxWidth=800] - Maximum allowed width in px.
 * @param {number} [options.maxHeight=800] - Maximum allowed height in px.
 * @param {number} [options.quality=0.7] - Compression quality (0–1) for JPEG/WebP.
 * @param {string} [options.outputFormat='image/jpeg'] - Desired output MIME type (e.g. 'image/jpeg', 'image/webp').
 * @returns {Promise<string>} - A Promise resolving to the compressed base64 string.
 */
export function compressBase64Image(
  base64Image,
  {
    maxWidth = 800,
    maxHeight = 800,
    quality = 0.7,
    outputFormat = 'image/jpeg',
  } = {}
) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('canvas');

      let { width, height } = img;
      // Maintain aspect ratio if resizing is needed
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round(height * (maxWidth / width));
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round(width * (maxHeight / height));
          height = maxHeight;
        }
      }

      // Adjust canvas size to the new dimensions
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      // Convert canvas to the desired format & quality
      const compressedBase64 = canvas.toDataURL(outputFormat, quality);
      resolve(compressedBase64);
    };

    img.onerror = (error) => {
      console.error('Error loading image:', error);
      reject(error);
    };

    // Trigger the load
    img.src = base64Image;
  });
}

/**
 * Ensures the given object is stripped of any properties
 * Firestore can't serialize (like functions, undefined, etc.)
 * Returns a plain JSON-serializable object.
 *
 * @param {any} obj - Any JavaScript value
 * @returns {any} - JSON-serializable version of the input
 */
function makeSerializable(obj) {
  // A simple approach: deep clone via JSON.
  // This removes any functions/DOM elements and converts undefined → null
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Helper function to compress images in an array of field objects,
 * returning a new array where each field is plain JS (Firestore-friendly).
 *
 * @param {Array} fields - An array of field objects.
 * @returns {Promise<Array>} - A Promise that resolves to an array of sanitized, compressed fields.
 */
export async function compressImageFields(fields) {
  // 1) Make each field serializable before we begin
  const clonedFields = fields.map((field) => makeSerializable(field));

  // 2) Process each field, compress if it's an ImagePicker
  const processedFields = await Promise.all(
    clonedFields.map(async (field) => {
      if (
        field.type === 'ImagePicker' &&
        typeof field.value === 'string' &&
        field.value.startsWith('data:image/')
      ) {
        try {
          // Use your new compressor with custom options (if desired)
          const compressedValue = await compressBase64Image(field.value, {
            maxWidth: 800,
            maxHeight: 800,
            quality: 0.7,
            outputFormat: 'image/jpeg',
          });

          // Return a new object with the compressed value
          return {
            ...field,
            value: compressedValue,
          };
        } catch (err) {
          console.error('Error compressing image field:', err);
          // If compression fails, return the original field
          return field;
        }
      }

      // For non-image fields, just return the field as-is
      return field;
    })
  );

  // 3) One last pass to ensure everything is JSON-friendly
  return processedFields.map((field) => makeSerializable(field));
}
