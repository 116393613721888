// src/components/Authentication/AuthenticationContainer.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Default Catholicore logo
import CatholicoreLogo from '../../assets/CatholicoreLogo.png';

// Optional spinner & verse
import Spinner from '../Common/Spinner';
import BibleVerse from '../BibleVerse/BibleVerse';

import './AuthenticationContainer.css';

const AuthenticationContainer = ({
  topLeftLogo = CatholicoreLogo,
  appSettings,
  isLoading,
  loadingContent,
  error,
  errorMessage,
  hideLogosAndVerse = false,
  rightPanelBgColor,
  leftPanelComponent,
  rightPanelComponent,
}) => {
  const { t } = useTranslation();

  // If there's an appSettings.profilePicture, we might replace the main logo
  const appProfilePic = appSettings?.profilePicture || CatholicoreLogo;
  console.log(appSettings);

  // If we specifically want to hide the top-left if it references "CatholicoreLogo"
  // (some logic used in your original code). If not needed, you can remove.
  const hideTopLeftLogo =
    Boolean(appSettings?.profilePicture?.includes('CatholicoreLogo')) || false;

  // ----------------- LOADING STATE -----------------
  if (isLoading) {
    return (
      <div className="login-split-container">
        {/* LEFT PANEL */}
        <div className="login-left-panel">
        {!hideLogosAndVerse && !hideTopLeftLogo && (
              <div className="login-left-logo">
                <Link to="/">
                  <img src={topLeftLogo} alt="Catholicore Logo" />
                </Link>
              </div>
            )}
          <div className="login-left-content">


            <div className="login-center-content">
              {!hideLogosAndVerse && (
                <div className="login-logo">
                  <Link to="/">
                    <img
                      src={appProfilePic}
                      alt={t('login.logoAlt') || 'Catholicore'}
                    />
                  </Link>
                </div>
              )}
              <h2>Loading…</h2>
              <Spinner />
              {loadingContent && (
                <div className="login-loading-extra">{loadingContent}</div>
              )}
            </div>
          </div>

          <footer className="login-footer desktop-footer">
            <div className="login-policy-links">
              <Link to="/policies?policy=privacy" className="login-policy-link">
                Privacy Policy
              </Link>
              <span className="login-policy-separator">|</span>
              <Link to="/policies?policy=terms" className="login-policy-link">
                Terms of Service
              </Link>
            </div>
          </footer>
        </div>

        {/* RIGHT PANEL */}
        <div
          className="login-right-panel"
          style={{ background: rightPanelBgColor }}
        >
          {!hideLogosAndVerse && <BibleVerse />}

          <footer className="login-footer-mobile">
            <div className="login-policy-links-mobile">
              <Link to="/policies?policy=privacy" className="login-policy-link-mobile">
                Privacy Policy
              </Link>
              <span className="login-policy-separator-mobile">|</span>
              <Link to="/policies?policy=terms" className="login-policy-link-mobile">
                Terms of Service
              </Link>
            </div>
          </footer>
        </div>
      </div>
    );
  }

  // ----------------- ERROR STATE -----------------
  if (error) {
    return (
      <div className="login-split-container">
        {/* LEFT PANEL */}
        <div className="login-left-panel">
        {!hideLogosAndVerse && !hideTopLeftLogo && (
              <div className="login-left-logo">
                <Link to="/">
                  <img src={topLeftLogo} alt="Catholicore Logo" />
                </Link>
              </div>
            )}
          <div className="login-left-content">
            <div className="login-center-content">
              {!hideLogosAndVerse && (
                <div className="login-logo">
                  <Link to="/">
                    <img
                      src={appProfilePic}
                      alt={t('login.logoAlt') || 'Catholicore'}
                    />
                  </Link>
                </div>
              )}
              <h2>Sign In Error</h2>
              <p>{errorMessage}</p>
            </div>
          </div>

          <footer className="login-footer desktop-footer">
            <div className="login-policy-links">
              <Link to="/policies?policy=privacy" className="login-policy-link">
                Privacy Policy
              </Link>
              <span className="login-policy-separator">|</span>
              <Link to="/policies?policy=terms" className="login-policy-link">
                Terms of Service
              </Link>
            </div>
          </footer>
        </div>

        {/* RIGHT PANEL */}
        <div
          className="login-right-panel"
          style={{ background: rightPanelBgColor }}
        >
          {!hideLogosAndVerse && <BibleVerse />}
          <footer className="login-footer-mobile">
            <div className="login-policy-links-mobile">
              <Link to="/policies?policy=privacy" className="login-policy-link-mobile">
                Privacy Policy
              </Link>
              <span className="login-policy-separator-mobile">|</span>
              <Link to="/policies?policy=terms" className="login-policy-link-mobile">
                Terms of Service
              </Link>
            </div>
          </footer>
        </div>
      </div>
    );
  }

  // ----------------- NORMAL STATE -----------------
  return (
    <div className="login-split-container">
      <div className="login-left-panel">
      {!hideTopLeftLogo && (
            <div className="login-left-logo">
              <Link to="/">
                <img src={topLeftLogo} alt="Catholicore Logo" />
              </Link>
            </div>
          )}
        <div className="login-left-content">
          {/* Only hide top-left if certain conditions met */}


          <div className="login-center-content">
            {!hideLogosAndVerse && (
              <div className="login-logo">
                <Link to="/">
                  <img
                    src={appProfilePic}
                    alt={t('login.logoAlt') || 'Catholicore'}
                  />
                </Link>
              </div>
            )}
            {/* The left panel custom component */}
            {leftPanelComponent}
          </div>
        </div>

        <footer className="login-footer desktop-footer">
          <div className="login-policy-links">
            <Link to="/policies?policy=privacy" className="login-policy-link">
              Privacy Policy
            </Link>
            <span className="login-policy-separator">|</span>
            <Link to="/policies?policy=terms" className="login-policy-link">
              Terms of Service
            </Link>
          </div>
        </footer>
      </div>

      {/* RIGHT PANEL */}
      <div
        className="login-right-panel"
        style={{ background: rightPanelBgColor }}
      >
        {/* If we have a custom right panel, use it; else show verse if not hidden */}
        {rightPanelComponent || (!hideLogosAndVerse && <BibleVerse />)}

        <footer className="login-footer-mobile">
          <div className="login-policy-links-mobile">
            <Link to="/policies?policy=privacy" className="login-policy-link-mobile">
              Privacy Policy
            </Link>
            <span className="login-policy-separator-mobile">|</span>
            <Link to="/policies?policy=terms" className="login-policy-link-mobile">
              Terms of Service
            </Link>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default AuthenticationContainer;
