import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UserMenu from './UserMenu';
import Logo from './Logo';
import TabNavigation from './TabNavigation';
import ToastContainer from '../Common/ToastContainer';

import CatholicoreLogoWhite from '../../assets/CatholicoreLogoWhite.png';

import { UserContext } from '../../contexts/UserContext';
import { AuthContext } from '../../contexts/AuthContext';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';
import { useColorSettings } from '../Common/ColorSettingsContext';

import './Header.css';

const Header = ({ currentTab, onTabChange, isTransitioning }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Pull from contexts
  // 1) Pull user info from UserContext
  const { userData, currentUser } = useContext(UserContext);

  // 2) Pull auth methods from AuthContext
  const { signOut, sendPasswordResetEmail } = useContext(AuthContext);

  // 3) Pull organizationData and color from AppSettingsContext
  const { organizationData } = useContext(AppSettingsContext);
  const { profilePicture, color } = useColorSettings();

  const [toasts, setToasts] = useState([]);

  const showToastMessage = (
    message,
    type = 'success',
    duration = 5000
  ) => {
    const id = Date.now();
    setToasts((prevToasts) => [
      ...prevToasts,
      { id, message, type, duration },
    ]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) =>
      prevToasts.filter((toast) => toast.id !== id)
    );
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/' + organizationData.groupId + '/login');
    } catch (error) {
      console.error('Error signing out:', error);
      showToastMessage(t('header.logoutError'), 'error');
    }
  };

  const handleResetPassword = async () => {
    try {
      const email = userData.email || currentUser.email;
      if (email) {
        const catholicoreLogo = "https://catholicore.com/static/media/CatholicoreLogoWhite.2b64249d4eacac07ba63.png";
        await sendPasswordResetEmail(email,catholicoreLogo,color);
        showToastMessage(t('header.resetEmailSent'), 'success');
      } else {
        showToastMessage(t('header.noEmailAssociated'), 'error');
      }
    } catch (error) {
      console.error('Error sending password reset email:', error);
      showToastMessage(t('header.resetEmailError'), 'error');
    }
  };

  const handleSwitchGroup = () => {
    navigate('/organization-selection');
  };

  return (
    <header className="app-header">
      {/* White Logo Positioned at Top Left */}
      <div className="header-white-logo">
        <img src={CatholicoreLogoWhite} alt="Catholicore White Logo" />
      </div>

      <UserMenu
        userData={userData}
        onLogout={handleLogout}
        onResetPassword={handleResetPassword}
        onSwitchGroup={handleSwitchGroup}
        isTransitioning={isTransitioning}
      />

      <Logo
        className="organization-logo"
        profilePicture={profilePicture}
      />

      <TabNavigation
        currentTab={currentTab}
        onTabChange={onTabChange}
        isTransitioning={isTransitioning}
      />

      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </header>
  );
};

export default Header;
