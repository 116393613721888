// src/components/Authentication/SignUpSteps/Step5Form.jsx

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../Common/Spinner';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../../DataContext';
import Tabs from '../../Common/Tabs';
import './Step5Form.css';

const Step5Form = ({ onNext, onBack, formData }) => {
  const { t } = useTranslation();
  const { createOrganizationWithUser } = useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [selectedTab, setSelectedTab] = useState('personal');
  const [profilePicPreview, setProfilePicPreview] = useState(null);

  useEffect(() => {
    // Now `formData.profilePicture` should be a string URL
    if (typeof formData.profilePicture === 'string') {
      setProfilePicPreview(formData.profilePicture);
    } else {
      setProfilePicPreview(null);
    }
  }, [formData.profilePicture]);

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage('');

    try {
      const {
        email,
        firstName,
        lastName,
        password,
        profilePicture, // URL from ImagePicker
        organizationName,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
        country,
        timezone,
        language,
        color,
        subdomain,
      } = formData;

      const userData = {
        email,
        firstName,
        lastName,
        password,
        // Only attach the string URL if it exists
        profilePicture: profilePicture || '',
      };

      const organizationData = {
        organizationName,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
        country,
        timezone,
        language,
        color,
        subdomain,
      };

      // Instead of `FormData`, we'll send JSON:
      const payload = {
        user: userData,
        organization: organizationData,
      };

      // Create org + user by calling your custom function
      await createOrganizationWithUser(payload);

      // If everything’s good, show success message
      setSuccessMessage('SUCCESS');
    } catch (error) {
      console.error('Error creating organization:', error);
      setErrorMessage(t('signUp.errorCreatingOrganization'));
    } finally {
      setLoading(false);
    }
  };

  // If successMessage is set, show final "thank you" screen
  if (successMessage) {
    return (
      <div className="sign-up-summary final-step">
        <h2>{t('signUp.step5Title')}</h2>

        <div className="thank-you-container">
          <h3>Thank You for Choosing Catholicore!</h3>
          <p>
            We have received your sign-up request and will review your account details shortly.
            You will be contacted once your account is activated.
          </p>
          <button
            type="button"
            className="sign-up-home-button"
            onClick={() => (window.location.href = '/')}
          >
            Return to Home
          </button>
        </div>
      </div>
    );
  }

  // Otherwise, continue with the summary + tabs
  const tabs = [
    { id: 'personal', label: t('signUp.personalInformation') },
    { id: 'organization', label: t('signUp.organizationInformation') },
    { id: 'address', label: t('signUp.address') },
  ];

  return (
    <>
      <h2>{t('signUp.step5Title')}</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <div className="sign-up-tabs-wrapper">
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          onTabSelect={(tabId) => setSelectedTab(tabId)}
        />

        <div className="sign-up-tab-content">
          {selectedTab === 'personal' && (
            <div className="summary-section">
              <h4>{t('signUp.personalInformation')}</h4>
              <table>
                <tbody>
                  <tr>
                    <th>{t('signUp.emailLabel')}</th>
                    <td>{formData.email}</td>
                  </tr>
                  <tr>
                    <th>{t('signUp.firstNameLabel')}</th>
                    <td>{formData.firstName}</td>
                  </tr>
                  <tr>
                    <th>{t('signUp.lastNameLabel')}</th>
                    <td>{formData.lastName}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {selectedTab === 'organization' && (
            <div className="summary-section">
              <h4>{t('signUp.organizationInformation')}</h4>
              {profilePicPreview && (
                <div className="profile-picture-wrapper">
                  <img
                    src={profilePicPreview}
                    alt="Profile"
                    className="profile-picture-preview"
                  />
                </div>
              )}
              <table>
                <tbody>
                  <tr>
                    <th>{t('signUp.organizationNameLabel')}</th>
                    <td>{formData.organizationName}</td>
                  </tr>
                  <tr>
                    <th>{t('signUp.subdomainLabel')}</th>
                    <td>{formData.subdomain}</td>
                  </tr>
                  <tr>
                    <th>{t('signUp.languageLabel')}</th>
                    <td>{formData.language}</td>
                  </tr>
                  <tr>
                    <th>{t('signUp.timezoneLabel')}</th>
                    <td>{formData.timezone}</td>
                  </tr>
                  <tr>
                    <th>{t('signUp.colorLabel')}</th>
                    <td>
                      <div
                        className="color-box"
                        style={{ backgroundColor: formData.color }}
                      ></div>
                      {formData.color}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {selectedTab === 'address' && (
            <div className="summary-section">
              <h4>{t('signUp.address')}</h4>
              <table>
                <tbody>
                  <tr>
                    <th>{t('signUp.addressLine1Label')}</th>
                    <td>{formData.addressLine1}</td>
                  </tr>
                  {formData.addressLine2 && (
                    <tr>
                      <th>{t('signUp.addressLine2Label')}</th>
                      <td>{formData.addressLine2}</td>
                    </tr>
                  )}
                  <tr>
                    <th>{t('signUp.cityLabel')}</th>
                    <td>{formData.city}</td>
                  </tr>
                  <tr>
                    <th>{t('signUp.stateLabel')}</th>
                    <td>{formData.state}</td>
                  </tr>
                  <tr>
                    <th>{t('signUp.zipCodeLabel')}</th>
                    <td>{formData.zipCode}</td>
                  </tr>
                  <tr>
                    <th>{t('signUp.countryLabel')}</th>
                    <td>{formData.country}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      <div className="sign-up-buttons">
        <button
          type="button"
          className="sign-up-submit-button"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <Spinner /> : t('signUp.finishButton')}
        </button>

        <button
          type="button"
          className="sign-up-back-button"
          onClick={() => onBack({})}
        >
          {t('signUp.backButton')}
        </button>
      </div>
    </>
  );
};

Step5Form.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    password: PropTypes.string,
    /** NOTE: now a URL string, not a File */
    profilePicture: PropTypes.string,
    organizationName: PropTypes.string,
    subdomain: PropTypes.string,
    language: PropTypes.string,
    timezone: PropTypes.string,
    color: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
};

export default Step5Form;
