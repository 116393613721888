import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../contexts/UserContext';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';
import './TabNavigation.css';

const TabNavigation = ({
  currentTab,
  onTabChange,
  isTransitioning,
}) => {
  const { t } = useTranslation();
  const { hasPermission } = useContext(AppSettingsContext);
  /**
   * Each tab object includes:
   * - name: The name (for routing / identifying)
   * - label: The label to display
   * - moduleName: The top-level module (e.g. "members", "groups", "teams", etc.)
   * - viewPerm: The "viewX" permission string
   * - customCheck?: For modules that have no "viewX" (like 'communications')
   */
  const tabs = [
    {
      name: 'members',
      label: t('tabNavigation.members'),
      moduleName: 'members',
      viewPerm: 'viewMember',
    },
    {
      name: 'groups',
      label: t('tabNavigation.groups'),
      moduleName: 'groups',
      viewPerm: 'viewGroup',
    },
    {
      name: 'teams',
      label: t('tabNavigation.teams'),
      moduleName: 'teams',
      viewPerm: 'viewTeam',
    },
    {
      name: 'communications',
      label: t('tabNavigation.communications'),
      moduleName: 'communications',
      // For communications, no "viewCommunications" in your list,
      // so let's show if user has "sendMessage" or "scheduleMessage"
      customCheck: () =>
        hasPermission('communications', 'sendMessage') ||
        hasPermission('communications', 'scheduleMessage'),
    },
    {
      name: 'forms',
      label: t('tabNavigation.forms'),
      moduleName: 'forms',
      viewPerm: 'viewForm',
    },
    {
      name: 'calendar',
      label: t('tabNavigation.calendar'),
      moduleName: 'calendar',
      viewPerm: 'viewEvent', 
      // your permissionOptions for "calendar" uses viewEvent
    },
    {
      name: 'settings',
      label: t('tabNavigation.settings'),
      moduleName: 'settings',
      viewPerm: 'viewSettings',
    },
    {
      name: 'security',
      label: t('tabNavigation.security', 'Security'),
      moduleName: 'security',
      // No "viewSecurity" in your permissionOptions, so let's show if user can see *some* security
      customCheck: () =>
        hasPermission('security', 'viewUsers') ||
        hasPermission('security', 'viewRoles'),
    },
  ];

  /**
   * Helper that determines if a tab is visible.
   * If tab has a "viewPerm", we check hasPermission(tab.moduleName, tab.viewPerm).
   * If tab has a "customCheck", we run that. If neither is defined, default to false.
   */
  const canViewTab = (tab) => {
    if (tab.customCheck) {
      return tab.customCheck();
    }
    if (tab.viewPerm) {
      return hasPermission(tab.moduleName, tab.viewPerm);
    }
    return false;
  };

  return (
    <nav className="tab-navigation" aria-label={t('tabNavigation.ariaLabel')}>
      {tabs.map((tab) => {
        if (!canViewTab(tab)) return null; // Hide if user lacks permission
        return (
          <button
            key={tab.name}
            onClick={() => onTabChange(tab.name)}
            className={currentTab === tab.name ? 'active-tab' : ''}
            disabled={isTransitioning}
            aria-current={currentTab === tab.name ? 'page' : undefined}
          >
            {tab.label}
          </button>
        );
      })}
    </nav>
  );
};

export default TabNavigation;
