import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/UserContext'; // Permission checks
import { DataContext } from '../../DataContext';

import GroupsTable from './GroupsTable';
import Spinner from '../Common/Spinner';
import FillOutForm from '../FormBuilder/FillOutForm';
import CustomModal from '../Common/CustomModal';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import ToastContainer from '../Common/ToastContainer';
import ActionButton from '../Common/ActionButton';
import ConfigureFieldsModal from '../Common/ConfigureFieldsModal';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

import {
  generateCSV,
  downloadCSV,
} from '../FormBuilder/CSVUtility';
import { generatePDF } from '../FormBuilder/PDFUtility';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faCog,
  faFileCsv,
  faFilePdf,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import './GroupsPage.css';

const GroupsPage = () => {
  const { t } = useTranslation();

  // Pull from context
  const { userRolesLoading } = useContext(UserContext);
  const { hasPermission } = useContext(AppSettingsContext);
  const {
    useResponses,
    //deleteResponse,
    useFormFields,
    getTableFields,
    saveTableFields,
    appSettings,
  } = useContext(DataContext);

  // Fetch data for Groups
  const {
    data: responses = [],
    isLoading,
    error,
    refetch,
  } = useResponses('groupsForm');

  const {
    data: formFieldsData = {},
    isLoading: formFieldsLoading,
  } = useFormFields('groupsForm');

  // Table fields config
  const [tableFields, setTableFields] = useState([]);
  const [loadingTableFields, setLoadingTableFields] = useState(true);

  // Exporting state
  const [isExportingCSV, setIsExportingCSV] = useState(false);
  const [isExportingPDF, setIsExportingPDF] = useState(false);

  // Modal & Toast state
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');
  const [toasts, setToasts] = useState([]);
  const [showConfigModal, setShowConfigModal] = useState(false);

  // Menu
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);

  // -------------------------------------------
  //    Fetch Table Fields
  // -------------------------------------------
  useEffect(() => {
    const fetchTableFields = async () => {
      try {
        const fields = await getTableFields('groupsForm');
        if (fields && fields.length > 0) {
          setTableFields(fields);
        } else {
          // Provide default fields if none exist
          setTableFields([
            {
              id: 'groupName',
              label: 'Group Name',
              accessor: 'groupName',
              sortable: true,
              type: 'Text',
              hideInMobile: false,
              clickToEdit: false,
            },
            {
              id: 'description',
              label: 'Description',
              accessor: 'description',
              sortable: true,
              type: 'Text',
              hideInMobile: false,
              clickToEdit: false,
            },
            {
              id: 'createdAt',
              label: 'Created At',
              accessor: 'createdAt',
              sortable: true,
              type: 'DatePicker',
              hideInMobile: false,
              clickToEdit: false,
            },
          ]);
        }
      } catch (error) {
        console.error('Error fetching table fields:', error);
      } finally {
        setLoadingTableFields(false);
      }
    };
    fetchTableFields();
  }, [getTableFields]);

  // -------------------------------------------
  //    Build Groups Array
  // -------------------------------------------
  const groups = responses.map((response) => {
    const group = {};
    response.fields.forEach((field) => {
      group[field.id] = field.value;
    });
    group.id = response.id;
    group.fields = response.fields;
    group.submittedAt = response.submittedAt;
    group.createdAt = response.createdAt;
    group.updatedAt = response.updatedAt;
    group.response = response;
    return group;
  });

  // -------------------------------------------
  //    Toast Helpers
  // -------------------------------------------
  const showToast = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // -------------------------------------------
  //    Export Handlers
  // -------------------------------------------
  const handleExportCSV = async () => {
    // Must have exportGroup
    if (!hasPermission('groups', 'exportGroup')) {
      showToast('You do not have permission to export groups.', 'error');
      setMenuVisible(false);
      return;
    }

    if (responses.length === 0) {
      showToast(t('responsesTab.noResponsesToExport'), 'error');
      return;
    }
    setIsExportingCSV(true);

    try {
      const csvContent = generateCSV(responses, formFieldsData.fields);
      downloadCSV(csvContent, 'groups.csv');
      showToast(t('responsesTab.csvExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.csvExportError'), 'error');
      console.error('Error exporting CSV:', error);
    } finally {
      setIsExportingCSV(false);
      setMenuVisible(false);
    }
  };

  const handleExportPDF = async () => {
    // Must have exportGroup
    if (!hasPermission('groups', 'exportGroup')) {
      showToast('You do not have permission to export groups.', 'error');
      setMenuVisible(false);
      return;
    }

    if (responses.length === 0) {
      showToast(t('responsesTab.noResponsesToExport'), 'error');
      return;
    }
    setIsExportingPDF(true);

    try {
      const groupLogo = appSettings?.profilePicture || null;
      await generatePDF(
        responses,
        formFieldsData.fields,
        false,
        groupLogo,
        t('groupsForm', 'Groups')
      );
      showToast(t('responsesTab.pdfExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.pdfExportError'), 'error');
      console.error('Error exporting PDF:', error);
    } finally {
      setIsExportingPDF(false);
      setMenuVisible(false);
    }
  };

  // -------------------------------------------
  //    Action Handlers
  // -------------------------------------------
  const handleAdd = () => {
    // Must have addGroup
    if (!hasPermission('groups', 'addGroup')) {
      showToast('You are not authorized to add groups.', 'error');
      return;
    }

    setModalContent(
      <FillOutForm
        fields={formFieldsData.fields}
        formId="groupsForm"
        showToast={showToast}
        initialData={null}
        onCancel={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
          refetch();
        }}
      />
    );
    setModalTitle(t('groupsTable.addGroup', 'Add Group'));
    setShowModal(true);
  };

  const handleEdit = (record) => {
    // Check if we can edit or only view
    const canEdit = hasPermission('groups', 'editGroup');

    setModalContent(
      <FillOutForm
        fields={formFieldsData.fields}
        formId="groupsForm"
        showToast={showToast}
        initialData={record}
        responseId={record.id}
        onCancel={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
          refetch();
        }}
        viewMode={!canEdit} // read-only if cannot edit
      />
    );

    setModalTitle(
      canEdit
        ? t('groupsTable.editGroup', { name: record.groupName })
        : t('groupsTable.viewGroup', { name: record.groupName })
    );
    setShowModal(true);
  };

  /*const handleDelete = (record) => {
    // Must have deleteGroup
    if (!hasPermission('groups', 'deleteGroup')) {
      showToast('You are not authorized to delete groups.', 'error');
      return;
    }
    setRecordToDelete(record);
    setDeleteModalMessage(
      t('deleteConfirmation.message', {
        item: record.groupName || '',
      })
    );
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!recordToDelete) return;
    try {
      await deleteResponse('groupsForm', recordToDelete.id);
      showToast(t('groupsTable.recordDeleted'), 'success');
      setShowDeleteModal(false);
      setRecordToDelete(null);
      refetch();
    } catch (error) {
      console.error('Error deleting group:', error);
      showToast(t('groupsTable.deleteError'), 'error');
    }
  };*/

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setRecordToDelete(null);
  };

  const openGroupLink = (record) => {
    // Example "Open" action
    const groupId = record.id;
    const rootUrl = window.location.origin;
    const groupUrl = `${rootUrl}/${groupId}/main`;
    window.open(groupUrl, '_blank');
  };

  // Row-level PDF Export for a single record
  const handleGeneratePdfForSingle = async (record) => {
    // Must have exportGroup
    if (!hasPermission('groups', 'exportGroup')) {
      showToast('You do not have permission to export groups.', 'error');
      return;
    }

    try {
      const groupLogo = appSettings?.profilePicture || null;
      await generatePDF(
        [record.response],
        formFieldsData.fields,
        false,
        groupLogo,
        t('groupsForm', 'Group')
      );
      showToast(t('responsesTab.pdfExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.pdfExportError'), 'error');
      console.error('Error exporting PDF:', error);
    }
  };

  // -------------------------------------------
  //    Row-level Actions
  // -------------------------------------------
  const actions = [
    {
      label: hasPermission('groups', 'editGroup') ? t('edit', 'Edit') : t('view', 'View'),
      icon: hasPermission('groups', 'editGroup') ? 'faEdit' : 'faEye',
      callback: handleEdit,
      variant: 'secondary',
      condition: () => hasPermission('groups', 'viewGroup'), // must at least view
    },
    {
      label: t('open', 'Open'),
      icon: 'faLink',
      callback: openGroupLink,
      variant: 'secondary',
      condition: () => true,
    },
    {
      label: t('exportPDF', 'Export PDF'),
      icon: 'faFilePdf',
      callback: handleGeneratePdfForSingle,
      variant: 'secondary',
      condition: () => hasPermission('groups', 'exportGroup'),
    },
    /*{
      label: t('delete', 'Delete'),
      icon: 'faTrash',
      callback: handleDelete,
      variant: 'danger',
      condition: () => hasPermission('groups', 'deleteGroup'),
    },*/
  ];

  // -------------------------------------------
  //    Configuration Modal
  // -------------------------------------------
  const handleOpenConfigModal = () => {
    // Must have configureGroup
    if (!hasPermission('groups', 'configureGroup')) {
      showToast('You do not have permission to configure groups.', 'error');
      setMenuVisible(false);
      return;
    }
    setShowConfigModal(true);
    setMenuVisible(false);
  };

  const handleSaveConfig = async (configuredFields) => {
    try {
      await saveTableFields('groupsForm', configuredFields);
      setTableFields(configuredFields);
      setShowConfigModal(false);
      showToast(
        t('tableConfigurationSuccessful', 'Table configuration saved successfully'),
        'success'
      );
    } catch (error) {
      console.error('Error saving table configuration:', error);
      showToast(
        t('tableConfigurationFailed', 'Failed to save table configuration'),
        'error'
      );
    }
  };

  // -------------------------------------------
  //    Hide Ellipsis if no configure or export
  // -------------------------------------------
  const canConfigureOrExport =
    hasPermission('groups', 'configureGroup') ||
    hasPermission('groups', 'exportGroup');

  // -------------------------------------------
  //    Detect clicks outside menu to close
  // -------------------------------------------
  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };
    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutsideMenu);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [menuVisible]);

  // -------------------------------------------
  //    Loading / Permission Checks
  // -------------------------------------------
  if (userRolesLoading) {
    return <div className="loading-container">Loading user permissions...</div>;
  }

  // Must have viewGroup
  if (!hasPermission('groups', 'viewGroup')) {
    return (
      <div className="access-denied">
        403 - You do not have permission to view groups.
      </div>
    );
  }

  if (isLoading || loadingTableFields || formFieldsLoading) {
    return (
      <div className="loading-container">
        <Spinner size="100px" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-message">
        {t('groupsPage.errorFetchingGroups')}
      </div>
    );
  }

  // -------------------------------------------
  //    Render
  // -------------------------------------------
  return (
    <div className="groups-page-container">
      {/* Header Container */}
      <div className="header-container">
        <h2>{t('groupsPage.title', 'Groups')}</h2>

        {/* Configuration / Export Menu */}
        {canConfigureOrExport && (
          <div className="config-button">
            <div className="menu-container" ref={menuRef}>
              <ActionButton
                onClick={() => setMenuVisible(!menuVisible)}
                icon="faEllipsisV"
                variant="secondary"
                label={t('moreOptions', 'More Options')}
              />
              {menuVisible && (
                <div className="dropdown-menu">
                  {/* Configure button if user has "configureGroup" */}
                  {hasPermission('groups', 'configureGroup') && (
                    <button onClick={handleOpenConfigModal}>
                      <FontAwesomeIcon icon={faCog} className="menu-icon" />
                      {t('configureTableFields', 'Configure Table Fields')}
                    </button>
                  )}

                  {/* Export CSV / PDF if user has "exportGroup" */}
                  {hasPermission('groups', 'exportGroup') && (
                    <>
                      <button
                        onClick={handleExportCSV}
                        disabled={isExportingCSV}
                      >
                        <FontAwesomeIcon icon={faFileCsv} className="menu-icon" />
                        {t('exportCSV', 'Export CSV')}
                        {isExportingCSV && (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            className="menu-spinner"
                          />
                        )}
                      </button>
                      <button
                        onClick={handleExportPDF}
                        disabled={isExportingPDF}
                      >
                        <FontAwesomeIcon icon={faFilePdf} className="menu-icon" />
                        {t('exportPDF', 'Export PDF')}
                        {isExportingPDF && (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            className="menu-spinner"
                          />
                        )}
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <GroupsTable
        formId="groupsForm"
        fields={tableFields}
        actions={actions}
        groups={groups}
        // Hide the “Add New Group” button if user lacks addGroup
        addGroup={hasPermission('groups', 'addGroup') ? handleAdd : undefined}
        onRowClick={handleEdit}
      />

      {/* Add/Edit Modal */}
      <CustomModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
        }}
        title={modalTitle}
      >
        {modalContent}
      </CustomModal>

      {/* Delete Confirmation Modal */}
      {/*showDeleteModal && (
        <DeleteConfirmationModal
          show={showDeleteModal}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          title={t('deleteConfirmation.title')}
          message={deleteModalMessage}
        />
      )*/}

      {/* Configure Fields Modal */}
      {showConfigModal && (
        <ConfigureFieldsModal
          show={showConfigModal}
          onClose={() => setShowConfigModal(false)}
          onSave={handleSaveConfig}
          availableFields={formFieldsData.fields}
          currentConfig={tableFields}
        />
      )}

      {/* Toast Notifications */}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

export default GroupsPage;
