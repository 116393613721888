import React, { useEffect, useMemo, useState, useContext } from 'react';
import './FormTable.css';
import SearchBar from '../Common/SearchBar';
import PaginationControls from '../Common/PaginationControls';
import Table from '../Common/Table';
import ActionButton from '../Common/ActionButton';
import { useTranslation } from 'react-i18next';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

const FormTable = ({
  forms,
  searchTerm,
  setSearchTerm,
  sortField,
  sortOrder,
  setSorting,
  onAddForm,
  onEditForm,
  onCopyForm,
  onDeleteForm,
  onOpenFormUrl,
  onViewResponses,
}) => {
  const { t } = useTranslation();

  // For permissions
  const { hasPermission } = useContext(AppSettingsContext);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Debounce search input
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Filter forms
  const filteredForms = useMemo(() => {
    if (!debouncedSearchTerm) return forms || [];
    const lowercasedTerm = debouncedSearchTerm.toLowerCase();
    return (forms || []).filter((form) => {
      const formName = form.name ? form.name.toLowerCase() : '';
      return formName.includes(lowercasedTerm);
    });
  }, [debouncedSearchTerm, forms]);

  // Pagination
  const totalPages = useMemo(() => {
    return filteredForms.length > 0
      ? Math.ceil(filteredForms.length / itemsPerPage)
      : 0;
  }, [filteredForms.length, itemsPerPage]);

  useEffect(() => {
    if (totalPages === 0 && currentPage !== 1) {
      setCurrentPage(1);
    } else if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  const paginatedForms = useMemo(() => {
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    return filteredForms.slice(startIdx, endIdx);
  }, [filteredForms, currentPage, itemsPerPage]);

  // Table columns
  const columns = [
    {
      id: 'name',
      label: t('formTable.formName'),
      accessor: 'name',
      sortable: true,
    },
    {
      id: 'responseCount',
      label: t('formTable.responseCount'),
      accessor: 'responseCount',
      sortable: true,
    },
    {
      id: 'lastResponseDate',
      label: t('formTable.lastResponseSubmitted'),
      accessor: (row) => {
        if (!row.lastResponseDate) return t('table.noData');
        const date = row.lastResponseDate.toDate
          ? row.lastResponseDate.toDate()
          : new Date(row.lastResponseDate);
        return date.toLocaleString();
      },
      sortable: true,
    },
  ];

  // Row Actions
  const actions = [
    {
      label: t('formTable.edit'),
      icon: 'faPencil',
      callback: onEditForm,
      condition: () => hasPermission('forms', 'editForm'),
      colorType: 'secondary',
    },
    {
      label: t('formTable.copy'),
      icon: 'faCopy',
      callback: onCopyForm,
      condition: () => hasPermission('forms', 'addForm'),
      colorType: 'secondary',
    },
    {
      label: t('formTable.viewResponses'),
      icon: 'faEye',
      callback: onViewResponses,
      condition: () => true, // no extra permission needed for "view responses" beyond "viewForm"
      colorType: 'secondary',
    },
    {
      label: t('formTable.openFormUrl'),
      icon: 'faExternalLinkAlt',
      callback: onOpenFormUrl,
      condition: () => true,
      colorType: 'secondary',
    },
    {
      label: t('formTable.delete'),
      icon: 'faTrash',
      callback: onDeleteForm,
      condition: () => hasPermission('forms', 'deleteForm'),
      colorType: 'danger',
    },
  ];

  // Sorting
  const handleSort = (field) => {
    setSorting(field);
  };

  // Handlers
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="form-table-header">
        <SearchBar
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          placeholder={t('formTable.searchPlaceholder', 'Search forms...')}
        />
        {/* Only show "Add Form" button if user has "addForm" */}
        {hasPermission('forms', 'addForm') && (
          <ActionButton
            onClick={onAddForm}
            label={t('formTable.addNewForm')}
            icon={'faPlus'}
            isMobile={false}
            colorType="primary"
            spacing="10px"
            ariaLabel={t('formTable.addNewFormAriaLabel')}
          />
        )}
      </div>

      <div className="record-count" aria-live="polite">
        {filteredForms.length === 1
          ? t('formTable.recordsFound', { count: filteredForms.length })
          : t('formTable.recordsFound_plural', { count: filteredForms.length })}
      </div>

      <Table
        columns={columns}
        data={paginatedForms}
        actions={actions}
        setSorting={handleSort}
        sortField={sortField}
        sortOrder={sortOrder}
        isMobile={window.innerWidth <= 768}
        noRecordsMessage={t('formTable.noRecords')}
        actionsHeaderLabel={t('actions')}
      />

      {totalPages > 0 && (
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPrev={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          onNext={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          onPageClick={(page) => setCurrentPage(page)}
          itemsPerPage={itemsPerPage}
          itemsPerPageLabel={t('formTable.itemsPerPageLabel', 'Forms per page:')}
          noun={t('formTable.forms')}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};

export default FormTable;
