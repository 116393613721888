import React, { useState, useContext, useEffect, useMemo } from 'react';
import './SecurityPage.css';
import Users from './Users';
import Roles from './Roles';
import { useTranslation } from 'react-i18next';

// Contexts
import { UserContext } from '../../contexts/UserContext';
import { GroupContext } from '../../contexts/GroupContext';
import { UserManagementContext } from '../../contexts/UserManagementContext';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

import TwoPanelLayout from '../Common/TwoPanelLayout';
import UserIcon from '../../assets/UserIcon.png';

const SecurityPage = () => {
  const { t } = useTranslation();

  // 1) HOOKS
  const { hasPermission } = useContext(AppSettingsContext);
  const { userRolesLoading } = useContext(UserContext);
  const { organizationData } = useContext(AppSettingsContext);
  const { groupsData, groupsLoading } = useContext(GroupContext);
  const { getUsersAndRolesByGroupId } = useContext(UserManagementContext);

  // Permission checks
  const canViewUsers = hasPermission('security', 'viewUsers');
  const canViewRoles = hasPermission('security', 'viewRoles');

  // Are we "org-level" or "group-level"?
  const userIsOrgLevel =
    organizationData?.organizationId &&
    organizationData.organizationId === organizationData.groupId;

  // If group-level => default to organizationData.groupId
  // If org-level => default to null
  const [selectedGroupId, setSelectedGroupId] = useState(() =>
    userIsOrgLevel ? null : organizationData?.groupId || null
  );

  const [activeTab, setActiveTab] = useState('users'); // or 'roles'
  const [groupUsers, setGroupUsers] = useState([]);
  const [groupRoles, setGroupRoles] = useState([]);
  const [groupRolesMap, setGroupRolesMap] = useState({});
  const [loadingGroupData, setLoadingGroupData] = useState(false);

  // We'll keep a boolean flag to ensure we only auto-select once (org-level)
  const [didAutoSelectOrgGroup, setDidAutoSelectOrgGroup] = useState(false);

  // Sort the groups with the main org ID on top (only if org-level)
  const sortedGroups = useMemo(() => {
    if (!userIsOrgLevel || !groupsData) return [];
    const copy = [...groupsData];
    const orgId = organizationData?.organizationId || '';
    copy.sort((a, b) => {
      const aIsOrg = a.id === orgId;
      const bIsOrg = b.id === orgId;
      if (aIsOrg && !bIsOrg) return -1;
      if (bIsOrg && !aIsOrg) return 1;
      return (a.groupName || '').localeCompare(b.groupName || '');
    });
    return copy;
  }, [userIsOrgLevel, groupsData, organizationData?.organizationId]);

  // Switch default tab if user can/cannot view certain sections
  useEffect(() => {
    if (activeTab === 'users' && !canViewUsers && canViewRoles) {
      setActiveTab('roles');
    } else if (activeTab === 'roles' && !canViewRoles && canViewUsers) {
      setActiveTab('users');
    }
  }, [activeTab, canViewUsers, canViewRoles]);

  // For org-level: auto-select the first group exactly once
  useEffect(() => {
    if (
      userIsOrgLevel &&
      !didAutoSelectOrgGroup &&
      sortedGroups.length > 0 &&
      selectedGroupId === null
    ) {
      setDidAutoSelectOrgGroup(true);
      handleGroupClick(sortedGroups[0].id);
    }
  }, [userIsOrgLevel, didAutoSelectOrgGroup, sortedGroups, selectedGroupId]);

  // For group-level: if there's a defaultGroupId and we haven't set anything yet
  useEffect(() => {
    if (!userIsOrgLevel && organizationData?.groupId) {
      handleGroupClick(organizationData.groupId);
    }
  }, [userIsOrgLevel, selectedGroupId, organizationData?.groupId]);

  // If either user roles or groups are loading, show a spinner
  if (userRolesLoading || groupsLoading) {
    return (
      <div className="loading-container">
        {t('loadingPermissions', 'Loading...')}
      </div>
    );
  }

  // If they cannot view either users or roles, show an access-denied
  if (!canViewUsers && !canViewRoles) {
    return (
      <div className="access-denied">
        403 - {t('securityPage.noViewPermission', 'No permission to view security.')}
      </div>
    );
  }

  // The function for switching groups
  const handleGroupClick = async (grpId) => {
    setSelectedGroupId(grpId);
    setLoadingGroupData(true);
    try {
      const { users, roles, rolesMap } = await getUsersAndRolesByGroupId(grpId);
      setGroupUsers(users);
      setGroupRoles(roles);
      setGroupRolesMap(rolesMap);
    } catch (err) {
      // Error silently handled or console.warn if needed
    } finally {
      setLoadingGroupData(false);
    }
  };

  // We'll pass this callback to <Roles> or <Users> so they can refresh after CRUD ops
  const refreshGroupData = async () => {
    if (!selectedGroupId) return;
    await handleGroupClick(selectedGroupId);
  };

  // Left panel content for org-level
  const leftPanelContent = userIsOrgLevel ? (
    <div className="groups-section">
      <h4>{t('securityPage.groupsHeader', 'All Groups')}</h4>
      <ul className="left-groups-list">
        {sortedGroups.length === 0 ? (
          <li className="group-item">
            <em>{t('securityPage.noGroupsFound', 'No groups found.')}</em>
          </li>
        ) : (
          sortedGroups.map((grp) => (
            <li
              key={grp.id}
              className={grp.id === selectedGroupId ? 'group-item selected' : 'group-item'}
              onClick={() => handleGroupClick(grp.id)}
            >
              <img
                src={grp.profilePicture || UserIcon}
                alt={grp.groupName || 'group'}
                className="group-thumbnail"
                style={{ backgroundColor: grp.color || 'var(--main-color)' }}
              />
              <span>{grp.groupName || '[Unnamed Group]'}</span>
            </li>
          ))
        )}
      </ul>
    </div>
  ) : null;

  // Right panel: tabs + either <Users> or <Roles>
  const rightPanelContent = (
    <div className="security-right-panel-content">
      <div className="security-page-tabs">
        {canViewUsers && (
          <button
            className={`security-tab-button ${activeTab === 'users' ? 'active' : ''}`}
            onClick={() => setActiveTab('users')}
          >
            {t('securityPage.usersTab', 'Users')}
          </button>
        )}
        {canViewRoles && (
          <button
            className={`security-tab-button ${activeTab === 'roles' ? 'active' : ''}`}
            onClick={() => setActiveTab('roles')}
          >
            {t('securityPage.rolesTab', 'Roles')}
          </button>
        )}
      </div>

      <div className="security-page-content">
        {!selectedGroupId && userIsOrgLevel && (
          <div className="no-group-selected">
            {t('securityPage.selectGroupPrompt', 'Please select a group on the left.')}
          </div>
        )}

        {selectedGroupId && activeTab === 'users' && canViewUsers && (
          <Users
            selectedGroupId={selectedGroupId}
            groupUsers={groupUsers}
            groupRolesMap={groupRolesMap}
            loadingGroupData={loadingGroupData}
            onRefreshGroup={refreshGroupData}
          />
        )}
        {selectedGroupId && activeTab === 'roles' && canViewRoles && (
          <Roles
            selectedGroupId={selectedGroupId}
            groupRoles={groupRoles}
            loadingGroupData={loadingGroupData}
            onRefreshGroup={refreshGroupData}
          />
        )}
      </div>
    </div>
  );

  if (userIsOrgLevel) {
    return (
      <div className="security-page-container">
        <TwoPanelLayout leftPanel={leftPanelContent} rightPanel={rightPanelContent} />
      </div>
    );
  } else {
    // group-level => single-column
    return (
      <div className="security-page-container single-column">
        {rightPanelContent}
      </div>
    );
  }
};

export default SecurityPage;
