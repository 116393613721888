import React from 'react';
import PropTypes from 'prop-types';
import './SearchBar.css';
import Spinner from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ searchTerm, onSearchChange, placeholder, isLoading }) => {
  if (isLoading) {
    return (
      <div className="search-bar-container spinner-container">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="search-bar-container">
      <div className="search-icon-container">
        <FontAwesomeIcon icon={faSearch} className="search-icon" />
      </div>
      <input
        type="text"
        placeholder={placeholder}
        className="search-bar"
        value={searchTerm}
        onChange={onSearchChange}
        aria-label={placeholder}
      />
    </div>
  );
};

SearchBar.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
};

SearchBar.defaultProps = {
  placeholder: 'Search...',
  isLoading: false,
};

export default React.memo(SearchBar);
