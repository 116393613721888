import React, { useState, useEffect, useContext } from 'react';
import { serverTimestamp } from 'firebase/firestore';
import FormTable from '../FormBuilder/FormTable';
import { DataContext } from '../../DataContext';
import { UserContext } from '../../contexts/UserContext'; // <--- for hasPermission
import { useTranslation } from 'react-i18next';
import Spinner from '../Common/Spinner';
import CustomModal from '../Common/CustomModal';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import ToastContainer from '../Common/ToastContainer';
import FormManager from '../FormBuilder/FormManager';
import './FormsPage.css';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';

const FormsPage = () => {
  const { t } = useTranslation();
  // Pull from DataContext
  const {
    sortedForms,
    allFormsLoading,
    allFormsError,
    sortField,
    sortOrder,
    setSorting,
    deleteForm,
    refetchAllForms,
    saveForm,
    organizationData,
    appSettings,
  } = useContext(DataContext);

  // Pull from UserContext for permissions
  const { userRolesLoading } = useContext(UserContext);
  const { hasPermission } = useContext(AppSettingsContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [showFormManagerModal, setShowFormManagerModal] = useState(false);
  const [formToEdit, setFormToEdit] = useState(null);
  const [activeTabForFormManager, setActiveTabForFormManager] = useState('general');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formToDelete, setFormToDelete] = useState(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');
  const [toasts, setToasts] = useState([]);

  const showToastMessage = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // -------------------------------------------
  // Fetch all forms on mount
  // -------------------------------------------
  useEffect(() => {
    refetchAllForms();
  }, [refetchAllForms]);

  // -------------------------------------------
  //  Permission / Loading checks
  // -------------------------------------------
  if (userRolesLoading) {
    return <div className="loading-container">Loading user permissions...</div>;
  }

  // If user cannot view forms at all, block the page
  if (!hasPermission('forms', 'viewForm')) {
    return (
      <div className="access-denied">
        403 - You do not have permission to view forms.
      </div>
    );
  }

  // Loading and Error
  if (allFormsLoading) {
    return (
      <div className="loading-container">
        <Spinner size="100px" />
      </div>
    );
  }
  if (allFormsError) {
    return (
      <div className="error-message">
        {t('formTable.fetchError', { message: allFormsError.message })}
      </div>
    );
  }

  // -------------------------------------------
  //  Handlers
  // -------------------------------------------
  const handleOpenFormUrl = (form) => {
    const formUrl = `${window.location.origin}/${organizationData.groupId}/forms/${form.id}`;
    window.open(formUrl, '_blank');
  };

  const handleViewResponses = (form) => {
    // Just let them view the form. 
    // "View responses" is still a read operation, so we assume "viewForm" is enough.
    setFormToEdit(form);
    setActiveTabForFormManager('responses');
    setShowFormManagerModal(true);
  };

  const handleAddForm = () => {
    // Must have addForm
    if (!hasPermission('forms', 'addForm')) {
      showToastMessage(t('formTable.noAddPermission', 'You do not have permission to add forms.'), 'error');
      return;
    }
    setFormToEdit(null);
    setActiveTabForFormManager('general');
    setShowFormManagerModal(true);
  };

  const handleEditForm = (form) => {
    // Must have editForm
    if (!hasPermission('forms', 'editForm')) {
      showToastMessage(t('formTable.noEditPermission', 'You do not have permission to edit forms.'), 'error');
      return;
    }
    setFormToEdit(form);
    setActiveTabForFormManager('general');
    setShowFormManagerModal(true);
  };

  const handleDeleteForm = (form) => {
    // Must have deleteForm
    if (!hasPermission('forms', 'deleteForm')) {
      showToastMessage(t('formTable.noDeletePermission', 'You do not have permission to delete forms.'), 'error');
      return;
    }
    setFormToDelete(form);
    setDeleteModalMessage(
      t('deleteConfirmation.message', {
        item: form.name,
      })
    );
    setShowDeleteModal(true);
  };

  const handleCopyForm = async (form) => {
    // Copying a form effectively creates a new form => need addForm
    if (!hasPermission('forms', 'addForm')) {
      showToastMessage(t('formTable.noAddPermission', 'You do not have permission to add/copy forms.'), 'error');
      return;
    }
    try {
      const newFormName = prompt(t('formTable.enterNewFormName'), `${form.name} Copy`);
      if (!newFormName) return;

      const newFormData = {
        ...form,
        name: newFormName,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      delete newFormData.id;

      await saveForm(null, newFormData);

      showToastMessage(t('formTable.formCopied', { name: newFormName }), 'success');
      refetchAllForms();
    } catch (error) {
      console.error('Error copying form:', error);
      showToastMessage(t('formTable.copyFormError'), 'error');
    }
  };

  const handleConfirmDelete = async () => {
    if (!formToDelete) return;

    // Protect built-in forms
    const protectedFormIds = ['membersForm', 'groupsForm'];
    if (protectedFormIds.includes(formToDelete.id)) {
      showToastMessage(
        t('formTable.cannotDeleteProtectedForm', { name: formToDelete.name }),
        'error'
      );
      setFormToDelete(null);
      setShowDeleteModal(false);
      return;
    }

    try {
      await deleteForm(formToDelete.id);
      showToastMessage(
        t('formTable.formDeleted', { name: formToDelete.name }),
        'success'
      );
      setFormToDelete(null);
      setShowDeleteModal(false);
      refetchAllForms();
    } catch (error) {
      console.error('Error deleting form:', error);
      showToastMessage(t('formTable.deleteFormError'), 'error');
    }
  };

  const handleCancelDelete = () => {
    setFormToDelete(null);
    setShowDeleteModal(false);
  };

  const handleFormUpdate = (updatedForm) => {
    refetchAllForms();
    showToastMessage(
      updatedForm.id ? t('formTable.formSaved') : t('formTable.formCreated'),
      'success'
    );
    setShowFormManagerModal(false);
  };

  // -------------------------------------------
  //  Render
  // -------------------------------------------
  return (
    <div className="forms-page-container">
      {/* Header Container */}
      <div className="header-container">
        <h2>{t('formsPage.title', 'Forms')}</h2>
      </div>

      <FormTable
        forms={sortedForms}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        sortField={sortField}
        sortOrder={sortOrder}
        setSorting={setSorting}
        onAddForm={handleAddForm}
        onEditForm={handleEditForm}
        onCopyForm={handleCopyForm}
        onDeleteForm={handleDeleteForm}
        onOpenFormUrl={handleOpenFormUrl}
        onViewResponses={handleViewResponses}
      />

      {/* FormManager Modal */}
      <CustomModal
        show={showFormManagerModal}
        onClose={() => setShowFormManagerModal(false)}
        title={formToEdit ? t('formTable.editForm') : t('formTable.createForm')}
      >
        <FormManager
          initialData={formToEdit}
          onUpdate={handleFormUpdate}
          initialActiveTab={activeTabForFormManager}
        />
      </CustomModal>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmationModal
          show={showDeleteModal}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          title={t('deleteConfirmation.title')}
          message={deleteModalMessage}
          isMobile={false}
        />
      )}

      {/* Toasts */}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

export default FormsPage;
