// src/components/Teams/TeamForm.jsx

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './TeamForm.css';
import { useTranslation } from 'react-i18next';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';
import { useColorSettings } from '../Common/ColorSettingsContext';

import Spinner from '../Common/Spinner';
import Select from 'react-select';
import TextField from '../FormBuilder/Fields/TextField';
import ActionButton from '../Common/ActionButton';
import SearchBar from '../Common/SearchBar';
import Table from '../Common/Table';
import PaginationControls from '../Common/PaginationControls';

import ToggleButton from '../Common/ToggleButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';

const TeamForm = ({
  name,
  setName,
  members,
  setMembers,
  leaders,
  setLeaders,
  isMobile,
  onSubmit,
  isEditing,
  membersData,
}) => {
  const { t } = useTranslation();
  const { loading: languageLoading } = useLanguageSettings();
  const { loading: colorLoading } = useColorSettings();

  const [searchTerm, setSearchTerm] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(20);
  const [tempSelectedOptions, setTempSelectedOptions] = React.useState([]);

  // Filter out members that are already selected and then sort by full name
  const availableMemberOptions = useMemo(() => {
    const notSelected = membersData.filter((m) => !members.includes(`${m.groupId}_${m.id}`));
    // Sort by name
    notSelected.sort((a, b) => {
      const nameA = (a.firstName + ' ' + a.lastName).toLowerCase();
      const nameB = (b.firstName + ' ' + b.lastName).toLowerCase();
      return nameA.localeCompare(nameB);
    });

    return notSelected.map((member) => ({
      value: `${member.groupId}_${member.id}`,
      label: `${member.firstName} ${member.lastName}`,
      photoURL: member.profilePicture || '',
      groupId: member.groupId,
      firstName: member.firstName,
      lastName: member.lastName,
    }));
  }, [membersData, members]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: state.isSelected ? '#fff' : '#333',
      backgroundColor: state.isSelected
        ? 'var(--main-color)'
        : state.isFocused
        ? '#f0f0f0'
        : '#fff',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'var(--main-color)',
      color: '#fff',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#fff',
      ':hover': {
        backgroundColor: '#c82333',
        color: '#fff',
      },
    }),
  };

  const formatOptionLabel = ({ label, photoURL }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className="team-form-photo-wrapper" style={{ marginRight: '10px' }}>
        {photoURL ? (
          <img src={photoURL} alt={label} className="team-form-photo" />
        ) : (
          <div className="team-form-no-photo">
            <i className="fas faUser" />
          </div>
        )}
      </div>
      <span>{label}</span>
    </div>
  );

  const selectedTempMembers = useMemo(() => {
    return availableMemberOptions.filter((opt) => tempSelectedOptions.includes(opt.value));
  }, [tempSelectedOptions, availableMemberOptions]);

  const handleAddMembers = () => {
    const newMembers = Array.from(new Set([...members, ...tempSelectedOptions]));
    setMembers(newMembers);
    setTempSelectedOptions([]);
  };

  const selectedMembersData = useMemo(() => {
    return membersData.filter((m) => members.includes(`${m.groupId}_${m.id}`));
  }, [members, membersData]);

  // Add leader field and sort by leader then name
  const selectedMembersWithLeader = useMemo(() => {
    const annotated = selectedMembersData.map((m) => ({
      ...m,
      leader: leaders.includes(`${m.groupId}_${m.id}`),
    }));
    annotated.sort((a, b) => {
      if (a.leader && !b.leader) return -1;
      if (!a.leader && b.leader) return 1;

      const nameA = (a.firstName + ' ' + a.lastName).toLowerCase();
      const nameB = (b.firstName + ' ' + b.lastName).toLowerCase();
      return nameA.localeCompare(nameB);
    });
    return annotated;
  }, [selectedMembersData, leaders]);

  const filteredTableData = useMemo(() => {
    const lowerSearch = searchTerm.toLowerCase();
    return selectedMembersWithLeader.filter((member) => {
      const fullName = `${member.firstName} ${member.lastName}`.toLowerCase();
      return fullName.includes(lowerSearch);
    });
  }, [searchTerm, selectedMembersWithLeader]);

  const handleToggleLeader = (member) => {
    const key = `${member.groupId}_${member.id}`;
    setLeaders((prevLeaders) =>
      prevLeaders.includes(key)
        ? prevLeaders.filter((l) => l !== key)
        : [...prevLeaders, key]
    );
  };

  const columns = [
    {
      id: 'photo',
      label: t('profilePicture'),
      accessor: (member) => member.profilePicture,
      type: 'ImagePicker',
      sortable: false,
    },
    {
      id: 'fullName',
      label: t('teamForm.table.name'),
      accessor: (member) => `${member.firstName} ${member.lastName}`,
      sortable: true,
    },
  ];

  const actions = [
    {
      customRender: (member) => {
        const key = `${member.groupId}_${member.id}`;
        const isLeader = leaders.includes(key);
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <ToggleButton
              isActive={isLeader}
              onToggle={() => handleToggleLeader(member)}
              icon={<FontAwesomeIcon icon={faCrown} />}
              ariaLabel="Toggle leader status"
            />
            <ActionButton
              onClick={() => {
                setMembers((prev) => prev.filter((m) => m !== key));
                setLeaders((prev) => prev.filter((l) => l !== key));
              }}
              icon="faTrash"
              colorType="danger"
              text=""
              ariaLabel={t('teamForm.table.remove')}
            />
          </div>
        );
      },
    },
  ];

  const totalItems = filteredTableData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage) || 1;

  if (currentPage > totalPages && totalPages > 0) {
    setCurrentPage(totalPages);
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredTableData.slice(startIndex, endIndex);

  const handlePrev = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNext = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const handlePageClick = (pageNumber) => setCurrentPage(pageNumber);
  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  const isLoading = colorLoading || languageLoading;

  return (
    <form className="teams-modal-form" onSubmit={onSubmit}>
      {isLoading ? (
        <div className="teams-form-loading">
          <Spinner size="40px" />
        </div>
      ) : (
        <>
          <div className="teams-input-group">
            <TextField
              label={t('teamForm.nameLabel')}
              name="name"
              value={name}
              onChange={(value) => setName(value)}
              required
              placeholder={t('teamForm.namePlaceholder')}
              size="full"
            />
          </div>

          <div className="teams-input-group">
            <label>{t('teamForm.membersLabel')}</label>
            <div className="teams-member-select-row">
              <div className="select-wrapper">
                <Select
                  isMulti
                  options={availableMemberOptions}
                  value={availableMemberOptions.filter((o) => tempSelectedOptions.includes(o.value))}
                  onChange={(selectedOptions) => {
                    const values = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setTempSelectedOptions(values);
                  }}
                  placeholder={t('teamForm.membersPlaceholder')}
                  styles={customStyles}
                  formatOptionLabel={formatOptionLabel}
                  classNamePrefix="react-select"
                />
              </div>

              <ActionButton
                onClick={handleAddMembers}
                icon="faPlus"
                colorType="primary"
                text=""
                ariaLabel={t('teamForm.addMembersAria')}
              />
            </div>
          </div>

          <div className="teams-input-group">
            <label style={{ fontWeight: 'bold' }}>
              {t('teamForm.selectedMembersLabel')}
            </label>
            <div className="search-wrapper">
              <SearchBar
                searchTerm={searchTerm}
                onSearchChange={(e) => setSearchTerm(e.target.value)}
                placeholder={t('teamForm.membersSearchPlaceholder')}
              />
            </div>

            <Table
              data={paginatedData}
              columns={columns}
              actions={actions}
              noRecordsMessage={t('teamForm.membersNoRecords')}
              actionsHeaderLabel={t('actions')}
              sortField={null}
              sortOrder="asc"
              setSorting={() => {}}
            />

            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              onPrev={handlePrev}
              onNext={handleNext}
              onPageClick={handlePageClick}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
              itemsPerPageOptions={[20, 50, 100]}
              itemsPerPageLabel={t('teamForm.itemsPerPageLabel', 'Members per page:')}
              previousLabel={t('pagination.previous', 'Previous')}
              nextLabel={t('pagination.next', 'Next')}
              pageLabel={t('pagination.pageLabel', 'Page')}
            />
          </div>

          <div className="teams-submit-button-container">
            <ActionButton
              type="submit"
              label={isEditing ? t('teamForm.updateButton') : t('teamForm.saveButton')}
              text={isEditing ? t('teamForm.updateButton') : t('teamForm.saveButton')}
              icon="faSave"
              isMobile={isMobile}
              colorType="primary"
              ariaLabel={
                isEditing ? t('teamForm.updateButtonAria') : t('teamForm.saveButtonAria')
              }
            />
          </div>
        </>
      )}
    </form>
  );
};

TeamForm.propTypes = {
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
  setMembers: PropTypes.func.isRequired,
  leaders: PropTypes.array.isRequired,
  setLeaders: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  membersData: PropTypes.array.isRequired,
};

export default TeamForm;
