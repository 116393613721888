// src/components/Policies/TermsService.jsx

import React from 'react';
import './TermsService.css'; // Updated CSS file for styling
import CatholicoreLogo from '../../assets/CatholicoreLogo.png';
import { Link } from 'react-router-dom';

const TermsService = () => {
  return (
    <div className='terms-service-background'>
      <div className="terms-service-container">
        <header className="terms-service-header">
          <img className="terms-service-logo" src={CatholicoreLogo} alt="Catholicore Logo" />
          <h1>Terms of Service</h1>
          <h2>Catholicore</h2>
        </header>

        <div className="terms-service-content">
          <div className="service-dates">
            <p><strong>Effective Date:</strong> December 26th, 2024</p>
            <p><strong>Last Updated:</strong> January 17th, 2025</p>
          </div>

          <article>
            <h3>Introduction</h3>
            <p>
              Thank you for choosing Catholicore, a dedicated platform for managing Catholic parishes and
              religious groups. These Terms of Service (“Terms”) govern your access to and use of the
              Catholicore website and services (collectively, “Services”). By accessing or using our Services,
              you agree to be bound by these Terms and our <Link to="/privacy-policy">Privacy Policy</Link>.
            </p>
          </article>

          <article>
            <h3>Using Our Services</h3>
            <ul>
              <li>
                <strong>Eligibility:</strong> You must be at least 18 years old to use our Services. By
                agreeing to these Terms, you represent and warrant that you are of legal age to form a
                binding contract with Catholicore.
              </li>
              <li>
                <strong>Account Registration:</strong> To access certain features of our Services, you may
                need to register an account. You agree to provide accurate, current, and complete information
                during the registration process and to update such information to keep it accurate, current,
                and complete.
              </li>
              <li>
                <strong>User Responsibilities:</strong> You are responsible for maintaining the confidentiality
                of your account credentials and for all activities that occur under your account.
              </li>
              <li>
                <strong>Prohibited Activities:</strong> You agree not to use the Services for any illegal or
                unauthorized purpose. This includes, but is not limited to, transmitting harmful or disruptive
                content, infringing on intellectual property rights, or attempting to gain unauthorized
                access to our systems.
              </li>
            </ul>
          </article>

          <article>
            <h3>Subscription and Billing</h3>
            <ul>
              <li>
                <strong>Subscription Plans:</strong> Catholicore offers various subscription plans to access
                premium features. Details of available plans are provided on our website.
              </li>
              <li>
                <strong>Billing:</strong> By subscribing to a paid plan, you agree to provide accurate billing
                information and authorize Catholicore to charge the specified fees on a recurring basis.
              </li>
              <li>
                <strong>Cancellation:</strong> You may cancel your subscription at any time through your
                account settings. Upon cancellation, you will retain access to the Services until the end
                of your current billing cycle.
              </li>
              <li>
                <strong>Refund and Dispute Policy:</strong> All subscription fees paid to Catholicore
                are <u>non-refundable</u>. If you believe you have been charged in error or wish to dispute
                a charge, please contact us at <a href="mailto:info@catholicore.com">info@catholicore.com</a>
                within 30 days of the transaction. We will investigate any claims and provide a resolution
                in accordance with our internal policies and applicable law.
              </li>
            </ul>
          </article>

          {/* Removed the Donations section entirely */}

          <article>
            <h3>Content and Intellectual Property</h3>
            <ul>
              <li>
                <strong>User Content:</strong> You retain ownership of all content you submit to Catholicore.
                By submitting content, you grant us a worldwide, non-exclusive, royalty-free license to use,
                copy, modify, distribute, and display your content in connection with providing the Services.
              </li>
              <li>
                <strong>Intellectual Property:</strong> All intellectual property rights in Catholicore,
                including but not limited to software, design, logos, and trademarks, are owned by Catholicore
                or its licensors. You agree not to reproduce, distribute, or create derivative works based
                on our intellectual property without explicit permission.
              </li>
            </ul>
          </article>

          <article>
            <h3>Termination</h3>
            <p>
              We reserve the right to terminate or suspend your access to the Services immediately, without
              prior notice or liability, for any reason, including if you breach these Terms.
            </p>
          </article>

          <article>
            <h3>Disclaimers and Limitation of Liability</h3>
            <ul>
              <li>
                <strong>Disclaimers:</strong> Our Services are provided on an “as is” and “as available” basis.
                Catholicore disclaims all warranties, express or implied, including but not limited to the
                implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
              </li>
              <li>
                <strong>Limitation of Liability:</strong> To the fullest extent permitted by law, Catholicore
                shall not be liable for any indirect, incidental, special, consequential, or punitive damages,
                or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of
                data, use, goodwill, or other intangible losses resulting from (a) your access to or use of or
                inability to access or use the Services; (b) any conduct or content of any third party on the
                Services; or (c) unauthorized access, use, or alteration of your transmissions or content.
              </li>
            </ul>
          </article>

          <article>
            <h3>Indemnification</h3>
            <p>
              You agree to defend, indemnify, and hold harmless Catholicore and its affiliates, officers,
              agents, and employees from and against any claims, liabilities, damages, losses, and expenses,
              including reasonable attorneys’ fees, arising out of or in any way connected with your access
              to or use of the Services, or your violation of these Terms.
            </p>
          </article>

          <article>
            <h3>Governing Law</h3>
            <p>
              These Terms shall be governed by and construed in accordance with the laws of Connecticut, without
              regard to its conflict of law provisions.
            </p>
          </article>

          <article>
            <h3>Changes to Terms</h3>
            <p>
              We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
              We will notify you of any changes by updating the “Last Updated” date at the top of these
              Terms and, in some cases, we may provide additional notice (such as adding a statement
              to our homepage or sending you a notification). Your continued use of the Services after such
              changes constitutes acceptance of the new Terms.
            </p>
          </article>

          <article>
            <h3>Severability</h3>
            <p>
              If any provision of these Terms is found to be unenforceable or invalid, that provision will be
              limited or eliminated to the minimum extent necessary, and the remaining provisions will remain
              in full force and effect.
            </p>
          </article>

          <article>
            <h3>Entire Agreement</h3>
            <p>
              These Terms constitute the entire agreement between you and Catholicore regarding your use of
              the Services and supersede all prior agreements, understandings, and representations.
            </p>
          </article>

          <article>
            <h3>Contact Us</h3>
            <p>
              If you have any questions or concerns about these Terms, please contact us at
              <a href="mailto:info@catholicore.com"> info@catholicore.com</a>.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default TermsService;
