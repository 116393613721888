// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { useQueryClient } from '@tanstack/react-query';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Minimal tracked user (optional)
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserLoading, setCurrentUserLoading] = useState(true);

  const queryClient = useQueryClient();

  useEffect(() => {
    // This listener can be used for invalidating queries, etc.
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setCurrentUserLoading(false);

      if (user) {
        // example: re-fetch any queries that need auth
        queryClient.invalidateQueries(['members', 'formGroups']);
      } else {
        // remove or reset queries
        queryClient.removeQueries(['members', 'formGroups']);
      }
    });
    return () => unsubscribe();
  }, [queryClient]);

  // AUTH METHODS: signIn, signOut, password resets, etc.

  const signIn = async (email, password) => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      return true;
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  };

  const verifyPasswordResetCode = async (code) => {
    try {
      await auth.verifyPasswordResetCode(code);
      return true;
    } catch (error) {
      console.error('Error verifying password reset code:', error);
      throw error;
    }
  };

  const confirmPasswordReset = async (oobCode, newPassword) => {
    try {
      await auth.confirmPasswordReset(oobCode, newPassword);
      return true;
    } catch (error) {
      console.error('Error confirming password reset:', error);
      throw error;
    }
  };

  const sendPasswordResetEmail = async (email, logoImage, accentColor) => {
    const CLOUD_FUNCTION_URL = 'https://sendresetpasswordemail-lgfph5hmwq-uc.a.run.app';
    try {
      const response = await fetch(CLOUD_FUNCTION_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, logoImage, accentColor }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Server Error: ${errorText}`);
      }

      const data = await response.json();
      console.log('Password reset email sent:', data.message);
      return true;
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  };

  const getCurrentUserEmail = () => {
    return auth.currentUser ? auth.currentUser.email : null;
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentUserLoading,
        signIn,
        signOut,
        sendPasswordResetEmail,
        confirmPasswordReset,
        verifyPasswordResetCode,
        getCurrentUserEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
