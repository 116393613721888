import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './TimePicker.css';

const CustomTimePicker = ({ label, name, value, onChange, placeholder, required, error }) => {
  const [localError, setLocalError] = useState(error);

  useEffect(() => {
    setLocalError(error);
  }, [error]);

  const handleTimeChange = (time) => {
    onChange(time);
    if (required && !time) {
      setLocalError('This field is required.');
    } else {
      setLocalError('');
    }
  };

  const handleBlur = () => {
    if (required && !value) {
      setLocalError('This field is required.');
    }
  };

  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {required && <span className="required-asterisk">*</span>}
      </label>
      <ReactDatePicker
        id={name}
        selected={value}
        onChange={handleTimeChange}
        onBlur={handleBlur}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="HH:mm"
        placeholderText={placeholder}
        className={`form-control ${localError ? 'input-error' : ''}`}
        isClearable
        aria-invalid={localError ? 'true' : 'false'}
        aria-describedby={localError ? `${name}-error` : undefined}
      />
      {localError && <span id={`${name}-error`} className="error-message">{localError}</span>}
    </div>
  );
};

CustomTimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
};

CustomTimePicker.defaultProps = {
  name: '',
  value: null,
  placeholder: '',
  required: false,
  error: '',
};

export default CustomTimePicker;
