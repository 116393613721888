// src/contexts/AppSettingsContext.js

import React, {
  createContext,
  useEffect,
  useState,
  useContext,
} from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { firestore } from '../firebase';
import { defaultColors, defaultLanguage, defaultLogo } from '../config';

// 1) Import the UserContext
import { UserContext } from './UserContext';

export const AppSettingsContext = createContext();

/**
 * AppSettingsProvider
 * - Observes `groupID` (from props)
 * - Exposes organizationData { groupId, organizationId }
 * - Fetches group doc => color, language, profilePicture, etc.
 */
export const AppSettingsProvider = ({ children, groupID }) => {
  const queryClient = useQueryClient();

  // 2) Local state to track the "active" group and organization
  const [organizationData, setOrganizationData] = useState({
    groupId: groupID || '',
    organizationId: '',
  });

  // 3) Whenever parent changes groupID, update local state
  useEffect(() => {
    setOrganizationData((prev) => ({
      ...prev,
      groupId: groupID || '',
      // organizationId will be set once we fetch the group doc
    }));
  }, [groupID]);

  // 4) React Query to fetch the group doc => store color/language => also store organizationId
  const {
    data: appSettingsData,
    isLoading: appSettingsLoading,
    error: appSettingsError,
    refetch: refetchAppSettings,
  } = useQuery({
    queryKey: ['appSettings', organizationData.groupId || 'no-group'],
    queryFn: async () => {
      const { groupId } = organizationData;
      if (!groupId) {
        // Return default settings if no group
        return {
          profilePicture: defaultLogo.main,
          color: defaultColors.main,
          language: defaultLanguage.main,
          organizationId: '',
        };
      }

      // Fetch the group doc
      const groupDocRef = doc(firestore, 'groups', groupId);
      const snapshot = await getDoc(groupDocRef);
      if (!snapshot.exists()) {
        // If no group doc => default
        return {
          profilePicture: defaultLogo.main,
          color: defaultColors.main,
          language: defaultLanguage.main,
          organizationId: '',
        };
      }

      const data = snapshot.data();
      return {
        profilePicture: data.profilePicture ?? defaultLogo.main,
        color: data.color ?? defaultColors.main,
        language: data.language ?? defaultLanguage.main,
        // If the doc has an "organizationId" reference, store that ID
        organizationId: data.organizationId?.id || '',
      };
    },
    keepPreviousData: true,
    enabled: !!firestore, // only run if Firestore is ready
  });

  // 5) Update local organizationData once we have appSettingsData
  useEffect(() => {
    if (!appSettingsData) return;
    setOrganizationData((prev) => ({
      ...prev,
      organizationId: appSettingsData.organizationId || '',
    }));
  }, [appSettingsData]);

  // 6) Mutation for updating app settings in Firestore
  const updateAppSettingsMutation = useMutation({
    mutationFn: async (newSettings) => {
      const { groupId } = organizationData;
      if (!groupId) {
        throw new Error('No active groupId to update settings for.');
      }
      const docRef = doc(firestore, 'groups', groupId);
      await setDoc(docRef, newSettings, { merge: true });
    },
    onMutate: async (newSettings) => {
      const key = ['appSettings', organizationData.groupId || 'no-group'];
      await queryClient.cancelQueries(key);

      const previousAppSettings = queryClient.getQueryData(key);
      queryClient.setQueryData(key, (old) => ({
        ...old,
        ...newSettings,
      }));

      return { previousAppSettings };
    },
    onError: (err, newSettings, context) => {
      const key = ['appSettings', organizationData.groupId || 'no-group'];
      if (context?.previousAppSettings) {
        queryClient.setQueryData(key, context.previousAppSettings);
      }
      console.error('Error updating settings:', err);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['appSettings', organizationData.groupId || 'no-group']);
    },
  });

  const updateSettings = async (newSettings) => {
    await updateAppSettingsMutation.mutateAsync(newSettings);
  };

  /**
   * 7) Example: upload a new group logo
   */
  const uploadLogo = async (logoFile, onProgress) => {
    const { groupId } = organizationData;
    if (!groupId) {
      throw new Error('No groupId set for uploading logo.');
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.onload = async () => {
          const canvas = document.createElement('canvas');
          const maxWidth = 800;
          const maxHeight = 800;

          let width = img.width;
          let height = img.height;

          if (width > height && width > maxWidth) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
          } else if (height > maxHeight) {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          const compressedBase64 = canvas.toDataURL('image/png', 0.7);

          try {
            const docRef = doc(firestore, 'groups', groupId);
            await setDoc(docRef, { profilePicture: compressedBase64 }, { merge: true });
            resolve(compressedBase64);
          } catch (error) {
            reject(error);
          }
        };
        img.onerror = reject;
        img.src = event.target.result;
      };

      reader.onerror = reject;
      reader.onprogress = (e) => {
        if (e.lengthComputable && onProgress) {
          const progress = (e.loaded / e.total) * 100;
          onProgress(progress);
        }
      };

      reader.readAsDataURL(logoFile);
    });
  };

  // 8) Now bring in the hasPermission from UserContext, and wrap it
  const { hasPermission: userHasPermission } = useContext(UserContext);

  /**
   * Optional convenience function in AppSettingsContext that calls userHasPermission.
   * If the caller doesn't provide a groupId, we can default to organizationData.groupId
   */
  const hasPermission = (moduleName, permissionName, groupId) => {
    const effectiveGroupId = groupId || organizationData.groupId;
    return userHasPermission(moduleName, permissionName, effectiveGroupId);
  };

  return (
    <AppSettingsContext.Provider
      value={{
        organizationData,
        setOrganizationData,
        appSettings: appSettingsData,
        appSettingsLoading,
        appSettingsError,
        refetchAppSettings,
        updateSettings,
        uploadLogo,
        // Expose our newly added hasPermission
        hasPermission,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
};

export default AppSettingsProvider;
