// src/components/Authentication/BillingGuard.jsx

import React, { useContext, useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import NotFound from '../../NotFound';
import { AppSettingsContext } from '../../contexts/AppSettingsContext';
import { UserContext } from '../../contexts/UserContext';

/**
 * BillingGuard checks if the current :groupId matches
 * the main organization's ID. If not, it shows <NotFound />
 * (or <Navigate to="/unauthorized" />).
 * 
 * Also uses a 1-sec delayed initialization to match
 * the logic in ProtectedRoute.
 */
const BillingGuard = ({ children }) => {
  const { groupId } = useParams();

  // Grab authInitialized from UserContext
  const { authInitialized } = useContext(UserContext);

  // 1-second delayed init
  const [delayedInitialized, setDelayedInitialized] = useState(false);

  useEffect(() => {
    if (!authInitialized) {
      setDelayedInitialized(false);
    } else {
      const timer = setTimeout(() => {
        setDelayedInitialized(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [authInitialized]);

  // Also get the organizationData from context
  const { organizationData } = useContext(AppSettingsContext);

  // If not delayedInitialized or org data not loaded, show nothing or a loader
  if (!delayedInitialized || !organizationData) {
    return null;
  }

  // Compare param groupId with the main org's ID (adjust field if different)
  const mainOrgId = organizationData.organizationId;

  if (groupId !== mainOrgId) {
    // Show NotFound or navigate to Unauthorized
    return <Navigate to="/unauthorized" replace />;
    // or: return <NotFound />;
  }

  // Otherwise, allow access
  return children;
};

export default BillingGuard;
