// src/components/Unauthorized/Unauthorized.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconMap from './components/Common/IconMap';
import CatholicoreLogo from './assets/CatholicoreLogo.png';
import './Unauthorized.css';

export default function Unauthorized() {
  const handleLoginClick = () => {
    // Optionally redirect to a login page or any page you want
    window.location.href = '/login';
  };

  return (
    <div className="unauth-split-container">
      <div className="unauth-left-panel">
        <div className="unauth-left-logo">
          <img src={CatholicoreLogo} alt="Catholicore Logo" />
        </div>
        <div className="unauth-left-content">
          <FontAwesomeIcon icon={IconMap.faLock} className="unauth-lock-icon" />
          <h2 className="unauth-title">Unauthorized</h2>
          <p className="unauth-message">
            You do not have permission to view this page.
          </p>
          <button className="unauth-button" onClick={handleLoginClick}>
            Go to Login
          </button>
        </div>
      </div>
      <div className="unauth-right-panel">
        <div className="unauth-right-content">
          <h3>Need Assistance?</h3>
          <p>
            If you believe you've reached this page in error, please contact
            support or your organization’s admin to verify your access.
          </p>
        </div>
      </div>
    </div>
  );
}
