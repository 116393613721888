// src/contexts/FormContext.js
import React, { createContext, useContext, useState, useMemo } from 'react';
import { firestore } from '../firebase';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  orderBy,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  getCountFromServer,
  Timestamp,
  limit,
} from 'firebase/firestore';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AppSettingsContext } from './AppSettingsContext';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const { organizationData } = useContext(AppSettingsContext) || {};
  // Safely extract groupId and organizationId
  const groupId = organizationData?.groupId;
  const organizationId = organizationData?.organizationId;

  const queryClient = useQueryClient();
  const [sortField, setSortField] = useState('fullName'); // Default sort field
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

  /**
   * QUERY: Fetch forms under 'formGroups/{groupId}/forms'
   */
  const {
    data: sortedForms,
    isLoading: allFormsLoading,
    error: allFormsError,
    refetch: refetchAllForms,
  } = useQuery({
    // Provide a fallback for the queryKey so we don't pass `undefined`.
    queryKey: ['formGroups', groupId || 'no-group', 'forms'],

    // Only run this query if we have both groupId and organizationId
    enabled: !!firestore && !!groupId && !!organizationId,

    // The function will only be called if `enabled` is true
    queryFn: async () => {
      // If for some reason groupId is missing, return empty array
      if (!groupId) return [];

      // Reference to formGroups/{groupId}/forms
      const formsRef = collection(firestore, 'formGroups', groupId, 'forms');
      // NOTE: The "orderBy('createdAt')" usage with getDocs needs a query builder
      // but your code as written is mostly okay as a simplified example:
      const snapshot = await getDocs(formsRef);

      const fetchedForms = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // For each form, fetch the response count + last response date
      const formsWithStats = await Promise.all(
        fetchedForms.map(async (form) => {
          const responsesCollectionRef = collection(
            firestore,
            'formGroups',
            groupId,
            'forms',
            form.id,
            'responses'
          );

          // Get the count of responses
          const countSnapshot = await getCountFromServer(responsesCollectionRef);
          const responseCount = countSnapshot.data().count;

          // Last response date
          const lastResponseSnapshot = await getDocs(
            responsesCollectionRef,
            orderBy('submittedAt', 'desc'),
            limit(1)
          );
          let lastResponseDate = null;
          if (!lastResponseSnapshot.empty) {
            const lastResponseDoc = lastResponseSnapshot.docs[0];
            lastResponseDate = lastResponseDoc.data().submittedAt;
          }

          return {
            ...form,
            responseCount,
            lastResponseDate,
          };
        })
      );

      return formsWithStats;
    },
    onError: (err) => {
      console.error('Error fetching forms:', err);
    },
  });

  /**
   * Memo: Sort forms client-side
   */
  const sortedFormsData = useMemo(() => {
    if (!sortedForms) return [];

    const sorted = [...sortedForms].sort((a, b) => {
      const aField = a[sortField];
      const bField = b[sortField];

      // Handle null or undefined fields
      if (aField == null && bField == null) return 0;
      if (aField == null) return sortOrder === 'asc' ? -1 : 1;
      if (bField == null) return sortOrder === 'asc' ? 1 : -1;

      // Strings
      if (typeof aField === 'string' && typeof bField === 'string') {
        return sortOrder === 'asc'
          ? aField.localeCompare(bField)
          : bField.localeCompare(aField);
      }

      // Timestamps
      if (aField instanceof Timestamp && bField instanceof Timestamp) {
        return sortOrder === 'asc'
          ? aField.toMillis() - bField.toMillis()
          : bField.toMillis() - aField.toMillis();
      }

      // Numbers (e.g. responseCount)
      if (typeof aField === 'number' && typeof bField === 'number') {
        return sortOrder === 'asc' ? aField - bField : bField - aField;
      }

      // Fallback
      if (aField < bField) return sortOrder === 'asc' ? -1 : 1;
      if (aField > bField) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    return sorted;
  }, [sortedForms, sortField, sortOrder]);

  // Set sorting parameters
  const setSorting = (field) => {
    if (sortField === field) {
      // Toggle asc/desc
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  /**
   * fetchFormDetails: read a single form doc
   */
  const fetchFormDetails = async (formId) => {
    if (!formId) {
      throw new Error('fetchFormDetails called without a formId.');
    }
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);
      const formSnapshot = await getDoc(formDocRef);

      if (formSnapshot.exists()) {
        return { id: formId, ...formSnapshot.data() };
      } else {
        console.warn(`Form with ID ${formId} does not exist in group ${groupId}.`);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching form details for formId ${formId}:`, error);
      throw error;
    }
  };

  /**
   * saveForm: create or update a form under formGroups/{groupId}/forms/{formId}
   */
  const saveForm = async (formId, formData) => {
    if (!groupId) {
      throw new Error('Group ID is missing in organizationData.');
    }

    try {
      if (formId) {
        // update existing
        const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);
        await updateDoc(formDocRef, {
          ...formData,
          updatedAt: serverTimestamp(),
        });
      } else {
        // create new
        const formCollection = collection(firestore, 'formGroups', groupId, 'forms');
        await addDoc(formCollection, {
          ...formData,
          groupId,
          organizationId,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
      }

      // Invalidate queries so we refetch
      queryClient.invalidateQueries(['formGroups', groupId, 'forms']);
    } catch (error) {
      console.error('Error saving form:', error);
      throw error;
    }
  };

  /**
   * deleteForm: remove a form doc
   */
  const deleteForm = async (formId) => {
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    // Protected forms you cannot delete
    const protectedFormIds = ['membersForm', 'groupsForm'];
    if (protectedFormIds.includes(formId)) {
      throw new Error('This form cannot be deleted.');
    }

    try {
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);
      await deleteDoc(formDocRef);

      // Invalidate queries so we refetch
      queryClient.invalidateQueries(['formGroups', groupId, 'forms']);
    } catch (error) {
      console.error('Error deleting form:', error);
      throw error;
    }
  };

  /**
   * getFormFields: fetch a single form doc (fields)
   */
  const getFormFields = async (formId, customGroupId) => {
    if (!formId) {
      throw new Error('Form ID is required to fetch form fields.');
    }

    const safeGroupId = customGroupId || groupId;
    if (!safeGroupId) {
      throw new Error('No groupId found in organizationData or passed as an argument.');
    }

    try {
      const formDocRef = doc(firestore, 'formGroups', safeGroupId, 'forms', formId);
      const formSnapshot = await getDoc(formDocRef);

      if (formSnapshot.exists()) {
        return formSnapshot.data();
      } else {
        throw new Error(`Form with ID ${formId} does not exist under group ${safeGroupId}.`);
      }
    } catch (error) {
      console.error(`Error fetching form fields for formId ${formId}:`, error);
      throw error;
    }
  };

  /**
   * useFormFields: a custom hook for React Query
   */
  const useFormFields = (formId, customGroupId) => {
    const safeGroupId = customGroupId || groupId;
    return useQuery({
      queryKey: ['formFields', safeGroupId, formId],
      queryFn: () => getFormFields(formId, customGroupId),
      enabled: !!formId && !!safeGroupId,
      staleTime: 5 * 60 * 1000,
    });
  };

  /**
   * getTableFields: retrieve a form's tableFields
   */
  const getTableFields = async (formId) => {
    if (!formId) {
      throw new Error('Form ID is required to get table fields.');
    }
    if (!groupId) {
      throw new Error('No group ID found in organizationData.');
    }

    try {
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);
      const formSnapshot = await getDoc(formDocRef);

      if (formSnapshot.exists()) {
        const formData = formSnapshot.data();
        return formData.tableFields || [];
      } else {
        console.warn(`Form with ID ${formId} does not exist in group ${groupId}.`);
        return [];
      }
    } catch (error) {
      console.error('Error fetching table fields:', error);
      throw error;
    }
  };

  /**
   * saveTableFields: update a form's tableFields
   */
  const saveTableFields = async (formId, tableFields) => {
    if (!formId) {
      throw new Error('Form ID is required to save table fields.');
    }
    if (!groupId) {
      throw new Error('No group ID found in organizationData.');
    }

    try {
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);
      await updateDoc(formDocRef, {
        tableFields,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error saving table fields:', error);
      throw error;
    }
  };

  return (
    <FormContext.Provider
      value={{
        // Data
        sortedForms: sortedFormsData || [],
        allFormsLoading,
        allFormsError,
        refetchAllForms,
        // Sorting
        sortField,
        sortOrder,
        setSorting,
        // Form-related methods
        fetchFormDetails,
        saveForm,
        deleteForm,
        getFormFields,
        useFormFields,
        getTableFields,
        saveTableFields,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider;
