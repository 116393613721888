// src/components/Common/Table.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './Table.css';
import ActionButton from './ActionButton';
import ToggleButton from './ToggleButton'; // NEW: import ToggleButton
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortUp,
  faSortDown,
  faSort,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Table = ({
  data,
  columns,
  actions,
  setSorting,
  sortField,
  sortOrder,
  noRecordsMessage,
  actionsHeaderLabel,
  onRowClick,
}) => {
  const { t } = useTranslation();

  const formatDate = (value) => {
    const date = new Date(value);
    return isNaN(date.getTime()) ? value : date.toLocaleString();
  };

  const formatDateOnly = (value) => {
    const date = new Date(value);
    return isNaN(date.getTime()) ? value : date.toLocaleDateString();
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  const camelCaseToNormal = (text) => {
    if (!text) return '';
    const result = text
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase());
    return result;
  };

  const renderFieldValue = (field, record) => {
    const { accessor, type, render } = field;

    // If there's a custom render prop, use it
    if (render) {
      return render(record);
    }

    // Otherwise, derive the value from 'accessor'
    let value;
    if (typeof accessor === 'function') {
      value = accessor(record);
    } else if (Array.isArray(accessor)) {
      // If array of fields
      if (type === 'Checkbox') {
        // e.g. "multi-checkbox" situation
        value = record[accessor[0]];
      } else {
        value = accessor
          .map((key) => record[key] || '')
          .join(' ')
          .trim();
      }
    } else if (typeof accessor === 'string') {
      value = record[accessor];
    } else {
      value = '';
    }

    // If it's an array, handle specially
    if (Array.isArray(value)) {
      if (type === 'Checkbox') {
        // Possibly an array of subPermissions?
        return value
          .filter((option) => option.completed)
          .map((option) => {
            const optionLabel = camelCaseToNormal(
              t(option.name, option.name)
            );
            return optionLabel;
          })
          .join(', ');
      }
      return value.join(', ');
    }

    // Otherwise handle formatting by field type
    let formattedValue;
    switch (type) {
      case 'ImagePicker':
        formattedValue = (
          <img
            src={value || '/default-profile.png'}
            alt={`${record.firstname || 'Image'}`}
            className="profile-picture"
          />
        );
        break;
      case 'DatePicker':
        formattedValue = isValidDate(new Date(value))
          ? formatDateOnly(value)
          : value?.toString();
        break;
      case 'DateTimePicker':
      case 'TimePicker':
        formattedValue = isValidDate(new Date(value))
          ? formatDate(value)
          : value?.toString();
        break;
      case 'RangeSlider':
      case 'Number':
        formattedValue = value?.toString();
        break;
      case 'RadioButton':
      case 'Select':
        // Translate the display of the selected option if desired
        formattedValue = value ? t(value.toString(), value.toString()) : '';
        break;
      case 'ToggleSwitch':
        // Might store a boolean
        formattedValue = value ? t('yes') : t('no');
        break;
      case 'TextArea':
      case 'Text':
      default:
        formattedValue =
          value !== null && value !== undefined ? value.toString() : '';
        break;
    }

    // If "clickToEdit" is true, make the field clickable
    if (field.clickToEdit && onRowClick) {
      return (
        <span
          className="clickable-value"
          onClick={() => onRowClick(record)}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onRowClick(record);
            }
          }}
        >
          {formattedValue}
        </span>
      );
    }

    return formattedValue;
  };

  return (
    <div className="table-wrapper">
      <table className="generic-table">
        <thead>
          <tr>
            {/* Render table headers */}
            {columns.map((column) => (
              <th
                key={column.id}
                className={`
                  ${column.sortable ? 'sortable' : ''}
                  ${column.hideInMobile ? 'hide-in-mobile' : ''}
                `}
                onClick={() => {
                  if (column.sortable) {
                    setSorting(column.id);
                  }
                }}
              >
                {t(column.id, column.label)}
                {column.sortable && (
                  <span className="sort-indicator">
                    {sortField === column.id ? (
                      sortOrder === 'asc' ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} />
                    )}
                  </span>
                )}
              </th>
            ))}

            {/* If there are actions, add an Actions column */}
            {actions && actions.length > 0 && <th>{actionsHeaderLabel}</th>}
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((record) => (
              <tr key={record.id || `${record.groupId}_${record.id}`}>
                {/* Render each field's value */}
                {columns.map((column) => (
                  <td
                    key={column.id}
                    className={column.hideInMobile ? 'hide-in-mobile' : ''}
                  >
                    {renderFieldValue(column, record)}
                  </td>
                ))}

                {/* Render action buttons, if provided */}
                {actions && actions.length > 0 && (
                  <td className="actions-cell">
                    {actions.map((action, index) => {
                      // Only render if condition is not present or condition(record) is true
                      if (
                        typeof action.condition === 'function' &&
                        !action.condition(record)
                      ) {
                        return null;
                      }

                      // If there's a customRender function, use it directly
                      if (action.customRender) {
                        return (
                          <React.Fragment key={index}>
                            {action.customRender(record)}
                          </React.Fragment>
                        );
                      }

                      // Otherwise, standard ActionButton
                      const isDisabled = action.disabledAccessor
                        ? action.disabledAccessor(record)
                        : false;

                      const href = action.hrefAccessor
                        ? action.hrefAccessor(record)
                        : undefined;

                      return (
                        <ActionButton
                          key={index}
                          onClick={() => !href && action.callback(record)}
                          label={action.label}
                          icon={action.icon}
                          variant={action.variant || 'default'}
                          as={action.as || 'button'}
                          href={href}
                          disabled={isDisabled}
                        />
                      );
                    })}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={
                  columns.length + (actions && actions.length > 0 ? 1 : 0)
                }
              >
                {noRecordsMessage || 'No records found.'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      accessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.string),
      ]).isRequired,
      sortable: PropTypes.bool,
      type: PropTypes.string,
      render: PropTypes.func,
      clickToEdit: PropTypes.bool,
    })
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      callback: PropTypes.func,
      variant: PropTypes.string,
      condition: PropTypes.func,       // <-- We now properly use this
      as: PropTypes.string,
      hrefAccessor: PropTypes.func,
      disabledAccessor: PropTypes.func,
      customRender: PropTypes.func,
    })
  ),
  setSorting: PropTypes.func,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  noRecordsMessage: PropTypes.string,
  actionsHeaderLabel: PropTypes.string,
  onRowClick: PropTypes.func,
};

Table.defaultProps = {
  actions: [],
  sortField: null,
  sortOrder: 'asc',
  noRecordsMessage: 'No records found.',
  actionsHeaderLabel: 'Actions',
  onRowClick: null,
  setSorting: () => {},
};

export default Table;
